<template>
  <v-footer color="surface-variant" >
    <v-row justify="center" no-gutters>
      
      <v-col class="text-center mt-4">
        <a href="https://www.easyestate.jp/aboutus.html" class="aLinkColor" target="_blank">運営会社</a>
        /
        <a href="https://www.easyestate.jp/agreement.html" class="aLinkColor" target="_blank">利用規約</a>
        /
        <a href="https://www.easyestate.jp/law.html" class="aLinkColor" target="_blank">特定商取引に関する法律に基づく表記</a>
        /
        <a href="https://www.easyestate.jp/privacy.html" class="aLinkColor" target="_blank">個人情報保護方針</a>
        /
        <a href="https://www.easyestate.jp/security.html" class="aLinkColor" target="_blank">セキュリティ</a>
        /
        <a class="aLinkColor" @click="toInquirtySupport">{{$t('theFooter.footerText')}}</a>
      </v-col>
      <v-col class="text-center mt-4 footRowBox" cols="12">
        <a class="bottomquestion aLinkColor" href="https://www.easyestate.jp/" target="_blank">https://www.easyestate.jp</a>&nbsp;&nbsp;&nbsp;&nbsp;<br class="laScreen">
        {{$t('theFooter.ContactInformation')}}： <span>03-6709-1353</span>&nbsp;&nbsp;&nbsp;&nbsp;<br class="laScreen">
        {{$t('theFooter.email')}}： <a class="bottomquestion aLinkColor" href="mailto:info@easyestate.jp">info@easyestate.jp</a>&nbsp;&nbsp;&nbsp;&nbsp;<br class="laScreen">
        
      </v-col>
      <v-col class="text-center" cols="12">©2023 Easyestate.Jp.Co.,Ltd. All rights reserved.</v-col>
      <v-col class="text-center" cols="12">R4 事業再構築シス-1</v-col>
      <v-col class="text-center" cols="12">※事業再構築補助金事業以外での使用禁止</v-col>
    </v-row>
  </v-footer>
</template>

<script lang="ts">
import { defineComponent,reactive, computed, onMounted, defineProps } from 'vue'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router';
export default defineComponent({
  name: 'TheFooter',
  props: {
    isLogin: Boolean,
  },
  setup(props) {
    console.log(props);
    const router = useRouter()
    const route = useRoute();
    const toInquirtySupport = () => {
        router.push({ path: '/inquirtySupport'}); 
    }
    return {
        props,
        toInquirtySupport
    }
  },
});

</script>
<style>
  .bottomquestion{
    cursor: pointer;
    color: #007bff;
  }
  .bottomquestion:hover{
    color: blue;
  }
  .aLinkColor{
    cursor: pointer;
    color: white;
    text-decoration: none;
  }
  .aLinkColor:hover{
    color: rgba(255, 255, 255, 0.8);
  }
  .footRowBox{
    margin-bottom: 20px;
  }
</style>
