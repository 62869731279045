const en = {
    languageType:{
        Japanese:'Japanese',
        Chinese:'Chinese',
        English:'English'
    },
    unitMessage:{
        month:'month',
    },
    LoginMessage:{
      title:'Login',
      registerCard:{
        title:'If you have not yet registered',
        content:'Once you log in and register, you can share your favorite properties, search history, etc. on your PC, smartphone, or app.',
        button:'Sign up'
      },
      loginCard:{
        title:'Login',
        email:'Email',
        password:'Password',
        forgetButton:'For those who have forgotten their password',
      },
      forgetMessage:{
        title:'Reset password',
        sendButton:'Send verification code',
        certification:'Verification code',
        email:'Email',
        password:'Password',
        repassword:'Password confirmation',
        changeButton:'Reset',
        toLoginMessage:{
          message:'If you have an account,',
          button:'Please login'
        },     

      },
    },
    RegisterMessage:{
      title1:'New registration (Input)',
      title2:'New registration (Confirmation)',
      title3:'New registration (Verification code)',
      title4:'New registration (Registration completed)',
      registerStep1:{
        title:'Enter login information',
        userName:'Username',
        userNameHint:'Please enter your username',
        email:'Email',
        emailHint:'Please enter your Email',
        password:'Password',
        passwordHint:'Please enter at least 8 characters (uppercase, lowercase, and numbers)',
        repassword:'Password (For confirmation)',
        agreementMessage:{
          first:'CLOUDPF規約',
          second:'個人情報の取り扱いについて',
          last:'I agree.'
        },
        agreeButton:'Agree and proceed to "Confirmation"',
      },
      registerStep2:{
        title:'Content confirmation',
        userName:'Username',
        email:'Email',
        password:'Password',
        passwordMessage:'Passwords are hidden for security.',
        changeButton:'Correct input contents',
        sendButton:'Send verification code'
      },
      registerStep3:{
        title:'Verification code input',
        certification:'Verification code',
        confirmationButton:'Confirmation',
      },
      registerStep4:{
        title:'Completion of registration',
        message:'Registration has been completed.。',
        confirmationButton:'Confirmation',
      },
    },
    messageproprty: {
        roomNo:'room number',
         propertyTypeLarge:'item',
         commission: 'rent',
         managementFee :'administrative fee',
         securityDeposit :'cash pledge',
         keyMoney:'gratuity',
         floorPlan :'plane arrangement',
         exclusiveArea :'Dedicated area',
         buildingDate :'Date of construction',
         address_other :'Total number of layers/layers',
         propertyAddress :'address',
         trafficMeans:'transportation',
         SupportLanguage:'※Languages supported: Japanese, Chinese, English',
         buttonName:{
           consult:'consult',
           FavoritesLogin:'Add To Favorites',
           Favorited:'Already Favorited',
           appointment:'appointment',
           initialcost:'initial cost',
           apply:'apply',
           login:'login'
         }
    },
    AppliedPropertyMessage:{
        titleMessage:'Application',
        CategorySelection:'Application (Select category)',
        importTitle:'Application (input)',
        notarize:'Application (confirmation)',
        finish: 'Application (completed)',
        SelectCategoryCont:{
            productName:'Product Name',
            rentOrsell:'Rent・Sell',
            productType:'Product Type',
            ClassApplicant:'Class of Applicant',
            TypeOfPayment:'Type of Payment',
            CategoryApplication:'Category of Application',
            rent:'Rent',
            sell:'Sell',
            residence:'Residence',
            office:'Office',
            individual:'individual',
            LegalPerson:'Legal Person',
            mortgage:'mortgage',
            onePayment:'one-off payment'
        },
        importTitleCont:'The type of goods applied for',
        appliedDetailTitle:'Details of the application',
        buttonDetailTitle:{
            EnterDetails:'Enter Details',
            ConfirmNext:'Check and proceed to the next step',
            send:'Send',
            ApplicationList:'To Application List',
            ModifyInput:'Modify Input',
            completeTitle:'The housing application is complete!',
            backHomepage:'Back Homepage'
        }
    },
    IndexViewMessage:{
        IndexNavList:{
          MyCollection:'My Collection',
          RecentlyViewed:'Recently Viewed',
          consultationService:'Consultation Service',
          ObjectOfApplication:'Object Of Application',
          makeAppointment:'Make Appointment',
          loanAdministration:'Loan Administration',
          SellOrRent:'Sell Or Rent',
          simulator:'Simulator',
          Search:'Search'
        },
        NewGoodsList:{
            title:'Latest object list',
        },
        payType:{
            fristType:'rental',
            SecondType:'buying'
        }
    },
    theProperty:{
        roomNo:'room number',
        commission: 'rent',
        price:'price',
        payTypeFrist:'Apartment for sale',
        payTypeSecond:'Rental apartment',
        managementFee :'administrative fee',
        securityDeposit :'cash pledge',
        keyMoney:'gratuity',
        floorPlan :'plane arrangement',
        exclusiveArea :'Dedicated area',
        SeeDetails:'View Details',
        ProductPicture:'Product Picture',
        addFavorites:'Favorites',
        ViewPhotos:'View Photos',
        store:'Add To Favorites',
        storeTrue:'Already Favorited'
    },
    PreviewMessage:{
        title:'Viewing Appointment',
        navTitleFrist:'Appointment (Registration)',
        navTitleSecond:'Appointment (confirmed)',
        navTitleThirdly:'Appointment (completed)',
        formTitle:{
          name:'product name',
          email:'Email',
          phone:'phone number',
          dateFrist:'First expected time',
          dateSecond:'Second expected time',
          dateThirdly:'Third expected time',
          ContactInformation:'Contact information'
        },
        lastTitle:'The Viewing Appointment has been sent!'
    },
    InitialCostMessage:{
        title:'initial cost estimation',
        rent:'rent',
        managementFee:'administrative fee',
        securityDeposit:'cash pledge',
        keyMoney:'gratuity',
        BackDetail:'Back To Detail',
        total:'total'
    },
    previewListView:{
        title:'Reservation list',
        previewNumber:'Preview number',
        previewNameTh:"Reservation name", 
        goodsName:'Object name',
        propertyType:'Property type',
        state:'state',
        dateFrist:'First hope date',
        dateSecond:'Second hope date',
        dateThirdly:'Third hope date',
        remark:'descr',
        operation:'operation',
        close:'close',
        timeChange:'Because of time change',
        otherGoods:'Because the client decided to buy another house',
        other:'Other reasons',
    },
    previewDetailView:{
        title:'Viewing reservation details',
        previewNameTh:"Object name",
        email:'Email',
        phone:'phone number',
        dateFrist:'First hope date',
        dateSecond:'Second hope date',
        dateThirdly:'Third hope date',
        requestedApprovalTime:'Confirmed hope date',
        remark:'descr',
        edit:'edit',
        goback:'Back to Reservation List',
        isShow:' confirm',
        falseBut:'cancel',
        propertyDetailBtn:'property detail',
        noTopreview:'cancel the reservation',
        noTopreviewBut:'cancel the reservation',
    },
    appliedPropertyListView:{
        title:'Housing application list',
        appliedNo:'request number',
        goodName:'name of an object',
        propertyType:'Property type',
        state:'state',
        home:'address',
        homeNumber:'Room number・floor',
        DedicatedArea:'Dedicated area'
    },
    AppliedPropertyDetail:{
        title:'Details of the items requested',
        goback:'Enter the application list screen',
        subtitleFrist:'Progress status',
        subtitle:'Current status:',
        serve:'save',
        subtitleSecond:'Registration Details',
        subtitleThirdly:'House details',
        subtitleFourthly:'enterprise information',
        subtitleFristMessage:{
            frist:'submit information',
            second:'audit',
            third:'contract',
          },
          subtitleSecondMessage:{
            applicationForm:'application Form',
            identificationPaper:'identification Paper',
            GuarantorInformation:'Guarantor Information',
            studentIDCard:'student ID Card',
            noticeOfDecision:'notice Of Decision',
            employmentContract:'employment Contract',
            ThreeMonthsSalaryList:'Three MonthsS alary List',
            ThreeMonthsCopyOfPassbook:'Three Months Copy Of Passbook',
            TheBalanceOfTheAccount:'The Balance Of TheAccount',
            incumbencyCertification:'incumbency Certification',
            residentCertificate:'resident Certificate',
            certificate:'certificate',
            taxCertificate:'taxCertificate',
            ResidentCertificateSponsor:'Resident Certificate Sponsor',
            certificateSponsor:'certificate Sponsor',
            taxCertificateSponsor:'taxCertificate Sponsor',
            CertificateCompleteResume:'Certificate Complete Resume',
            companyBrochure:'company Brochure',
            ASetOfAccounts:'ASet Of Accounts',
            proofOfIncome:'proof Of Income',
          },
        subtitleThirdlyMessage:{
            goodsId:'item id',
            goodsName:'item name',
            homeNumber:'Room number・floor',
            ObjectItemLarge:'Object Item(Large)',
            ObjectItemSmall:'Object Item',
            buildName:'build Name',
            address:'address',
            architecturalStructure:'architectural Structure',
            date:'when built',
            vehicle:"vehicle",
            TrafficDistance:'Traffic(Distance)',
            DedicatedArea:'Dedicated Area',
            balcony:'balcony',
            MainLightSurface:'Main Light Surface',
            managementFee:'management Fee',
            price:'price',
            rent:'rent',
            accumulationFund:'accumulation Fund',
            keyMoney:'remuneration',
            securityDeposit :'cash pledge',
            earnestMoney:'earnest Money',
            termOfContract:'term Of Contract',
            drawing:'drawing',
            remark:'remark',
            comment:'comment'
          },
          subtitleFourthlyMessage:{
            id:'Enterprise ID',
            name:'the name of firm',
            phoneNumber:'phone number',
            FixedRestDay:'Fixed Rest Day',
            businessHours:'business Hours'
          },
          customer:{
            customInfo:'Customer details',
            name:'name',
            birth:'birth',
            age:'age',
            sex:'sex',
            phone:'phone',
            email:'email',
            monthlySalary:'monthly Salary',
            maritalStatus:'marital Status',
            nationality:'nationality',
            occupationType:'occupation Type',
            kinds:'kinds',
            address:'address',
            tradeName:'tradeName',
            headOfficeLocation:'headOfficeLocation',
            mainPhone:'mainPhone',
            tenantName:'tenantName',
            tenantBirth:'tenantBirth',
            tenantPhone:'tenantPhone',
            required:'(Required)',
            selectList:{
              man:'man',
              woman:'woman',
              married:'married',
              unmarried:'unmarried',
              CompanyEmployee:'CompanyEmployee',
              SelfEmployed:'SelfEmployed',
              User:'User',
              companies:'companies',
            }
    
          },
          propBox:{
            title:'Cancel application',
            boxBtn:'Cancel application',
            inputLable:'Please enter the reason for cancellation here.'
          }
    },
    favoriteListMessage:{
        title:'My Collection',
        buildingName:'Building Name',
        type:'Type',
        propertyType:'Property type',
        // propertyType:{
        //   rental:'Rental',
        //   buying:'Buying',
        // },
        address:'Address',
        roomNo:'Room Number',
        exclusiveArea:'Exclusive Area',
        control:'Control',
        buttonName:{
          detail:'Detail',
          delete:'Delete',
          goHome:'go Home'
        },
        deleteMessage:{
          title:'Deletion confirmation of designated favorite property',
          text:'Are you sure you want to delete this favorite property?',
          confirm:'Confirm',
          cancel:'Cancel'
        }
    },
      browsedListMessage:{
        title:'Recently Viewed',
        buildingName:'Building Name',
        type:'Type',
        propertyType:'Property type',
        // propertyType:{
        //   rental:'Rental',
        //   buying:'Buying',
        // },
        address:'Address',
        roomNo:'Room Number',
        exclusiveArea:'Exclusive Area',
        browsingTime:'Browsing Time',
        buttonName:{
          goHome:'go Home'
        }
    },
    PropertyDetail:{
        title:{
            goodsMessage:'Object information',
            RentalDtoData:'Occupancy conditions',
            SellingData:'Conditions of contract',
            featureData:'equipment',
            FacilitiesData:'peculiarity',
            StatusProperty:'Status of Property',
            Buysell:'Buy and sell only',
            Others:'Others',
            companyData:'Affiliated company information',
          },
          companyData:{
            companyName:'Company name',
            staffName:'Person in charge name',
            staffEmail:'Person in charge email address',
            staffPhone:'Person in charge phone number'
          },
        util:{
          util1:'without',
          util2:'have',
          util3:'Yes',
          util4:'No',
          util5:'Yes',
          util6:'No',
        }, 
        childenClassTitle:{
          relevantProof:'relevant proof',
          equipmentTitle1:'Bath, toilet and washroom',
          equipmentTitle2:'storage',
          equipmentTitle3:'Equipment and function ',
          equipmentTitle4:'Television and telecommunications ',
          equipmentTitle5:'Balcony garden ',
          equipmentTitle6:'Kitchen. ',
          equipmentTitle7:'Furniture, home appliances, lighting ',
          equipmentTitle8:'Heating and cooling ',
          equipmentTitle9:'Security.  ',
          equipmentTitle10:'Public facilities  ',
          featureTitle1:'feature',
        featureTitle2:'Construction method and structure',
        featureTitle3:'Evaluation and certificate',
        },
        theSlectData:{
          buildingName:'building Name',
          address:'address',
          address_other:'Detailed address',
          advertisingFeePercent:'advertising Fee Percent',
          allowedNumberOfApplicants: 'allowedNumber Of Applicants',
          appeal: 'appeal',
          balconyArea: 'balcony Area',
          buildingDate: "building Date",
          buildingNameOpenStatus: 'building Name Open Status',
          buildingStructure: "building Structure",
          comment: 'comment', 
          commission: 'commission',
          companyId: "company Id",
          companyStaffId: "company Staff Id",
          contractPeriod: 'contract Period',
          controlNumber: 'control Number',
          currentSituation: 'current Situation',
          deposit: 'deposit',
          drawing: 'drawing',
          email: 'email',
          exclusiveArea: "exclusiveArea",
          floorPlan: "floor Plan",
          guaranteeDeposit: 'guarantee Deposit',
          inHouseControlNumber: 'in House Control Number',
          keyMoney: 'key Money',
          landRights: 'land Rights',
          locationOpenStatus: 'location Open status',
          mainLightSurface: 'main Light Surface',
          managementFee: 'management Fee',
          modeOfTransaction: 'mode Of Transaction',
          moveInApplication: 'move In Application',
          moveInSelectDueDate: 'move In Select Due Date',
          moveInStatus: 'move In Status',
          note: 'note',
          preview: 'preview',
          price: 'price',
          propertyFileId: "property File Id",
          propertyId: 'property Id',
          otherTransportation:'other Transportation',
          propertyOpenStatus: 'property Open Status',
          propertyType: "property Type ",
          propertyTypeLarge: "property Type Large",
          propertyTypeSmall: "property Type Small",
          propertyViewingId: 'property Viewing Id',
          roomNo: "room No",
          roomNoOpenStatus: 'room No Open Status',
          roughEstimate: 'rough Estimate',
          scheduledMoveOutDate: 'scheduled Move Out Date',
          securityDeposit: 'security Deposit',
          totalUnits: 'total Units',
          trafficDistance: 'traffic Distance',
          trafficMeans: 'traffic Means',
          useStation1: 'use Station 1',
          useStation2: 'use Station 2',
          workStyleManager: 'work Style Manager',
          newUsedStatus:'New/Used',
  
        },
        RentalDtoData:{
          bed:'bed',
          bicycleParking:'bicycle Parking',
          bikeStorage:'bike Storage',
          parkingFee:'parking Fee',
          bicycleParkingFee:'bicycleParking Fee',
          bikeStorageFee:'bikeStorage Fee',
          commonServiceFee:'common Service Fee',
          companyHousing:'company Housing',
          rentType:'rent Type',
          rentContractPeriod:'contract Period',
          contractPeriod:'contract Period',
          corporateContract:'corporate Contract',
          creditCardPayment:'credit Card Payment',
          deductionFromRentalDepositMoney:'deduction From Rental Deposit Money',
          deductionFromRentalDepositMonth:'deduction From Rental Deposit Month',
          diy:'DIY',
          fixedTermLease:'fixed Term Lease',
          forPaperwork:'for Paperwork',
          foreigner:'foreigner',
          freeRent:'free Rent',
          guaranteeDepositMoney:'guarantee Deposit Money',
          guaranteeDepositMonth:'guarantee Deposit Month',
          guarantor:'guarantor',
          homeInsurance:'home Insurance',
          instrumentConsultation:'instrument Consultation',
          insuranceEtc:'insurance Etc',
          keyExchangeFee:'key Exchange Fee',
          keyMoney:'key Money',
          keyMonth:'key Month',
          managementFee:'management Fee',
          moveInPeriod:'move In Period',
          numberOfFloors:'number Of Floors',
          numberOfOccupants:'number Of Occupants',
          otherLumpSum:'other Lump Sum',
          others:'others',
          parking:'parking',
          renewalFeeMoney:'renewal Fee Money',
          renewalFeeMonth:'renewal Fee Month',
          renewalFeeType:'renewal Fee Type',
          rent:'rent',
          rentalGuarantee:'rental Guarantee',
          roomShare:'room Share',
          roughEstimate:'rough Estimate',
          securityDepositMoney:'security Deposit Money',
          securityDepositMonth:'security Deposit Month',
          seniorCitizen:'senior Citizen',
          sex:'sex',
          smoking:'smoking',
          sohoUse:'soho Use',
          studentsOnly:'students Only',
        },
        SellingData:{
          parkingFee:'parking Fee',
          bicycleParkingFee:'bicycleParking Fee',
          bikeStorageFee:'bikeStorage Fee',
          bicycleParking:'bicycle Parking',
          bikeStorage:'bike Storage',
          deposit:'deposit',
          flat35conformityCertificateAvailable:'flat 35 conformity Certificate Available',
          flat35sconformityCertificate:'flat 35 sconformity Certificate',
          grossYield:'gross Yield',
          landRights:'land Rights',
          legalComplianceSurveyReportAvailable:'legal Compliance Survey Report Available',
          managementFee:'management Fee',
          otherLumpSum:'other Lump Sum',
          parking:'parking',
          pet:'pet',
          plannedAnnualRentalIncome:'planned Annual Rental Income',
          price:'price',
          rentalGuarantee:'rental Guarantee',
          seismicStandardConformityCertificate:'seismic Standard Conformity Certificate',
          specialNote:'special Note',
        },
        featureData:{
          adoptionOfHighSash:'adoption Of High Sash',
          allElectric:'all Electric',
          allRoomsSidesLighting2:'all Rooms Sides Lighting2',
          barrierFree:'barrier Free',
          belsEnergySavingStandardComplianceCertification:'bels Energy Saving Standard Compliance Certification',
          ceilingHeightMore250cm:'ceiling Height More 250cm',
          constructionMethod24:'construction Method 24',
          constructionMethod26:'construction Method 26',
          cornerRoom:'corner Room',
          dampingStructure:'damping Structure',
          designers:'designers',
          doubleFloor:'double Floor',
          doubleFloorDoubleCeiling:'double Floor Double Ceiling',
          doubleHeightSpaceAllocation:'double Height Space Allocation',
          exteriorTiled:'exterior Tiled',
          exteriorWallConcrete:'exterior Wall Concrete',
          exteriorWallSiding:'exterior Wall Siding',
          externalInsulationMethod:'external Insulation Method',
          garbageTakenOutAnyTime:'garbage Taken Out Any Time',
          highStrengthConcrete:'high Strength Concrete',
          highlyAirtightHighlyInsulatedHouse:'highly Airtight Highly Insulated House',
          interiorConcrete:'interior Concrete',
          nonFormaldehyde:'non Formaldehyde',
          outFrameMethod:'out Frame Method',
          propertyCorrespondingToItHeavyTheory:'property Corresponding To It Heavy Theory',
          reverseBeamConstructionMethod:'reverse Beam Construction Method',
          roadHeating:'roadHeating',
          salesType:'sales Type',
          seismicComplianceCertificate:'seismic Compliance Certificate',
          seismicIsolationStructure:'seismic Isolation Structure',
          shareHouse:'share House',
          sidesLighting2:'sides Lighting 2',
          sidesLighting3:'sides Lighting 3',
          skeletonInfill:'skeleton Infill',
          smartHouse:'smart House',
          suitableTwoHouseholds:'suitable Two Households',
          topFloor:'top Floor',
          twentyFourHourVentilationSystem:'twenty Four Hour Ventilation System',
        },
        FacilitiesData:{
          airConditioner:'air Conditioner',
          airConditionerBuiltin:'air Conditioner Builtin',
          airConditionersTwo:'air Conditioners Two',
          autoLockWithMonitor:'autoLock With Monitor',
          automaticBathShower:'automatic Bath Shower',
          balcony:'balcony',
          bathSame:'bathSame',
          bathToiletSame:'bath Toilet Same',
          bathToiletSeparate:'bath Toilet Separate',
          bathroomDryer:'bathroom Dryer',
          bathroomHeating:'bathroom Heating',
          bathroomTv:'bathroom Tv',
          bed:'bed',
          bsTerminal:'bs Terminal',
          burnerStove2:'burner Stove 2 ',
          burnerStove3:'burner Stove 3',
          busAudio:'bus Audio',
          cableBroadcastCs:'cable Broadcast Cs',
          catv:'CATV',
          cityGas:'city Gas',
          cleaningService:'cleaning Service',
          closet:'closet',
          coinLaundry:'coin Laundry',
          cooling:'cooling',
          deliveryBox:'delivery Box',
          dimpleKey:'dimple Key',
          doubleLockDoorAutoLock:'double Lock Door Auto Lock',
          electricStove:'electric Stove',
          elevator:'elevator',
          elevatorsTwoMore:'elevators Two More',
          energySavingWaterHeater:'energy Saving Water Heater',
          ffHeating:'ff Heating',
          floorHeating:'floor Heating',
          floto:'floto',
          freeInternetUsage:'free Internet Usage',
          frontService:'front Service',
          garden:'garden',
          gardenOver10:'garden Over 10',
          gasStovePossible:'gas Stove Possible',
          grill:'grill',
          heating:'heating',
          highTemperatureDifferenceHotwaterType:'high Temperature Difference Hotwater Type',
          hotWaterSupply:'hot Water Supply',
          ihCookingHeater:'ih Cooking Heater',
          indirectLighting:'indirect Lighting',
          indoorAundryRoom:'indoor Aundry Room',
          indoorClothesline:'indoor Clothesline',
          laundryPlace:'laundry Place',
          lightingWithMotionSensor:'lighting With Motion Sensor',
          mmOutletInternetCompatible:'mm Outlet Internet Compatible',
          phone:'phone',
          privateGarden:'private Garden',
          propaneGas:'propane Gas',
          propertyFacilities:'property Facilities',
          refrigerator:'refrigerator',
          reheatingFunction:'reheating Function',
          roofBalcony:'roof Balcony',
          sauna:'sauna',
          saunaMist:'sauna Mist',
          securityGlass:'security Glass',
          sharedRoomGuest:'shared Room Guest',
          sharedRoomParty:'shared Room Party',
          sharedRoomTheater:'shared Room Theater',
          shoesBox:'shoes Box',
          shoesIc:'shoes Ic',
          showerRoom:'shower Room',
          shutter:'shutter',
          storageAllRoom:'storage All Room',
          storageSpace:'storage Space',
          sunroomGreenSide:'sunroom Green Side',
          systemKitchen:'system Kitchen',
          threeSideBalcony:'three Side Balcony',
          twoSideBalcony:'two Side Balcony',
          underfloorStorage:'under floor Storage',
          walkinCloset:'walkin Closet',
          washToilet:'wash Toilet',
          washerDryerClothesDryer:'washer Dryer Clothes Dryer',
          wideBalcony:'wide Balcony',
          withGasStove:'with Gas Stove',
          withHomeAppliances:'with Home Appliances',
          withLightingFixtures:'with Lighting Fixtures',
          woodDeckTerrace:'wood Deck Terrace',
        } 
      },
    SellingLendingAssessment:{
        assessmentList:'Assessment List',
        title:'Assessment Request',
        nameNone:'None',
        navTitleFrist:'Enter Information',
        assessmentDetail:"Assessment request details screen",
        detailButton:'Assessment request details',
        navTitleSecond:'Choose Real Estate Agency',
        navTitleThirdly:'Send',
        lastTitle:'Sent successfully!',
        propertyName:'Property Name',
        status:'Status',
        required:'(Required)',
        way:{
          title:'Entrust Way',
          firstWay:'I want to sell',
          secondWay:'I want to rent'
        },
        propertyInfo :{
          info:'Property information',
          title:'Property Type',
          type:'Property types',
          mansion:'Mansion',
          individualHouse:'Individual House',
          land:'Land',
          others:'Others',
          buildingName:'Building Name',
          buildingAddress:'Building Address',
          roomNumber:'Room Number',
          constructionDate:'Construction Date',
          floorPlan:'Floor Plan',
          buildingArea:'Building Area',
          landArea:'Land Area',
          price:'Price',
          managementFee:'Management Fee',
          reserveFund:'Reserve Fund',
          landOwnership:'Land Ownership',
          contractPeriod:'Contract Period',
          guaranteeDeposit:'Guarantee Deposit',
          keyMoney:'Key Money',
          rentDeposit:'Rent Deposit',
          securityDeposit:'Security Deposit'
        },
        buildingStucture:{
          title:'Building Stucture',
          wood:'wooden',
          block:'Reinforced Concrete Construction',
          steel:'Steel Reinforced Concrete Construction',
          RC:'RC',
          SRC:'SRC',
          PC:'PC',
          HPC:'HPC',
          ALC:'ALC',
          lightweightSteel:'Lightweight Steel',
          other:'Others'
        },
        customerInfo:{
          title:'Customer Information',
          ownerName:'owner Name',
          ownerPhone:'Owner Phone',
          ownerNote:'Notes'
        },
        companyInfo:{
          title:"Proposed information",
          tableHead:{
            RealEstateAgent:'	Real estate agent',
            Status:'Status.',
            proposal:'Proposal',
            SalesContract:'Sales contract',
            ContractDate:'Contract date',
          },
          butList:{
            approval:'approval',
            rejected:'rejected',
          },
          porpBoxText:{
            Comment:'Comment',
            Cancel:'Cancel',
            Confirmed:'Confirmed'
          },
          statusList:{
            InProposal:'In proposal',
            Contract:'Contract',
            elected:'elected',
            Unelected:'Unelected'
          }
        },
        userUploadFiles:{
          addBut:'additional',
          title:'User attachments',
          fileName:'File name',
          comment:'Comment.',
          actions:'operation'
        },
        selectEstateAgent:{
          title:'Select EstateAgent',
          estateAgent:'EstateAgent',
          agent1:'EstateAgent1',
          agent2:'EstateAgent2',
          agent3:'EstateAgent3',
          agent4:'EstateAgent4',
          agent5:'EstateAgent5',
          agent6:'EstateAgent6',
        },
        buttonDetailTitle:{
          selectEstateAgent:'Choose Estate Agency',
          send:'send',
          input:'back to Information',
          backSellingLendingList:'Back to list',
          newAssessment:'Create Assessment',
          goHome:'Go Home',
          download:'download',
          delete:'delete',
          cancel:'cancel',
          upload:'Upload',
      }
    },
    ModelEnumMessage:{
      ApplicationStatus:{
        Application:'Application',
        Inspection:'Approved',
        Contract:'Cancel',
        Complete:'Complete',
      },
      PreviewStatus:{
        Unsettled:'Unsettled',
        ReApplication:'ReApplication',
        Confirmed:'Confirmed',
        Cancel:'Cancel'
      }
    },
    Inquiry:{
      InquiryList:{
        title:'Inquiry List',
        cancelPopupTitle:'Inquiry Cancellation Confirmation',
        cancelPopupMessage:'Confirm to cancel?',
        propertyName:'title',
        status:{
          title:'status',
          underInquiry:'Under Inquiry',
          completed:'Completed',
          cancelled:'Cancelled'
        },
        address:'Address',
        roomNumber:'Room Number',
        inquiryContent:'Inquiry Content',
        actions:{
          title:'Action',
          cancel:'Cancel',
          goHome:'Go Home',
          confirm:'Confirm'
        }
      },
      InquiryDetail:{
        title:'Inquiry Details',
        toInquiriyListTitle:'Inquiry Close Confirm',
        toInquiriyListContent:'Confirm to close this inquiry?',
        cancelTitle:'Inquiry Cancel Confirm',
        cancelContent:'Confirm to cancel this inquiry?',
        notes:'Notes',
        name:'Name',
        mailAddress:'E-mail Address',
        phoneNumber:'Phone Number',
        chat:{
          chatHistory:'Chat History',
          me:'Me',
        },
        actions:{
          toInquiriyList:'To Inquiriy List',
          close:'Close',
          cancel:'Cancel',
          confirm:'Confirm',
          send:'Send'
        }
      }
    },
    RentalManagement:{
      rentalManagementList:'Rental Management List',
      propertyName:'Property Name',
      propertyInfo:'Property Information',
      owner:'Owner',
      manager:'Manager',
      roomStatus:'Room Status',
      roomNumber:'Room Number',
      address:'Address',
      managementStartDate:'Management Start Date',
      owenerInfoDetail:'Owener Information Detail',
      managerInfo:'Manager Information',
      name:'Name',
      katakana:'Katakana',
      phone:'Phone Number',
      mail:'E-mail',
      date:'Date',
      title:'Title',
      content:'Content',
      file:'Attachments',
      reportList:'Report List',
      rentalContractList:'Rental Contract List',
      rentalContractDetail:'Rental Contract Detail',
      rentalPaymentList:'Rental Payment List',
      rentalPaymentDetail:'Rental Payment Detail',
      rentalPropertyReport:'Rental Property Report',
      rentalPropertyReportDetail:'Rental Property Report Detail',
      rentalTenantReport:'Rental Tenant Report',
      rentalTenantReportDetail:'Rental Tenant Report Detail',
      rentManagementDetail:{
        title:'Rental Management Detail',
        buttons:{
          goToRentalPayment:'Rental Payment',
          goBackToRentalPayment:'Back To Rental Payment',
          goToTenantReport: 'Tenant Report',
          goBackToTenantReport: 'Back To Tenant Report',
          goToPropertyReport:'Property Report',
          goBackToPropertyReport:'Back To Property Report',
          goToContract:'Contract',
          goRentalManagementDetail:'Back To Rental Management Detail',
          goBackToContract:'Back To Contract',
          goHome:'Go Home'
        }
      }
    },
    searchMessage:{
      title:'Search',
      area:'Area',
      rent:'Rent',
      buyingPrice:'Price',
      buildingAge:'Building Age',
      detailedCondition:'Detailed Of Condition',
      station:'Station',
      button:{
        search:'Search',
        clearCondition:'Clear Condition',
        fullImage:'Full Of Images',
        viewDetails:'View The Details'
      },
      checkbox:{
        rantal:'Rent',
        buying:'Buy'
      },
      tableInfo :{
        roomNumber:'Room Number',
        constructionDate:'Construction Date',
        floorPlan:'FloorPlan',
        buildingArea:'Building Area',
        landArea:'Land Area',
        price:'Price',
        images:'Images',
        exclusiveArea:'Exclusive Area',
        managementFee:'Management Fee',
        deposit:'Deposit',
        guaranteeDeposit:'Guarantee Deposit',
        keyMoney:'Key Money',
        rentDeposit:'Rent Deposit',
        securityDeposit:'Security Deposit',
        favorite:'Favorite'
      },
    },
    InquiryNewMessage:{
      title:'Consult',
      navtitle:{
        nav1:'Input the consultation content',
        nav2:'Confirm content',
        nav3:'End of consultation'
      },
      tabletitle:{
        pid:'House ID',
        controlNumber:'Administration number',
        inquiryUserName:'Name',
        phone:'Phone',
        email:'email',
        title:'title',
        content:'content',
        note:'note',
      },
      overContentText:'The contents of the consultation were sent',
      buttonText:{
        ModifyInput:'Modifying the input',
        ConfirmNext:'Confirm your input and proceed to the next step',
        send:'Send',
        backHomepage:'Return'
      },
      errText:{
        nullError:'incomplete information'
      }
    },
    inquirtySupportView:{
      title:"Contact us",
      concentText:'If you have any questions or requests, please feel free to contact us. Our support team will review the details and get back to you. (support hours: 10:00-17:00 on weekdays)',
      overText:'The inquiry is complete.',
      inputTitle:{
        type:"Categories",
        name:"name",
        phone:'phone',
        email:'email',
        content:'content'
      },
      buttomlais:{
        confirm:'confirmed',
        empty:"Reset"
      },
      typeList:{
        issue:'Questions',
        FunctionalRequirement:'Functional requirements',
        VulnerabilityReport:"Bug report",
        systemDevelopment:'System development',
        other:'Others'
      }
    },
    theFooter:{
      ContactInformation:'Contact information',
      email:"Email",
      footerText:'Inquiries about the system'
    },
    simulatorView:{
      borrowMuch:'How much you want to borrow',
      internalBonus:'The loan amount of the internal bonus',
      RepaymentPeriod:'Repayment period',
      rate:'Interest rate (year)',
      repayment:'Method of repayment',
      CurrentIncome:'Current income',
      ReduceRepaymentBurden:'Reduce repayment burden',
      MonthlyRepayment:'Monthly repayment',
      repaymentList:{
        EqualInterestAndPrincipal:'Equal interest and principal',
        EqualPrincipal:'Equal principal'
      },
      butTo:'Calculate',
      resultBox:{
        title:'Simulation results',
        MonthlyRepayment:'Monthly repayment',
        MonthlyBonusPayments:'Monthly bonus payments',
        AnnualRepaymentAmount:'Annual repayment amount',
        TotalRepayment:'Total repayment',
        TheRepayment:'The repayment amount of the first month',
        AmountOfBorrowing:'Amount of borrowing',
      },
      titleNavList:{
        RequiredAmountOfLoan:'Required amount of loan',
        AnnualIncome:'Annual income',
        MonthlyRepayment:'Monthly repayment',
        andOr:'from',
        frist:'Check monthly payments',
        second:'Check the amount you can borrow',
      }
    },
    BackButtomList:{
      goHome:'back homepage',
      goBack:'return',
      send:'send',
      closing:'close',
      cancle:'cancle',
      confirmed:'confirmed',
    },
    formVerification:{
      date:'date',
      name:'Please enter your name.',
      email:'Enter your email address.',
      emailType:'The email address you entered is incorrect.',
      phone:'Please enter your phone number.',
      phoneType:'Please make sure you have the correct number.',
      title:'Please enter the title.',
      content:'Please enter the content.',

      dateFrist:'Please enter your preferred date and time.',
      dateFristType:'Please make the right date.',
      dateSecond:'Please enter your preferred date and time.',
      dateThirder:'Please enter your preferred date and time.',
    },
    theHeader:{
      notification:'notification',
      SystemNotification:'System Notification',
      Profile:'Profile',
      ChangePassword:'Change Password',
      LogOut:'Log Out'
    },
    PostcodeMessage:{
      confirmed:"I'll get the address from the zip code.",
      value:'Please give me the zip code.',
      errorText:'Zip code does not exist'
    },
    PaginationMessage:{
      ItemsPerPage:'Items Per Page',
    },
    InitialCost:{
      price:'price',
      CommonServiceFee:'Common service fee',
      ManagementFee:'Management fee',
      deposit:'deposit',
      securityDeposit:'Security Deposit',
      reward:'reward',
      bail:'bail',
      depreciation:'depreciation',
      lumpSum:'other lump sum.'
    },
    detailButton:'detail',
    
}

export default en