<template>
  <v-card>
    <v-snackbar
        class="successSnackbar"
        v-model="data.snackbarSuccess"
        top
        fixed
        >
        {{ data.successText }}
    </v-snackbar>
    <v-snackbar
        class="errorSnackbar"
        v-model="data.errorSnackbar"
        top
        fixed
        >
        {{ data.errorText }}
    </v-snackbar>
    <v-layout>
        <the-header
        @chooselanguage='changeLanguageType'
        ></the-header>
        <v-navigation-drawer color="surface" width="180">
        </v-navigation-drawer>
        <v-navigation-drawer color="surface" location="right" width="180">
        </v-navigation-drawer>
        <v-main>
        <v-container class="align-center">
            <!-- 申込物件詳細画面 -->
            <v-row no-gutters>
                <v-col cols="12">
                    <p class="text-h4">{{ $t('AppliedPropertyDetail.title') }}</p>
                    
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col cols="12" class="text-right">
                    <v-btn size="large" class="" v-on:click="toAppliedPropertyList">{{ $t('AppliedPropertyDetail.goback') }}</v-btn>
                </v-col>
            </v-row>

            <v-row no-gutters class="mt-4">
                <v-col col="12">
                    <p class="text-h6">◆{{ $t('AppliedPropertyDetail.subtitleFrist') }}</p>
                    <p class="text-h6">{{$t('AppliedPropertyDetail.subtitle')}}{{data.thisStepCurrent}}</p>
                </v-col>
            </v-row>

            <v-container class="">
                <v-row no-gutters>
                <v-col v-for="(item,n) in data.appliedProperty.applicationStepBeanList" :key="n" cols="12" sm="3">
                    <v-sheet class="text-center" >
                        <v-btn width="90%" @click="toThisStep(n)" :style="data.stepNumNow.nowStep==n?'background: #c4c4c4;':''">{{ $t(item.stepName) }}</v-btn>
                        <v-label class="ml-2" v-if="n<data.appliedProperty.applicationStepBeanList.length-1">⇒</v-label>
                        <v-label class="ml-2 opacityItem" v-if="n>=data.appliedProperty.applicationStepBeanList.length-1">⇒</v-label>
                    </v-sheet>
                </v-col>
                </v-row>
            </v-container>
            <v-row no-gutters>
                <v-col cols="12 mt-4 text-center">
                    <v-btn size="large" class="submitButton" @click="submitDetail" v-if="data.appliedProperty.applicationStatus != 3 && data.appliedProperty.applicationStatus != 4">{{ $t('AppliedPropertyDetail.serve') }}</v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-4">
                <v-col col="12">
                    <p class="text-h6">◆{{ $t('AppliedPropertyDetail.subtitleSecond') }}</p>
                </v-col>
            </v-row>

            <v-container class="">
                <v-row no-gutters>
                    <v-col cols="12">
                    <v-table class="table-border">
                        <tbody>
                            <tr v-for="(item, index) in data.items" :key="index">
                                <td class="bg-teal-lighten-3" style="width: 30%;">{{ $t(item.displayName) }}</td>
                                <td>
                                    <v-text-field 
                                    v-if="!item.fileFlag" 
                                    v-model="item.content" 
                                    class="mt-4" 
                                    :disabled="(Number(data.thisStepCurrent)-1 != data.stepNumNow.nowStep)"
                                    v-bind:label="$t(item.displayName)"  
                                    required 
                                    :hint='item.comment'
                                    ></v-text-field>

                                    <v-file-input 
                                    v-if="item.fileFlag&&!item.fileName&&data.appliedProperty.applicationStatus != 3 && data.appliedProperty.applicationStatus != 4 && (Number(data.thisStepCurrent)-1 == data.stepNumNow.nowStep)" 
                                    clearable 
                                    v-bind:name="item.fileName" 
                                    v-bind:label="$t(item.displayName)" 
                                    @change="uploadFile" 
                                    :hint='item.comment'
                                    v-model='item.file'
                                    >                          
                                    </v-file-input>

                                    <v-btn
                                    class=" thisButton xsThisButton"
                                    v-if="item.fileFlag&&item.fileName"  
                                    border="none"
                                    @click="downFile(item)"
                                    text
                                    >
                                        <v-icon left>
                                            mdi-paperclip
                                        </v-icon>
                                         {{item.fileName}}
                                    </v-btn>

                                    <v-btn
                                        v-if="item.fileFlag&&item.fileName&&data.appliedProperty.applicationStatus != 3 && data.appliedProperty.applicationStatus != 4 && (Number(data.thisStepCurrent)-1 == data.stepNumNow.nowStep)" 
                                        class="ma-2 DeleteButtonStyle"
                                        @click="DeleteFile(item)"
                                    >
                                        <v-icon
                                        icon="mdi-delete"
                                        ></v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                    </v-col>
                </v-row>
            </v-container>

            <v-row no-gutters class="mt-4">
                <v-col col="12">
                    <p class="text-h6">◆{{ $t('AppliedPropertyDetail.subtitleThirdly') }}</p>
                </v-col>
            </v-row>

            <v-container class="">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-table class="table-border">
                        <tbody>
                            <tr>
                                <td class="bg-teal-lighten-3" style="width: 30%;" >{{ $t('AppliedPropertyDetail.subtitleThirdlyMessage.goodsId') }}</td>
                                <td> <a class="a-link" href="#" @click.stop.prevent="toPropertyDetail(data.property.propertyId)" v-if="data.property.propertyDeletedFlag == 0">{{ data.property.propertyId }} </a></td>
                            </tr>
                            
                            <tr v-for="(item,index) in data.ProductDetailsList" :key="index">
                                <td class="bg-teal-lighten-3" style="width: 30%;"  v-if="item.type?item.type==data.thisType:true">{{item.tdTitle}}</td>
                                <td  v-if="item.type?item.type==data.thisType:true">
                                     {{item.contentText}}  {{item.util?item.util:''}}
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                    </v-col>
                </v-row>
            </v-container>

            <v-row no-gutters class="mt-4">
                <v-col col="12">
                    <p class="text-h6 mt-2">◆{{$t('AppliedPropertyDetail.customer.customInfo')}}</p>
                </v-col>
            </v-row>
            <v-container class="">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-table class="table-border">
                            <tbody v-if="data.customDataList.customerType == 1">
                                <tr v-for="(item,index) in data.customerInputList" :key="index">
                                    <td class="bg-teal-lighten-3" style="width: 30%;">{{item.name}}</td>
                                    <td>
                                        <v-text-field  v-if="item.type=='input'" class="mt-4" :placeholder="item.placeholder" :disabled="item.hidden" v-model="data.customDataList[item.value]" :label="item.name"></v-text-field>
                                        <v-combobox
                                            v-if="item.type=='select'"
                                            v-model="data.customDataList[item.value]"
                                            :items="item.item"
                                            density="comfortable"
                                            :label="item.name"
                                            :disabled="item.hidden"
                                        ></v-combobox>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-if="data.customDataList.customerType == 2">
                                <tr v-for="(item,index) in data.legalPersonInputList" :key="index">
                                    <td class="bg-teal-lighten-3" style="width: 30%;">{{item.name}}</td>
                                    <td>
                                        <v-text-field  v-if="item.type=='input'" class="mt-4" :placeholder="item.placeholder" :disabled="item.hidden" v-model="data.customDataList[item.value]" :label="item.name"></v-text-field>
                                        <v-combobox
                                            v-if="item.type=='select'"
                                            v-model="data.customDataList[item.value]"
                                            :items="item.item"
                                            density="comfortable"
                                            :label="item.name"
                                            :disabled="item.hidden"
                                        ></v-combobox>
                                    </td>
                                </tr>
                            </tbody>
                        </v-table>   
                    </v-col>
                </v-row>
            </v-container>

               

            <v-row no-gutters class="mt-4">
                <v-col col="12">
                    <p class="text-h6">◆{{ $t('AppliedPropertyDetail.subtitleFourthly') }}</p>
                </v-col>
            </v-row>

            <v-container class="">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-table class="table-border">
                        <tbody>
                            <tr v-for="(item,n) in data.OwnerDataList" :key="n">
                                <td class="bg-teal-lighten-3" style="width: 30%;">{{item.tdTitle}}</td>
                                <td>
                                    {{item.contentText}}
                                </td>
                            </tr>
                        </tbody>
                        </v-table>
                    </v-col>
                </v-row>
            </v-container>



            <v-row class="mt-10">
                <the-footer></the-footer>
            </v-row>
        </v-container>
        </v-main>
    </v-layout>
  </v-card>
</template>
<style>
.table-border {
  border:1px;
  border-style: solid;
  border-color: antiquewhite;
}
.thisButton{
    border: none;
    box-shadow: none; 
    text-transform: none;
}
.thisButton .v-btn__content{
    color: #76b495;
}
.submitButton{
    float: right;
}
</style>
<script lang="ts">
import { defineComponent,reactive, computed, inject, getCurrentInstance, onMounted, defineProps, watch, watchEffect } from 'vue'
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import { useRoute, useRouter } from 'vue-router'
import api from '@/api/axios/axios'
import i18n from '../language/index'
import { VDatePicker } from 'vuetify/labs/VDatePicker'
import {Property, Company, Preview, ApplyDetailItem, AppliedPropertyType, FileItem, ProcessTitle, ApplyProperty ,ApplyProcedure, PropertyDetailList, ApplicationStepDetail, ApplicationStepUpdate} from './../api/sys/model/model'
import { downloadFile } from '@/api/axios/downLoad'
import {ItemsFrist ,ItemsSecond } from "../api//sys/model/speciesList";
import {uploadFileToS3} from'@/api/sys/model/upLoadFiileS3'



export default defineComponent({
    name: 'PropertyDetail',
    components: {
        TheHeader,
        TheFooter,
    },
});
</script>

<script lang="ts" setup>
    const router = useRouter()
    const route = useRoute()
    const props = defineProps({

    })


    const nationalityMessage = ['日本国','中国','韓国']
    const data = reactive({
        thisStepCurrent:'1',
        customerInputList:[
            {name:'氏名',type:'input',value:'name',hidden:true},
            {name:'生年月日',type:'input',value:'birth',placeholder:'2001/01/11',hidden:true},
            {name:'年齢',type:'input',value:'age',hidden:true},
            {name:'性別',type:'select',value:'sex',item:['未入力','男','女'],hidden:true},
            {name:'電話番号',type:'input',value:'phone',hidden:true},
            {name:'メールアドレス',type:'input',value:'email',placeholder:'name@email.com',hidden:true},
            {name:'月給',type:'input',value:'monthlySalary',hidden:true},
            {name:'婚姻状況',type:'select',value:'maritalStatus',item:['未婚','既婚'],hidden:true},     
            {name:'国籍',type:'input',value:'nationality',hidden:true},
            {name:'職種種類',type:'select',value:'occupationType',item:['未入力','会社員','法人','学生','主婦','無職','個人事業主'],hidden:true},
            {name:'住所',type:'input',value:'address',hidden:true},
        ],

        legalPersonInputList:[
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.tradeName,type:'input',value:'tradeName',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.headOfficeLocation,type:'input',value:'headOfficeLocation',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.mainPhone,type:'input',value:'mainPhone',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.tenantName,type:'input',value:'tenantName',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.tenantBirth,type:'input',value:'tenantBirth',placeholder:'2001/01/11',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.tenantPhone,type:'input',value:'tenantPhone',hidden:true},
        ],
        customDataList:{},
        appliedProperty: {} as ApplyProperty,
        propertySelling:{} as Property,
        propertyRentalDto:{} as Property,
        property: {
        } as Property,
        company:{
        } as Company,
        items: [] as ApplyDetailItem[],
        fileItems: [{name: "appliedFile", displayName: "申込書"},
         {name: "identificationFile", displayName: "身分証明書"},
         {name: "certificateOfIncumbency", displayName: "在職証明書"},
        ] as FileItem[],
        stepNumNow:{
            nowStep:0,
            stepTypeChange:false
        } as ApplyProcedure,
        languageType:'JP' as string,
        ProductDetailsList:[]as PropertyDetailList[],
        OwnerDataList:[]as PropertyDetailList[],
        applicationStepDetail: {} as ApplicationStepDetail,
        thisType:'1',
        errorSnackbar:false,
        snackbarSuccess: false,
        successText: ``,
        errorText:'',
    });
    const buildingStructureItem=['','木造','ブロック造','鉄骨造','RC','SRC','PC','HPC','ALC','軽量鉄骨造','その他']
    const trafficMeansItem=['徒歩','バス']
    const mainLightSurfaceItem=['','北','南','西','東','北東','南東','南西','北西']
    const toAppliedPropertyList = () => {
        router.push('/appliedPropertyList')
    }

    const cancelPriview = (propertyViewingId : string) => {
        const modify = {status: "1"}
        api.put('/user/previews/' + propertyViewingId, modify)
        .then(res => {
            router.push('/previewList')
        })
        .catch(error => {
            alert("キャンセル失敗しました。");
            console.log(error, propertyViewingId);
        });
    }

     onMounted(() => {
        getInitData();
        
    });


    watchEffect(() => {
        data.ProductDetailsList = [
            {
                tdTitle:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.subtitleThirdlyMessage.goodsName,
                contentText:data.property.buildingName
            },
            {
                tdTitle:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.subtitleThirdlyMessage.homeNumber,
                contentText:data.property.roomNo
            },
            {
                tdTitle:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.subtitleThirdlyMessage.ObjectItemSmall,
                contentText:data.property.propertyTypeSmall
                },
            {
                tdTitle:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.subtitleThirdlyMessage.address,
                contentText:data.property.address
                },
            {
                tdTitle:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.subtitleThirdlyMessage.architecturalStructure,
                contentText:buildingStructureItem[Number(data.property.buildingStructure)]
                },
            {
                tdTitle:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.subtitleThirdlyMessage.date,
                contentText:data.property.buildingDate
                },
            {
                tdTitle:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.subtitleThirdlyMessage.DedicatedArea,
                contentText:data.property.exclusiveArea,
                util:'㎡'
                },
            {
                tdTitle:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.subtitleThirdlyMessage.balcony,
                contentText:data.property.balconyArea,
                util:'㎡'
                },
            {
                tdTitle:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.subtitleThirdlyMessage.MainLightSurface,
                contentText:mainLightSurfaceItem[Number(data.property.mainLightSurface)]
                },
            {
                tdTitle:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.subtitleThirdlyMessage.price,
                contentText:data.propertySelling.price,
                 type:'1',
                util:'万円'
                },
            {
                tdTitle:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.subtitleThirdlyMessage.managementFee,
                contentText:data.property.managementFee,
                 type:'1',
                util:'万円'
                },
            {
                tdTitle:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.subtitleThirdlyMessage.accumulationFund,
                contentText:data.propertySelling.deposit,
                type:'1',
                util:'万円'
                },
            {
                tdTitle:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.subtitleThirdlyMessage.rent,
                contentText:data.propertyRentalDto.rent,
                 type:'2',
                util:'万円'
                },
            {
                tdTitle:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.subtitleThirdlyMessage.keyMoney,
                contentText:data.propertyRentalDto.keyMoney,
                 type:'2',
                util:'万円'
                },
            {
                tdTitle:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.subtitleThirdlyMessage.securityDeposit,
                contentText:data.propertyRentalDto.securityDepositMoney,
                 util:'万円',
                 type:'2',
                },
            {
                tdTitle:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.subtitleThirdlyMessage.earnestMoney,
                contentText:data.propertyRentalDto.guaranteeDepositMoney,
                 util:'万円',
                 type:'2',
                },
            {
                tdTitle:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.subtitleThirdlyMessage.termOfContract,
                contentText:data.propertyRentalDto.contractPeriod,
                type:'2',
                },
            {
                tdTitle:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.subtitleThirdlyMessage.comment,
                contentText:data.property.comment
                },
        ]
        data.OwnerDataList =[
            {
                tdTitle:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.subtitleFourthlyMessage.id,
                contentText:data.company.companyId
                },
            {
                tdTitle:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.subtitleFourthlyMessage.name,
                contentText:data.company.companyName
                },
            {
                tdTitle:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.subtitleFourthlyMessage.phoneNumber,
                contentText:data.company.phone
                },
            {
                tdTitle:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.subtitleFourthlyMessage.FixedRestDay,
                contentText:data.company.regularHoliday
                },
            {
                tdTitle:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.subtitleFourthlyMessage.businessHours,
                contentText:data.company.businessHours
                },
        ] 
        data.customerInputList=[
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.name,type:'input',value:'name',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.birth,type:'input',value:'birth',placeholder:'2001/01/11',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.age,type:'input',value:'age',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.sex,type:'select',value:'sex',item:['未入力','男','女'],hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.phone,type:'input',value:'phone',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.email,type:'input',value:'email',placeholder:'name@email.com',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.monthlySalary,type:'input',value:'monthlySalary',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.maritalStatus,type:'select',value:'maritalStatus',item:['未婚','既婚'],hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.nationality,type:'input',value:'nationality',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.occupationType,type:'select',value:'occupationType',item:['未入力','会社員','法人','学生','主婦','無職','個人事業主'],hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.address,type:'input',value:'address',hidden:true},
        ]

        data.legalPersonInputList=[
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.tradeName,type:'input',value:'tradeName',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.headOfficeLocation,type:'input',value:'headOfficeLocation',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.mainPhone,type:'input',value:'mainPhone',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.tenantName,type:'input',value:'tenantName',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.tenantBirth,type:'input',value:'tenantBirth',placeholder:'2001/01/11',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.tenantPhone,type:'input',value:'tenantPhone',hidden:true},
        ]
        
    });
 
    let occupationTypeList =['未入力','会社員','法人','学生','主婦','無職','個人事業主']
    let nationalityList = ['国籍未入力','日本国','中国','韓国']
    let sexList = ['未入力','男','女']
    let maritalStatusList = ['未婚','既婚',]
    const getInitData  =  () => {
        data.appliedProperty.appliedPropertyId = route.params.appliedPropertyId.toString()     
        api.get('/user/applications/'+ data.appliedProperty.appliedPropertyId + `/detail`)
        .then((res) => {
            data.appliedProperty = res.data
            data.thisStepCurrent = res.data.currentStep
            console.log(res.data);
            
            
            if(res.data.customerMessage){
                data.customDataList = res.data.customerMessage
                if(data.customDataList.customerType == 1) {

                    data.customDataList.sex = sexList[data.customDataList.sex]
                    if(data.customDataList.nationality == 4) {
                        data.customDataList.nationality = data.customDataList.nationalityInput
                    }else {
                        data.customDataList.nationality = nationalityList[data.customDataList.nationality]
                    }
                    
                    data.customDataList.occupationType = occupationTypeList[data.customDataList.occupationType]
                    data.customDataList.maritalStatus = maritalStatusList[Number(data.customDataList.maritalStatus)]

                }
                
            }
            if(!data.stepNumNow.stepTypeChange){
                 data.stepNumNow.nowStep = data.thisStepCurrent-1
            }
            activeChangeLanguage();
            getformDetailData(route.params.appliedPropertyId.toString(),
                           data.appliedProperty.applicationStepBeanList[data.stepNumNow.nowStep].applicationFlowId,
                           data.appliedProperty.applicationStepBeanList[data.stepNumNow.nowStep].applicationStepId)
            ItemDetailsData(data.appliedProperty.propertyId);
            
        })
        .catch((error) => {
            console.log(error.message)
        })
    };

    const toPropertyDetail = (propertyId: string) => {
    router.push({
        name: 'detail',
        query: {
            id: propertyId,
            type: 'appliedPropertyDetail',
            fromId: route.params.appliedPropertyId.toString()
        },
    })
    };

    const toThisStep =(thisStep:number)=>{
        data.stepNumNow.nowStep = thisStep 
        data.stepNumNow.stepTypeChange = true 
        getformDetailData(route.params.appliedPropertyId.toString(),
                data.appliedProperty.applicationStepBeanList[data.stepNumNow.nowStep].applicationFlowId,
                data.appliedProperty.applicationStepBeanList[data.stepNumNow.nowStep].applicationStepId)
    }
     const getformDetailData  =  (applicationId:string,applicationFlowId:string,applicationStepId:string) => {                
        api.get(`/user/applications/${applicationId}/${applicationFlowId}/${data.stepNumNow.nowStep+1}/detail`)
        .then((res) => {
            data.items = res.data.applicationInputInformationBeanList 
            activeChangeLanguageSecound()
            data.applicationStepDetail = res.data
            data.items.sort((a,b)=>{
                return a.displayOrder - b.displayOrder
            })
        })
        .catch((error) => {
            console.log(error.message)
        })
    };

    const ItemDetailsData = (thisItemId:string)=>{
        api.get(`/user/public/property/${thisItemId}`)
        .then((res) => {
            data.property = res.data.property  
            data.propertyRentalDto = res.data.propertyRentalDto
            data.propertySelling =  res.data.propertySelling
            
            console.log(data.property.propertyTypeSmall)
            data.property.propertyTypeSmall = ItemsSecond(data.property.propertyTypeSmall,data.property.propertyType=='3'?'1':'2')
            if(res.data.propertyRentalDto.propertyRentalId){
                data.thisType = '2'
            }
            if(res.data.propertySelling.propertyBuyId){
                data.thisType = '1'
            }      
            if(data.languageType=='CN'){
                data.property.address = res.data.property.addressCn
                data.property.buildingName = res.data.property.buildingNameCn
                data.property.roomNo = res.data.property.roomNoCn
            }else if(data.languageType=='EN'){
                data.property.address = res.data.property.addressEn
                data.property.buildingName = res.data.property.buildingNameEn
                data.property.roomNo = res.data.property.roomNoEn

            }else if(data.languageType=='JP'){
                data.property.address = res.data.property.addressJp
                data.property.buildingName = res.data.property.buildingNameJp
                data.property.roomNo = res.data.property.roomNoJp
            }
            getCompanyData(data.property.companyId)
        })
        .catch((error) => {
            console.log(error.message)
        })
    }
    const getCompanyData = (companyId:string)=>{
        api.get(`/user/public/company/${companyId}`)
        .then((res) => {
            console.log(res);  
            data.company = res.data
        })
        .catch((error) => {
            console.log(error.message)
        })
    }

    // submitDetail
    const submitDetail = ()=>{
        console.log(111);
        const targetObjs = data.applicationStepDetail.applicationInputInformationBeanList.map(obj => {
            const newObj = {} as ApplicationStepUpdate
            newObj.applicationId = obj.applicationId
            newObj.applicationFlorId = obj.applicationFlowId
            newObj.applicationStepId = obj.applicationStepId
            newObj.applicationStepItemId  = obj.applicationStepItemId
            newObj.applicationInputInformationId = obj.applicationInputInformationId

            newObj.displayOrder = obj.displayOrder
            newObj.displayName = obj.displayName
            newObj.content = obj.content
            newObj.requiredFlag = obj.requiredFlag
            newObj.fileFlag = obj.fileFlag
            newObj.confirmationFlag = obj.confirmationFlag
            if (obj.file) {
                console.log(obj.file);
                newObj.fileName = obj.file[0].name
                newObj.fileContentType = obj.file[0].type
                newObj.fileSize = obj.file[0].size
            } else {
                newObj.fileName = obj.fileName
                newObj.fileContentType = obj.fileContentType
                newObj.fileSize = parseFloat(obj.fileSize)
            }
            return newObj;
      })

      console.log(data.applicationStepDetail.applicationInputInformationBeanList);
      
     for(let index in data.applicationStepDetail.applicationInputInformationBeanList){
        if(data.applicationStepDetail.applicationInputInformationBeanList[index].file){
            let fileData = {
                applicationInputInformationId:data.applicationStepDetail.applicationInputInformationBeanList[index].applicationInputInformationId,
                fileContentType:data.applicationStepDetail.applicationInputInformationBeanList[index].file[0].type,
                fileName:data.applicationStepDetail.applicationInputInformationBeanList[index].file[0].name,
                fileSize:data.applicationStepDetail.applicationInputInformationBeanList[index].file[0].size
            }
            api.post(`/user/applications/input/information/files/upload`,fileData)
            .then((res)=>{
                console.log(res);
                console.log(data.applicationStepDetail.applicationInputInformationBeanList[index].file);
                
                uploadFileToS3(data.applicationStepDetail.applicationInputInformationBeanList[index].file[0],res.data.uploadPresignUrl)

            })
            .catch((error)=>{
                console.log(error);
            })
        }
       

     }

     data.customDataList.age = Number(data.customDataList.age)
      data.customDataList.monthlySalary = Number(data.customDataList.monthlySalary)
      data.customDataList.kinds = 0
      data.customDataList.sex = sexList.indexOf(data.customDataList.sex)
      data.customDataList.nationality = nationalityList.indexOf(data.customDataList.nationality)
      data.customDataList.occupationType = occupationTypeList.indexOf(data.customDataList.occupationType)
      data.customDataList.maritalStatus = maritalStatusList.indexOf(data.customDataList.maritalStatus)
      data.customDataList.companyId = data.property.companyId
      data.customDataList.companyStaffId = data.property.companyStaffId

      let MessageCustomList = JSON.parse(JSON.stringify(data.customDataList))
      delete MessageCustomList.controlNumber

     let thisData = {
        applicationInputInformationBeanList:targetObjs,
        customerMessage:data.customDataList
      }

      // 申込入力情報詳細更新
      api.put(`/user/applications/input/information`, thisData )
        .then((res) => {  
            console.log("sucess")
            console.log(data.stepNumNow.nowStep);
            data.snackbarSuccess = true
            data.successText = '保存が成功しました'
            setTimeout(() => {
                data.snackbarSuccess = false;
            }, 1000);
             getInitData();
             //保存成功提醒
             
        })
        .catch((error) => {
            console.log(error.message)
        })
        
    }

    const uploadFile = ($event: Event) => {
        const target = $event.target as HTMLInputElement;
        if (target && target.files) {
            const targetName = target.files[0].name;
            const fileItem = data.fileItems.find(fileItem => fileItem.name == target.name);
            if(fileItem) {
                fileItem.selectedFile =target.files[0];
            }

        }
    }
    const DeleteFile = (item:any) => {
        console.log(item);
        api.delete(`/user/applications/input/information/files/delete/${item.applicationInputInformationId}`)
        .then((res)=>{
            console.log(res);
            getInitData()
        })
        .catch((error)=>{
            console.log(error);
        })

    }
    const activeChangeLanguage = () => {
        for(let item of data.appliedProperty.applicationStepBeanList){ 
                if(item.stepName == i18n.global.messages['jp'].AppliedPropertyDetail.subtitleFristMessage.frist){
                    item.stepName = 'AppliedPropertyDetail.subtitleFristMessage.frist'
                }
                if(item.stepName == i18n.global.messages['jp'].AppliedPropertyDetail.subtitleFristMessage.second){
                    item.stepName = 'AppliedPropertyDetail.subtitleFristMessage.second'
                }
                if(item.stepName == i18n.global.messages['jp'].AppliedPropertyDetail.subtitleFristMessage.third){
                    item.stepName = 'AppliedPropertyDetail.subtitleFristMessage.third'
                }
        }
    }
    const activeChangeLanguageSecound = () => {
        console.log(i18n.global.messages['jp'].AppliedPropertyDetail.subtitleSecondMessage);
        console.log(data.items);
        
        let languageMessage = i18n.global.messages['jp'].AppliedPropertyDetail.subtitleSecondMessage
        for(let index in languageMessage){
            for(let item of data.items){
                if(item.displayName == languageMessage[index]){
                    item.name = `AppliedPropertyDetail.subtitleSecondMessage.${index}`               
                }    
            }
        }
    }
     const changeLanguageType = (val:'jp'|'en'|'zh')=>{
       activeChangeLanguageSecound()
    }
    const downFile = (item:any) => {
        console.log(item);
        api.get(`/user/applications/input/information/files/download?applicationId=${item.applicationId}&&applicationInputInformationId=${item.applicationInputInformationId}`)
        .then((res)=>{
            console.log(res);
            downloadFile(res.data.downloadPresignUrl,item.fileName)
        })
        .catch((error)=>{
            console.log(error);
        })
        
    }

</script>
<style scoped>
@media screen and (max-width:784px){
    .xsThisButton .v-btn__content{
        width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
.DeleteButtonStyle{
    color: #989898;
    box-shadow: none;
}
.opacityItem{
    opacity: 0;
}
</style>
