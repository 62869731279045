<template>
  <v-card>
    <v-layout>
        <the-header
        @chooselanguage='changeLanguageType'
        ></the-header>
        <v-navigation-drawer color="surface" width="180">
        </v-navigation-drawer>
        <v-navigation-drawer color="surface" location="right" width="180">
        </v-navigation-drawer>
        <v-main>
        <v-container lass="align-center">
            <v-row>
                <v-col cols="12" sm="6" xs="12" md="6">
                    <p class="text-h4">{{ data.property.buildingName }}</p>
                </v-col>
                <v-col cols="12" sm="6" xs="12" md="6">
                    <v-btn outlined fab elevation="2" class="ml-2 butStylewidth" @click="ToInquiryList">{{ $t('messageproprty.buttonName.consult') }}</v-btn>

                    <v-btn outlined fab elevation="2" class="ml-2 butStylewidth" v-if="!data.isfavorite" @click="AddFavorites">{{ $t('messageproprty.buttonName.FavoritesLogin') }}</v-btn>
                    <v-btn outlined fab elevation="2" class="ml-2 butStylewidth" v-if="data.isfavorite" @click="DelFavorites">{{ $t('messageproprty.buttonName.Favorited') }}</v-btn>

                    <v-btn outlined fab elevation="2" class="ml-2 butStylewidth" v-on:click="toPreview" >{{ $t('messageproprty.buttonName.appointment') }}</v-btn>
                    <v-dialog
                        
                        transition="dialog-top-transition"
                        width="50%"
                    >
                        <template v-slot:activator="{ props }">
                        <v-btn
                            class="ml-2 butStylewidth"
                            v-bind="props"
                        >{{ $t('messageproprty.buttonName.initialcost') }}</v-btn>
                        </template>
                        <template v-slot:default="{ isActive }">
                        <v-card v-if="data.payType == 1">
                            <v-toolbar                           
                            :title="$t('messageproprty.buttonName.initialcost')"
                            ></v-toolbar>
                            <v-card-text>
                            <div class="text" style="text-align: center;">
                                 <!-- 賃料 -->
                                <v-row>
                                    <v-col cols="6" style="text-align: right;">
                                        {{ $t('InitialCostMessage.rent') }}  :
                                    </v-col>
                                    <v-col cols="6" style="text-align: left;">
                                        {{data.budgetInfo.rent == undefined ? '0万円' : data.budgetInfo.rent + '万円'}}
                                    </v-col>
                                </v-row>
                                 <!-- 共益費 / 管理費 -->
                                <v-row>
                                    <v-col cols="6" style="text-align: right;">
                                        {{ $t('InitialCost.CommonServiceFee') }} /  {{ $t('InitialCost.ManagementFee') }}  :
                                    </v-col>
                                    <v-col cols="6" style="text-align: left;">
                                        <span>
                                            {{data.budgetInfo.commonServiceFee == undefined ? '0万円' : data.budgetInfo.commonServiceFee + '万円'}}
                                        </span>
                                        /
                                        <span>
                                            {{data.budgetInfo.managementFee == undefined ? '0万円' : data.budgetInfo.managementFee + '万円'}}
                                        </span>
                                    </v-col>
                                </v-row>
                                <!-- 敷金 / 礼金-->
                                <v-row>
                                    <v-col cols="6" style="text-align: right;">
                                        {{ $t('InitialCost.securityDeposit') }} /  {{ $t('InitialCost.reward') }}  :
                                    </v-col>
                                    <v-col cols="6" style="text-align: left;">
                                        <span>{{ securityDepositDisplay }}</span>
                                        /
                                        <span>{{ keyMoneyDisplay }}</span>
                                    </v-col>
                                </v-row>
                                <!-- 保証金 / 敷引 -->
                                <v-row>
                                    <v-col cols="6" style="text-align: right;">
                                        {{ $t('InitialCost.bail') }} /  {{ $t('InitialCost.depreciation') }}  :
                                    </v-col>
                                    <v-col cols="6" style="text-align: left;">
                                        <span>{{ guaranteeDepositDisplay }}</span>
                                        /
                                        <span>{{ deductionFromRentalDepositDisplay }}</span>
                                    </v-col>
                                </v-row>
                                <!-- その他一時金 -->
                                <v-row>
                                    <v-col cols="6" style="text-align: right;">
                                        {{ $t('InitialCost.lumpSum') }}  :
                                    </v-col>
                                    <v-col cols="6" style="text-align: left;">
                                        <span>{{
                                            data.budgetInfo.rentalOtherLumpSum == null ||
                                            data.budgetInfo.rentalOtherLumpSum == 0
                                                ? '0万円'
                                                : data.budgetInfo.rentalOtherLumpSum + '万円'
                                            }}</span>
                                    </v-col>
                                </v-row>
                                <!-- 合計 -->
                                <v-row>
                                    <v-col cols="6" style="text-align: right;">
                                        {{ $t('InitialCostMessage.total') }}  :
                                    </v-col>
                                    <v-col cols="6" style="text-align: left;">
                                        <span> {{ totalBudget }}万円</span>
                                    </v-col>
                                </v-row>
                            </div>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                            <v-btn
                                variant="text"
                                @click="isActive.value = false"
                            >キャンセル</v-btn>
                            </v-card-actions>
                        </v-card>

                        <v-card v-if="data.payType == 2">
                            <v-toolbar                           
                            :title="$t('messageproprty.buttonName.initialcost')"
                            ></v-toolbar>
                            <v-card-text>
                            <div class="text" style="text-align: center;">
                                 <!-- 価格 -->
                                <v-row>
                                    <v-col cols="6" style="text-align: right;">
                                        {{ $t('InitialCost.price') }}  :
                                    </v-col>
                                    <v-col cols="6" style="text-align: left;">
                                        {{
                                            (data.budgetInfo.price == null || data.budgetInfo.price == 0)
                                            ? '0万円'
                                            : data.budgetInfo.price + '万円'
                                        }}
                                    </v-col>
                                </v-row>
                                 <!-- 管理費 -->
                                <v-row>
                                    <v-col cols="6" style="text-align: right;">
                                        {{ $t('InitialCost.ManagementFee') }}  :
                                    </v-col>
                                    <v-col cols="6" style="text-align: left;">                                       
                                        <span>
                                            {{
                                                (data.budgetInfo.managementFee == null || data.budgetInfo.managementFee == 0)
                                                ? '0万円'
                                                : data.budgetInfo.managementFee + '万円'
                                            }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <!-- 積立金-->
                                <v-row>
                                    <v-col cols="6" style="text-align: right;">
                                        {{ $t('InitialCost.deposit') }}:
                                    </v-col>
                                    <v-col cols="6" style="text-align: left;">
                                        {{
                                            (data.budgetInfo.deposit == null || data.budgetInfo.deposit == 0)
                                            ? '0万円'
                                            : data.budgetInfo.deposit + '万円'
                                        }}
                                    </v-col>
                                </v-row>
                                <!-- その他一時金 -->
                                <v-row>
                                    <v-col cols="6" style="text-align: right;">
                                        {{ $t('InitialCost.lumpSum') }}  :
                                    </v-col>
                                    <v-col cols="6" style="text-align: left;">
                                        <span>{{
                                            data.budgetInfo.sellingOtherLumpSum == null ||
                                            data.budgetInfo.sellingOtherLumpSum == 0
                                                ? '0万円'
                                                : data.budgetInfo.sellingOtherLumpSum + '万円'
                                            }}</span>
                                    </v-col>
                                </v-row>
                                <!-- 合計 -->
                                <v-row>
                                    <v-col cols="6" style="text-align: right;">
                                        {{ $t('InitialCostMessage.total') }}  :
                                    </v-col>
                                    <v-col cols="6" style="text-align: left;">
                                        <span> {{
                                            (
                                            (data.budgetInfo.price == null ? 0 : data.budgetInfo.price) +
                                            (data.budgetInfo.sellingOtherLumpSum == null
                                                ? 0
                                                : data.budgetInfo.sellingOtherLumpSum) +
                                            (data.budgetInfo.deposit == null ? 0 : data.budgetInfo.deposit) +
                                            (data.budgetInfo.managementFee == null
                                                ? 0
                                                : data.budgetInfo.managementFee)
                                            ).toFixed(2)
                                        }}万円</span>
                                    </v-col>
                                </v-row>
                            </div>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                            <v-btn
                                variant="text"
                                @click="isActive.value = false"
                            >キャンセル</v-btn>
                            </v-card-actions>
                        </v-card>
                        </template>
                    </v-dialog>


                    <v-btn outlined fab elevation="2" class="ml-2  butStylewidth" @click ="toAppliedProperty">{{ $t('messageproprty.buttonName.apply') }}</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" xs="12" md="6">
                    <v-card>
                        <v-dialog max-width="600" class="dialogBox">
                            <template v-slot:activator="{ props }">
                                <v-img v-bind="props" :src="data.mainImage" class="bg-grey-lighten-2" contain></v-img>
                            </template>

                            <template v-slot:default="{ isActive }">
                                <v-card>
                                    <v-carousel>
                                        <v-carousel-item
                                            :src="data.mainImage"
                                            contain
                                        ></v-carousel-item>
                                        <v-carousel-item
                                            v-for="(item, i) in data.images.filter(item => item !== data.mainImage)"
                                            :key="i"
                                            :src="item"
                                            contain
                                        ></v-carousel-item>

                                    </v-carousel>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                    text="Close"
                                    @click="isActive.value = false"
                                    ></v-btn>
                                </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" xs="12" md="6">
                    <div class="smallImageBox">
                        <v-row>
                            <v-col cols="4" v-for="(item, i) in data.images" :key="i">
                                <v-card  @click="imageChange(item)">
                                    <v-img :src="item" class="bg-grey-lighten-2 smallImage"></v-img>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>    
            </v-row>
            <v-row>
                <v-col cols="12">
                <span class="text-red">{{ $t('messageproprty.SupportLanguage') }}</span>
                 <v-expansion-panels v-model="data.expandedPanels" multiple="true">
                    <the-select-box
                    :titleName = "$t('PropertyDetail.title.goodsMessage')"
                    :SelectPropertyList='data.SelectPropertyMessage'
                    ></the-select-box>

                    <the-select-box
                    v-if="data.payType == 2"
                    :titleName = "$t('PropertyDetail.title.Buysell')"
                    :SelectPropertyList='data.SelectSelling'
                    ></the-select-box>

                    <the-select-box
                    v-if="data.payType == 1"
                    :titleName = "$t('PropertyDetail.title.SellingData')"
                    :SelectPropertyList='data.SelectconditionContract'
                    ></the-select-box>
                   
                    <the-select-box
                    v-if="data.payType == 1"
                    :titleName = "$t('PropertyDetail.title.RentalDtoData')"
                    :SelectPropertyList='data.SelectOccupancyCondition'
                    ></the-select-box>

                    <the-select-box
                    :titleName = "$t('PropertyDetail.title.StatusProperty')"
                    :SelectPropertyList='data.SelectObjectCondition'
                    ></the-select-box>

                    <the-select-box
                    :titleName = "$t('PropertyDetail.title.Others')"
                    :SelectPropertyList='data.SelectOtherMessage'
                    ></the-select-box>

                    <the-select-box
                    :titleName = "$t('PropertyDetail.title.featureData')"
                    :SelectPropertyList='data.SelectFacilities'
                    ></the-select-box>

                    <the-select-box
                    :titleName = "$t('PropertyDetail.title.FacilitiesData')"
                    :SelectPropertyList='data.SelectpropertyFeature'
                    ></the-select-box>
                    
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            {{ $t('PropertyDetail.title.companyData') }}
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <v-container>
                                <v-row>
                                    <v-col :span="12">
                                        <v-row>
                                            <div style="margin-bottom: 20px;">
                                                <p class="theSelectBoxCont companyrow">
                                                <span class="textName">{{ $t('PropertyDetail.companyData.companyName') }} : </span>  
                                                <span class="a-link" @click="toShowCompanyDetail(data.ApiData.propertyStaffInfo.companyId)">{{ data.ApiData.propertyStaffInfo.companyName }}</span>
                                                </p> 
                                            </div>                                           
                                        </v-row>
                                        <v-row>
                                            <div style="margin-bottom: 20px;">
                                                <span class="textName">{{ $t('PropertyDetail.companyData.staffName') }} : </span>  
                                                <span>{{ data.ApiData.propertyStaffInfo.name }}</span>    
                                            </div>
                                        </v-row>
                                        <v-row>
                                            <div style="margin-bottom: 20px;">
                                                <span class="textName">{{ $t('PropertyDetail.companyData.staffEmail') }} : </span>
                                                <span>{{ data.ApiData.propertyStaffInfo.email }}</span>   
                                            </div>
                                        </v-row>
                                        <v-row>
                                            <div>
                                                <span class="textName">{{ $t('PropertyDetail.companyData.staffPhone') }} : </span>
                                                <span>{{ data.ApiData.propertyStaffInfo.phone }}</span>   
                                            </div>
                                        </v-row>

                                    </v-col>

                                    <v-col :span="12">
                                        <v-row>
                                            <v-img :src="data.ApiData.propertyStaffInfo.imageUrl" max-height="150"></v-img>                                     
                                        </v-row>

                                    </v-col>
                                </v-row>

                            </v-container>

                        </v-expansion-panel-text>
                    </v-expansion-panel>
                 </v-expansion-panels>
            </v-col>
            </v-row>


            <v-row class="home-button-row">
                <v-btn class="mt-10 home-button" color="success" variant="outlined" @click="goBack">
                    {{ $t('BackButtomList.goBack') }}
                </v-btn>
            </v-row>

            <v-row class="mt-10">
                <the-footer></the-footer>
            </v-row>
        </v-container>
        </v-main>
    </v-layout>
  </v-card>
</template>

<script lang="ts">
import { defineComponent,reactive, computed, onMounted, defineProps, getCurrentInstance, watchEffect,} from 'vue'
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import TheSelectBox from './components/TheSelectBox.vue'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import axions from 'axios'
import {Property, dataListTitle ,DetaliViewData} from './../api/sys/model/model'
import { processingPropertyMessage , ObjectCondition , otherPropertyMessage , ConditionContract , OccupancyCondition ,propertySellingData ,propertyFacilitiesData , propertyFeatureData} from './../api/sys/model/processingData'
import api from '@/api/axios/axios'
import i18n from '../language/index'
import { nextDay } from 'date-fns'
import { urlToBase64 } from '@/api/sys/model/base64Conver'
export default defineComponent({
    name: 'PropertyDetail',
    components: {
        TheHeader,
        TheFooter,
        TheSelectBox
    },
});
</script>




<script lang="ts" setup>
    const app: any = getCurrentInstance()
    const props = defineProps({
      propertyInfo: Object,

    })
    const data = reactive({
        budgetInfo:{
            rent:null,
            price:null,
            sellingOtherLumpSum:null,
            managementFee:null,
            commonServiceFee:null,
            securityDepositMoney:null,
            securityDepositMonth:null,
            keyMoney:null,
            keyMonth:null,
            deposit:null,
            guaranteeDepositMoney:null,
            guaranteeDepositMonth:null,
            deductionFromRentalDepositMoney:null,
            deductionFromRentalDepositMonth:null,
            rentalOtherLumpSum:null,
        },
        totalPrice:0,
        companyDataDetail:{},
        isfavorite:false,
        favoriteListData:[{
            propertyId:'',
            userFavoriteId:''
        }],
        payType:1,
        expandedPanels:[0],
        SelectPropertyList:[] as DetaliViewData[],
        SelectPropertyMessage:[] as DetaliViewData[],
        SelectObjectCondition:[] as DetaliViewData[],
        SelectOtherMessage:[] as DetaliViewData[],
        SelectconditionContract:[] as DetaliViewData[],
        SelectOccupancyCondition:[] as DetaliViewData[],
        SelectSelling:[] as DetaliViewData[],
        SelectpropertyFeature:[] as DetaliViewData[],
        SelectFacilities:[] as DetaliViewData[],
        ApiData:{
            property:{} as Property,
            propertyFacilities:{},
            propertyRentalDto:{},
            propertySelling:{},
            propertyFeature:{},
            propertyStaffInfo:{}
        },
        locations: ['Australia', 'Barbados', 'Chile', 'Denmark', 'Ecuador', 'France'],
        propertyDetail: {
            id: "1",
            name: "サクラハウス 4階建",
            roomNumber: "123",
            rent: "20万円",
            managementFee: "1万5000円",
            deposit: "1ヶ月",
            keyMoney: "1ヶ月",
            floorPlan: "65.34m",
            exclusiveArea: "52.23m",
            address:"日文地址"
        },
        property: {
        } as Property,
        mainImage: "",
        images: [] as string[],
        languageType:'jp' as string,
        dataList:[] as dataListTitle[],
        fromPage:'',
    });
    const imageChange = (imageFileName: string) => {
      data.mainImage = imageFileName;
    }
    const router = useRouter()
    const toPreview = () => {
      router.push('/preview/1/' + data.propertyDetail.id)
    }
    const toAppliedProperty = () => {
        router.push('/appliedProperty/'+ data.propertyDetail.id)
    }
    const route = useRoute()
    onMounted(
        async ()=>{
        console.log(route.query.id);
        data.propertyDetail.id = route.query.id as string     
        if(localStorage.getItem('CLOUDPFM_CO__REFRESH__')){
            await collectList()
            await getPropertyDetailData()
            await budget()

            await BrowsedData()
            processingData()
        }else{
            await getPropertyDetailData()
            await budget()
            processingData()

        }
        
    })


    onBeforeRouteLeave((to, from, next) => {
        if(to.name == 'index' || to.name == 'PropertySearch') {
            console.log("离开detail")
            to.meta.keepAlive = true;
        }
        next()
    });

    const goBack = () => {

        if(route.query.type == "index") {
            router.push({ name:'index'});
        } else if (route.query.type == "search") {
            router.push({ name:'PropertySearch'});
        } else if (route.query.type == "favoriteList") {
            router.push({ name:'FavoriteList'});
        } else if (route.query.type == "browsedList") {
            router.push({ name:'BrowsedList'});
        } else if (route.query.type == "appliedPropertyDetail") {
            router.push('/appliedPropertyDetail/' + route.query.fromId)
        } else if (route.query.type == "previewList") {
            router.push({ name:'previewList'});
        } 

    };


    //
    const changeLanguageType = (val:'jp'|'en'|'zh')=>{
        console.log(i18n.global);
        data.languageType = val
        getPropertyDetailData()
    }
    // 
    const getPropertyDetailData  =  
    async () => {                
        await api.get(`/user/public/property/${route.query.id}`)
        .then((res) => {
            console.log(res);  
            data.ApiData = res.data
            data.property = res.data.property

            // 从数据propertyFileList获取downloadPresignUrl
            data.images = res.data.propertyFileList.map((obj:any) => obj.downloadPresignUrl)

            Promise.all(data.images.map(urlToBase64))
                .then((base64Array) => {
                    // 将每个转换后的 Base64 数据放回原来的数组中
                    data.images = base64Array;
                })
                .catch((error) => {
                    console.error('转换为 Base64 出错：', error);
                });

            if(res.data.propertyFileList.length>0){
                data.mainImage = res.data.propertyFileList.filter((file:any) => file.fileType == 1)[0].downloadPresignUrl;
            }else{
                data.mainImage = undefined
            }
            
            if(res.data.propertyRentalDto.propertyRentalId){
                data.payType = 1
            }else if(res.data.propertySelling.propertyBuyId){
                 data.payType = 2
            }
            if(i18n.global.locale=='zh'){
                data.property.address = res.data.property.addressCn
                data.property.buildingName = res.data.property.buildingNameCn
                data.property.roomNo = res.data.property.roomNoCn
                data.property.useStation1 = res.data.property.useStation1Cn
                data.property.useStation2 = res.data.property.useStation2Cn
                data.property.address_other = res.data.property.addressOtherCn
                data.property.otherTransportation = res.data.property.otherTransportationCn
            }else if(i18n.global.locale=='en'){
                data.property.address = res.data.property.addressEn
                data.property.buildingName = res.data.property.buildingNameEn
                data.property.roomNo = res.data.property.roomNoEn
                data.property.useStation1 = res.data.property.useStation1En
                data.property.useStation2 = res.data.property.useStation2En
                data.property.address_other = res.data.property.addressOtherEn
                data.property.otherTransportation = res.data.property.otherTransportationEn
            }else if(i18n.global.locale=='jp'){
                data.property.address = res.data.property.addressJp
                data.property.buildingName = res.data.property.buildingNameJp
                data.property.roomNo = res.data.property.roomNoJp
                data.property.useStation1 = res.data.property.useStation1Jp
                data.property.useStation2 = res.data.property.useStation2Jp
                data.property.address_other = res.data.property.addressOtherJp
                data.property.otherTransportation = res.data.property.otherTransportationJp
            }

            urlToBase64(res.data.propertyStaffInfo.imageUrl).then((base64) => {
                    data.ApiData.propertyStaffInfo.imageUrl = base64;
                });

            
            
        })
        // .catch((error) => {
        //     console.log(error.message)
        // })
    };

    const ToInquiryList = () => {
        router.push({
        path: './inquiry',
        query: {
            controlNumber: data.ApiData.property.controlNumber,
            pId:route.query.id
        }
        });
    }

    // 数据处理方法
    const processingData = () => {
        console.log(data.property);
        // 物件情報
        data.SelectPropertyMessage = processingPropertyMessage(data.property,data.payType.toString())
        // 物件状況
        data.SelectObjectCondition = ObjectCondition(data.property)
        // その他
        data.SelectOtherMessage = otherPropertyMessage(data.property)
        // 契約条件
        data.SelectconditionContract = ConditionContract(data.ApiData.propertyRentalDto,data.property)
        // 入居条件
        data.SelectOccupancyCondition = OccupancyCondition(data.ApiData.propertyRentalDto,data.property)
        // 売買のみ
        data.SelectSelling = propertySellingData(data.ApiData.propertySelling,data.property)
        // 設備
        data.SelectFacilities = propertyFacilitiesData(data.ApiData.propertyFacilities)
        // 特徴
        data.SelectpropertyFeature = propertyFeatureData(data.ApiData.propertyFeature)
    }


    // 收藏夹
    const collectList = 
    async () =>{
        await api.get(`/user/favorite`)
        .then((res)=>{
            console.log(res);
            data.favoriteListData = res.data
            for(let item of res.data){
                if(item.propertyId== route.query.id){
                    data.isfavorite = true
                }
            }
        })
        .catch((error)=>{
            console.log(error);  
        })
    }

    const AddFavorites = () => {
        if(localStorage.getItem('CLOUDPFM_CO__REFRESH__')){
            let toData = {
            "propertyId": route.query.id
            }
            api.post(`/user/favorite`,toData)
            .then((res)=>{
                console.log(res);
                localStorage.setItem('CLOUDPFM_CO__PAYTYPE__',data.payType.toString())
                data.isfavorite = true      
            })
            .catch((error)=>{
                console.log(error);  
            })
        }else{
            router.push('/login')
        }
        
    }
    const DelFavorites = () => {
        console.log(data.favoriteListData);
        let userFavoriteId 
        for(let item of data.favoriteListData){
            if(item.propertyId==route.query.id){
                userFavoriteId = item.userFavoriteId
            }
        }
        api.delete(`/user/favorite/${userFavoriteId}`)
        .then((res)=>{
            console.log(res);
            localStorage.setItem('CLOUDPFM_CO__PAYTYPE__',data.payType.toString())
            data.isfavorite = false
        })
        .catch((error)=>{
            console.log(error);  
        })
    }

    //跳转業者详情页面
    const toShowCompanyDetail =(val:string) => {
        router.push(
        { 
            path: '/companyView', 
            query: { 
                companyId : val
            } 
        }
    );
    }

    const BrowsedData = 
    async () => {
        let data = {
            propertyId:route.query.id
        }
        await api.post(`/user/browsed`,data)
        .then((res)=>{
            console.log(res);
            
        })
        .catch((error)=>{
            console.log(error);
            
        })
    }





// 概算見積
const budget = () => {


    // 概算見積情報設定
    // 賃料
    data.budgetInfo.rent = data.ApiData.propertyRentalDto.rent;
    // 価格
    data.budgetInfo.price = data.ApiData.propertySelling.price;
    // 積立金
    data.budgetInfo.deposit = data.ApiData.propertySelling.deposit;
    // その他一時金
    data.budgetInfo.sellingOtherLumpSum = data.ApiData.propertySelling.otherLumpSum;
    // 管理費
    data.budgetInfo.managementFee = data.ApiData.property.managementFee;
    data.budgetInfo.commonServiceFee = data.ApiData.propertyRentalDto.commonServiceFee;
    // 敷金
    data.budgetInfo.securityDepositMoney = data.ApiData.propertyRentalDto.securityDepositMoney;
    data.budgetInfo.securityDepositMonth = data.ApiData.propertyRentalDto.securityDepositMonth;
    // 礼金
    data.budgetInfo.keyMoney = data.ApiData.propertyRentalDto.keyMoney;
    data.budgetInfo.keyMonth = data.ApiData.propertyRentalDto.keyMonth;
    // 保証金
    data.budgetInfo.guaranteeDepositMoney = data.ApiData.propertyRentalDto.guaranteeDepositMoney;
    data.budgetInfo.guaranteeDepositMonth = data.ApiData.propertyRentalDto.guaranteeDepositMonth;
    // 敷引
    data.budgetInfo.deductionFromRentalDepositMoney =
    data.ApiData.propertyRentalDto.deductionFromRentalDepositMoney;
      data.budgetInfo.deductionFromRentalDepositMonth =
      data.ApiData.propertyRentalDto.deductionFromRentalDepositMonth;
    // その他費用(その他一時金)
    data.budgetInfo.rentalOtherLumpSum =data.ApiData.propertyRentalDto.otherLumpSum;
  };

  const securityDepositDisplay = computed(() => {
    if (
      data.budgetInfo.securityDepositMoney !== null &&
      data.budgetInfo.securityDepositMoney !== 0
    ) {
      return data.budgetInfo.securityDepositMoney + '万円';
    } else if (data.budgetInfo.securityDepositMonth !== null) {
      return data.budgetInfo.securityDepositMonth * data.budgetInfo.rent + '万円';
    } else {
      return '0万円';
    }
  });
  const keyMoneyDisplay = computed(() => {
    if (data.budgetInfo.keyMoney !== null && data.budgetInfo.keyMoney !== 0) {
      return data.budgetInfo.keyMoney + '万円';
    } else if (data.budgetInfo.keyMonth !== null) {
      return data.budgetInfo.keyMonth * data.budgetInfo.rent + '万円';
    } else {
      return '0万円';
    }
  });
  const guaranteeDepositDisplay = computed(() => {
    if (
      data.budgetInfo.guaranteeDepositMoney !== null &&
      data.budgetInfo.guaranteeDepositMoney !== 0
    ) {
      return data.budgetInfo.guaranteeDepositMoney + '万円';
    } else if (data.budgetInfo.guaranteeDepositMonth !== null) {
      return data.budgetInfo.guaranteeDepositMonth * data.budgetInfo.rent + '万円';
    } else {
      return '0万円';
    }
  });
  const deductionFromRentalDepositDisplay = computed(() => {
    if (
        data.budgetInfo.deductionFromRentalDepositMoney !== null &&
        data.budgetInfo.deductionFromRentalDepositMoney !== 0
    ) {
      return data.budgetInfo.deductionFromRentalDepositMoney + '万円';
    } else if (data.budgetInfo.deductionFromRentalDepositMonth !== null) {
      return (
        data.budgetInfo.deductionFromRentalDepositMonth * data.budgetInfo.rent + '万円'
      );
    } else {
      return '0万円';
    }
  });

  const totalBudget = computed(() => {
    const {
      keyMoney,
      keyMonth,
      securityDepositMoney,
      securityDepositMonth,
      guaranteeDepositMoney,
      guaranteeDepositMonth,
      deductionFromRentalDepositMoney,
      deductionFromRentalDepositMonth,
      rentalOtherLumpSum,
      managementFee,
      commonServiceFee,
      rent,
    } = data.budgetInfo;
    let calculatedKeyMoney = 0;
    if (keyMoney !== null && keyMoney !== 0) {
      calculatedKeyMoney = keyMoney;
    } else if (keyMonth !== null) {
      calculatedKeyMoney = keyMonth * rent;
    }
    let calculatedSecurityDepositMoney = 0;
    if (securityDepositMoney !== null && securityDepositMoney !== 0) {
      calculatedSecurityDepositMoney = securityDepositMoney;
    } else if (securityDepositMonth !== null) {
      calculatedSecurityDepositMoney = securityDepositMonth * rent;
    }

    let calculatedGuaranteeDepositMoney = 0;
    if (guaranteeDepositMoney !== null && guaranteeDepositMoney !== 0) {
      calculatedGuaranteeDepositMoney = guaranteeDepositMoney;
    } else if (guaranteeDepositMonth !== null) {
      calculatedGuaranteeDepositMoney = guaranteeDepositMonth * rent;
    }

    let calculatedDeductionFromRentalDepositMoney = 0;
    if (deductionFromRentalDepositMoney !== null && deductionFromRentalDepositMoney !== 0) {
      calculatedDeductionFromRentalDepositMoney = deductionFromRentalDepositMoney;
    } else if (deductionFromRentalDepositMonth !== null) {
      calculatedDeductionFromRentalDepositMoney = deductionFromRentalDepositMonth * rent;
    }
    const total =
      calculatedDeductionFromRentalDepositMoney +
      (rentalOtherLumpSum || 0) +
      calculatedGuaranteeDepositMoney +
      calculatedKeyMoney +
      calculatedSecurityDepositMoney +
      (managementFee || 0) +
      (commonServiceFee || 0) +
      (rent || 0);

    const formattedTotal = total.toFixed(2).replace(/\.0*$/, '');
    return formattedTotal;
  });




</script>
<style>
    .butStylewidth{
        min-width: 140px;
        margin-bottom: 20px;
    }
    .PropertyTable{
        width: 240px;
        background: #009688;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .propertyDetailBox{
        margin: 20px 0;
    }
    .dialogBox .v-card-actions{
        display:none;
    }
    .dialogBox .v-responsive__sizer{
        padding-bottom: 109% !important;
    }
</style>
<style scoped>
    .smallImage{
        height: 120px;
    }
    .smallImageBox{
        max-height:340px;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-right: 20px;
    }
    .smallImageBox::-webkit-scrollbar {
        width: 8px; 
    }

    .smallImageBox::-webkit-scrollbar-track {
        background-color: #ffffff; 
    }

    .smallImageBox::-webkit-scrollbar-thumb {
        background-color: #888888;
    }

    .smallImageBox::-webkit-scrollbar-thumb:hover {
        background-color: #555555;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); 
    }
    .initialcostDetail{
        margin-top: 20px;
    }

    .companyrow{
        text-align: left !important;
    }
    .companyrow_span{
        color: #007bff;
        cursor: pointer;
        font-weight: 700;
    }
    .companyrow_span:hover{
        color: blue;
    }
    
</style>