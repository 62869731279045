//内見ステータス
export const PreviewStatus = Object.freeze({
    Unsettled : {
        val:"0",
        text:"ModelEnumMessage.PreviewStatus.Unsettled"
      },
      ReApplication : {
        val:"1",
        text:"ModelEnumMessage.PreviewStatus.ReApplication"
      },
      Confirmed : {
        val:"2",
        text:"ModelEnumMessage.PreviewStatus.Confirmed"
      },
      Cancel : {
        val:"3",
        text:"ModelEnumMessage.PreviewStatus.Cancel"
      }
  });

  //申込ステータス
export const ApplicationStatus = Object.freeze({
  Application : {
      val:"1",
      text:"ModelEnumMessage.ApplicationStatus.Application"
    },
    Inspection : {
      val:"2",
      text:"ModelEnumMessage.ApplicationStatus.Inspection"
    },
    Contract : {
      val:"3",
      text:"ModelEnumMessage.ApplicationStatus.Contract"
    },
    Complete : {
      val:"4",
      text:"ModelEnumMessage.ApplicationStatus.Complete"
    }
});
// お問い合わせステータス	
export const InquiryStatus = Object.freeze({
  UnderInquiry: {
    val:"0",
    text:"問い合わせ中"
  },
  Completed: {
    val:"1",
    text:"完了"
  },
  Cancelled: {
    val:"2",
    text:"キャンセル"
  },
})
// 査定依頼ステータス
export const ApplicationAssessment = Object.freeze({
  Settlement: {
    val:"1",
    text:"查定依頼済"
  },
  AssessmentResponse: {
    val:"2",
    text:"查定回答"
  },
  Agreement: {
    val:"3",
    text:"成约"
  },
})
// 物件タイプ
export const PropertyType = Object.freeze({
  Buying: {
    val:"1",
    text:"売買"
  },
  Rental: {
    val:"2",
    text:"賃貸"
  }
});
//顧客タイプ
export const CustomerType = Object.freeze({
    User : {
      val: 1,
      text:"ユーザー"
    },
    ReApplication : {
      val: 2,
      text:"業者"
    }
});
