<template>
    <v-card>
        <v-layout>
            <the-header @chooselanguage='changeLanguageType'></the-header>
            <v-navigation-drawer width="180">
            </v-navigation-drawer>
            <v-navigation-drawer location="right" width="180">
            </v-navigation-drawer>
            <v-main>
                <v-container class="align-center">
                    <v-row>
                        <v-col cols="12">
                            <p class="text-h4" mt-4>{{ $t('RentalManagement.rentalManagementList') }}</p>
                        </v-col>
                    </v-row>
                    <!-- 一覧画面 -->
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-table>
                                <thead>
                                    <tr>
                                        <th class="text-left text-h6">
                                            No.
                                        </th>
                                        <th class="text-left text-h6">
                                            {{ $t('RentalManagement.propertyName') }}
                                        </th>
                                        <th class="text-left text-h6">
                                            {{ $t('RentalManagement.owner') }}
                                        </th>
                                        <th class="text-left text-h6">
                                            {{ $t('RentalManagement.manager') }}

                                        </th>
                                        <th class="text-left text-h6">
                                            {{ $t('RentalManagement.roomStatus') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in data.rentalProperties" :key="item.rentalManagementId">
                                        <td>{{ index + 1 }}</td>
                                        <td> <a class="a-link" href="#"
                                                @click.stop.prevent="selectRentalProperty(item.rentalManagementId)">
                                                {{ item.propertyName }}
                                            </a></td>
                                        <td>{{ item.ownerName }}</td>
                                        <td>{{ item.contactPersonName }}</td>
                                        <td>{{ stateList[item.status] }}</td>
                                    </tr>
                                </tbody>

                            </v-table>
                        </v-col>
                    </v-row>
                    <v-row class="home-button-row">
                        <v-btn class="home-button" color="success" variant="outlined" @click="goHome">
                            {{ $t('RentalManagement.rentManagementDetail.buttons.goHome') }}
                        </v-btn>
                    </v-row>
                    <v-row class="mt-10">
                        <the-footer></the-footer>
                    </v-row>
                </v-container>
            </v-main>
        </v-layout>
    </v-card>
</template>
<style>
.table-border {
    border: 1px;
    border-style: solid;
    border-color: antiquewhite;
}

.home-button-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.home-button {
    background-color: #ffffff;
    color: #4CAF50;
}

.home-button:hover {
    background-color: #4CAF50 !important;
    ;
    color: #ffffff !important;
    ;
}
</style>
<script lang="ts">
import { defineComponent, reactive, computed, getCurrentInstance, onMounted, defineProps } from 'vue'
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import { useRoute, useRouter } from 'vue-router'
// import axions from 'axios'
import api from '@/api/axios/axios'
import {RentalManagement, Property } from './../api/sys/model/model'
import LoginView from './LoginView.vue'
import i18n from '../language/index'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    name: 'RentalManagement',
    components: {
        TheHeader,
        TheFooter,
    },
});
</script>

<script lang="ts" setup>
const props = defineProps({
    propertyInfo: Object,
})
const stateList = ['空室','賃貸中','满室']
const data = reactive({
    dialog: false,
    rentalProperties: {} as RentalManagement[],
    property: {} as Property,
    languageType: 'jp' as string
});
const router = useRouter();
const route = useRoute();

const selectRentalProperty = (rentalManagementId: string) => {
    router.push(`/rentalManagement/${rentalManagementId}`);
};
const goHome = () => {
    router.go(-1);
};
onMounted(() => {
    getInitData()
});

const getInitData = () => {
    api.get(`/user/rentalManagement`,{params:{ownerEmail:route.query.ownerEmail}})
        .then((res) => {
            data.rentalProperties = res.data
        })
        .catch((error) => {
            console.log(error.message);
        });
};
const changeLanguageType = (val: 'jp' | 'en' | 'zh') => {
    data.languageType = val
    console.log(val);
}
</script> 
