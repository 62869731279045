<template>
    <v-card>
      <v-layout>
          <v-app-bar color="surface-variant"  height="50">
              <v-toolbar-title><a href="#" style ="text-decoration: none; color:aliceblue;" @click="toHome()">CLOUDPF</a></v-toolbar-title>
          </v-app-bar>
          <v-navigation-drawer color="surface" width="180">
          </v-navigation-drawer>
          <v-navigation-drawer color="surface" location="right" width="180">
          </v-navigation-drawer>
          <v-main>
          <v-container lass="align-center">
            <div class="outLineBox">
            <div class="registerViewTitleBox">
                <p class="registerViewTitle" v-if="data.setpId == 0"><span>プロファイル</span></p>
                <p class="registerViewTitle" v-if="data.setpId == 1"><span>パスワード変更</span></p>
             </div>
             <div class="registerForm" v-if="data.setpId == 0">
                <v-form v-if="!data.changeUserName">
                    <v-container>
                        <v-row>
                            <v-col cols="4" class="registerinputName">
                                <span >メールアドレス</span>
                            </v-col>
                            <v-col cols="8" class="registerinputstyle">
                                {{data.userEmail}}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" class="registerinputName">
                                <span >名前</span>
                            </v-col>
                            <v-col cols="8" class="registerinputstyle">
                               
                            <v-text-field
                                v-model="data.userName"
                                type="userName"
                                required
                            ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" class="registerinputName">
                                <span >電話番号</span>
                            </v-col>
                            <v-col cols="8" class="registerinputstyle">
                               
                            <v-text-field
                                v-model="data.phone"
                                required
                            ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="agressMessage">
                            <div class="agressMessageBox">
                                <button class="registerViewButton" @click="goBack">ホームへ</button>
                                <button class="registerViewButton" @click="changeuserName">内容を更新</button>
                            </div>
                        </v-row>
                    </v-container>
                </v-form>
                <div class="changeUserNameTitle" v-if="data.changeUserName">
                    <p>プロファイル変更完了しました。</p>
                    <v-row class="agressMessage">
                            <div class="agressMessageBox">
                                <button class="registerViewButton" @click="goBackPage">ホームへ</button>
                            </div>
                    </v-row>
                </div>
             </div>
             <div class="registerForm" v-if="data.setpId == 1">
                <v-form v-if="!data.isChangePassword">
                    <v-container>
                        <v-row>
                            <v-col cols="4" class="registerinputName">
                                <span >現在パスワード</span>
                            </v-col>
                            <v-col cols="8" class="registerinputstyle">
                                <v-text-field
                                    v-model="data.oldPassword"
                                     type="password"
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" class="registerinputName">
                                <span >新しいパスワード</span>
                            </v-col>
                            <v-col cols="8" class="registerinputstyle">
                               <v-text-field
                                    v-model="data.newPassword"
                                     type="password"
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" class="registerinputName">
                                <span >新しいパスワード（確認用）</span>
                            </v-col>
                            <v-col cols="8" class="registerinputstyle">
                                <v-text-field
                                    v-model="data.reNewPassword"
                                     type="password"
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="agressMessage">
                            <div class="agressMessageBox">
                                <button class="registerViewButton" @click="TochangePassword">パスワードを変更する</button>
                            </div>
                        </v-row>
                    </v-container>
                </v-form>
                <div class="changeUserNameTitle" v-if="data.isChangePassword">
                    <p>パスワード変更が完了しました。</p>
                    <v-row class="agressMessage">
                            <div class="agressMessageBox">
                                <button class="registerViewButton" @click="goBackPage()">ホームへ</button>
                            </div>
                    </v-row>
                </div>
             </div>
             
             
            </div>
              <v-row class="mt-10">
                  <the-footer></the-footer>
              </v-row>
          </v-container>
          </v-main>
      </v-layout>
    </v-card>
</template>
  <script lang="ts">
  import { defineComponent,reactive, computed, getCurrentInstance, onMounted, defineProps } from 'vue'
  import TheFooter from './components/TheFooter.vue'
  import { useRoute, useRouter } from 'vue-router'
  import { changePassword } from '../cognito/cognito';
  import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
  } from 'amazon-cognito-identity-js';
    
  
  export default defineComponent({
      name: 'ChangeUserInfo',
      components: {
          TheFooter,
      },
  });
  </script>
  <script lang="ts" setup>
import api from '@/api/axios/axios';
      const data = reactive({
          isChangePassword:false,
          changeUserName:false,
          userInfoString:'' as any,
          userInfo:{
            email:'',
            userName:'',
            phoneNumber:''
          },
          phone:'',
          userName:'田中太郎',
          userEmail:'tanaka@gmail.com',
          setpId:0 as number,
          oldPassword:'',
          newPassword:'',
          reNewPassword:'',
      })
    const router = useRouter()
    const route = useRoute()
    onMounted(()=>{
        console.log(route.query.setpId);
        data.setpId = Number(route.query.setpId)
        data.userInfoString = localStorage.getItem('CLOUDPFM_CO__USERINFO')
        data.userInfo = JSON.parse(data.userInfoString)
        data.userName = data.userInfo.userName
        data.userEmail = data.userInfo.email
        data.phone = data.userInfo.phoneNumber
        
    })

    

    const toHome = () => {
        router.push("/index")
    }
    const goBackPage = () => {
        router.push("/")
    }
    const goBack = () => {
        router.push("/")
    }
    //修改用户名字 
    const changeuserName = ()=> {
        let registerData = {
            userName:data.userName,
            phoneNumber:data.phone
        }
        api.put(`/user/update`,registerData)
        .then((res) => {
           console.log(res.data);
           data.userInfo.userName = data.userName
           data.userInfo.phoneNumber = data.phone
           console.log(data.userInfo);
           localStorage.setItem('CLOUDPFM_CO__USERINFO',JSON.stringify(data.userInfo))
           data.changeUserName = true
        })
        .catch((error) => {
            console.log(error);
        })
    }

    // 修改密码
    const TochangePassword =()=> {
      changePassword(data.userInfo.email,data.oldPassword,data.newPassword)
      .then((res)=>{
        console.log(res);
        if(res=="修改成功"){
            data.isChangePassword = true
        }
      })
       
    }
  </script>
  <style scoped>
        .agressMessageBox>button{
            margin: 0 20px;
        }
        .changeUserNameTitle{
            font-size: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 20px 0;
        }
  </style>