<template>
    <v-card>
        <v-layout>
            <the-header @chooselanguage='changeLanguageType'></the-header>
            <v-main>
                <v-container class="align-center">
                    
                    <div>
                        <!-- 物件情報 -->
                        <v-row>
                            <v-col cols="12">
                                <div class="text-h4 text-left">{{ $t('RentalManagement.rentManagementDetail.title') }}</div>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-table>
                                    <thead>
                                        <tr>
                                            <th class="text-center text-h6">{{ $t('RentalManagement.propertyName') }}</th>
                                            <th class="text-center text-h6">{{ $t('RentalManagement.address') }}</th>
                                            <th class="text-center text-h6">{{ $t('RentalManagement.roomNumber') }}</th>
                                            <th class="text-center text-h6">{{ $t('RentalManagement.managementStartDate') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center">{{ data.propertyName }}</td>
                                            <td class="text-center">{{ data.address }}</td>
                                            <td class="text-center">{{ data.roomNumber }}</td>
                                            <td class="text-center">{{ data.managementStartDate }}</td>
                                        </tr>
                                    </tbody>

                                </v-table>
                            </v-col>
                        </v-row>
                        <!-- オーナー様情報詳細 -->
                        <div class="text-h4 text-left">{{ $t('RentalManagement.owenerInfoDetail') }}</div>
                        <v-row no-gutters class="mt-4">
                            <v-col col="12">
                                <v-table class="table-border">
                                    <tbody>
                                        <tr>
                                            <th class="text-left table-th">{{ $t('RentalManagement.name') }}</th>
                                            <th>{{ data.ownerName }}</th>
                                            <th class="text-left table-th">{{ $t('RentalManagement.katakana') }}</th>
                                            <th>{{ data.ownerKatakana }}</th>
                                        </tr>
                                        <tr>
                                            <th class="text-left table-th">{{ $t('RentalManagement.phone') }}</th>
                                            <th>{{ data.ownerPhone }}</th>
                                            <th class="text-left table-th">{{ $t('RentalManagement.mail') }}</th>
                                            <th>{{ data.ownerEmail }}</th>
                                        </tr>
                                    </tbody>
                                </v-table>
                            </v-col>
                        </v-row>


                    </div>
                    <div class="text-h4 text-left mt-4">{{ $t('RentalManagement.managerInfo') }}</div>
                    <v-row no-gutters class="mt-4">
                        <v-col col="12">
                            <v-table class="table-border">
                                <tbody>
                                    <tr>
                                        <th class="text-left table-th">{{ $t('RentalManagement.name') }}</th>
                                        <th>{{ data.contactPersonName }}</th>
                                        <th class="text-left table-th">{{ $t('RentalManagement.katakana') }}</th>
                                        <th>{{ data.contactPersonNameKatakana }}</th>
                                    </tr>
                                    <tr>
                                        <th class="text-left table-th">{{ $t('RentalManagement.phone') }}</th>
                                        <th>{{ data.contactPersonPhoneNo }}</th>
                                        <th class="text-left table-th">{{ $t('RentalManagement.mail') }}</th>
                                        <th>{{ data.contactPersonEmail }}</th>
                                    </tr>
                                </tbody>
                            </v-table>
                        </v-col>
                    </v-row>
                    <div class="text-h4 text-left mt-4">{{ $t('RentalManagement.reportList') }}</div>
                        <!-- buttons -->
                        <v-row class="mt-4" style="margin-bottom: 20px;">
                            <v-col cols="12">
                                <div class="justify-start">
                                    <v-btn class="detail-btn" @click="goToRentalPayment">{{ $t('RentalManagement.rentManagementDetail.buttons.goToRentalPayment') }}</v-btn>
                                    <v-btn class="ml-4 detail-btn" @click="goToTenantReport">{{ $t('RentalManagement.rentManagementDetail.buttons.goToTenantReport') }}</v-btn>
                                    <v-btn class="ml-4 detail-btn" @click="goToPropertyReport">{{ $t('RentalManagement.rentManagementDetail.buttons.goToPropertyReport') }}</v-btn>
                                    <v-btn class="ml-4 detail-btn" @click="goToContract">{{ $t('RentalManagement.rentManagementDetail.buttons.goToContract') }}</v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    <v-row class="mt-10">
                        <the-footer></the-footer>
                    </v-row>
                </v-container>
            </v-main>
        </v-layout>
    </v-card>
</template>
    
<style>
.table-border {
    border-collapse: collapse;
    border: 1px;
    border-style: solid;
    border-color: antiquewhite;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #e0e0e0;
}
</style>
<script lang="ts">
import { ref, reactive, onMounted, getCurrentInstance, defineComponent, defineProps } from 'vue'
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import { useRoute, useRouter } from 'vue-router'
// import axions from 'axios'
import api from '@/api/axios/axios'
import i18n from '../language/index'
import { RentalManagement, Property } from '@/api/sys/model/model'

export default defineComponent({
    name: 'RentalManagementDetail',
    components: {
        TheHeader,
        TheFooter,
    }
});
</script>

<script lang="ts" setup>
const router = useRouter();
const route = useRoute();

const data = reactive({
    address: "",
    contactPersonEmail: "",
    contactPersonName: "",
    contactPersonNameKatakana: "",
    contactPersonPhoneNo: "",
    managementStartDate: "",
    ownerEmail: "",
    ownerKatakana: "",
    ownerName: "",
    ownerPhone: "",
    propertyName: "",
    rentalManagementId: "",
    roomNumber: "",
    status: 0,
    languageType: 'jp' as string
});
onMounted(() => {
    getInitData();

});
const getInitData = () => {
    data.rentalManagementId = route.params.rentalManagementId.toString();
    api.get('/user/rentalManagement/' + data.rentalManagementId).then((res) => {
        data.address = res.data.address;
        data.contactPersonEmail = res.data.contactPersonEmail;
        data.contactPersonName = res.data.contactPersonName;
        data.contactPersonNameKatakana = res.data.contactPersonNameKatakana;
        data.contactPersonPhoneNo = res.data.contactPersonPhoneNo;
        data.managementStartDate = res.data.managementStartDate;
        data.ownerEmail = res.data.ownerEmail;
        data.ownerKatakana = res.data.ownerKatakana;
        data.ownerName = res.data.ownerName;
        data.ownerPhone = res.data.ownerPhone;
        data.propertyName = res.data.propertyName;
        data.rentalManagementId = res.data.rentalManagementId;
        data.roomNumber = res.data.roomNumber;
        data.status = res.data.status;
    }).catch((error) => {
        console.log(error.message)
    })

}
const goToRentalPayment = () => {
    router.push({
        name: 'RentalPayment',
        params: { rentalManagementId: data.rentalManagementId }
    });
}
const goToTenantReport = () => {
    router.push({
        name: 'RentalTenantReport',
        params: { rentalManagementId: data.rentalManagementId }
    });
}
const goToPropertyReport = () => {
    router.push({
        name: 'RentalPropertyReport',
        params: { rentalManagementId: data.rentalManagementId }
    });
}
const goToContract = () => {
    router.push({
        name: 'RentalContract',
        params: { rentalManagementId: data.rentalManagementId }
    });
}
const changeLanguageType = (val: 'jp' | 'en' | 'zh') => {
    data.languageType = val
}

</script>
<style scoped>

.detail-btn{
    --v-theme-overlay-multiplier: var(--v-theme-success-overlay-multiplier);
    background-color: rgb(var(--v-theme-success)) !important;
    color: rgb(var(--v-theme-on-success)) !important;
    font-size: medium;
}


</style>
