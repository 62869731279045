<template>
  <main>
    <!-- <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view> -->
    <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" :key="$route.path" v-if="$route.meta.keepAlive"/>
    </keep-alive>
      <component :is="Component" :key="$route.path" v-if="!$route.meta.keepAlive"/>
    </router-view>   
  </main>
</template>


<script lang="ts">
import { defineComponent } from 'vue'
import { RouterView } from 'vue-router'

export default defineComponent({

})
</script>
<style>
/* App.vue 或其他主要的布局组件 */
@import '@/assets/css/style.css'; /* 使用@符号表示项目根目录 */
</style>