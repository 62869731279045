<template>
    <v-card>
      <v-layout>
           <the-header></the-header>
          <v-navigation-drawer color="surface" width="180">
          </v-navigation-drawer>
          <v-navigation-drawer color="surface" location="right" width="180">
          </v-navigation-drawer>
          <v-main>
          <v-container lass="align-center">
            <v-card>
                <v-tabs
                v-model="data.tab"
                bg-color="#f1f5f9"
                class="simulatorTitle"
                >
                    <v-tab value="one"
                        class="simulatorTitleItemBox"
                    >
                       <p class="simulatorTitleFrist"><span>{{$t('simulatorView.titleNavList.RequiredAmountOfLoan')}}</span>{{$t('simulatorView.titleNavList.andOr')}}</p>
                       <p class="simulatorTitleSecond">{{$t('simulatorView.titleNavList.frist')}}</p>
                    </v-tab>

                    <v-tab value="two"
                        class="simulatorTitleItemBox"
                    >
                       <p class="simulatorTitleFrist"><span>{{$t('simulatorView.titleNavList.AnnualIncome')}}</span>{{$t('simulatorView.titleNavList.andOr')}}</p>
                       <p class="simulatorTitleSecond">{{$t('simulatorView.titleNavList.second')}}</p>
                    </v-tab>

                    <v-tab value="three"
                        class="simulatorTitleItemBox"
                    >
                       <p class="simulatorTitleFrist"><span>{{$t('simulatorView.titleNavList.MonthlyRepayment')}}</span>{{$t('simulatorView.titleNavList.andOr')}}</p>
                       <p class="simulatorTitleSecond">{{$t('simulatorView.titleNavList.second')}}</p>
                    </v-tab>
                </v-tabs>

                <v-card-text>
                    <v-window v-model="data.tab">
                        <v-window-item value="one">
                            <div class="contentBox">
                                <v-row>
                                    <v-col class="simulatorInputTitle" cols="3">{{$t('simulatorView.borrowMuch')}}</v-col>
                                    <v-col cols="5" class="simulatorInput"><v-text-field v-model="data.fristForm.allPrice" class="inputContent"></v-text-field><span class="InputUtil">万円</span></v-col>
                                </v-row>

                                <v-row v-if="data.fristForm.outinline!='2'">
                                    <v-col class="simulatorInputTitle" cols="3">{{$t('simulatorView.internalBonus')}}</v-col>
                                    <v-col cols="5" class="simulatorInput"><v-text-field v-model="data.fristForm.bonus" class="inputContent"></v-text-field><span class="InputUtil">万円</span></v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="simulatorInputTitle" cols="3">
                                        <p>{{$t('simulatorView.RepaymentPeriod')}}</p>
                                    </v-col>
                                    <v-col cols="5" md="3" class="simulatorInput">
                                        <v-select 
                                        :items="yearArray"
                                        v-model="data.fristForm.year"
                                        >
                                        </v-select>
                                        <span class="InputUtil">年</span>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="simulatorInputTitle" cols="3">{{$t('simulatorView.rate')}}</v-col>
                                    <v-col  cols="5" md="3" class="simulatorInput"><v-text-field v-model="data.fristForm.rent" class="inputContent"></v-text-field><span class="InputUtil">%</span></v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="simulatorInputTitle" cols="3">
                                        <p>{{$t('simulatorView.repayment')}}</p>
                                    </v-col>
                                    <v-col cols="9" class="simulatorInput">
                                        <v-radio-group
                                        v-model="data.fristForm.outinline"
                                        @click="toCleanbox"
                                        inline
                                        >
                                        <v-radio
                                            :label="$t('simulatorView.repaymentList.EqualInterestAndPrincipal')"
                                            value="1"
                                        ></v-radio>

                                        <v-radio
                                            :label="$t('simulatorView.repaymentList.EqualPrincipal')"
                                            value="2"
                                        ></v-radio>

                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </div>
                            <div class="buttonBox">
                                <v-btn class="simulatorButton" @click="toCalculate">
                                    {{$t('simulatorView.butTo')}}
                                </v-btn>
                            </div>
                        </v-window-item>

                        <v-window-item value="two">
                            <div class="contentBox">
                                <v-row>
                                    <v-col class="simulatorInputTitle" cols="3">{{$t('simulatorView.CurrentIncome')}}</v-col>
                                    <v-col cols="5" class="simulatorInput"><v-text-field v-model="data.secondForm.income" class="inputContent"></v-text-field><span class="InputUtil">万円</span></v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="simulatorInputTitle" cols="3">
                                        <p>{{$t('simulatorView.RepaymentPeriod')}}</p>
                                    </v-col>
                                    <v-col cols="5" md="3" class="simulatorInput">
                                        <v-select 
                                        :items="yearArray"
                                        v-model="data.secondForm.year"
                                        >
                                        </v-select>
                                        <span class="InputUtil">年</span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="simulatorInputTitle" cols="3">{{$t('simulatorView.rate')}}</v-col>
                                    <v-col cols="5" md="3" class="simulatorInput"><v-text-field v-model="data.secondForm.rent" class="inputContent"></v-text-field><span class="InputUtil">%</span></v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="simulatorInputTitle" cols="3">{{$t('simulatorView.ReduceRepaymentBurden')}}</v-col>
                                    <v-col cols="5" md="3" class="simulatorInput"><v-text-field v-model="data.secondForm.RepaymentRatio" class="inputContent"></v-text-field><span class="InputUtil">%</span></v-col>
                                </v-row>

                            </div>
                            <div class="buttonBox">
                                <v-btn class="simulatorButton" @click="toCalculateLoanAmounts">
                                    {{$t('simulatorView.butTo')}}
                                </v-btn>
                            </div>
                        </v-window-item>

                        <v-window-item value="three">
                            <div class="contentBox">
                                <v-row>
                                    <v-col class="simulatorInputTitle" cols="3">{{$t('simulatorView.MonthlyRepayment') }}</v-col>
                                    <v-col cols="5" class="simulatorInput"><v-text-field v-model="data.thirdlyForm.MonthlyPayment" class="inputContent"></v-text-field><span class="InputUtil">万円</span></v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="simulatorInputTitle" cols="3">
                                        <p>{{$t('simulatorView.RepaymentPeriod') }}</p>
                                    </v-col>
                                    <v-col cols="5" md="3" class="simulatorInput">
                                        <v-select 
                                        v-model="data.thirdlyForm.year"
                                        :items="yearArray"
                                        >
                                        </v-select>
                                        <span class="InputUtil">年</span>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="simulatorInputTitle" cols="3">{{$t('simulatorView.rate') }}</v-col>
                                    <v-col cols="5" md="3" class="simulatorInput"><v-text-field v-model="data.thirdlyForm.rent" class="inputContent"></v-text-field><span class="InputUtil">%</span></v-col>
                                </v-row>
                            </div>
                            <div class="buttonBox">
                                <v-btn class="simulatorButton" @click="ToMonthlyPayment">
                                    {{$t('simulatorView.butTo')}}
                                </v-btn>
                            </div>
                        </v-window-item>
                    </v-window>
                </v-card-text>

            </v-card>

            <v-card class="result-card">
               <v-card class="resultBox" v-if="data.tab=='one'">
                    <div class="titleButtomLine">
                        <p class="resultTitle  resultwidth">{{$t('simulatorView.resultBox.title')}}</p>
                    </div>
                    
                    <div class="titleButtomLine">
                        <p class="resultNumber" v-if="data.fristForm.outinline=='1'">
                            <span class="resultTxt">{{$t('simulatorView.resultBox.MonthlyRepayment')}} <span class="resultwidth"> : </span></span>
                            <span class="NumberText">
                                {{data.fristForm.monthlyPayment/10000}}
                                <span class="resultUtil">万円</span>
                            </span>
                        </p>
                        <p class="resultNumber" v-if="data.fristForm.outinline=='2'">
                            <span class="resultTxt ">{{$t('simulatorView.resultBox.TheRepayment')}} <span class="resultwidth"> : </span></span>
                            <span class="NumberText">
                                {{data.fristForm.monthlyPayment/10000}}
                                <span class="resultUtil">万円</span>
                            </span>
                        </p>
                    </div>

                    <div class="titleButtomLine" v-if="data.fristForm.outinline=='1'">
                        <p class="resultNumberContent" >
                            <span class="resultTxt ">{{$t('simulatorView.resultBox.MonthlyBonusPayments')}} <span class="resultwidth"> : </span></span>
                            <span class="NumberText">
                                {{data.fristForm.BonusPayment/10000}}
                                <span class="resultUtil">万円</span>
                            </span>
                        </p>
                    </div>

                    <div class="titleButtomLine">
                        <p class="resultNumberContent">
                            <span class="resultTxt ">{{$t('simulatorView.resultBox.AnnualRepaymentAmount')}} <span class="resultwidth"> : </span></span>
                            <span class="NumberText">
                                {{data.fristForm.AnnualPayment/10000}}
                                <span class="resultUtil">万円</span>
                            </span>
                        </p>

                        <p class="resultNumberContent" v-if="data.fristForm.outinline=='1'">
                            <span class="resultTxt ">{{$t('simulatorView.resultBox.TotalRepayment')}} <span class="resultwidth"> : </span></span>
                            <span class="NumberText">
                                {{data.fristForm.TotalPayment/10000}}
                                <span class="resultUtil">万円</span>
                            </span>
                        </p>

                        <p class="resultNumberContent" v-if="data.fristForm.outinline=='2'">
                            <span class="resultTxt ">{{$t('simulatorView.resultBox.TotalRepayment')}} <span class="resultwidth"> : </span></span>
                            <span class="NumberText">
                                {{data.fristForm.TotalPayment/10000}}
                                <span class="resultUtil">万円</span>
                            </span>
                        </p>
                    </div>
               </v-card>

               <v-card class="resultBox" v-if="data.tab=='two'">
                    <div class="titleButtomLine">
                        <p class="resultTitle  resultwidth">{{$t('simulatorView.resultBox.title')}}</p>
                    </div>
                    
                    <div class="titleButtomLine">
                        <p class="resultNumber" >
                            <span class="resultTxt ">{{$t('simulatorView.resultBox.AmountOfBorrowing')}} <span class="resultwidth"> : </span></span>
                            <span class="NumberText">
                                {{data.secondForm.LoanableAmountAll/10000}}
                                <span class="resultUtil">万円</span>
                            </span>
                        </p>
                    </div>

                    <div class="titleButtomLine">
                        <p class="resultNumberContent">
                            <span class="resultTxt ">{{$t('simulatorView.resultBox.MonthlyBonusPayments')}} <span class="resultwidth"> : </span></span>
                            <span class="NumberText">
                                {{data.secondForm.LoanableAmountMondth/10000}}
                                <span class="resultUtil">万円</span>
                            </span>
                        </p>
                    </div>

                    <div class="titleButtomLine">
                        <p class="resultNumberContent">
                            <span class="resultTxt ">{{$t('simulatorView.resultBox.AnnualRepaymentAmount')}} <span class="resultwidth"> : </span></span>
                            <span class="NumberText">
                                {{data.secondForm.LoanableAmountYear/10000}}
                                <span class="resultUtil">万円</span>
                            </span>
                        </p>
                        <p class="resultNumberContent">
                            <span class="resultTxt">{{$t('simulatorView.resultBox.TotalRepayment')}} <span class="resultwidth"> : </span></span>
                            <span class="NumberText">
                                {{data.secondForm.LoanableAmount/10000}}
                                <span class="resultUtil">万円</span>
                            </span>
                        </p>
                    </div>
               </v-card>

               <v-card class="resultBox" v-if="data.tab=='three'">
                    <div class="titleButtomLine">
                        <p class="resultTitle  resultwidth">{{$t('simulatorView.resultBox.title')}}</p>
                    </div>
                    
                    <div class="titleButtomLine">
                        <p class="resultNumber">
                            <span class="resultTxt">{{$t('simulatorView.resultBox.AmountOfBorrowing')}} <span class="resultwidth"> : </span></span>
                            <span class="NumberText">
                                {{data.thirdlyForm.LoanableAmountAll/10000}}
                                <span class="resultUtil">万円</span>
                            </span>
                        </p>
                    </div>

                    <div class="titleButtomLine">
                        <p class="resultNumberContent">
                            <span class="resultTxt">{{$t('simulatorView.resultBox.AnnualRepaymentAmount')}} <span class="resultwidth"> : </span></span>
                               <span class="NumberText">
                                    {{data.thirdlyForm.LoanableAmountYear/10000}}
                                    <span class="resultUtilCont">万円</span>
                               </span>
                            
                        </p>
                        <p class="resultNumberContent">
                            <span class="resultTxt">{{$t('simulatorView.resultBox.TotalRepayment')}} <span class="resultwidth"> : </span></span>
                            <span class="NumberText">
                                {{data.thirdlyForm.LoanableAmount/10000}}
                                <span class="resultUtilCont">万円</span>
                            </span>      
                        </p>
                    </div>
               </v-card>
            </v-card>

              <v-row class="mt-10">
                  <the-footer></the-footer>
              </v-row>
          </v-container>
          </v-main>
      </v-layout>
    </v-card>
</template>
  <script lang="ts">
  import { defineComponent,reactive, computed, getCurrentInstance, onMounted, defineProps } from 'vue'
  import api from '@/api/axios/axios'
  import TheFooter from './components/TheFooter.vue'
  import TheHeader from './components/TheHeader.vue'
  import { useRouter ,useRoute} from 'vue-router'
  
  export default defineComponent({
      name: 'InitialCostView',
      components: {
          TheHeader,
          TheFooter,
      },
  });
  </script>
  <script lang="ts" setup>
      const data = reactive({
       tab:'one',
       inline: null,
       thirdlyForm:{
        MonthlyPayment:80000,
        year:35,
        rent:0.219,
        LoanableAmountAll:0,
        LoanableAmountYear:0,
        LoanableAmount:0,
       },
       secondForm:{
        income:400,
        year:35,
        rent:0.219,
        RepaymentRatio:20,
        LoanableAmountAll:0,
        LoanableAmountMondth:0,
        LoanableAmountYear:0,
        LoanableAmount:0,
       },
       fristForm:{
        allPrice:2700,
        bonus:300,
        year:24,
        rent:1.8,
        outinline:'1',
        monthlyPayment:0,
        BonusPayment:0,
        AnnualPayment:0,
        TotalPayment:0
       }
      })
    const router = useRouter()
    const route = useRoute()
    const toHome = () => {
        router.push("/index")
    }
    const toGoBack = () => {
        router.push("/detail")
    }

    let yearArray: number[] = [];

    for (let i = 1; i <= 35; i++) {
        yearArray.push(i);
    }
    const toCalculate = () => {
        if(data.fristForm.outinline=='1'){
            let monthlyRate = (data.fristForm.rent/100/12).toFixed(5)   //月利率

            console.log(data.fristForm.allPrice*Number(monthlyRate)*(1+Number(monthlyRate))**(data.fristForm.year*12));
            console.log(((1+Number(monthlyRate))**(data.fristForm.year*12)-1));

            data.fristForm.monthlyPayment = Math.floor((data.fristForm.allPrice*10000)*Number(monthlyRate)*(1+Number(monthlyRate))**(data.fristForm.year*12)/((1+Number(monthlyRate))**(data.fristForm.year*12)-1))

            data.fristForm.AnnualPayment = data.fristForm.monthlyPayment*12

            data.fristForm.TotalPayment = data.fristForm.monthlyPayment*12*data.fristForm.year

            let twoRate = (data.fristForm.rent/100/2).toFixed(5)
            console.log(twoRate);
            if(data.fristForm.bonus==0){
                data.fristForm.BonusPayment = data.fristForm.monthlyPayment
            }else{
                let twoMonthlyPayment = Math.floor(((data.fristForm.bonus)*10000)*Number(twoRate)*(1+Number(twoRate))**(data.fristForm.year*2)/((1+Number(twoRate))**(data.fristForm.year*2)-1))

                data.fristForm.BonusPayment = twoMonthlyPayment + data.fristForm.monthlyPayment
            }
        }else
        if(data.fristForm.outinline=='2'){
            let monthlyRate = (data.fristForm.rent/100/12).toFixed(5) 
            console.log(monthlyRate);
            
            // 元金返済額
            let PrincipalRepayment = Math.floor((data.fristForm.allPrice*10000)/(data.fristForm.year*12)) 

            // 利息返済額
            let InterestRepayment = (data.fristForm.allPrice*10000)*(data.fristForm.rent/12/100)
                        
            data.fristForm.monthlyPayment = InterestRepayment + PrincipalRepayment
            // 借入金残高
            let LoanBalance = (data.fristForm.allPrice*10000)-PrincipalRepayment
            
            let thisAll  =  data.fristForm.monthlyPayment
            
            for(let i = 1;i<12;i++){
                let thisRent = LoanBalance*(data.fristForm.rent/12/100)
                thisAll = thisAll+Math.floor(thisRent+PrincipalRepayment)
                LoanBalance =  LoanBalance-PrincipalRepayment
            }
            console.log(thisAll);
            data.fristForm.AnnualPayment = thisAll

            data.fristForm.TotalPayment = Math.floor(Number(data.fristForm.allPrice)*10000 + (Number(data.fristForm.allPrice)*10000)*(data.fristForm.rent/12/100)*(Number(data.fristForm.year)*12+1)*1/2)
            

        }
      
        
       
        
    }
    const toCalculateLoanAmounts = () => {
        console.log(data.secondForm);
        // 返済負担率
        let ratio = data.secondForm.RepaymentRatio/100
        // 年間の返済上限額の計算
        let repaymentCapYear = ratio*(data.secondForm.income*10000)
        // 月間の返済上限額の計算
        let repaymentCapMonth = repaymentCapYear/12
        // 月利の計算
        let MonthCom = data.secondForm.rent/100/12
        // 返済回数
        let numAll = data.secondForm.year*12
        
        data.secondForm.LoanableAmountAll = Math.floor(repaymentCapMonth*((1-1/(1+MonthCom)**numAll)/MonthCom))
        data.secondForm.LoanableAmountMondth = Math.floor(repaymentCapMonth)
        data.secondForm.LoanableAmountYear = repaymentCapYear
        data.secondForm.LoanableAmount =  Math.floor(repaymentCapMonth*numAll)
    }
    const ToMonthlyPayment = () => {
        // 返済回数
        let numAll = data.thirdlyForm.year*12
        //月利の計算
        let MonthCom = data.thirdlyForm.rent/100/12

        data.thirdlyForm.LoanableAmountAll = Math.floor(data.thirdlyForm.MonthlyPayment*((1-1/(1+MonthCom)**numAll)/MonthCom))
        data.thirdlyForm.LoanableAmountYear = data.thirdlyForm.MonthlyPayment*12
        data.thirdlyForm.LoanableAmount = data.thirdlyForm.LoanableAmountYear*data.thirdlyForm.year
    }
    const toCleanbox = () => {
       data.fristForm.AnnualPayment = 0
       data.fristForm.BonusPayment = 0
       data.fristForm.TotalPayment = 0
       data.fristForm.monthlyPayment = 0
        
    }
     
  </script>
  <style >
   @media screen and (min-width:784px){
        .simulatorTitle{
            display: flex;
            height: 100px;
            border: 2px solid #cbd5e1;
        }
        .laScreen{
            display: none;
        }
    }
    @media screen and (max-width:784px){
        .simulatorTitle{
            display: flex;
            height: 295px;
            border: 2px solid #cbd5e1;
        }
        .simulatorTitle .v-slide-group__content{
            flex-direction: column;
        }
        .xsScreen{
            display: none;
        }
    }
    
    .simulatorTitle button{
        flex: 1;
    }
    .simulatorTitle .v-btn{
        height: 100px;
        border-right: 2px solid #cbd5e1;
    }
    .simulatorTitle .v-slide-group-item--active{
        background: #ffffff;
        border-bottom: none;
    }
    .simulatorTitleItemBox .v-btn__content{
        flex-direction: column;
    }
    .simulatorTitleItemBox p{
        font-weight: 700;
    }
    .simulatorTitleFrist span{
        font-size: 1.3rem;
        color: #d97706;
    }
  </style>
  <style scoped>
    .simulatorInput{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .simulatorInput span{
        font-weight: 700;
    }
    .simulatorInput .inputContent{
        width: 200px;
        text-align: right;
        font-weight: 700;
    }
    .simulatorInputTitle{
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        flex-direction: column;
    }
    .smallSpan{
        font-size: 13px;
    }
    .InputUtil{
        position: relative;
        top: -10px;
        right: -10px;
        margin-right: 20px;
    }
    .buttonBox{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
    }
    .simulatorButton{
        width: 250px;
        height: 50px;
        border: 2px solid #1e293b;
        font-weight: 700;
        font-size: 16px;
        border-radius: 50px;
    }
    .resultBox{
        width: 100%;
        padding: 20px 0;
        background: #f1f5f9;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 2px solid #cbd5e1;
    }
    .result-card{
        width: 100%;
        padding: 10px 30px;
    }
    .resultTitle{
        font-size: 18px;
    }
    .titleButtomLine{
        padding: 10px 15px;
        border-bottom: 2px solid #cbd5e1;
        width: 80%;
        text-align: center;
        padding-bottom: 25px;
    }
    .resultwidth{
        font-weight: 800;
    }
    .resultNumber{
        font-size: 25px;
        display: flex;
        align-items: center;
    }
    .resultNumber>span{
        flex: 1;
    }
    .resultTxt{
        font-size: 15px;
        margin-right: 20px;
        text-align: right;
    }
    .NumberText{
        text-align: left;
    }
    .resultUtil{
       font-size: 18px; 
    }
    .resultNumberContent{
        font-size: 22px;
        display: flex;
        align-items: center;
    }
    .resultNumberContent>span{
        flex: 1;
    }
    .resultUtilCont{
        font-size: 16px;
    }
</style>
