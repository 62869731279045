<template>
  <v-card>
    <v-layout>
        <the-header
        @chooselanguage='changeLanguageType'
        ></the-header>
        <v-navigation-drawer color="surface" width="180">
        </v-navigation-drawer>
        <v-navigation-drawer color="surface" location="right" width="180">
        </v-navigation-drawer>
        <v-main>
        <v-container lass="align-center">
            <v-row>
                <v-carousel cycle interval="4000" :show-arrows="true" height="290" >
                    <v-carousel-item :src="require('@/assets/img/1.png')" cover></v-carousel-item>
                    <v-carousel-item :src="require('@/assets/img/2.png')" cover></v-carousel-item>
                    <v-carousel-item :src="require('@/assets/img/3.png')" cover ></v-carousel-item>
                </v-carousel>
            </v-row>
            <v-row class="mt-6 text-h6 col-3 " no-gutters >
                <v-col  cols="12" md="4" sm="6" xs='12' class="mt-2">
                    <router-link to="/favoriteList" style="text-decoration: none" class="menu-link">

                            <v-icon size="large" color="green-darken-2" icon="mdi-heart-outline"></v-icon>
                            <span class="ml-2">{{ $t('IndexViewMessage.IndexNavList.MyCollection') }}</span> 

                    </router-link>

                </v-col>

                <v-col  cols="12" md="4" sm="6" xs='12' class="mt-2">
                    <router-link to="/browsedList" style="text-decoration: none" class="menu-link">
                            <v-icon size="large" color="green-darken-2" icon="mdi-table-heart"></v-icon>
                            <i class="bi bi-chat-dots"></i>
                            <span class="ml-2">{{ $t('IndexViewMessage.IndexNavList.RecentlyViewed') }}</span> 
                              
                    </router-link>
                </v-col>


                <v-col  cols="12" md="4" sm="6" xs='12' class="mt-2">

                    <router-link to="/inquiryList" style="text-decoration: none" class="menu-link">
                            <v-icon size="large" color="green-darken-2" icon="mdi-message-text"></v-icon>
                            <span class="ml-2">{{ $t('IndexViewMessage.IndexNavList.consultationService') }}</span> <span class="text-h6" style="color: red">{{inquiryContComputed}}</span>
                    
                </router-link>
                </v-col>


                <v-col  cols="12" md="4" sm="6" xs='12' class="mt-2">
                    <router-link to="/appliedPropertyList" style="text-decoration: none" class="menu-link">
                            <v-icon size="large" color="green-darken-2" icon="mdi-star-outline"></v-icon>
                            <span class="ml-2">{{ $t('IndexViewMessage.IndexNavList.ObjectOfApplication') }}</span> <span class="text-h6" style="color: red">{{applyContComputed}}</span>
                    
                    </router-link>
                </v-col>

                <v-col  cols="12" md="4" sm="6" xs='12' class="mt-2">
                    <router-link to="/previewList" style="text-decoration: none" class="menu-link">
                            <v-icon size="large" color="green-darken-2" icon="mdi-calendar-account"></v-icon>
                            <span class="ml-2">{{ $t('IndexViewMessage.IndexNavList.makeAppointment') }}</span> <span class="text-h6" style="color: red">{{previewContComputed}}</span>
                   
                    </router-link>
                </v-col>

                <v-col  cols="12" md="4" sm="6" xs='12' class="mt-2">

                    <router-link :to="{ path: '/rentalManagement', query: { ownerEmail: data.UserEmail }}" style="text-decoration: none" class="menu-link">
                            <v-icon size="large" color="green-darken-2" icon="mdi-calendar-blank-multiple"></v-icon>
                            <span class="ml-2">{{ $t('IndexViewMessage.IndexNavList.loanAdministration') }}</span> 
                        
                    </router-link>
                </v-col>

                <v-col  cols="12" md="4" sm="6" xs='12' class="mt-2">
                    <router-link to="/sellingLendingList" style="text-decoration: none" class="menu-link">
                            <v-icon size="large" color="green-darken-2" icon="mdi-calendar-sync-outline"></v-icon>
                            <span class="ml-2">{{ $t('IndexViewMessage.IndexNavList.SellOrRent') }}</span>
                        
                    </router-link>
                </v-col>

                <v-col  cols="12" md="4" sm="6" xs='12' class="mt-2">
                    <router-link  to="/simulator" style="text-decoration: none" class="menu-link">
                            <v-icon size="large" color="green-darken-2" icon="mdi-sim"></v-icon>
                            <span class="ml-2">{{ $t('IndexViewMessage.IndexNavList.simulator') }}</span>
                        
                    </router-link>
                </v-col>


                <v-col  cols="12" md="4" sm="6" xs='12' class="mt-2">
                    <router-link to="/search" style="text-decoration: none" class="menu-link">
                            <v-icon size="large" color="green-darken-2" icon="mdi-text-search"></v-icon>
                            <span class="ml-2">{{ $t('IndexViewMessage.IndexNavList.Search') }}</span>               
                    </router-link>
                </v-col>


            </v-row>
            <v-row class="mt-10">
                <div class="NewGoodsListTitle"><span class="text-h5">{{ $t('IndexViewMessage.NewGoodsList.title') }}</span> <span class="text-h6" style="color: red">{{recommendedCount }}</span></div>
                <div class="NewGoodsListTitle">
                     <v-radio-group class="NewGoodsListTitle" inline v-model="data.payType" @change="changepropetyType(data.payType)">
                                <v-radio 
                                :label="$t('IndexViewMessage.payType.SecondType')" 
                                :value="1"
                                ></v-radio>
                                <v-radio 
                                :label="$t('IndexViewMessage.payType.fristType')"
                                :value="2"
                                 ></v-radio>
                    </v-radio-group>
                </div>
                <v-divider color="success"></v-divider>
                <the-property 
                v-for="(propertyInfoValue,index) in data.propertyInfos" 
                :key="index" 
                v-bind:propertyInfo="propertyInfoValue"
                v-bind:thisType='data.payType'
                @AddFavorites = 'AddFavorites'
                @DelFavorites = 'DelFavorites'
                :id="'property-' + propertyInfoValue.id"
                ></the-property>
            </v-row>

            <the-pagination 
                v-if="data.totalItems"
                :limit="data.limit"
                :perPageOptions="data.perPageOptions"
                :page="data.page"
                :totalItems="data.totalItems"
                @pagination="getList"
            />

            <v-row class="mt-10">
                <the-footer></the-footer>
            </v-row>

        </v-container>
        </v-main>
    </v-layout>
  </v-card>

</template>

<script lang="ts">
import { defineComponent,reactive, computed, onMounted, defineProps ,watchEffect, nextTick, onActivated} from 'vue'
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import TheProperty from './components/TheProperty.vue'
import ThePagination from './components/ThePagination.vue'
import i18n from '../language/index'
import api from '@/api/axios/axios'
import { useRoute, useRouter,onBeforeRouteLeave} from 'vue-router'
import { downloadFile } from '@/api/axios/downLoad'
import { urlToBase64 } from '@/api/sys/model/base64Conver'
export default defineComponent({
    name: 'IndexVue',
    components: {
        TheProperty,
        TheFooter,
        TheHeader,
        ThePagination
    },
    
});
</script>

<script lang="ts" setup>

const router = useRouter()
const route = useRoute()


const props = defineProps({
      propertyInfo: Object,

    })
    const data = reactive({
        UserEmail:'',
        favoriteListData:[{
            propertyId:'',
            userFavoriteId:''
        }],
        //分页
        page:1,
        limit:10,
        totalItems:0,
        perPageOptions:[5,10,15,20],
        scrollPosition:0,
        isInfiniteScrollDisabled:false,
        isfavorite:[] as string[],
        payType:1,
        favoriteCount: 0,
        historyCont: 0,
        inquiryCont: 0,
        applyCont: 0,
        previewCont: 0,
        rentalManagementCont:0,
        isGetApiData:false,
        propertyList:[
            {
                property:{ 
                    floorPlan:'',
                    buildingDate:'',
                    buildingNameCn:'',
                    addressCn:'',
                    roomNoCn:'',
                    buildingNameEn:'',
                    addressEn:'',
                    roomNoEn:'',
                    buildingNameJp:'',
                    addressJp:'',
                    roomNoJp:'',
                    deposit:'',
                    keyMoney:'',
                    exclusiveArea:'',
                    currentSituation:'',
                    managementFee:'',
                    securityDeposit:'',
                },
                propertyRentalDto:{} as any
            }
        ],
        propertyInfos: [
            {
                id: "",
                roomNo:'',
                name: "",
                address: "",
                train: "",
                type: "",
                floors: "",
                buildingDate: "",
                mainImage: "",
                images: [] as string[],
                favorite:false,
                propertyItems: [
                    {
                    roomNumber: "",
                    rent: "",
                    managementFee: "",
                    deposit: "",
                    keyMoney: "",
                    floorPlan: "",
                    exclusiveArea: "",
                    }
                ],
            },
        ],
    });

    const favoriteCountComputed = computed(() => { return data.favoriteCount?"(" + data.favoriteCount +")件":''});
    const historyContComputed = computed(() => { return data.historyCont?"(" + data.historyCont +")件":''});
    const inquiryContComputed = computed(() => { return data.inquiryCont?"(" + data.inquiryCont +")件":''});
    const applyContComputed = computed(() => { return data.applyCont?"(" + data.applyCont +")件":''});
    const previewContComputed = computed(() => { return data.previewCont?"(" + data.previewCont +")件":''});
    const rentalManagementContComputed = computed(() => { return data.rentalManagementCont?"(" + data.rentalManagementCont +")件":''});

    const recommendedCount = computed(() => { return "(" + data.propertyInfos.length +")件"});


    onMounted(
       async () => {
    
        if(localStorage.getItem('CLOUDPFM_CO__PAYTYPE__')){
            data.payType = Number(localStorage.getItem('CLOUDPFM_CO__PAYTYPE__'))
        }

        if(localStorage.getItem('CLOUDPFM_CO__REFRESH__')){
           await thisFavorite()
           await changepropetyType(data.payType);
           await thisListNum()
           data.UserEmail = JSON.parse(localStorage.getItem('CLOUDPFM_CO__USERINFO') as string).email
        }else{
            changepropetyType(data.payType);
        }
                
    });
    


    const thisFavorite = 
    async () =>{
        await api.get(`/user/favorite`)
        .then((res)=>{
            console.log(res);
            // changepropetyType(data.payType);
           if(res.data.length>0){
            data.favoriteListData =  res.data
            for(const item of res.data){
                data.isfavorite.push(item.propertyId)
            }
           }
           console.log(data.isfavorite);
           
            data.favoriteCount = res.data.length
        })
        .catch((error)=>{
            console.log(error);  
        })
    }
    const thisListNum =  
    async () => {
        await api.get(`/user/inquiries`,{ params: {
            userId: JSON.parse(localStorage.getItem('CLOUDPFM_CO__USERINFO')).userId,
            limit: 100,
            offset: 0
        }})
        .then((res)=>{
            console.log(res);
            data.inquiryCont = res.data.filter(item => item.status == 0).length
        })
        .catch((error)=>{
            console.log(error);  
        })

        await api.get(`/user/applications`)
        .then((res)=>{
            console.log(res);
            
            data.applyCont = res.data.filter(item => (item.applicationStatus === 1 && item.propertyDeletedFlag != 1)).length 
        })
        .catch((error)=>{
            console.log(error);  
        })

        await api.get(`/user/previews`)
        .then((res)=>{
            console.log(res);
            data.previewCont = res.data.filter(item => ((item.status === 0 || item.status === 1) && item.propertyDeletedFlag != 1)).length 
            
        })
        .catch((error)=>{
            console.log(error);  
        })
        
    }
    const getPropertyData = 
    async (thisData:number) => {
        await api.get(`/user/public/property?propertyType=${thisData}&offset=${(data.page-1) * data.limit}&limit=${data.limit}`)
        .then((res)=>{
            console.log(res);
            
            data.propertyList = res.data
            data.isGetApiData = true
            console.log(data.propertyList);
            if(data.propertyList.length>0){
                processingData(data.propertyList)
                data.totalItems = data.propertyList[0].countItems
            }else{
                data.propertyInfos= [
                                    {
                                        id: "",
                                        roomNo:'',
                                        name: "",
                                        address: "",
                                        train: "",
                                        type: "",
                                        floors: "",
                                        buildingDate: "",
                                        mainImage:'',
                                        images: [],
                                        favorite:false,
                                        propertyItems: [
                                            {
                                            roomNumber: "",
                                            rent: "",
                                            managementFee: "",
                                            deposit: "",
                                            keyMoney: "",
                                            floorPlan: "",
                                            exclusiveArea: "",
                                            }
                                        ],
                                    },
                                ]
            }
           
        })
        .catch((error)=>{
             
            // console.log(error);
            
        })
    }


    onBeforeRouteLeave((to, from, next) => {
        if(to.name === 'detail') {
            data.scrollPosition = window.scrollY;
            next()
        }else {
            data.scrollPosition = 0;
            next()
        }
    })

    onActivated(() => {

        nextTick(() => {
            setTimeout(() => {
                window.scrollTo({ top: data.scrollPosition, behavior: 'smooth' });
            }, 100); // 调整延迟时间
        });

    })


 

    //分页
    const getList = async (payload)=>{


        data.limit = payload.limit;
        data.page = payload.page;

        console.log("翻页了"+data.page)

        getPropertyData(data.payType);

        // 滚动到页面顶部
        window.scrollTo({ top: 250, behavior: 'smooth' });

    }


    const totalPages = computed(() => { 
        console.log("totalPages"+Math.ceil(data.totalItems/data.limit))
        return Math.ceil(data.totalItems/data.limit)
    });

    const changeLanguageType = (val:'jp'|'en'|'zh')=>{
        changepropetyType(data.payType)
    }

    
    
    const toPreview = () => {
        router.push('/preview')
    }
    const processingData = (propertyList:any) => {
        for(let index in propertyList){               
                if(Number(index) > 0){
                    data.propertyInfos[Number(index)] = JSON.parse(JSON.stringify(data.propertyInfos[0]))
                }else if(Number(index) == 0){
                    data.propertyInfos.splice(1);
                }
                if(data.payType==1){
                    data.propertyInfos[Number(index)].type =i18n.global.messages[i18n.global.locale].theProperty.payTypeFrist
                    console.log( propertyList[index].propertySelling.price);
                    data.propertyInfos[Number(index)].propertyItems[0].rent = propertyList[index].propertySelling.price?propertyList[index].propertySelling.price+"万円":''
                }else{
                    data.propertyInfos[Number(index)].type =i18n.global.messages[i18n.global.locale].theProperty.payTypeSecond
                    data.propertyInfos[Number(index)].propertyItems[0].rent = propertyList[index].propertyRentalDto.rent?propertyList[index].propertyRentalDto.rent+"万円":''
                   
                    if(data.propertyList[Number(index)].propertyRentalDto.guaranteeDepositMoney){
                        data.propertyInfos[Number(index)].propertyItems[0].deposit = propertyList[index].propertyRentalDto.guaranteeDepositMoney+"万円"
                    }else if(!data.propertyList[Number(index)].propertyRentalDto.guaranteeDepositMoney&&data.propertyList[Number(index)].propertyRentalDto.guaranteeDepositMonth){
                        data.propertyInfos[Number(index)].propertyItems[0].deposit = propertyList[index].propertyRentalDto.guaranteeDepositMonth+"ヶ月"
                    }else if(!data.propertyList[Number(index)].propertyRentalDto.guaranteeDepositMoney&&!data.propertyList[Number(index)].propertyRentalDto.guaranteeDepositMonth){
                        data.propertyInfos[Number(index)].propertyItems[0].deposit = ''
                    }


                    if(data.propertyList[Number(index)].propertyRentalDto.keyMoney){
                        data.propertyInfos[Number(index)].propertyItems[0].keyMoney = propertyList[index].propertyRentalDto.keyMoney+"万円"
                    }else if(!data.propertyList[Number(index)].propertyRentalDto.keyMoney&&data.propertyList[Number(index)].propertyRentalDto.keyMonth){
                        data.propertyInfos[Number(index)].propertyItems[0].keyMoney = data.propertyList[Number(index)].propertyRentalDto.keyMonth+"ヶ月"
                    }else if(!data.propertyList[Number(index)].propertyRentalDto.keyMoney&&!data.propertyList[Number(index)].propertyRentalDto.keyMonth){
                        data.propertyInfos[Number(index)].propertyItems[0].keyMoney = ''
                    }
                    

                }
 
                console.log(propertyList[index].propertyFileList.filter((obj:any) => obj.fileType === 1).map((obj:any) => obj.downloadPresignUrl));
                
                data.propertyInfos[Number(index)].images = propertyList[index].propertyFileList.map((obj:any) => obj.downloadPresignUrl)

                Promise.all(data.propertyInfos[Number(index)].images.map(urlToBase64))
                .then((base64Array) => {
                    // 将每个转换后的 Base64 数据放回原来的数组中
                    data.propertyInfos[Number(index)].images = base64Array;
                })
                .catch((error) => {
                    console.error('转换为 Base64 出错：', error);
                });

                console.log(propertyList[index].propertyFileList);
                if(propertyList[index].propertyFileList.length>0){
                    data.propertyInfos[Number(index)].mainImage = propertyList[index].propertyFileList.filter((obj:any) => obj.fileType === 1).map((obj:any) => obj.downloadPresignUrl)[0]
                    
                    if(data.propertyInfos[Number(index)].mainImage) {
                        urlToBase64(data.propertyInfos[Number(index)].mainImage).then((base64) => {
                            data.propertyInfos[Number(index)].mainImage = base64;
                        });
                    }else {

                        data.propertyInfos[Number(index)].mainImage = data.propertyInfos[Number(index)].images[0];

                    }

                }else{                    
                    data.propertyInfos[Number(index)].mainImage = ''
                }
                
                
                data.propertyInfos[Number(index)].floors = propertyList[index].property.floorPlan
                data.propertyInfos[Number(index)].id = propertyList[index].property.propertyId
                data.propertyInfos[Number(index)].favorite = false

                data.propertyInfos[Number(index)].buildingDate = propertyList[index].property.buildingDate

                console.log(data.propertyList);

               let floorPlan_dict:any = {
                "1":'ワンルーム',
                "2":'1K',
                "3":"1DK",
                "4":'1LDK',
                '5':'2K',
                "6":'2DK',
                '7':'2LDK',
                '8':'3K',
                '9':'3DK',
                '10':'3LDK',
                '11':'4K',
                '12':'4DK',
                '13':'4LDK以上',
               }
                 
                data.propertyInfos[Number(index)].propertyItems[0].floorPlan = floorPlan_dict[propertyList[index].property.floorPlan]   
                data.propertyInfos[Number(index)].propertyItems[0].exclusiveArea = propertyList[index].property.exclusiveArea
                data.propertyInfos[Number(index)].propertyItems[0].managementFee = propertyList[index].property.managementFee
                
                if(i18n.global.locale=='zh'){
                    data.propertyInfos[Number(index)].name = propertyList[index].property.buildingNameCn
                    data.propertyInfos[Number(index)].address = propertyList[index].property.addressCn
                    data.propertyInfos[Number(index)].train = (propertyList[index].property.trafficLine1Cn ? propertyList[index].property.trafficLine1Cn : propertyList[index].property.trafficLine1Jp) + ' 線 ' + (propertyList[index].property.useStation1Cn ? propertyList[index].property.useStation1Cn : propertyList[index].property.useStation1Jp) + ' 駅 ' + (propertyList[index].property.trafficMeans1 == '1' ? '徒歩' : 'バス') + propertyList[index].property.useStation1TrafficDistance + ' 分';
                    data.propertyInfos[Number(index)].propertyItems[0].roomNumber = propertyList[index].property.roomNoCn
                    
                }else if(i18n.global.locale=='en'){
                    data.propertyInfos[Number(index)].name =propertyList[index].property.buildingNameEn
                    data.propertyInfos[Number(index)].address = propertyList[index].property.addressEn
                    data.propertyInfos[Number(index)].train = (propertyList[index].property.trafficLine1En ? propertyList[index].property.trafficLine1En : propertyList[index].property.trafficLine1Jp) + ' 線 ' + (propertyList[index].property.useStation1En ? propertyList[index].property.useStation1En : propertyList[index].property.useStation1Jp) + ' 駅 ' + (propertyList[index].property.trafficMeans1 == '1' ? '徒歩' : 'バス') + propertyList[index].property.useStation1TrafficDistance + ' 分';
                    data.propertyInfos[Number(index)].propertyItems[0].roomNumber = propertyList[index].property.roomNoEn
                }else if(i18n.global.locale=='jp'){
                    data.propertyInfos[Number(index)].name =propertyList[index].property.buildingNameJp
                     data.propertyInfos[Number(index)].address = propertyList[index].property.addressJp
                    data.propertyInfos[Number(index)].train = propertyList[index].property.useStation1Jp + ' 線 ' +  propertyList[index].property.useStation1Jp + ' 駅 ' + (propertyList[index].property.trafficMeans1 == '1' ? ' 徒歩 ' : 'バス') + propertyList[index].property.useStation1TrafficDistance + ' 分';
                     data.propertyInfos[Number(index)].propertyItems[0].roomNumber = propertyList[index].property.roomNoJp
                }
        }        
            
        for(let item of data.isfavorite){
            for(let itemChild of data.propertyInfos){
                console.log(item);
                console.log(itemChild);
                if(item==itemChild.id){
                    itemChild.favorite = true
                }
                
            }
        }
        console.log(data.propertyInfos);
        
    }
    const AddFavorites = (pId:string) => {
     
        let toData = {
            "propertyId": pId
        }
        api.post(`/user/favorite`,toData)
        .then((res)=>{
            console.log(res);
            localStorage.setItem('CLOUDPFM_CO__PAYTYPE__',data.payType.toString())
            const targetIndex = data.propertyInfos.findIndex(item => item.id === pId)
            if (targetIndex !== -1) {
             data.propertyInfos[targetIndex].favorite = true
            }     
            thisFavorite()
        })
        .catch((error)=>{
            console.log(error);  
        })
    }
    const DelFavorites = (pId:string) => {
        console.log(data.favoriteListData);
        let userFavoriteId 
        for(let item of data.favoriteListData){
            if(item.propertyId==pId){
                userFavoriteId = item.userFavoriteId
            }
        }
        api.delete(`/user/favorite/${userFavoriteId}`)
        .then((res)=>{
            console.log(res);
            localStorage.setItem('CLOUDPFM_CO__PAYTYPE__',data.payType.toString())
            const targetIndex = data.propertyInfos.findIndex(item => item.id === pId)
            if (targetIndex !== -1) {
             data.propertyInfos[targetIndex].favorite = false
            }     
            thisFavorite()
        })
        .catch((error)=>{
            console.log(error);  
        })
    }
    const changepropetyType =  (val:number) => {
        // val = 1 賃貸    val = 2 売買
        data.payType = val
        localStorage.setItem('CLOUDPFM_CO__PAYTYPE__',val.toString())
        data.totalItems = 0;
        data.page = 1;
        getPropertyData(val)
        // thisChangeType(val)

    }

    
    
</script>
<style scoped>
.NewGoodsListTitle{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
}

.menu-card {
    max-width: 95%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #388e3c;
    transition: box-shadow 0.3s ease; /* 添加过渡效果 */
}

.menu-card:hover {
    box-shadow: 0 0 10px rgba(56, 142, 60, 0.8); /* 添加聚焦时的阴影效果 */
}

.menu-link {
    color: #009688;
}

.menu-link:hover {
    color: rgba(0, 150, 136, 0.8);
}


</style>
