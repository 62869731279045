<template>
  <v-card>
    <v-layout>
        <the-header></the-header>
        <v-navigation-drawer width="180">
        </v-navigation-drawer>
        <v-navigation-drawer location="right" width="180">
        </v-navigation-drawer>
        <v-main>
        <v-container class="align-center">
            <v-row>
                <v-col cols="12" md="10" sm="12">
                    <p class="text-h4">{{ $t('previewListView.title') }}</p>
                </v-col>
                <v-col cols="12" md="2" sm="12" class="goBackBox">
                    <v-btn  class="mt-10 home-button" color="success" variant="outlined" @click="goHome">
                        {{ $t('Inquiry.InquiryList.actions.goHome') }}
                    </v-btn>
                </v-col>
            </v-row>
            <!-- 一覧画面 -->
            <v-row no-gutters>
                <v-col cols="12">
                <v-table>
                <thead>
                    <tr>
                        <th class="text-left text-h6 mdScreen">
                        No.
                        </th>
                        <th class="text-left text-h6 mdScreen">
                        {{ $t('previewListView.goodsName') }}
                        </th>
                        <th class="text-left text-h6 xsScreen">
                        {{ $t('previewListView.state') }}
                        </th>
                        <th class="text-left text-h6 xsScreen">
                        {{ $t('previewListView.propertyType') }}
                        </th>
                        <th class="text-left text-h6 xsScreen">
                        {{ $t('previewDetailView.requestedApprovalTime') }}
                        </th>
                        <th class="text-left text-h6 xsScreen">
                        {{ $t('previewListView.dateFrist') }}
                        </th>
                        <th class="text-left text-h6 mdScreen">
                        {{ $t('previewListView.operation') }}
                        </th>
                    </tr>
                </thead>
                <tbody class="TbodyPLsit">
                    <tr v-for="(item, index) in data.previews" :key="item.propertyViewingId">
                        <td> 
                            <span>{{ index + 1 }}</span>
                        </td>
                        <td>{{ item.buildingNameJp }}</td>
                        <td class="xsScreen">
                            <div v-if="item.status == '0'">
                                {{ $t(PreviewStatus.Unsettled.text) }}
                            </div>
                            <div v-if="item.status == '1'">
                                {{ $t(PreviewStatus.ReApplication.text) }}
                            </div>
                            <div v-if="item.status == '2'">
                                {{ $t(PreviewStatus.Confirmed.text) }}
                            </div>
                            <div v-if="item.status == '3'">
                                {{ $t(PreviewStatus.Cancel.text) }}
                                
                            </div>
                        </td>
                        <td class="xsScreen">{{ ItemsSecond(item.propertyTypeSmall, item.propertyType == 3 ? 1 : 2) }}</td>
                        <td class="xsScreen">
                            <span v-if="item.requestedApprovalTime == 1">{{ item.preferenceTimeFirst }}</span>
                            <span v-if="item.requestedApprovalTime == 2">{{ item.preferenceTimeSecond }}</span>
                            <span v-if="item.requestedApprovalTime == 3">{{ item.preferenceTimeThird }}</span>
                        </td>
                        <td class="xsScreen">{{ item.preferenceTimeFirst }}</td>
                        <td>
                            <span>
                                <a class="a-link" href="#" @click.stop.prevent="toPropertyDetail(item.propertyId)" v-if="item.propertyDeletedFlag != 1">{{ $t('previewDetailView.propertyDetailBtn') }}</a>
                            </span>
                            <span>&ensp; </span>
                            <span>
                                <a class="a-link" href="#" @click.stop.prevent="selectPreview(item.propertyViewingId)" v-if="item.propertyDeletedFlag != 1">{{ $t('detailButton') }}</a>
                            </span>
                            <span>&ensp; </span>
                            <span v-if="(item.status == '0' || item.status == '1') && item.propertyDeletedFlag != 1">
                                <a class="a-link" href="#" @click.stop.prevent="data.dialog=true">{{ $t('previewDetailView.falseBut') }}</a>
                            </span>
                            <v-dialog max-width="70%" v-if="(item.status == '0' || item.status == '1') && item.propertyDeletedFlag != 1"  v-model="data.dialog" class="custom-dialog">

                                <v-card>
                                    <v-card-title>{{ $t('previewDetailView.noTopreviewBut') }}</v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text style="height: 300px;">
                                    <v-radio-group v-model="item.cancelReason" column >
                                        <v-radio :label="$t('previewListView.timeChange')" value="1"></v-radio>
                                        <v-radio :label="$t('previewListView.otherGoods')" value="2"></v-radio>
                                        <v-radio :label="$t('previewListView.other')" value="3"></v-radio>
                                    </v-radio-group>
                                    <v-text-field v-if="item.cancelReason == 3" v-model="item.others" label="そのたの場合はこちらにキャンセル理由をご入力ください。"></v-text-field>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                    <v-btn variant="text" @click="data.dialog = false">
                                        {{$t('previewListView.close')}}
                                    </v-btn>
                                    <v-btn variant="text" @click="cancelPriview(item.propertyViewingId)">
                                    {{ $t('previewDetailView.noTopreviewBut') }}
                                    </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </td>
                    </tr>
                </tbody>
                </v-table>
                </v-col>
            </v-row>

            <v-row class="mt-10">
                <the-footer></the-footer>
            </v-row>
        </v-container>
        </v-main>
    </v-layout>
  </v-card>
</template>
<style>
.table-border {
  border:1px;
  border-style: solid;
  border-color: antiquewhite;
}
</style>
<script lang="ts">
import { defineComponent,reactive, computed, getCurrentInstance, onMounted, defineProps } from 'vue'
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import { useRoute, useRouter } from 'vue-router'
import api from '@/api/axios/axios'
import {PreviewStatus} from '../api/sys/model/enum';
import {Property, Company, Preview, ApplyDetailItem, AppliedPropertyType, FileItem, ProcessTitle, ApplyProperty } from './../api/sys/model/model'
import {ItemsFrist ,ItemsSecond } from "../api/sys/model/speciesList";

export default defineComponent({
    name: 'PropertyDetail',
    components: {
        TheHeader,
        TheFooter,
    },
});
</script>

<script lang="ts" setup>
    const props = defineProps({
      propertyInfo: Object,

    })
    const data = reactive({
        dialog: false,
        selectedPreview: {} as Preview ,
        cancelPreview: {} as Preview ,
        modifyPreview: {} as Preview ,
        previews: [] as Preview[],
        });

    const router = useRouter()

    const cancelPriview = (propertyViewingId : string) => {
        let cancelPreivewObj = data.previews.find(fileItem => fileItem.propertyViewingId == propertyViewingId)
        if (cancelPreivewObj) {
            data.cancelPreview = cancelPreivewObj
            data.cancelPreview.status = '3'
        }
        api.put('/user/previews/' + propertyViewingId + '/previewCancel', data.cancelPreview)
        .then(res => {
            data.dialog = false;
            router.push('/previewList')
        })
        .catch(error => {
            alert("キャンセル失敗しました。");
            console.log(error, propertyViewingId);
        });
    }


     const selectPreview = (propertyViewingId : string) => {
        let selectPreivewObj = data.previews.find(element => element.propertyViewingId == propertyViewingId);
        if(selectPreivewObj) {
            data.selectedPreview = selectPreivewObj
        }
        router.push('/previewDetail/' + propertyViewingId)
     }

     onMounted(() => {
        getInitData()
    });

    const getInitData  =  () => {
        let thisUserId = JSON.parse(localStorage.getItem('CLOUDPFM_CO__USERINFO')).userId
        
        api.get(`/user/previews?userId=${thisUserId}`)
        .then((res) => {
            data.previews = res.data;
            console.log(res.data.results);
        })
        .catch((error) => {
            console.log(error.message);
        })
    };
    const goHome = () => {
        router.push(`/`);
    }

    const toPropertyDetail = (propertyId: string) => {
    router.push({
        name: 'detail',
        query: {
            id: propertyId,
            type: 'previewList',
        },
    })
    };
</script>
<style>
    @media screen and (max-width:784px){
      .xsScreen{
        display: none;
      }
      .pviewId{
            display: block;
            width: 100px;
        }
    }
    @media screen and (min-width:784px){
        .laScreen{
            display: none;
        }
        .pviewId{
            display: block;
            width: 170px;
        }
    }
    .custom-dialog .v-overlay__scrim {
        background-color: rgba(0, 0, 0, 0.3); /* 设置遮罩层透明度为 0.5 */
        box-shadow: none;
    }
    .custom-dialog > .v-overlay__content > .v-card, .v-dialog > .v-overlay__content > .v-sheet {
        box-shadow: none !important;
    }
    
</style>