<template>
    <v-card>
        <v-layout>
            <the-header @chooselanguage='changeLanguageType'></the-header>
            <v-navigation-drawer color="surface" width="180">
            </v-navigation-drawer>
            <v-navigation-drawer color="surface" location="right" width="180">
            </v-navigation-drawer>
            <v-main>
                <v-container>
                    <!-- お問い合わせ詳細画面 -->
                    <v-row no-gutters>
                        <v-col cols="12">
                            <p class="text-h4">{{ $t('Inquiry.InquiryDetail.title') }}</p>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-4">
                        <v-container class="d-flex justify-space-between">
                            <span class="text-h4">{{ data.inquiryDetail.title }}</span>
                            <div>
                                <!-- 問い合わせ一覧画面へ -->
                                <v-btn size="large" @click="toInquiryList" color="info">{{
                                    $t('Inquiry.InquiryDetail.actions.toInquiriyList') }}</v-btn>
                                <!-- close -->
                                <v-btn v-if="data.inquiryDetail.status === 0" size="large" class="ml-2"
                                    @click="closeInquiry" color="success">{{ $t('Inquiry.InquiryDetail.actions.close')
                                    }}</v-btn>
                                <v-dialog max-width="40%" style="height: 300px;" v-model="data.showDialog1" position="top">
                                    <v-card elevation="5">
                                        <v-card-title>{{ $t('Inquiry.InquiryDetail.toInquiriyListTitle') }}</v-card-title>
                                        <v-card-text>
                                            {{ $t('Inquiry.InquiryDetail.toInquiriyListContent') }}
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn color="normal" @click="closeDialog1" variant="text">{{
                                                $t('Inquiry.InquiryDetail.actions.cancel') }}</v-btn>
                                            <v-btn color="success" @click="confirmClose(data.inquiryId)" variant="text">{{
                                                $t('Inquiry.InquiryDetail.actions.close') }}</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <!-- cancel -->
                                <v-btn v-if="data.inquiryDetail.status === 0" size="large" class="ml-2"
                                    @click="cancelInquiry" color="info">{{ $t('Inquiry.InquiryDetail.actions.cancel')
                                    }}</v-btn>
                                <v-dialog max-width="40%" style="height: 300px;" v-model="data.showDialog2" position="top">
                                    <v-card elevation="5">
                                        <v-card-title>{{ $t('Inquiry.InquiryDetail.cancelTitle') }}</v-card-title>
                                        <v-card-text>
                                            {{ $t('Inquiry.InquiryDetail.cancelContent') }}
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn color="normal" @click="closeDialog2" variant="text">{{
                                                $t('Inquiry.InquiryDetail.actions.cancel') }}</v-btn>
                                            <v-btn color="success" @click="confirmCancel(data.inquiryId)" variant="text">{{
                                                $t('Inquiry.InquiryDetail.actions.confirm') }}</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </div>
                        </v-container>
                    </v-row>
                    <v-row no-gutters class="mt-4">
                        <v-col col="12">
                            <v-table class="table-border">
                                <tbody>
                                    <tr>
                                        <th class="text-left table-th">{{ $t('Inquiry.InquiryList.status.title') }}</th>
                                        <td>
                                            <div v-if="data.inquiryDetail.status === 0">
                                                {{ $t('Inquiry.InquiryList.status.underInquiry') }}
                                            </div>
                                            <div v-if="data.inquiryDetail.status === 1">
                                                {{ $t('Inquiry.InquiryList.status.completed') }}
                                            </div>
                                            <div v-if="data.inquiryDetail.status === 2">
                                                {{ $t('Inquiry.InquiryList.status.cancelled') }}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="text-left table-th">{{ $t('Inquiry.InquiryList.inquiryContent') }}</th>
                                        <td>{{ data.inquiryDetail.content }}</td>
                                    </tr>

                                    <tr>
                                        <th class="text-left table-th">{{ $t('Inquiry.InquiryDetail.notes') }}</th>

                                        <td>{{ data.inquiryDetail.note }}</td>
                                    </tr>
                                    <tr>
                                        <th class="text-left table-th">{{ $t('Inquiry.InquiryDetail.name') }}</th>
                                        <th>{{ data.inquiryDetail.inquiryUserName }}</th>
                                    </tr>
                                    <tr>
                                        <th class="text-left table-th">{{ $t('Inquiry.InquiryDetail.mailAddress') }}</th>
                                        <th>{{ data.inquiryDetail.email }}</th>
                                    </tr>
                                    <tr>
                                        <th class="text-left table-th">{{ $t('Inquiry.InquiryDetail.phoneNumber') }}</th>
                                        <th>{{ data.inquiryDetail.phone }}</th>
                                    </tr>

                                </tbody>

                            </v-table>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-8">
                        <v-col cols="12">
                            <p class="text-h4">{{ $t('Inquiry.InquiryDetail.chat.chatHistory') }}</p>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-4">
                        <v-col col="12" justify="space-around">
                            <v-card class="chat-card" style="width: 100%">
                                <v-list>
                                    <v-list-item v-for="item in data.commentList" :key="item.inquiryCommentId">
                                        <v-row no-gutters>
                                            <v-col cols="6" style="margin-top: 20px; margin-bottom: 20px;"
                                                class="text-left">

                                                <v-row>
                                                    <v-col cols="2" v-show="item.commentUserType === 1">
                                                        <v-avatar color="primary" class="mr-6" size="large">
                                                            {{ item.commentUserName }}
                                                        </v-avatar>
                                                    </v-col>
                                                    <v-col cols="8" v-show="item.commentUserType === 1">

                                                        <span class="inquiryBox">
                                                            <div class="inquiryTime-left">
                                                                {{ item.createdAt.replace('T', '&nbsp;&nbsp;&nbsp;') }}
                                                            </div>
                                                            <div class="talk-left text-left">{{ item.content }}</div>
                                                        </span>

                                                    </v-col>
                                                </v-row>

                                            </v-col>
                                            <v-col cols="6" style="margin-top: 20px; margin-bottom: 20px;"
                                                class="text-right">

                                                <v-row>
                                                    <v-col cols="8" v-show="item.commentUserType === 0">

                                                        <span class="inquiryBox">
                                                            <div class="inquiryTime-right">
                                                                {{ item.createdAt.replace('T', '&nbsp;&nbsp;&nbsp;') }}
                                                            </div>
                                                            <div class="talk-right text-left">{{ item.content }}</div>
                                                        </span>

                                                    </v-col>
                                                    <v-col cols="2" v-show="item.commentUserType === 0">
                                                        <v-avatar color="success" class="ml-6" size="large">
                                                            {{ $t('Inquiry.InquiryDetail.chat.me') }}
                                                        </v-avatar>
                                                    </v-col>
                                                    <!-- 占位 -->
                                                    <v-col cols="2"></v-col>
                                                </v-row>

                                            </v-col>
                                        </v-row>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-col>
                    </v-row>
                    <!-- 送信 -->
                    <div class="mt-8" v-show="data.inquiryDetail.status === 0">
                        <v-row no-gutters>
                            <v-col cols="8" class="mr-4">
                                <v-textarea v-model="data.newMessage" placeholder="" auto-grow rows="1" label=""
                                    variant="solo" class="rounded-lg"></v-textarea>
                            </v-col>
                            <v-col cols="3">
                                <v-btn @click="sendMessage" color="success" style="height: ;100%">
                                    {{ $t('Inquiry.InquiryDetail.actions.send') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>

                    <v-row class="mt-10">
                        <the-footer></the-footer>
                    </v-row>
                </v-container>
            </v-main>
        </v-layout>
    </v-card>
</template>
<style>
.table-border {
    border: 1px;
    border-style: solid;
    border-color: antiquewhite;
}
</style>
<script lang="ts">
import { reactive, defineComponent, getCurrentInstance, onMounted, watch } from 'vue';
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import { useRoute, useRouter } from 'vue-router'
// import axios from 'axios'
import api from '@/api/axios/axios'
import i18n from '../language/index'
import { InquiryStatus } from '../api/sys/model/enum';
export default defineComponent({
    name: 'InquiryDetail',
    components: {
        TheHeader,
        TheFooter,
    },
});
</script>
  
<script lang="ts" setup>
const router = useRouter()
const route = useRoute()
const data = reactive({
    inquiryDetail: {},
    inquiryId: '',
    userProfile: {},
    commentList: [],
    showDialog1: false,
    showDialog2: false,
    commentUserType: '',
    newMessage: '',
    languageType: 'jp' as string
})
// 送信
const sendMessage = () => {
    const messageContent = data.newMessage.trim();
    if (messageContent !== '') {
        api.post(`/user/inquiries/comments`, {
            content: messageContent,
            inquiryId: data.inquiryId
        })
            .then((res) => {
                data.newMessage = '';
                updateData()
                console.log('Comment posted successfully:', res.data);
            })
            .catch((error) => {
                console.log('Error posting comment:', error.message);
            });
    } else {
        console.log('Error: Message cannot be empty.');
    }
}
// クローズボタン
const closeDialog1 = () => {
    data.showDialog1 = false;
}
const closeInquiry = () => {
    data.showDialog1 = true
}
const confirmClose = (inquiryId: string) => {
    api.put(`/user/inquiries`, {
        "inquiryId": inquiryId,
        "status": 1
    }).then((res) => {
        console.log("close is successed")
        updateData()
        data.showDialog1 = false
    }).catch((error) => {
        console.log(error.message);
    });
}
// キャンセルボタン
const closeDialog2 = () => {
    data.showDialog2 = false;
}
const cancelInquiry = () => {
    data.showDialog2 = true
}
const confirmCancel = (inquiryId: string) => {
    api.put(`/user/inquiries`, {
        "inquiryId": inquiryId,
        "status": 2
    }).then((res) => {
        updateData()
        console.log("cancel is successed")
        data.showDialog2 = false
    }).catch((error) => {
        console.log(error.message);
    });

}
// アップデート
const updateData = () => {
    let timeStamp = Date.now()
    api.get(`/user/inquiries/` + data.inquiryId + `?time=` + timeStamp)
        .then((res) => {
            data.inquiryDetail = res.data;
            data.commentList = res.data.commentList;
        })
        .catch((error) => {
            console.log(error.message);
        });
}
const toInquiryList = () => {
    router.push('/inquiryList');
}
onMounted(() => {
    getInitData();
    getChatMessages();
});
const getChatMessages = () => {
    data.inquiryId = route.params.inquiryId.toString();
    api
        .get(`/user/inquiries/` + data.inquiryId)
        .then((res) => {
            data.commentList = res.data.commentList;
        })
        .catch((error) => {
            console.log(error.message);
        });
}
const getInitData = () => {
    let timeStamp = Date.now()
    data.inquiryId = route.params.inquiryId.toString();
    api
        .get(`/user/inquiries/` + data.inquiryId + `?time=` + timeStamp)
        .then((res) => {
            data.inquiryDetail = res.data;
        })
        .catch((error) => {
            console.log(error.message);
        });
};
const changeLanguageType = (val: 'jp' | 'en' | 'zh') => {
    data.languageType = val
    console.log(i18n.global);
}
</script>
<style scoped>
.inquiryBox {
    position: relative;
}

.inquiryTime-left,
.inquiryTime-right {
    position: relative;
    /* 改为相对定位 */
    font-size: 13px;
    color: rgb(176, 176, 176);
}

.talk-left,
.talk-right {
    display: inline-block;
    max-width: 100%;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 12px;
    white-space: pre-wrap;
    position: relative;
    /* 改为相对定位 */
}

.talk-left:before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 15px 0 0;
    border-color: #ccc transparent transparent transparent;
}

.talk-right:before {
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 15px 0;
    border-color: transparent #ccc transparent transparent;
}</style>