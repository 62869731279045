<template>
    <v-container>
      <v-row d-flex>
        <v-col cols="12" md="6">

          <v-card style="position: relative; justify-content: center;display: flex;">
                  <v-img  v-if="props.propertyInfo?.mainImage != ''" :src="data.mainImageFileName != ''?data.mainImageFileName:props.propertyInfo?.mainImage" class="bg-grey-lighten-2" cover></v-img>
                  <v-dialog width="700" class="dialogBox" v-if="props.propertyInfo?.mainImage != ''">
                      <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" class="imageBtn_dia"> </v-btn>
                      </template>

                      <template v-slot:default="{ isActive }">
                          <v-card>
                              <v-carousel>
                                  <v-carousel-item
                                      :src="props.propertyInfo?.mainImage"
                                      contain
                                  ></v-carousel-item>
                                  <v-carousel-item
                                       v-for="(item, i) in propertyInfo?.images.filter(item => item !== props.propertyInfo?.mainImage)"
                                       :key="i"
                                      :src="item"
                                      contain
                                  ></v-carousel-item>
                              </v-carousel>
                          <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                              text="Close"
                              @click="isActive.value = false"
                              ></v-btn>
                          </v-card-actions>
                          </v-card>
                      </template>
                    </v-dialog>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="no-border-card">
            <v-list density="compact">
                <v-list-subheader class="text-h4">{{ propertyInfo?.name }}</v-list-subheader>
                <v-list-item color="primary">
                    <template v-slot:prepend>
                        <v-icon icon="mdi-map-marker"></v-icon>
                    </template>
                    <v-list-item-title>{{props.propertyInfo?.address}}</v-list-item-title>
                </v-list-item>
                
                <v-list-item color="primary">
                    <template v-slot:prepend>
                        <v-icon icon="mdi-train"></v-icon>
                    </template>
                    <v-list-item-title>{{props.propertyInfo?.train}}</v-list-item-title>
                </v-list-item>

                <v-list-item color="primary">
                    <template v-slot:prepend>
                        <v-icon icon="mdi-flag"></v-icon>
                    </template>
                    <v-list-item-title>{{props.propertyInfo?.type}}</v-list-item-title>
                </v-list-item>

                <v-list-item color="primary">
                    <template v-slot:prepend>
                      <v-icon ></v-icon>
                    </template>
                    <v-list-item-title>{{data.floorsList[Number(props.propertyInfo?.floors) - 1]}}</v-list-item-title>
                </v-list-item>
                <v-list-item color="primary">
                    <template v-slot:prepend>
                      <v-icon ></v-icon>
                    </template>
                    <v-list-item-title>{{props.propertyInfo?.buildingDate}} 築</v-list-item-title>
                </v-list-item>
            
            </v-list>
          </v-card>
           <v-row class="mt-2">
            <v-col cols="4" v-for="(item, i) in propertyInfo?.images" :key="i">
                <v-card  @click="imageChange(item)">
                    <v-img :src="item" class="bg-grey-lighten-2 smallImage" cover></v-img>
                </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-card class="mt-4">
        <v-table density="comfortable" fixed-header class="tableBox">
          <thead class="tableBox">
            <tr>
              <th class="text-left">
                &nbsp;
              </th>
              <th>&nbsp;</th>
              <th style="font-weight: bolder;" class="scressDom">{{ $t('theProperty.roomNo') }}</th>
              <th style="font-weight: bolder;" class="screenMed" v-if="props.thisType==1">{{$t('theProperty.price') }}<br/> {{ $t('theProperty.managementFee') }}</th>
              <th style="font-weight: bolder;" class="screenMed" v-if="props.thisType==2">{{$t('theProperty.commission') }}<br/> {{ $t('theProperty.managementFee') }}</th>
              <th style="font-weight: bolder;" class="screenMed"  v-if="props.thisType==2">{{ $t('theProperty.securityDeposit') }}<br/>{{ $t('theProperty.keyMoney') }}</th>
              <th style="font-weight: bolder;" class="screenSmall">{{ $t('theProperty.floorPlan') }}<br/>{{ $t('theProperty.exclusiveArea') }}</th>
              <th style="font-weight: bolder;" class="screenSmall">{{ $t('theProperty.addFavorites') }}</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody class="tableBox">
            <tr v-for="(property, i) in propertyInfo?.propertyItems" :key="i">
              <td class="d-flex justify-center" fluid ><v-checkbox label=""></v-checkbox></td>
              <td><image src=""></image></td>
              <td class="scressDom">{{property.roomNumber}}</td>
              <td class="screenMed">{{property.rent}}<br/>{{property.managementFee}} {{property.managementFee?'万円':''}}</td>
              <td class="screenMed" v-if="props.thisType==2">{{property.deposit}}<br/>{{property.keyMoney}}</td>
              <td class="screenSmall">{{property.floorPlan}}<br/>{{property.exclusiveArea}}{{property.exclusiveArea?'㎡':''}}</td>
              <td class="screenSmall">
                <v-btn prepend-icon="mdi-heart-outline" class="bg-teal" variant="outlined" @click="AddFavorites(props.propertyInfo?.id)" v-if="!props.propertyInfo?.favorite">{{ $t('theProperty.store') }}</v-btn>
                <v-btn prepend-icon="mdi-heart" class="bg-teal" variant="outlined" v-if="props.propertyInfo?.favorite" @click="DelFavorites(props.propertyInfo?.id)">{{ $t('theProperty.storeTrue') }}</v-btn>
              </td>
              <td class="scressDom">
                <v-btn class="home-button" color="success" variant="outlined" @click="toDetail(props.propertyInfo?.id)">{{ $t('theProperty.SeeDetails') }}</v-btn>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-card>
    </v-container>
</template>
<script lang="ts">
import api from '@/api/axios/axios'
import { defineComponent,reactive, computed, onMounted, defineProps , watchEffect ,defineEmits , watch} from 'vue'
import type { PropType } from 'vue'
import { useRouter } from 'vue-router'

interface PropertyItem{
  roomNumber: string,
  rent: string,
  managementFee: string,
  deposit: string,
  keyMoney: string,
  floorPlan: string,
  exclusiveArea: string,
}

interface PropertyInfo {
  id: string,
  name: string,
  address: string,
  train: string,
  type: string,
  floors: string,
  buildingDate: string,
  mainImage: string,
  images: Array<string>,
  propertyItems: Array<PropertyItem>,
  favorite:boolean
}

export default defineComponent({
  name: 'TheProperty',
});

</script>

<script lang="ts" setup>
    const props = defineProps({
      propertyInfo: Object as PropType<PropertyInfo>,
      thisType: Number,
      loopIndex: Number,
    })
    const data = reactive({
      index:props.loopIndex,
      imagetype:false,
      floorsList:['ワンルーム','1K','1DK','1LDK','2K','2DK','2LDK','3K','3DK','3LDK','4K',' 4DK','4LDK以上'],
      mainImageFileName:'',
      propertyInfoItems: [
        { text: props.propertyInfo?.address, icon: 'mdi-map-marker' },
        { text: props.propertyInfo?.train, icon: 'mdi-train' },
        { text: props.propertyInfo?.type, icon: 'mdi-flag' },
        { text: props.propertyInfo?.floors },
        { text: props.propertyInfo?.buildingDate+'築' },
      ],
    });
    const imageChange = (imageFileName: string) => {
      // alert(imageFileName);
      data.mainImageFileName = imageFileName;
    }
    onMounted(() => {
      data.mainImageFileName = ""
    })
    const router = useRouter()

    watchEffect(() => {
      if(props.thisType){
        data.mainImageFileName = ""
      }
      
      
    })
    const emit = defineEmits(['AddFavorites','DelFavorites'],)
    const AddFavorites = (propertyId:string | undefined) =>{
      if(localStorage.getItem('CLOUDPFM_CO__REFRESH__')){
        emit('AddFavorites',propertyId)
      }else{
        router.push('./login')
      }
      
    }

    const toDetail= (propertyId:string | undefined) =>{
        router.push({
          name:'detail',
          query: { 
            id: propertyId,
            type: 'index'
          },
        })
    }

    const DelFavorites = (propertyId:string | undefined) =>{
      // emit('DelFavorites',propertyId)
      if(localStorage.getItem('CLOUDPFM_CO__REFRESH__')){
        emit('DelFavorites',propertyId)
      }else{
        router.push('./login')
      }
    }
</script>
<style>
    @media screen and (max-width:784px){
      .screenMed{
        display: none;
      }
    }
    @media screen and (max-width:960px){
      .screenSmall{
        display: none;
      }
    }
    
    .scressDom{
      min-width: 120px;
    }
    .tableBox .d-flex .v-input__details{
      display: none !important;
    }
    .tableBox .v-checkbox{
      height: 48px !important;
      overflow: hidden;
    }
    .tableBox .v-table__wrapper::-webkit-scrollbar {
      display:none;
    }

</style>
<style scoped>
.smallImage{
  height: 100px;
}
.imageBtn_dia{
  position: absolute; 
  top: 0; 
  left: 0; 
  right: 0; 
  height: 100%;
  opacity: 0;
}


.no-border-card {
  border: none !important;
  border-radius: 0; /* If you also want to remove rounded corners */
  box-shadow: none !important; /* If there is any box shadow */
}
</style>