const cn = {
    languageType:{
        Japanese:'日语',
        Chinese:'汉语',
        English:'英语'
    },
    unitMessage:{
      month:'个月',
    },
    LoginMessage:{
      title:'登录',
      registerCard:{
        title:'如果您还没有注册',
        content:'注册并登录后，您可以在 PC、智能手机或应用程序上分享您喜爱的房产、搜索历史记录等。',
        button:'注册'
      },
      loginCard:{
        title:'登录',
        email:'邮箱地址',
        password:'密码',
        forgetButton:'忘记密码',
      },
      forgetMessage:{
        title:'重置密码',
        sendButton:'发送验证码',
        certification:'验证码',
        email:'邮箱地址',
        password:'密码',
        repassword:'确认密码',
        changeButton:'重置',
        toLoginMessage:{
          message:'如果您有帐户，',
          button:'请登录'
        },     

      },
    },
    RegisterMessage:{
      title1:'注册（输入）',
      title2:'注册（确认）',
      title3:'注册（验证码）',
      title4:'注册（注册完成）',
      registerStep1:{
        title:'注册信息输入',
        userName:'用户名',
        userNameHint:'请输入您的用户名',
        email:'邮箱地址',
        emailHint:'请输入您的邮箱地址',
        password:'密码',
        passwordHint:'请输入至少8个字符（大写、小写和数字）',
        repassword:'密码（用于确认）',
        agreementMessage:{
          first:'CLOUDPF規約',
          second:'個人情報の取り扱いについて',
          last:'我同意。'
        },
        agreeButton:'同意以上内容并进入下一步',
      },
      registerStep2:{
        title:'内容确认',
        userName:'用户名',
        email:'邮箱地址',
        password:'密码',
        passwordMessage:'为了安全起见，密码被隐藏。',
        changeButton:'修改输入的内容',
        sendButton:'发送验证码'
      },
      registerStep3:{
        title:'验证码输入',
        certification:'验证码',
        confirmationButton:'确定',
      },
      registerStep4:{
        title:'注册成功',
        message:'注册已完成。',
        confirmationButton:'确定',
      },
    },
    messageproprty: {
      roomNo:'房间号/楼层',
      propertyTypeLarge:'项目',
      commission: '租金',
      managementFee :'管理费',
      securityDeposit :'押金',
      keyMoney:'酬谢金',
      floorPlan :'平面布置',
      exclusiveArea :'专用面积',
      buildingDate :'建造日期',
      address_other :'总层数/层',
      propertyAddress :'住址',
      trafficMeans:'交通',
      SupportLanguage:'※支持语言： 日语、汉语、英语',
      buttonName:{
        consult:'咨询',
        FavoritesLogin:'添加到收藏',
        Favorited:'已收藏',
        appointment:'预约看房',
        initialcost:'估算初始成本',
        apply:'申请',
        login:' 登 录 ',
      }
      
    },
    AppliedPropertyMessage:{
        titleMessage:'申请',
        CategorySelection:'申请(选择类别)',
        importTitle:'申请(输入信息)',
        notarize:'申请(确认)',
        finish: '申请(完成)',
        SelectCategoryCont:{
            productName:'商品名',
            rentOrsell:'租赁・销售',
            productType:'商品类型',
            ClassApplicant:'申请人类型',
            TypeOfPayment:'支付方式',
            CategoryApplication:'申请类别',
            rent:'出租',
            sell:'售卖',
            residence:'住宅',
            office:'办公',
            individual:'个人申请',
            LegalPerson:'法人申请',
            mortgage:'按揭支付',
            onePayment:'一次付清'
        },
        importTitleCont:'申请的商品类别',
        appliedDetailTitle:'申请的详细信息',
        buttonDetailTitle:{
            EnterDetails:'输入详细信息',
            ConfirmNext:'确认输入内容后,进入下一步',
            send:'提交',
            ApplicationList:'前往申请列表',
            ModifyInput:'修改输入内容',
            completeTitle:'申请完成了!',
            backHomepage:'返回首页',
        }
    },
    IndexViewMessage:{
      IndexNavList:{
        MyCollection:'我的收藏',
        RecentlyViewed:'我的足迹',
        consultationService:'咨询服务',
        ObjectOfApplication:'申请的物品',
        makeAppointment:'看房预约',
        loanAdministration:'贷款管理',
        SellOrRent:'售卖・出租',
        simulator:'贷款模拟器',
        Search:'搜索'
      },
      NewGoodsList:{
        title:'最新物件列表',
      },
      payType:{
        fristType:'租赁',
        SecondType:'买卖'
      }
    },
    theProperty:{
      roomNo:'房间号/楼层',
      commission: '租金',
      price:'总价',
      payTypeFrist:'出售房屋',
      payTypeSecond:'租赁公寓',
      managementFee :'管理费',
      securityDeposit :'押金',
      keyMoney:'酬谢金',
      floorPlan :'平面布置',
      exclusiveArea :'专用面积',
      SeeDetails:'查看详情',
      ProductPicture:'图片',
      addFavorites:'收藏',
      ViewPhotos:'查看图片',
      store:'添加到收藏',
      storeTrue:'已收藏'

    },
    PreviewMessage:{
        title:'看房预约',
        navTitleFrist:'看房预约(登记)',
        navTitleSecond:'看房预约(确定)',
        navTitleThirdly:'看房预约(完成)',
        formTitle:{
          name:'商品名称',
          email:'邮箱地址',
          phone:'电话号码',
          dateFrist:'第一期望时间',
          dateSecond:'第二期望时间',
          dateThirdly:'第三期望时间',
          ContactInformation:'联系事项'
        },
        lastTitle:'预约看房申请已发送!'
    },
    InitialCostMessage:{
      title:'初期费用估算',
      rent:'租金',
      managementFee:'管理费',
      securityDeposit:'押金',
      keyMoney:'酬谢金',
      BackDetail:'返回详细画面',
      total:'合计'
    },
    previewListView:{
      title:'预约一览',
      previewNumber:'预约号',
      previewNameTh:"预约名", 
      goodsName:'物件名',
      propertyType:'物件种类',
      state:'状态',
      dateFrist:'第一希望日期',
      dateSecond:'第二希望日期',
      dateThirdly:'第三希望日期',
      remark:'备注',
      operation:'操作',
      close:'关闭',
      timeChange:'因为时间变动',
      otherGoods:'因为客户决定购买其他房子',
      other:'其他',
    },
    previewDetailView:{
      title:'看房预约详情',
      previewNameTh:"物件名",
      email:'邮箱地址',
      phone:'电话号码',
      dateFrist:'第一希望日期',
      dateSecond:'第二希望日期',
      dateThirdly:'第三希望日期',
      requestedApprovalTime:'已确认的希望日期',
      remark:'备注',
      edit:'编辑',
      goback:'返回预约一览',
      isShow:' 确定',
      falseBut:'取消',
      propertyDetailBtn:'物件详细',
      noTopreview:'取消预约',
      noTopreviewBut:'取消看房预约',
    },
    appliedPropertyListView:{
      title:'申请房屋列表',
      appliedNo:'申请号码',
      goodName:'物件名称',
      propertyType:'物件种类',
      state:'状态',
      home:'地址',
      homeNumber:'房间号・楼层',
      DedicatedArea:'专用面积'
    },
    AppliedPropertyDetail:{
      title:'申请物品的详细情况',
      goback:'进入申请一览画面',
      subtitleFrist:'进度状况',
      subtitle:'当前进度:',
      serve:'保存',
      subtitleSecond:'报名详细信息',
      subtitleThirdly:'房屋详细信息',
      subtitleFourthly:'企业信息',
      subtitleFristMessage:{
        frist:'提交资料',
        second:'审查',
        third:'合同',
      },
      subtitleSecondMessage:{
        applicationForm:'申请书',
        identificationPaper:'身份证明',
        GuarantorInformation:'连带担保人信息',
        studentIDCard:'学生证',
        noticeOfDecision:'内定通知书',
        employmentContract:'雇佣合同',
        ThreeMonthsSalaryList:'三个月的工资清单',
        ThreeMonthsCopyOfPassbook:'三个月的存折复印件',
        TheBalanceOfTheAccount:'账户的存款余额',
        incumbencyCertification:'在职证明',
        residentCertificate:'居民票',
        certificate:'印章证明书',
        taxCertificate:'纳税证明',
        ResidentCertificateSponsor:'连带保证人住民票',
        certificateSponsor:'连带保证人印章证明书',
        taxCertificateSponsor:'连带保证人纳税证明书',
        CertificateCompleteResume:'履历全部证明书',
        companyBrochure:'公司宣传册',
        ASetOfAccounts:'财务报表',
        proofOfIncome:'收入证明',
      },
      subtitleThirdlyMessage:{
        goodsId:'物品号',
        goodsName:'物品名称',
        homeNumber:'房间号・楼层',
        ObjectItemLarge:'物件项目(大)',
        ObjectItemSmall:'物件项目',
        buildName:'建筑名',
        address:'所在地',
        architecturalStructure:'建筑结构',
        date:'建造年月',
        vehicle:"交通工具",
        TrafficDistance:'交通(距离)',
        DedicatedArea:'专用面积',
        balcony:'阳台',
        MainLightSurface:'主要采光面',
        managementFee:'管理费',
        price:'价格',
        rent:'租金',
        accumulationFund:'公积金',
        keyMoney:'酬金',
        securityDeposit :'押金',
        earnestMoney:'保证金',
        termOfContract:'合同期限',
        drawing:'图纸',
        remark:'备注',
        comment:'评论'
      },
      subtitleFourthlyMessage:{
        id:'企业ID',
        name:'企业名称',
        phoneNumber:'电话号码',
        FixedRestDay:'固定休息日',
        businessHours:'营业时间'
      },
      customer:{
        customInfo:'顾客详细信息',
        name:'姓名',
        birth:'出生年月日',
        age:'年龄',
        sex:'性别',
        phone:'电话号码',
        email:'邮箱地址',
        monthlySalary:'月薪',
        maritalStatus:'婚姻状况',
        nationality:'国籍',
        occupationType:'职业种类',
        kinds:'种类',
        address:'住所',
        tradeName:'商业名称',
        headOfficeLocation:'公司所在地',
        mainPhone:'代表电话',
        tenantName:'居住人姓名',
        tenantBirth:'出生年月日',
        tenantPhone:'电话号码',
        required:'(必填)',
        selectList:{
          man:'男',
          woman:'女',
          married:'已婚',
          unmarried:'未婚',
          CompanyEmployee:'公司职员',
          SelfEmployed:'个体营业',
          User:'用户',
          companies:'企业',
        }


      },
      propBox:{
        title:'取消认购的房屋',
        boxBtn:'取消认购的房屋',
        inputLable:'请在这里输入取消理由。'
      }
    },
    favoriteListMessage:{
      title:'收藏夹',
      buildingName:'建筑名',
      type:'类型',
      propertyType:'物件种类',
      // propertyType:{
      //   rental:'租赁',
      //   buying:'购买',
      // },
      address:'地址',
      roomNo:'房间号',
      exclusiveArea:'专用面积',
      control:'管理',
      buttonName:{
        detail:'物件详细',
        delete:'删除',
        goHome:'返回主页'
      },
      deleteMessage:{
        title:'指定收藏删除确认',
        text:'您确定要删除该收藏吗？',
        confirm:'确定',
        cancel:'取消'
      }
    },
    browsedListMessage:{
      title:'浏览记录',
      buildingName:'建筑名',
      type:'类型',
      propertyType:'物件种类',
      // propertyType:{
      //   rental:'租赁',
      //   buying:'购买',
      // },
      address:'地址',
      roomNo:'房间号',
      exclusiveArea:'专用面积',
      browsingTime:'浏览时间',
      buttonName:{
        goHome:'返回主页'
      }
    },
    PropertyDetail:{
      title:{
        goodsMessage:'房屋信息',
        RentalDtoData:'入住条件',
        SellingData:'合同条件',
        StatusProperty:'物件状況',
        Buysell:'仅售卖',
        Others:'其他',
        featureData:'设备',
        FacilitiesData:'特点',
        companyData:'所属公司信息',
      },
      companyData:{
        companyName:'公司名称',
        staffName:'负责人姓名',
        staffEmail:'负责人邮箱地址',
        staffPhone:'负责人电话号'
      },
      childenClassTitle:{
        relevantProof:'有相关证明',
        equipmentTitle1:'浴室、厕所、盥洗室',
        equipmentTitle2:'收藏',
        equipmentTitle3:'设备、功能 ',
        equipmentTitle4:'电视通信 ',
        equipmentTitle5:'阳台庭院 ',
        equipmentTitle6:'厨房 ',
        equipmentTitle7:'家具、家电、照明 ',
        equipmentTitle8:'冷暖气 ',
        equipmentTitle9:'保安  ',
        equipmentTitle10:'公用设施  ',
        featureTitle1:'特点',
        featureTitle2:'工法、构造',
        featureTitle3:'评价、证明书',
      },
      util:{
        util1:'无',
        util2:'有',
        util3:'是',
        util4:'否',
        util5:'可',
        util6:'不可',
      },
      theSlectData:{
        buildingName:'建筑物名称',
        address:'所在地',
        address_other:'详细所在地',
        advertisingFeePercent:'广告费',
        allowedNumberOfApplicants: '允许申请人数',
        appeal: '吸引',
        balconyArea: '阳台',
        buildingDate: "建造年月",
        buildingNameOpenStatus: '建筑物名称公开状态',
        buildingStructure: "建筑结构",
        comment: '评论',
        commission: '手续费',
        companyId: "企业ID",
        companyStaffId: "工作人员ID",
        contractPeriod: '合同期限',
        controlNumber: '管理号码',
        currentSituation: '现状',
        deposit: '公积金',
        drawing: '图纸',
        email: '邮箱地址',
        exclusiveArea: "专有面积",
        floorPlan: "布局",
        guaranteeDeposit: '保证金',
        inHouseControlNumber: '本公司管理号码',
        keyMoney: '酬金',
        landRights: '土地权利',
        locationOpenStatus: '所在地公开状态',
        mainLightSurface: '主要采光面',
        managementFee: '管理费',
        modeOfTransaction: '交易形式',
        moveInApplication: '入住申请',
        moveInSelectDueDate: '入住日-预定日选择',
        moveInStatus: '广告费-万日元',
        note: '备注',
        preview: '看房',
        price: '价格',
        propertyFileId: "物件_文件ID",
        propertyId: '房屋ID',
        otherTransportation:'其他交通',
        propertyOpenStatus: '物件公开状态',
        propertyType: "物件项目",
        propertyTypeLarge: "物件项目（大）",
        propertyTypeSmall: "物件项目(小)",
        propertyViewingId: '预约ID',
        roomNo: "房间号",
        roomNoOpenStatus: '房间号公开状态',
        roughEstimate: '概算估计',
        scheduledMoveOutDate: '现况-预定交房日',
        securityDeposit: '押金',
        totalUnits: '总户数',
        trafficDistance: '交通(距离)',
        trafficMeans: '交通工具',
        useStation1: '使用车站1',
        useStation2: '利用车站2',
        workStyleManager: '管理人员的工作形式',
        newUsedStatus:'新房/二手房',

      },
      RentalDtoData:{
        bed:'床',
        bicycleParking:'自行车停车场',
        bikeStorage:'摩托车停放处',
        parkingFee:'停车场费',
        bicycleParkingFee:'自行车停车场费',
        bikeStorageFee:'摩托车停放处费',
        commonServiceFee:'公益费',
        companyHousing:'宿舍',
        rentType:'租赁类型',
        rentContractPeriod:'合同期限',
        contractPeriod:'合同期限',
        corporateContract:'法人合同',
        creditCardPayment:'信用卡结算',
        deductionFromRentalDepositMoney:'押金',
        deductionFromRentalDepositMonth:'押金和首月租金',
        diy:'DIY',
        fixedTermLease:'定期租房',
        forPaperwork:'处理事务用',
        foreigner:'外国人',
        freeRent:'免租金',
        guaranteeDepositMoney:'保证金费用',
        guaranteeDepositMonth:'保证金月份',
        guarantor:'保证人',
        homeInsurance:'住房保险',
        instrumentConsultation:'乐器咨询',
        insuranceEtc:'保险等',
        keyExchangeFee:'钥匙交换费',
        keyMoney:'礼金费用',
        keyMonth:'礼金月份',
        managementFee:'管理费',
        moveInPeriod:'可入住时间',
        numberOfFloors:'所在楼层/层数',
        numberOfOccupants:'入住人数',
        otherLumpSum:'其他临时金',
        others:'其他',
        parking:'停车场',
        renewalFeeMoney:'更新费用',
        renewalFeeMonth:'更新月份',
        renewalFeeType:'更新费类型',
        rent:'租赁',
        rentalGuarantee:'租赁保证',
        roomShare:'合租房间',
        roughEstimate:'概算估计',
        securityDepositMoney:'押金费用',
        securityDepositMonth:'押金月份',
        seniorCitizen:'老年人',
        sex:'性别',
        smoking:'吸烟',
        sohoUse:'SOHO使用',
        studentsOnly:'学生限定',
      },
      SellingData:{
        bicycleParking:'自行车停车场',
        bikeStorage:'摩托车停放处',
        parkingFee:'停车场费',
        bicycleParkingFee:'自行车停车场费',
        bikeStorageFee:'摩托车停放处费',
        deposit:'公积金',
        flat35conformityCertificateAvailable:'有flat 35合格证',
        flat35sconformityCertificate:'有flat 35s合格证',
        grossYield:'表面收益率',
        landRights:'土地权利',
        legalComplianceSurveyReportAvailable:'有法律符合状况调查报告',
        managementFee:'管理费',
        otherLumpSum:'其他临时金',
        parking:'停车场',
        pet:'宠物',
        plannedAnnualRentalIncome:'年预定租金收入',
        price:'价格',
        rentalGuarantee:'租赁保证',
        seismicStandardConformityCertificate:'有耐震基准合格证',
        specialNote:'特记',
      },
      featureData:{
        adoptionOfHighSash:'采用配页纸',
        allElectric:'电气化',
        allRoomsSidesLighting2:'全室两面采光',
        barrierFree:'无障碍',
        belsEnergySavingStandardComplianceCertification:'BELS /节能标准合格认定',
        ceilingHeightMore250cm:'天花板高2.5米以上',
        constructionMethod24:'2×4木造',
        constructionMethod26:'2×6木造',
        cornerRoom:'转角房间',
        dampingStructure:'防震构造',
        designers:'设计师',
        doubleFloor:'双层地板',
        doubleFloorDoubleCeiling:'双层地板、双层天花板',
        doubleHeightSpaceAllocation:'通风空间 隔断',
        exteriorTiled:'外观贴瓷砖',
        exteriorWallConcrete:'外墙混凝土',
        exteriorWallSiding:'外墙铺设',
        externalInsulationMethod:'外绝热工艺',
        garbageTakenOutAnyTime:'垃圾收集',
        highStrengthConcrete:'高强度混凝土',
        highlyAirtightHighlyInsulatedHouse:'高气密高隔热住宅',
        interiorConcrete:'室内装修混凝土',
        nonFormaldehyde:'无甲醛',
        outFrameMethod:'外架工艺',
        propertyCorrespondingToItHeavyTheory:'IT重说对应物件',
        reverseBeamConstructionMethod:'倒梁工艺',
        roadHeating:'载荷供暖',
        salesType:'商品房',
        seismicComplianceCertificate:'耐震标准合格证',
        seismicIsolationStructure:'防震结构',
        shareHouse:'合租房',
        sidesLighting2:'2面采光',
        sidesLighting3:'3面采光',
        skeletonInfill:'骨架结构填充材料',
        smartHouse:'智能房屋',
        suitableTwoHouseholds:'适合两户人家',
        topFloor:'顶层',
        twentyFourHourVentilationSystem:'24小时通风系统',
      },
      FacilitiesData:{
        airConditioner:'空调',
        airConditionerBuiltin:'室内空调',
        airConditionersTwo:'2台以上空调',
        autoLockWithMonitor:'监视器自动锁',
        automaticBathShower:'淋浴',
        balcony:'阳台，阳台，庭院',
        bathSame:'公共汽车',
        bathToiletSame:'干湿不分离',
        bathToiletSeparate:'干湿分离',
        bathroomDryer:'浴室干燥机',
        bathroomHeating:'浴室暖气',
        bathroomTv:'TV浴室',
        bed:'床',
        bsTerminal:'BS终端',
        burnerStove2:'两口炉子',
        burnerStove3:'三口以上炉灶',
        busAudio:'总线音频',
        cableBroadcastCs:'有线广播CS,TV',
        catv:'CATV',
        cityGas:'城市煤气',
        cleaningService:'清洁服务',
        closet:'衣柜',
        coinLaundry:'自助洗衣店',
        cooling:'冷气、冷气',
        deliveryBox:'快递箱',
        dimpleKey:'小酒窝',
        doubleLockDoorAutoLock:'双锁门，安全',
        electricStove:'电炉',
        elevator:'电梯公用设施',
        elevatorsTwoMore:'两部以上电梯',
        energySavingWaterHeater:'节能热水器',
        ffHeating:'FF暖气',
        floorHeating:'地暖',
        floto:'软盘',
        freeInternetUsage:'免费使用网络',
        frontService:'前台服务',
        garden:'庭院',
        gardenOver10:'庭院十坪以上',
        gasStovePossible:'炉子',
        grill:'烤架',
        heating:'暖气',
        highTemperatureDifferenceHotwaterType:'高温差汤式',
        hotWaterSupply:'电力',
        ihCookingHeater:'IH烹饪加热器',
        indirectLighting:'间接照明',
        indoorAundryRoom:'室内洗衣房，设备和功能',
        indoorClothesline:'室内晾衣',
        laundryPlace:'晾衣服的地方',
        lightingWithMotionSensor:'人感传感器照明',
        mmOutletInternetCompatible:'支持MM插座上网',
        phone:'电话，家具，家电，照明',
        privateGarden:'专用庭院',
        propaneGas:'丙烷',
        propertyFacilities:'物件_设备',
        refrigerator:'冰箱',
        reheatingFunction:'追踪功能',
        roofBalcony:'屋顶阳台',
        sauna:'桑拿',
        saunaMist:'桑拿蒸汽',
        securityGlass:'防盗玻璃',
        sharedRoomGuest:'公用客房',
        sharedRoomParty:'公共宴会厅',
        sharedRoomTheater:'公共剧场',
        shoesBox:'鞋柜',
        shoesIc:'鞋类IC',
        showerRoom:'淋浴间',
        shutter:'卷帘门',
        storageAllRoom:'全居室收纳',
        storageSpace:'收纳空间',
        sunroomGreenSide:'阳光房绿侧',
        systemKitchen:'厨房系统',
        threeSideBalcony:'三面阳台',
        twoSideBalcony:'两面阳台',
        underfloorStorage:'地板下收纳',
        walkinCloset:'步入式衣柜',
        washToilet:'温水战场座便器',
        washerDryerClothesDryer:'洗衣干衣机',
        wideBalcony:'宽阳台',
        withGasStove:'带燃气灶的厨房',
        withHomeAppliances:'家电付款',
        withLightingFixtures:'灯具家具',
        woodDeckTerrace:'木质甲板，露台',
      }

    },
    SellingLendingAssessment:{
      assessmentList:'评估委托列表',
      title:'评估委托',
      nameNone:'无',
      navTitleFrist:'输入房屋信息和顾客信息',
      navTitleSecond:'选择房地产商',
      assessmentDetail:"核定委托详细画面",
      detailButton:'核定委托详细',
      navTitleThirdly:'发送完成',
      propertyName:'物件名称',
      status:'状态',
      required:'(必填)',
      way:{
        title:'委托方式',
        firstWay:'卖房',
        secondWay:'租房'
      },
      propertyInfo :{
        info:'物件情報',
        title:'物件类型',
        type:'物件种类',
        mansion:'高级公寓',
        individualHouse:'独栋',
        land:'土地',
        others:'其他',
        buildingName:'建筑物名称',
        buildingAddress:'建筑物所在地',
        roomNumber:'房间号',
        constructionDate:'建造年月',
        floorPlan:'布局',
        buildingArea:'建筑面积',
        landArea:'土地面积',
        price:'价格',
        managementFee:'管理费',
        reserveFund:'押金',
        landOwnership:'土地所有权',
        contractPeriod:'合同期间',
        guaranteeDeposit:'保证金',
        keyMoney:'礼金',
        rentDeposit:'租金',
        securityDeposit:'押金'
      },
      buildingStucture:{
        title:'建筑结构',
        wood:'木造',
        block:'ブロック造',
        steel:'铁骨结构',
        RC:'RC',
        SRC:'SRC',
        PC:'PC',
        HPC:'HPC',
        ALC:'ALC',
        lightweightSteel:'轻量钢结构',
        other:'其他'
      },
      customerInfo:{
        title:'顾客信息',
        ownerName:'姓名',
        ownerPhone:'电话',
        ownerNote:'备注'
      },
      companyInfo:{
        title:"企业提案信息",
        tableHead:{
          RealEstateAgent:'房地产商',
          Status:'状态',
          proposal:'提案书',
          SalesContract:'买卖合同',
          ContractDate:'合同日期',
        },
        butList:{
          approval:'approval',
          rejected:'rejected',
        },
        porpBoxText:{
          Comment:'评论',
          Cancel:'取消',
          Confirmed:'确认'
        },
        statusList:{
          InProposal:'提案中',
          Contract:'已签约',
          elected:'当选',
          Unelected:'非当选'
        }
      },
      userUploadFiles:{
        addBut:'添加',
        title:'用户附件',
        fileName:'文件名',
        comment:'评论',
        actions:'操作'
      },
      selectEstateAgent:{
        title:'选择房地产商',
        estateAgent:'房地产商',
        agent1:'房地产商1',
        agent2:'房地产商2',
        agent3:'房地产商3',
        agent4:'房地产商4',
        agent5:'房地产商5',
        agent6:'房地产商6',
      },
      buttonDetailTitle:{
        selectEstateAgent:'选择房地产中介',
        send:'发送评估委托',
        input:'输入房屋信息和顾客信息',
        backSellingLendingList:'评估委托列表',
        newAssessment:'创建',
        goHome:'回到主页',
        download:'下载',
        delete:'删除',
        cancel:'取消',
        upload:'上传',
    },
      lastTitle:'发送成功!'
    },
    ModelEnumMessage:{
      ApplicationStatus:{
        Application:'申请',
        Inspection:'承认',
        Contract:'取消',
        Complete:'完成',
      },
      PreviewStatus:{
        Unsettled:'未确定',
        ReApplication:'重新申请',
        Confirmed:'确定完毕',
        Cancel:'取消'
      }
    },
    Inquiry:{
      InquiryList:{
        title:'问询一览',
        cancelPopupTitle:'取消问询',
        cancelPopupMessage:'确定要取消吗？',
        propertyName:'标题',
        status:{
          title:'状态',
          underInquiry:'问询中',
          completed:'已完成',
          cancelled:'已取消'
        },
        address:'地址',
        roomNumber:'房间号',
        inquiryContent:'问询内容',
        actions:{
          title:'操作',
          cancel:'取消',
          goHome:'回到主页',
          confirm:'确认'
        }
      },
      InquiryDetail:{
        title:'问询详细',
        toInquiriyListTitle:'确认关闭问询',
        toInquiriyListContent:'确认关闭此问询吗？',
        cancelTitle:'问询取消确认',
        cancelContent:'确认取消该问询吗？',
        notes:'备注',
        name:'姓名',
        mailAddress:'邮箱',
        phoneNumber:'电话',
        chat:{
          chatHistory:'聊天历史',
          me:'我',
        },
        actions:{
          toInquiriyList:'回到问询列表',
          close:'关闭',
          cancel:'取消',
          confirm:'确认',
          send:'发送'
        }
      }
    },
    RentalManagement:{
      rentalManagementList:'租赁管理列表',
      propertyName:'物件名',
      propertyInfo:'物件信息',
      owner:'业主',
      manager:'负责人',
      roomStatus:'房间状况',
      roomNumber:'房间号',
      address:'地址',
      managementStartDate:'管理开始日期',
      owenerInfoDetail:'详细业主信息',
      managerInfo:'负责人信息',
      name:'姓名',
      katakana:'片假名',
      phone:'电话号码',
      mail:'邮箱',
      date:'日期',
      title:'标题',
      content:'内容',
      file:'附件',
      reportList:'报告一览',
      rentalContractList:'合同信息一览',
      rentalContractDetail:'合同详细信息',
      rentalPaymentList:'付款信息一览',
      rentalPaymentDetail:'付款详细信息',
      rentalPropertyReport:'租赁物件报告一览',
      rentalPropertyReportDetail:'租赁物件报告详细',
      rentalTenantReport:'入住者对应报告一览',
      rentalTenantReportDetail:'入住者对应报告详细',
      rentManagementDetail:{
        title:'租赁管理详细画面',
        buttons:{
          goToRentalPayment:'汇款明细',
          goBackToRentalPayment:'回到汇款明细',
          goToTenantReport: '入住者对应报告',
          goBackToTenantReport: '回到入住者对应报告',
          goToPropertyReport:'租赁物件报告一览',
          goBackToPropertyReport:'回到租赁物件报告一览',
          goToContract:'合同信息',
          goRentalManagementDetail:'物件詳細へ',
          goBackToContract:'契約情報一覧へ',
          goHome:'回到主页'
        }
      }
    }, 
    searchMessage:{
      title:'搜索',
      area:'地区',
      rent:'租金',
      buyingPrice:'价格',
      buildingAge:'建造年月',
      detailedCondition:'详细条件',
      station:'站名',
      button:{
        search:'搜索',
        clearCondition:'清除搜索条件',
        fullImage:'全部图片',
        viewDetails:'查看详情'
      },
      checkbox:{
        rantal:'租赁',
        buying:'买卖'
      },
      tableInfo :{
        roomNumber:'房间号',
        constructionDate:'建造年月',
        floorPlan:'布局',
        buildingArea:'建筑面积',
        landArea:'土地面积',
        price:'价格',
        images:'图片',
        exclusiveArea:'专用面积',
        managementFee:'管理费',
        deposit: '公积金',
        guaranteeDeposit:'保证金',
        keyMoney:'礼金',
        rentDeposit:'租金',
        securityDeposit:'押金',
        favorite:'收藏'
      },
    },
    InquiryNewMessage:{
      title:'咨询',
      navtitle:{
        nav1:'输入咨询内容',
        nav2:'确认内容',
        nav3:'咨询完毕'
      },
      tabletitle:{
        pid:'房屋ID',
        controlNumber:'管理号码',
        inquiryUserName:'姓名',
        phone:'电话号码',
        email:'邮箱地址',
        title:'标题',
        content:'内容',
        note:'备注',
      },
      overContentText:'发送了咨询的内容。',
      buttonText:{
        ModifyInput:'修改输入内容',
        ConfirmNext:'确认输入内容后进入下一步',
        send:'发送',
        backHomepage:'返回'
      },
      errText:{
        nullError:'信息不完整'
      }
    },
    inquirtySupportView:{
      title:"咨询",
      concentText:'如果有不明白的地方或要求的话，请随时咨询。 支持团队确认了内容之后会联系您。(支援时间:平日10点~ 17点)',
      overText:'咨询结束了。',
      inputTitle:{
        type:"类别",
        name:"姓名",
        phone:'电话号码',
        email:'邮箱',
        content:'内容'
      },
      buttomlais:{
        confirm:'确认',
        empty:"清空"
      },
      typeList:{
        issue:'问题',
        FunctionalRequirement:'希望添加的功能',
        VulnerabilityReport:"bug报告",
        systemDevelopment:'系统开发',
        other:'其他'
      }
    },
    theFooter:{
      ContactInformation:'联系方式',
      email:"邮箱",
      footerText:'关于系统的咨询'
    },
    simulatorView:{
      borrowMuch:'想借的金额',
      internalBonus:'内奖金部分的借款额',
      RepaymentPeriod:'偿还期限',
      rate:'利息(年)',
      repayment:'偿还方法',
      CurrentIncome:'现在的年收入',
      ReduceRepaymentBurden:'偿还负担率',
      MonthlyRepayment:'每月还款额',
      repaymentList:{
        EqualInterestAndPrincipal:'本息均等',
        EqualPrincipal:'本金均等'
      },
      butTo:'估算',
      resultBox:{
        title:'模拟结果',
        MonthlyRepayment:'每月还款额',
        MonthlyBonusPayments:'奖金月还款额',
        AnnualRepaymentAmount:'年还款额',
        TotalRepayment:'总还款额',
        TheRepayment:'首月还款额',
        AmountOfBorrowing:'可借款金额',
      },
      titleNavList:{
        RequiredAmountOfLoan:'希望借款额',
        AnnualIncome:'年薪',
        MonthlyRepayment:'每月还款额',
        andOr:'为依据',
        frist:'计算每月的还款额',
        second:'计算可贷款金额',
      }
    },
    BackButtomList:{
      goHome:'返回首页',
      goBack:'返回',
      send:'发送',
      closing:'关闭',
      cancle:'清空',
      confirmed:'确定',
    },
    formVerification:{
      date:'日期',
      name:'请输入您的名字。',
      email:'请输入邮箱地址。',
      emailType:'您输入的邮箱地址是非法的',
      phone:'请输入电话号码。',
      phoneType:'请输入正确的电话号码。',
      title:'请输入标题。',
      content:'请输入内容。',

      dateFrist:'请输入第一希望的日期。',
      dateFristType:'请设定正确的日期。',
      dateSecond:'请输入第二希望的日期。',
      dateThirder:'请输入第三希望的日期。',
    },
    theHeader:{
      notification:'通知',
      SystemNotification:'系统通知',
      Profile:'概况',
      ChangePassword:'修改密码',
      LogOut:'退出登录'
    },
    PostcodeMessage:{
      confirmed:'从邮政编码查地址',
      value:'请输入邮编',
      errorText:'邮编不存在'
    },
    PaginationMessage:{
      ItemsPerPage:'每页条数',
    },
    InitialCost:{
      price:'价格',
      deposit:'公积金',
      CommonServiceFee:'公益费',
      ManagementFee:'管理费',
      securityDeposit:'押金',
      reward:'酬金',
      bail:'保证金',
      depreciation:'敷引',
      lumpSum:'其他临时金'
    },
    detailButton:'详细',
  }

export default cn