<template>
    <v-card>
        <v-layout>
            <the-header @chooselanguage='changeLanguageType'></the-header>
            <v-navigation-drawer width="180">
            </v-navigation-drawer>
            <v-navigation-drawer location="right" width="180">
            </v-navigation-drawer>
            <v-main>
                <v-container class="align-center">
                    <v-row>
                        <v-col cols="12">
                            <p class="text-h4">{{ $t('favoriteListMessage.title') }}</p>
                        </v-col>
                    </v-row>
                    <!-- お気に入り一覧 -->
                    <v-row no-gutters class="mt-4">
                        <v-col cols="12">
                            <v-table>
                                <thead>
                                    <tr>
                                        <th class="text-left text-base font-weight-bold">
                                            No.
                                        </th>
                                        <th class="text-left text-base font-weight-bold">
                                            {{ $t('favoriteListMessage.buildingName') }}
                                        </th>
                                        <th class="text-left text-base font-weight-bold xsScreen">
                                            {{ $t('favoriteListMessage.propertyType') }}
                                        </th>
                                        <th class="text-left text-base font-weight-bold">
                                            {{ $t('favoriteListMessage.address') }}
                                        </th>
                                        <th class="text-left text-base font-weight-bold xsScreen">
                                            {{ $t('favoriteListMessage.roomNo') }}
                                        </th>
                                        <th class="text-left text-base font-weight-bold xsScreen">
                                            {{ $t('favoriteListMessage.exclusiveArea') }}
                                        </th>
                                        <th class="text-left text-base font-weight-bold">
                                            {{ $t('favoriteListMessage.control') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="data.favoriteList.length != 0">
                                    <tr v-for="(item, index) in data.propertyInfos" :key="item.favoriteId">
                                        <td>
                                            {{ index + 1 }}
                                        </td>
                                        <td> 
                                            <span>{{ item.name }} </span>
                                        </td>
                                        <td class="xsScreen">{{ ItemsSecond(item.propertyTypeSmall, item.propertyType == 3 ? 1 : 2) }}</td>
                                        <td>{{ item.address }}</td>
                                        <td class="xsScreen">{{ item.roomNumber }}</td>
                                        <td class="xsScreen">{{ item.exclusiveArea }}</td>
                                        <td>
                                            <span>
                                                <a class="a-link" href="#" @click.stop.prevent="toPropertyDetail(item.propertyId)" v-if="item.propertyDeletedFlag == 0">{{ $t('favoriteListMessage.buttonName.detail') }}</a>
                                            </span>

                                            <span>&ensp; </span>
                                            <span>
                                                <a class="a-link" href="#" @click.stop.prevent="openDialog(item.favoriteId)">{{ $t('favoriteListMessage.buttonName.delete') }}</a>
                                            </span>
                                            <v-dialog max-width="60%" style="height: 300px;" v-model="data.showDialog" class="xsScreen"
                                                position="top">
                                                <v-card elevation="6">
                                                    <v-card-title>{{ $t('favoriteListMessage.deleteMessage.title')
                                                    }}</v-card-title>
                                                    <v-card-text>
                                                        {{ $t('favoriteListMessage.deleteMessage.text') }}
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-btn color="info" @click="confirmCancel"
                                                            variant="text">
                                                            {{$t('favoriteListMessage.deleteMessage.confirm') }}
                                                        </v-btn>
                                                        <v-btn color="error" @click="closeDialog" variant="text">{{
                                                            $t('favoriteListMessage.deleteMessage.cancel') }}
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                            <v-dialog max-width="80%" style="height: 300px;" v-model="data.showDialog" class="laScreen"
                                                position="top">
                                                <v-card elevation="6">
                                                    <v-card-title>{{ $t('favoriteListMessage.deleteMessage.title')
                                                    }}</v-card-title>
                                                    <v-card-text>
                                                        {{ $t('favoriteListMessage.deleteMessage.text') }}
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-btn color="info" @click="confirmCancel"
                                                            variant="text">
                                                            {{$t('favoriteListMessage.deleteMessage.confirm') }}
                                                        </v-btn>
                                                        <v-btn color="error" @click="closeDialog" variant="text">{{
                                                            $t('favoriteListMessage.deleteMessage.cancel') }}
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </v-col>
                    </v-row>
                    <v-row class="home-button-row">
                        <v-btn class="mt-10 home-button" color="success" variant="outlined" @click="goHome">
                            {{ $t('favoriteListMessage.buttonName.goHome') }}
                        </v-btn>
                    </v-row>
                    <v-row class="mt-10">
                        <the-footer></the-footer>
                    </v-row>
                </v-container>
            </v-main>
        </v-layout>
    </v-card>
</template>
<style>
.table-border {
    border: 1px;
    border-style: solid;
    border-color: antiquewhite;
}
</style>
<script lang="ts">
import { defineComponent, reactive, getCurrentInstance, onMounted, defineProps } from 'vue'
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import { useRoute, useRouter } from 'vue-router'
import api from '@/api/axios/axios'
import { PropertyType } from '../api/sys/model/enum';
import i18n from '../language/index'
import {ItemsFrist ,ItemsSecond } from "../api/sys/model/speciesList";

export default defineComponent({
    name: 'FavoriteList',
    components: {
        TheHeader,
        TheFooter,
    },
});
</script>
  
<script lang="ts" setup>

const props = defineProps({
    propertyInfo: Object,

})
const data = reactive({
    delFavoriteId:'',
    favoriteList: [],
    showDialog: false,
    languageType: 'jp' as string,
    propertyInfos: [
        {
            favoriteId: "",
            propertyId: "",
            roomNumber: '',
            name: "",
            address: "",
            type: 0,
            exclusiveArea: "",
        },
    ],

})


const openDialog = (id:string) => {
    data.showDialog = true;
    data.delFavoriteId = id
}
const closeDialog = () => {
    data.showDialog = false;
}
const confirmCancel = async () => {
    try {
        await api.delete(`/user/favorite/` + data.delFavoriteId)
        // alert('指定お気に入り物件を削除しました！')
        closeDialog()
        getInitData()
    } catch (error) {
        // alert("削除失敗しました。");
        // closeDialog();
        console.log(error, data.delFavoriteId);
    }
};


const router = useRouter()

const toPropertyDetail = (propertyId: string) => {
    router.push({
        name: 'detail',
        query: {
            id: propertyId,
            type: 'favoriteList'
        },
    })

}

const goHome = () => {
    router.push('/');
};

const changeLanguageType = (val: 'jp' | 'en' | 'zh') => {
    console.log(i18n.global);
    data.languageType = val
    processingData(data.favoriteList)
}

const processingData = (favoriteList: any) => {
    for (let index in favoriteList) {
        if (Number(index) > 0) {
            data.propertyInfos[Number(index)] = JSON.parse(JSON.stringify(data.propertyInfos[0]))
        } else if (Number(index) == 0) {
            data.propertyInfos.splice(1);
        }

        data.propertyInfos[Number(index)].favoriteId = favoriteList[index].userFavoriteId
        data.propertyInfos[Number(index)].propertyId = favoriteList[index].propertyId
        data.propertyInfos[Number(index)].exclusiveArea = favoriteList[index].exclusiveArea ? favoriteList[index].exclusiveArea + ' ㎡' : '未入力'
        data.propertyInfos[Number(index)].type = favoriteList[index].propertyType
        data.propertyInfos[Number(index)].propertyType = favoriteList[index].propertyType
        data.propertyInfos[Number(index)].propertyTypeSmall = favoriteList[index].propertyTypeSmall
        data.propertyInfos[Number(index)].propertyDeletedFlag = favoriteList[index].propertyDeletedFlag

        if (i18n.global.locale == 'zh') {
            data.propertyInfos[Number(index)].name = favoriteList[index].buildingNameCn
            data.propertyInfos[Number(index)].address = favoriteList[index].addressCn
            data.propertyInfos[Number(index)].roomNumber = favoriteList[index].roomNoCn

        } else if (i18n.global.locale == 'en') {
            data.propertyInfos[Number(index)].name = favoriteList[index].buildingNameEn
            data.propertyInfos[Number(index)].address = favoriteList[index].addressEn
            data.propertyInfos[Number(index)].roomNumber = favoriteList[index].roomNoEn

        } else if (i18n.global.locale == 'jp') {
            data.propertyInfos[Number(index)].name = favoriteList[index].buildingNameJp
            data.propertyInfos[Number(index)].address = favoriteList[index].addressJp
            data.propertyInfos[Number(index)].roomNumber = favoriteList[index].roomNoJp

        }
    }


}

onMounted(() => {
    getInitData()
});

const getInitData = () => {
    api.get(`/user/favorite`, {
        params: {
            limit: 100,
            offset: 0
        }
    })
        .then((res) => {
            data.favoriteList = res.data
            processingData(data.favoriteList)
        })
        .catch((error) => {
            console.log(error.message)
        })
};
</script>
  