<template>
    <v-card>
        <v-layout>
            <the-header @chooselanguage='changeLanguageType'></the-header>
            <v-navigation-drawer width="280">
            </v-navigation-drawer>
            <v-navigation-drawer location="right" width="280">
            </v-navigation-drawer>
            <v-main>
                <v-container class="align-center">
                    <v-row>
                        <v-col cols="12">
                            <p class="mt-4 text-h4">{{ $t('SellingLendingAssessment.assessmentDetail') }}</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="text-right">
                            <v-btn size="large" class="" v-on:click="toSellingLendingList">{{ $t('SellingLendingAssessment.buttonDetailTitle.backSellingLendingList') }}</v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <p class="mt-4 text-h4">{{ $t('SellingLendingAssessment.propertyInfo.info') }}</p>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-4">
                        <v-col col="12">
                            <v-table class="table-border">
                                <tbody>
                                    <tr>
                                        <th class="text-left table-th">{{
                                                $t('SellingLendingAssessment.propertyInfo.type') }}</th>
                                        <th v-if="requestType === 'seller'" class="th-maxWidth">{{ pType[data.sellerRequestInfo.propertyType]  }}</th>
                                        <th v-if="requestType === 'landlord'" class="th-maxWidth">{{ pType[data.landlordRequestInfo.propertyType]  }}</th>
                                        <th class="text-left table-th">{{
                                                $t('SellingLendingAssessment.propertyInfo.buildingName') }}</th>
                                        <th v-if="requestType === 'seller'" class="th-maxWidth">{{ data.sellerRequestInfo.buildingName }}</th>
                                        <th v-if="requestType === 'landlord'" class="th-maxWidth">{{ data.landlordRequestInfo.buildingName  }}</th>
                                    </tr>
                                    <tr>
                                        <th class="text-left table-th">{{
                                                $t('SellingLendingAssessment.propertyInfo.buildingAddress') }}</th>
                                        <th v-if="requestType === 'seller'" class="th-maxWidth">{{ data.sellerRequestInfo.buildingAddress }}</th>
                                        <th v-if="requestType === 'landlord'" class="th-maxWidth">{{ data.landlordRequestInfo.buildingAddress  }}</th>
                                        <th class="text-left table-th">{{
                                                $t('SellingLendingAssessment.propertyInfo.roomNumber') }}</th>
                                        <th v-if="requestType === 'seller'" class="th-maxWidth">{{ data.sellerRequestInfo.roomNumber }}</th>
                                        <th v-if="requestType === 'landlord'" class="th-maxWidth">{{ data.landlordRequestInfo.roomNumber  }}</th>
                                    </tr>
                                    <tr>
                                        <th class="text-left table-th">{{
                                                $t('SellingLendingAssessment.buildingStucture.title') }}</th>
                                        <th v-if="requestType === 'seller'" class="th-maxWidth">{{buildType[data.sellerRequestInfo.buildingStructure]  }}</th>
                                        <th v-if="requestType === 'landlord'" class="th-maxWidth">{{buildType[data.landlordRequestInfo.buildingStructure]  }}</th>
                                        <th class="text-left table-th">{{
                                                $t('SellingLendingAssessment.propertyInfo.constructionDate') }}</th>
                                        <th v-if="requestType === 'seller'" class="th-maxWidth">{{ data.sellerRequestInfo.constructionDate }}</th>
                                        <th v-if="requestType === 'landlord'" class="th-maxWidth">{{ data.landlordRequestInfo.buildingDate  }}</th>
                                    </tr>
                                    <tr>
                                        <th class="text-left table-th">{{
                                                $t('SellingLendingAssessment.propertyInfo.floorPlan') }}</th>
                                        <th v-if="requestType === 'seller'" class="th-maxWidth">{{ data.sellerRequestInfo.floorPlan }}</th>
                                        <th v-if="requestType === 'landlord'" class="th-maxWidth">{{ data.landlordRequestInfo.floorPlan  }}</th>

                                        <th class="text-left table-th "
                                            v-if="requestType === 'seller'">{{
                                                $t('SellingLendingAssessment.propertyInfo.landOwnership') }}</th>
                                        <th  v-if="requestType === 'seller'">{{ data.sellerRequestInfo.landOwnership  }}</th>
                                        
                                        <th class="text-left table-th"
                                            v-if="requestType === 'landlord'">{{
                                                $t('SellingLendingAssessment.propertyInfo.contractPeriod') }}</th>
                                        <th  v-if="requestType === 'landlord'">{{ data.landlordRequestInfo.contractPeriod  }}</th>
                                    </tr>
                                    <tr>
                                        <th class="text-left table-th">{{
                                                $t('SellingLendingAssessment.propertyInfo.buildingArea') }}</th>
                                        <th v-if="requestType === 'seller'" class="th-maxWidth">{{ data.sellerRequestInfo.buildingArea }}㎡</th>
                                        <th v-if="requestType === 'landlord'" class="th-maxWidth">{{ data.landlordRequestInfo.propertyArea}}㎡</th>
                                        <th class="text-left table-th">{{
                                                $t('SellingLendingAssessment.propertyInfo.landArea') }}</th>
                                        <th v-if="requestType === 'seller'" class="th-maxWidth">{{ data.sellerRequestInfo.landArea }}㎡</th>
                                        <th v-if="requestType === 'landlord'" class="th-maxWidth">{{ data.landlordRequestInfo.landArea}}㎡</th>
                                    </tr>
                                    
                                    <tr>
                                        <th class="text-left table-th"
                                        v-if="requestType === 'seller'">{{
                                                $t('SellingLendingAssessment.propertyInfo.price') }}</th>
                                        <th class="text-left table-th"
                                        v-if="requestType === 'landlord'">{{
                                                $t('SellingLendingAssessment.propertyInfo.guaranteeDeposit') }}</th>
                                        <th v-if="requestType === 'seller'" class="th-maxWidth">{{ data.sellerRequestInfo.price }}万円</th>
                                        <th v-if="requestType === 'landlord'" class="th-maxWidth">{{ data.landlordRequestInfo.guaranteeDeposit}}万円</th>


                                        <th class="text-left table-th"
                                             v-if="requestType === 'seller'">{{
                                                $t('SellingLendingAssessment.propertyInfo.managementFee') }}</th>

                                        <th class="text-left table-th"
                                            v-if="requestType === 'landlord'">{{
                                                $t('SellingLendingAssessment.propertyInfo.keyMoney') }}</th>


                                        <th v-if="requestType === 'seller'" class="th-maxWidth">{{ data.sellerRequestInfo.managementFee }}万円</th>
                                        <th v-if="requestType === 'landlord'" class="th-maxWidth">{{ data.landlordRequestInfo.keyMoney}}万円</th>
                                    </tr>
                                    <tr>
                                        <th class="text-left table-th"
                                            v-if="requestType === 'seller'">{{
                                                $t('SellingLendingAssessment.propertyInfo.reserveFund') }}</th>
                                        <th v-if="requestType === 'seller'" class="th-maxWidth">{{ data.sellerRequestInfo.reserveFund }} 万円</th>

                                        <th class="text-left table-th"
                                            v-if="requestType === 'landlord'">{{
                                                $t('SellingLendingAssessment.propertyInfo.securityDeposit') }}</th>
                                        <th v-if="requestType === 'landlord'" class="th-maxWidth">{{ data.landlordRequestInfo.securityDeposit }} 万円</th>

                                        <th class="text-left table-th"
                                            v-if="requestType === 'landlord'">{{
                                                $t('SellingLendingAssessment.propertyInfo.managementFee') }}</th>
                                        <th v-if="requestType === 'landlord'" class="th-maxWidth">{{ data.landlordRequestInfo.managementFee }} 万円</th>
                                    </tr>
                                    <tr>

                                        <th class="text-left table-th"
                                            v-if="requestType === 'landlord'">{{
                                                $t('SellingLendingAssessment.propertyInfo.rentDeposit') }}</th>
                                        <th v-if="requestType === 'landlord'" class="th-maxWidth">{{ data.landlordRequestInfo.rentDeposit }} 万円</th>           
                                    </tr>

                                </tbody>
                            </v-table>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <p class="mt-4 text-h4">{{ $t('SellingLendingAssessment.customerInfo.title') }}</p>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-4">
                        <v-col col="12">
                            <v-table class="table-border">
                                <tbody>
                                    <tr>
                                        <th class="text-left table-th">{{
                                                $t('SellingLendingAssessment.customerInfo.ownerName') }}</th>
                                        <th v-if="requestType === 'seller'">{{ data.sellerRequestInfo.ownerName }}</th>
                                        <th v-if="requestType === 'landlord'">{{ data.landlordRequestInfo.ownerName }}</th>
                                    </tr>
                                    <tr>
                                        <th class="text-left table-th">{{
                                                $t('SellingLendingAssessment.customerInfo.ownerPhone') }}</th>
                                        <th v-if="requestType === 'seller'">{{ data.sellerRequestInfo.ownerPhone }}</th>
                                        <th v-if="requestType === 'landlord'">{{ data.landlordRequestInfo.ownerPhone }}</th>
                                    </tr>
                                    <tr>
                                        <th class="text-left table-th">{{
                                                $t('SellingLendingAssessment.customerInfo.ownerNote') }}</th>
                                        <th v-if="requestType === 'seller'">{{ data.sellerRequestInfo.ownerNote }}</th>
                                        <th v-if="requestType === 'landlord'">{{ data.landlordRequestInfo.ownerNote }}</th>
                                    </tr>

                                </tbody>
                            </v-table>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <p class="mt-4 text-h4">{{ $t('SellingLendingAssessment.userUploadFiles.title') }}</p>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <v-table>
                                        <thead>
                                            <tr v-if="(requestType === 'seller' && data.sellerRequestInfo.sellerRequestStatus != 3) || (requestType === 'landlord' && data.landlordRequestInfo.landlordRequestStatus != 3)"><v-btn v-if="!data.addBtnClick" size="small" @click="addUserFile">{{
                                                $t('SellingLendingAssessment.buttonDetailTitle.addFile') }}</v-btn>

                                                <v-btn v-if="data.addBtnClick" size="small">{{
                                                $t('SellingLendingAssessment.buttonDetailTitle.addFile') }}</v-btn>
                                            </tr>
                                            <tr>
                                                <td>No.</td>
                                                <td>{{ $t('SellingLendingAssessment.userUploadFiles.fileName') }}</td>
                                                <td>{{ $t('SellingLendingAssessment.userUploadFiles.comment') }}</td>
                                                <td>{{ $t('SellingLendingAssessment.userUploadFiles.actions') }}</td>
                                            </tr>
                                        </thead>
                                        <tbody class="UserFileBox" v-if="requestType === 'seller'">
                                            <tr v-for="(file, index) in data.sellerRequestInfo.userFileList"
                                                :key="file.sellerRequestUserFileId">

                                                <td>{{ index + 1 }}</td>
                                                <td v-if="!file.fileName">
                                                    <v-file-input label="File input" hide-details @change="uploadFile"></v-file-input>
                                                </td>
                                                <td v-if="file.fileName">{{ file.fileName + '.' + file.fileContentType }}</td>
                                                <td v-if="file.fileName">{{ file.comment }}</td>
                                                <td v-if="!file.fileName">
                                                    <v-text-field
                                                        v-model="file.comment"
                                                        hide-details="auto"
                                                        :label="$t('SellingLendingAssessment.userUploadFiles.comment')"
                                                        type="text"
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <a href="#" class="mr-3 a-link" v-if="!file.fileName" @click.stop.prevent="toUploadUserFile(file)"
                                                        style="text-decoration: none">{{ $t('SellingLendingAssessment.buttonDetailTitle.upload') }}</a>
                                                    <a href="#" v-if="file.fileName" @click.stop.prevent="toDownloadUserFile(file)" class="mr-3 a-link"
                                                        style="text-decoration: none">{{
                                                            $t('SellingLendingAssessment.buttonDetailTitle.download') }}</a>
                                                    <a href="#" v-if="file.fileName && data.sellerRequestInfo.sellerRequestStatus != 3" @click.stop.prevent="toDeleteUserFile(file.sellerRequestUserFileId)" class="mr-3 a-link"
                                                        style="text-decoration: none">{{
                                                            $t('SellingLendingAssessment.buttonDetailTitle.delete') }}</a>
                                                    <a href="#" v-if="!file.fileName" @click.stop.prevent="toCancelFile" class="mr-3 a-link"
                                                        style="text-decoration: none">{{
                                                            $t('SellingLendingAssessment.buttonDetailTitle.cancel') }}</a>
                                                </td>
                                            </tr>
                                        </tbody>

                                        <tbody class="UserFileBox" v-if="requestType === 'landlord'">
                                            <tr v-for="(file, index) in data.landlordRequestInfo.userFileList"
                                                :key="file.landlordRequestUserFileId">

                                                <td>{{ index + 1 }}</td>
                                                <td v-if="!file.fileName">
                                                    <v-file-input label="File input" hide-details @change="uploadFileLandlord"></v-file-input>
                                                </td>
                                                <td v-if="file.fileName">{{ file.fileName + '.' + file.fileContentType }}</td>
                                                <td v-if="file.fileName">{{ file.comment }}</td>
                                                <td v-if="!file.fileName">
                                                    <v-text-field
                                                        v-model="file.comment"
                                                        hide-details="auto"
                                                        label="コメント"
                                                        type="text"
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <a href="#" class="mr-3 a-link" v-if="!file.fileName" @click.stop.prevent="toUploadUserFile(file)"
                                                        style="text-decoration: none">{{ $t('SellingLendingAssessment.buttonDetailTitle.upload') }}</a>
                                                    <a href="#" v-if="file.fileName" @click.stop.prevent="toDownloadUserFile(file)" class="mr-3 a-link"
                                                        style="text-decoration: none">{{
                                                            $t('SellingLendingAssessment.buttonDetailTitle.download') }}</a>
                                                    <a href="#" v-if="file.fileName && data.landlordRequestInfo.landlordRequestStatus != 3" @click.stop.prevent="toDeleteUserFile(file.landlordRequestUserFileId)" class="mr-3 a-link"
                                                        style="text-decoration: none">{{
                                                            $t('SellingLendingAssessment.buttonDetailTitle.delete') }}</a>
                                                    <a href="#" v-if="!file.fileName" @click.stop.prevent="toCancelFile" class="mr-3 a-link"
                                                        style="text-decoration: none">{{
                                                            $t('SellingLendingAssessment.buttonDetailTitle.cancel') }}</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </v-table>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <p class="mt-4 text-h4">{{ $t('SellingLendingAssessment.companyInfo.title') }}</p>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <v-table>
                                        <thead>
                                            <tr>
                                                <td>No.</td>
                                                <td>{{ $t('SellingLendingAssessment.companyInfo.tableHead.RealEstateAgent') }}</td>
                                                <td>{{ $t('SellingLendingAssessment.companyInfo.tableHead.Status') }}</td>
                                                <td>{{ $t('SellingLendingAssessment.companyInfo.tableHead.proposal') }}</td>
                                                <td class="xsScreen">{{ $t('SellingLendingAssessment.companyInfo.tableHead.SalesContract') }}</td>
                                                <td class="xsScreen">{{ $t('SellingLendingAssessment.companyInfo.tableHead.ContractDate') }}
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody v-if="requestType === 'seller'">
                                            <tr  v-for="(item, index) in data.sellerRequestInfo.companyList" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>
                                                    <span class="downFileCompany" 
                                                        @click="ToShowCompany(item.companyId)"       
                                                    >
                                                        {{item.companyName}}
                                                    </span>
                                                </td>
                                                <td>{{ cType[item.sellerRequestCompanyStatus-1] }}</td>
                                                <td>
                                                    <span class="downFileCompany" 
                                                    v-for="(itemchild,indexChild) in item.companyFileList" 
                                                    :key="indexChild"
                                                    @click="toDownloadCompanyFile(itemchild,item)"
                                                    >
                                                        {{itemchild.fileType=='1'?itemchild.fileName:''}}
                                                    </span>
                                                    <div class="fileNameButtom" v-if="item.companyFileList.length>0">
                                                        <a href="#" @click.stop.prevent="changeCompanyType(item,2)" class="mr-3 a-link"
                                                        v-if="item.sellerRequestCompanyStatus!=2  &&item.sellerRequestCompanyStatus!=3 &&item.sellerRequestCompanyStatus!=4 &&item.sellerRequestCompanyStatus!=6 && data.sellerRequestInfo.sellerRequestStatus !=2 && data.sellerRequestInfo.sellerRequestStatus !=3"
                                                        style="text-decoration: none">
                                                            {{ $t('SellingLendingAssessment.companyInfo.butList.approval') }}
                                                        </a>
                                                        <a  class="mr-3 a-link"  @click.stop.prevent="changeCompanyType(item,6)"
                                                            v-if="item.sellerRequestCompanyStatus!=3 && item.sellerRequestCompanyStatus!=4 &&item.sellerRequestCompanyStatus!=6 && !item.companyFileList[1] && data.sellerRequestInfo.sellerRequestStatus !=2 && data.sellerRequestInfo.sellerRequestStatus !=3"
                                                            style="text-decoration: none">
                                                            {{ $t('SellingLendingAssessment.companyInfo.butList.rejected') }}
                                                        </a>

                                                        <v-dialog width="500"  v-if="!item.companyFileList[1]" >
                                                            <template v-slot:activator="{ props }">
                                                                <a v-bind="props"  class="mr-3 a-link" style="text-decoration: none">
                                                                   {{ $t('SellingLendingAssessment.companyInfo.porpBoxText.Comment') }}
                                                                </a>
                                                            </template>
       
                                                            <v-card :title="$t('SellingLendingAssessment.companyInfo.porpBoxText.Comment')">
                                                                <v-card-text>
                                                                    <v-textarea
                                                                        v-model="item.companyFileList[0].comment"
                                                                        hide-details="auto"
                                                                        :label="$t('SellingLendingAssessment.companyInfo.porpBoxText.Comment')"
                                                                        disabled
                                                                    ></v-textarea>
                                                                </v-card-text>
                                                            </v-card>
                                                       
                                                        </v-dialog>
                                                       
                                                    </div>
                                                </td>
                                                <td class="xsScreen">
                                                    <span class="downFileCompany" 
                                                        v-for="(itemchild,indexChild) in item.companyFileList" 
                                                        :key="indexChild"
                                                        @click="toDownloadCompanyFile(itemchild,item)"
                                                        >
                                                        {{itemchild.fileType=='2'?itemchild.fileName:''}}
                                                    </span>
                                                    <div class="fileNameButtom" v-if="item.companyFileList.length>1">
                                                        <a href="#" @click.stop.prevent="changeCompanyType(item,3)" class="mr-3 a-link"
                                                        v-if="item.sellerRequestCompanyStatus!=1 && item.sellerRequestCompanyStatus !=3 && item.sellerRequestCompanyStatus !=4 &&item.sellerRequestCompanyStatus!=6 && data.sellerRequestInfo.sellerRequestStatus !=2 && data.sellerRequestInfo.sellerRequestStatus !=3"
                                                        style="text-decoration: none">
                                                             {{ $t('SellingLendingAssessment.companyInfo.butList.approval') }}
                                                        </a>

                                                        <v-dialog width="500"  v-if="item.companyFileList[1]" >
                                                            <template v-slot:activator="{ props }">
                                                                <a v-bind="props"  class="mr-3 a-link" style="text-decoration: none">
                                                                   {{ $t('SellingLendingAssessment.companyInfo.porpBoxText.Comment') }}
                                                                </a>
                                                            </template>
       
                                                            <v-card :title="$t('SellingLendingAssessment.companyInfo.porpBoxText.Comment')">
                                                                <v-card-text>
                                                                    <v-textarea
                                                                        v-model="item.companyFileList[1].comment"
                                                                        hide-details="auto"
                                                                        :label="$t('SellingLendingAssessment.companyInfo.porpBoxText.Comment')"
                                                                        disabled
                                                                    ></v-textarea>
                                                                </v-card-text>
                                                            </v-card>
                                                       
                                                        </v-dialog>

                                                    </div>
                                                </td>
                                                <td class="xsScreen">{{item.contractDate}}</td>
                                            </tr>
                                        </tbody>
                                        <tbody v-if="requestType === 'landlord'">
                                            <tr  v-for="(item, index) in data.landlordRequestInfo.companyList" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>
                                                    <span class="downFileCompany" 
                                                        @click="ToShowCompany(item.companyId)"       
                                                    >
                                                        {{item.companyName}}
                                                    </span>
                                                </td>
                                                <td>{{ cType[item.landlordRequestCompanyStatus-1] }}</td>
                                                <td>
                                                    <span class="downFileCompany" 
                                                    v-for="(itemchild,indexChild) in item.companyFileList" 
                                                    :key="indexChild"
                                                    @click="toDownloadCompanyFile(itemchild,item)"
                                                    >
                                                        {{itemchild.fileType=='1'?itemchild.fileName:''}}
                                                    </span>
                                                    <div class="fileNameButtom" v-if="item.companyFileList.length>0">
                                                        <a href="#" @click.stop.prevent="changeCompanyType(item,2)" class="mr-3 a-link"
                                                        v-if="item.landlordRequestCompanyStatus!=2  &&item.landlordRequestCompanyStatus!=3 && item.landlordRequestCompanyStatus!=4 &&item.landlordRequestCompanyStatus!=6 && data.landlordRequestInfo.landlordRequestStatus !=2 && data.landlordRequestInfo.landlordRequestStatus !=3"
                                                        style="text-decoration: none">
                                                             {{ $t('SellingLendingAssessment.companyInfo.butList.approval') }}
                                                        </a>
                                                        <a  class="mr-3 a-link" @click.stop.prevent="changeCompanyType(item,6)"
                                                            v-if="item.landlordRequestCompanyStatus!=3 && item.landlordRequestCompanyStatus!=4 &&item.landlordRequestCompanyStatus!=6 && !item.companyFileList[1] && data.landlordRequestInfo.landlordRequestStatus !=2 && data.landlordRequestInfo.landlordRequestStatus !=3"
                                                            style="text-decoration: none">
                                                            {{ $t('SellingLendingAssessment.companyInfo.butList.rejected') }}
                                                        </a>
                                                        <v-dialog width="500"  v-if="!item.companyFileList[1]">
                                                            <template v-slot:activator="{ props }">
                                                                <a v-bind="props"  class="mr-3 a-link" style="text-decoration: none">
                                                                   {{ $t('SellingLendingAssessment.companyInfo.porpBoxText.Comment') }}
                                                                </a>
                                                            </template>
       
                                                            <v-card :title="$t('SellingLendingAssessment.companyInfo.porpBoxText.Comment')">
                                                                <v-card-text>
                                                                    <v-textarea
                                                                        v-model="item.companyFileList[0].comment"
                                                                        hide-details="auto"
                                                                        :label="$t('SellingLendingAssessment.companyInfo.porpBoxText.Comment')"
                                                                        disabled
                                                                    ></v-textarea>
                                                                </v-card-text>
                                                            </v-card>
                                                       
                                                        </v-dialog>

                                                    </div>
                                                </td>
                                                <td class="xsScreen">
                                                    <span class="downFileCompany" 
                                                        v-for="(itemchild,indexChild) in item.companyFileList" 
                                                        :key="indexChild"
                                                        @click="toDownloadCompanyFile(itemchild,item)"
                                                        >
                                                        {{itemchild.fileType=='2'?itemchild.fileName:''}}
                                                    </span>
                                                    <div class="fileNameButtom" v-if="item.companyFileList.length>1">
                                                        <a href="#" @click.stop.prevent="changeCompanyType(item,3)" class="mr-3 a-link"
                                                        v-if="item.landlordRequestCompanyStatus!=1 && item.landlordRequestCompanyStatus !=3 && item.landlordRequestCompanyStatus !=4 &&item.landlordRequestCompanyStatus!=6 && data.landlordRequestInfo.landlordRequestStatus !=2 && data.landlordRequestInfo.landlordRequestStatus !=3"
                                                        style="text-decoration: none">
                                                            {{ $t('SellingLendingAssessment.companyInfo.butList.approval') }}
                                                        </a>
                                                        <v-dialog width="500"  v-if="item.companyFileList[1]" >
                                                            <template v-slot:activator="{ props }">
                                                                <a v-bind="props"  class="mr-3 a-link" style="text-decoration: none">
                                                                   {{ $t('SellingLendingAssessment.companyInfo.porpBoxText.Comment') }}
                                                                </a>
                                                            </template>
       
                                                            <v-card :title="$t('SellingLendingAssessment.companyInfo.porpBoxText.Comment')">
                                                                <v-card-text>
                                                                    <v-textarea
                                                                        v-model="item.companyFileList[1].comment"
                                                                        hide-details="auto"
                                                                        :label="$t('SellingLendingAssessment.companyInfo.porpBoxText.Comment')"
                                                                        disabled
                                                                    ></v-textarea>
                                                                </v-card-text>
                                                            </v-card>
                                                       
                                                        </v-dialog>
                                                    </div>
                                                </td>
                                                <td class="xsScreen">{{item.contractDate}}</td>
                                            </tr>
                                        </tbody>
                                    </v-table>
                                </v-col>
                            </v-row>

                        </v-col>
                    </v-row>
                    <v-row class="mt-10">
                        <the-footer></the-footer>
                    </v-row>
                </v-container>
            </v-main>
        </v-layout>
    </v-card>
</template>
<style>
.table-border {
    border: 1px;
    border-style: solid;
    border-color: antiquewhite;
}
.fileNameButtom{
    display: inline-block;
    margin-left: 20px;
}
.displaynone{
    color: rgb(167, 167, 167);
    cursor: pointer;
}
</style>
<script lang="ts">
import { reactive, onMounted, getCurrentInstance, defineComponent, ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
// import axios from 'axios'
import api from '@/api/axios/axios'
import {downloadFile} from'@/api/axios/downLoad'
import {uploadFileToS3} from'@/api/sys/model/upLoadFiileS3'
import i18n from '../language/index'
import { useI18n } from 'vue-i18n'
import vuetify from '@/plugins/vuetify'
import { SellingLending } from './../api/sys/model/model';

export default defineComponent({
    name: 'SellingLendingDetail',
    components: {
        TheHeader,
        TheFooter,
    },
});
</script>

<script lang="ts" setup>
const data = reactive({
    addBtnClick:false,
    evaluate:'',
    theUploadFile:{} as File,
    isShowDisplay:false,
    userFileList: {
        "comment": '',
        "fileContentType": '',
        "fileName": '',
        "fileSize": 0,
        "sellerRequestId": '',
    },
    userFileListLandlord: {
        "comment": '',
        "fileContentType": '',
        "fileName": '',
        "fileSize": 0,
        "landlordRequestId": '',
    },
    sellerRequestInfo: {
        "buildingAddress": "",
        "buildingArea": "",
        "buildingName": "",
        "buildingStructure": 0,
        "companyList": [
            {
                "companyFileList": [
                    {
                        "comment": "",
                        "fileContentType": "",
                        "fileName": "",
                        "fileSize": 0,
                        "sellerRequestCompanyFileId": "",
                        "sellerRequestCompanyId": "",
                        "fileType": "",
                    }
                ],
                "companyId": "",
                "companyName": "",
                "sellerRequestCompanyStatus":1,
                "companyStaffId": "",
                "sellerRequestCompanyId": "",
                "sellerRequestId": ""
            }
        ],
        "constructionDate": "",
        "controlNumber": "",
        "floorPlan": "",
        "landArea": "",
        "landOwnership": "",
        "managementFee": 0,
        "ownerName": "",
        "ownerNote": "",
        "ownerPhone": "",
        "price": 0,
        "propertyType": 0,
        "reserveFund": 0,
        "roomNumber": "",
        "sellerRequestId": "",
        "sellerRequestStatus": 0,
        "userFileList": [
            {
                "comment": "",
                "fileContentType": "",
                "fileName": "",
                "fileSize": 0,
                "sellerRequestId": "",
                "sellerRequestUserFileId": ""
            }
        ]
    },
    landlordRequestInfo: {
        "buildingAddress": "",
        "buildingDate": "",
        "buildingName": "",
        "buildingStructure": 0,
        "companyList": [
            {
                "companyFileList": [
                    {
                        "comment": "",
                        "fileContentType": "",
                        "fileName": "",
                        "fileSize": 0,
                        "landlordRequestCompanyFileId": "",
                        "landlordRequestCompanyId": "",
                        "fileType": "",
                    }
                ],
                "landlordRequestCompanyStatus":1,
                "companyId": "",
                "companyName": "",
                "companyStaffId": "",
                "landlordRequestCompanyId": "",
                "landlordRequestId": ""
            }
        ],
        "contractPeriod": "",
        "controlNumber": "",
        "floorPlan": "",
        "guaranteeDeposit": 0,
        "keyMoney": 0,
        "landArea": "",
        "landlordRequestId": "",
        "managementFee": 0,
        "ownerName": "",
        "ownerNote": "",
        "ownerPhone": "",
        "propertyArea": "",
        "propertyType": 0,
        "rentDeposit": 0,
        "roomNumber": "",
        "securityDeposit": 0,
        "landlordRequestStatus": 0,
        "userFileList": [
            {
                "comment": "",
                "fileContentType": "",
                "fileName": "",
                "fileSize": 0,
                "landlordRequestId": "",
                "landlordRequestUserFileId": ""
            }
        ]
    }
});
const router = useRouter();
const route = useRoute();
const requestType = route.query.type;
let cType:string[] =["提案中","契約中","当選","非当選","再提案","提案却下"]
let pType:object ={
    '0':'マンション',
    '1':'一戸建て',
    '2':'土地',
    '3':'その他'
}
let buildType:object ={
    '0':'',
    '1':'木造',
    '2':'ブロック造',
    '3':'鉄骨造',
    '4':'RC',
    '5':'SRC',
    '6':'PC',
    '7':'HPC',
    '8':'ALC',
    '9':'軽量鉄骨造',
    '10':'その他'
}
const fileInput = ref<HTMLInputElement | null>(null);
const openFilePicker = () => {
    if (fileInput.value) {
        fileInput.value.click();
    }
}
onMounted(() => {
    data.addBtnClick = false,
    getInitData();
})
const getInitData = 
async() => {
    data.addBtnClick = false,
    console.log( route.query.type);
    
    const id = route.params.id.toString();
    if (requestType === 'seller') {
        await api.get('/user/sellerRequest/' + id)
            .then((res) => {
                data.sellerRequestInfo = res.data
                console.log(data.sellerRequestInfo.companyList);
                for(const item of data.sellerRequestInfo.companyList){
                    if(item.sellerRequestCompanyStatus==2){
                        data.isShowDisplay = true
                    }
                }
                
            })
            .catch((error) => {
                console.log(error.message);
            });
    } else if (requestType === 'landlord') {
        await api.get('/user/landlordRequest/' + id)
            .then((res) => {
                data.landlordRequestInfo = res.data
                console.log(data.landlordRequestInfo);

            })
            .catch((error) => {
                console.log(error.message);
            });
    }
};
const changeLanguageType = (val: 'jp' | 'en' | 'zh') => {
    // console.log(val);
}
const addUserFile = () => {
    data.addBtnClick = true;
    if(requestType === 'seller'){
        data.userFileList =
        {
            "comment": '',
            "fileContentType": '',
            "fileName": '',
            "fileSize": 0,
            "sellerRequestId": '',
        }
        data.sellerRequestInfo.userFileList.push(data.userFileList)
    }else if(requestType === 'landlord'){
        data.userFileListLandlord =
        {
            "comment": '',
            "fileContentType": '',
            "fileName": '',
            "fileSize": 0,
            "landlordRequestId": '',
        }
        data.landlordRequestInfo.userFileList.push(data.userFileListLandlord)
    }
    
}

const toSellingLendingList = () => {
        router.push('/sellingLendingList')
    }

const toCancelFile = () => {
    if(requestType === 'seller'){
        data.sellerRequestInfo.userFileList.pop()
    }else if(requestType === 'landlord'){
        data.landlordRequestInfo.userFileList.pop()
    }
    data.addBtnClick = false
}
const uploadFile = ($event: Event) => {
        const target = $event.target as HTMLInputElement;
        if (target && target.files) {
            const targetName = target.files[0].name;
            console.log(target.files[0]);
            data.theUploadFile = target.files[0]
            data.userFileList={
                "comment": '',
                "fileContentType": target.files[0].type,
                "fileName": target.files[0].name,
                "fileSize": target.files[0].size,
                "sellerRequestId": data.sellerRequestInfo.sellerRequestId,
            }
            
        }
}
const uploadFileLandlord = ($event: Event) => {
        const target = $event.target as HTMLInputElement;
        if (target && target.files) {
            const targetName = target.files[0].name;
            console.log(target.files[0]);
            data.theUploadFile = target.files[0]
            data.userFileListLandlord={
                "comment": '',
                "fileContentType": target.files[0].type,
                "fileName": target.files[0].name,
                "fileSize": target.files[0].size,
                "landlordRequestId": data.landlordRequestInfo.landlordRequestId,
            }
            
        }
}
const toUploadUserFile = (file:object) => {
    console.log(data.userFileList);
    if(requestType === 'seller'){
        data.userFileList.comment = file.comment
        console.log(data.userFileList.comment);
        api.post('/user/sellerRequest/files/upload',data.userFileList)
        .then((res)=>{
            console.log(res);
            console.log(data.theUploadFile);
            uploadFileToS3(data.theUploadFile,res.data.uploadPresignUrl)
            getInitData()
            data.addBtnClick = false
        })
        .catch((error)=>{
            console.log(error);
            
        })   
    }else if(requestType === 'landlord'){
        data.userFileListLandlord.comment = file.comment
        api.post('/user/landlordRequest/files/upload',data.userFileListLandlord)
        .then((res)=>{
            console.log(res);
            console.log(data.theUploadFile);
            uploadFileToS3(data.theUploadFile,res.data.uploadPresignUrl)
            getInitData()
            data.addBtnClick = false
        })
        .catch((error)=>{
            console.log(error);
            
        })   
    }
   
}
const toDeleteUserFile = (thisData:string) => {
    console.log(thisData);
    if(requestType === 'seller'){
        api.delete(`/user/sellerRequest/files/${thisData}`)
        .then((res)=>{
            console.log(res);
            getInitData()
        })
        .catch((error)=>{
            console.log(error);
        })  
    }else if(requestType === 'landlord'){
        api.delete(`/user/landlordRequest/files/${thisData}`)
        .then((res)=>{
            console.log(res);
            getInitData()
        })
        .catch((error)=>{
            console.log(error);
        })  
    }
   
}
const toDownloadUserFile = (file:any) => {
    if(requestType === 'seller'){
        console.log(file);
        api.get(`/user/sellerRequest/files/download/user?sellerRequestId=${file.sellerRequestId}&&sellerRequestUserFileId=${file.sellerRequestUserFileId}`)
        .then((res)=>{
            console.log(res);
            downloadFile(res.data.downloadPresignUrl,file.fileName)
        })
        .catch((error)=>{
            console.log(error);
            
        })
    }else if(requestType === 'landlord'){
        console.log(file);
        api.get(`/user/landlordRequest/files/download/user?landlordRequestId=${file.landlordRequestId}&&landlordRequestUserFileId=${file.landlordRequestUserFileId}`)
        .then((res)=>{
            console.log(res);
            downloadFile(res.data.downloadPresignUrl,file.fileName)
        })
        .catch((error)=>{
            console.log(error);
            
        })
    }
   
    
}

const toDownloadCompanyFile = (itemChild:any,item:any) => {
    if(requestType === 'seller'){
        api.get(`/user/sellerRequest/files/download/company?sellerRequestCompanyFileId=${itemChild.sellerRequestCompanyFileId}&&sellerRequestCompanyId=${itemChild.sellerRequestCompanyId}&&sellerRequestId=${item.sellerRequestId}`)
        .then((res)=>{
            console.log(res);
            downloadFile(res.data.downloadPresignUrl,itemChild.fileName)
        })
        .catch((error)=>{
            console.log(error);
            
        })
    }else if(requestType === 'landlord'){
        api.get(`/user/landlordRequest/files/download/company?landlordRequestCompanyFileId=${itemChild.landlordRequestCompanyFileId}&&landlordRequestCompanyId=${itemChild.landlordRequestCompanyId}&&landlordRequestId=${item.landlordRequestId}`)
        .then((res)=>{
            console.log(res);
            downloadFile(res.data.downloadPresignUrl,itemChild.fileName)
        })
        .catch((error)=>{
            console.log(error);
            
        })
    }
    
}
const changeCompanyType = (item:any,type:number) => {
    if(requestType === 'seller'){
        if(type === 6){
        console.log(item);
        let thisData = {
            "sellerRequestCompanyId": item.sellerRequestCompanyId,
            "sellerRequestCompanyStatus": type,
            "sellerRequestId": item.sellerRequestId
        }
        api.put(`/user/sellerRequest`,thisData)
        .then((res)=>{
            console.log(res);
            item.sellerRequestCompanyStatus = type;
        })
        .catch((error)=>{
            console.log(error);
            
        })
            } else if(type === 3) {

                api.put('/user/sellerRequest/contract/' + item.sellerRequestId + '/' + item.sellerRequestCompanyId)
                .then((res)=>{
                    console.log(res);
                    getInitData();
                })
                .catch((error)=>{
                    console.log(error);
                    
                })

            }else{
                for(const item of data.sellerRequestInfo.companyList){
                    if(item.sellerRequestCompanyStatus==2){
                        data.isShowDisplay = true
                    }
                }
                let thisData = {
                    "sellerRequestCompanyId": item.sellerRequestCompanyId,
                    "sellerRequestCompanyStatus": type,
                    "sellerRequestId": item.sellerRequestId
                }
                api.put(`/user/sellerRequest`,thisData)
                .then((res)=>{
                    console.log(res);
                    item.sellerRequestCompanyStatus = type;
                    if(type === 2){
                        data.isShowDisplay = true
                    }
                })
                .catch((error)=>{
                    console.log(error);
                    
                })
            
            }
    }else if(requestType === 'landlord'){   
        if(type === 6){

            let thisData = {
                "landlordRequestCompanyId": item.landlordRequestCompanyId,
                "landlordRequestCompanyStatus": type,
                "landlordRequestId": item.landlordRequestId
            }
            api.put(`/user/landlordRequest`,thisData)
            .then((res)=>{
                console.log(res);
                item.landlordRequestCompanyStatus = type;
            })
            .catch((error)=>{
                console.log(error);
                
            })
            
        } else if(type === 3) {

            api.put('/user/landlordRequest/contract/' + item.landlordRequestId + '/' + item.landlordRequestCompanyId)
            .then((res)=>{
                console.log(res);
                getInitData();
            })
            .catch((error)=>{
                console.log(error);
                
            })

            }else{
            for(const item of data.landlordRequestInfo.companyList){
                    if(item.landlordRequestCompanyStatus==2){
                        data.isShowDisplay = true
                        }
            }
            let thisData = {
                "landlordRequestCompanyId": item.landlordRequestCompanyId,
                "landlordRequestCompanyStatus": type,
                "landlordRequestId": item.landlordRequestId
            }
            api.put(`/user/landlordRequest`,thisData)
            .then((res)=>{
                console.log(res);
                item.landlordRequestCompanyStatus = type;
            })
            .catch((error)=>{
                console.log(error);
                
            })
        }      
    }
 
}
const ToShowCompany = (companyId:string) => {
    router.push(
        { 
            path: '/companyView', 
            query: { 
                companyId ,
                sellerRequestId:route.params.id.toString(),
                requestType
            } 
        }
    );
}
</script>
<style>
   .UserFileBox .v-input__prepend{
        display: none;
    }
    .downFileCompany{
        color: #157bff;
        cursor: pointer;
    }
    .downFileCompany:hover{
        color: #1500f3;
    }
    .th-maxWidth{
        max-width: 130px;
    }
    .aButton{
        color: #0a0167;
        cursor: pointer;
    }
</style>
