import { createApp, ref, provide , DirectiveBinding } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import i18n from './language/index'

loadFonts()

const app = createApp(App)

app.directive('bubble', {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    el.addEventListener('click', (event) => {
      event.stopPropagation();
    });
  },
});

app.use(router)
  .use(store)
  .use(vuetify)

app.use(i18n)
// 共通プロパティ設定
app.mount('#app')
