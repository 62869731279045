<template>
    <v-card>
        <v-layout>
            <the-header @chooselanguage='changeLanguageType'></the-header>
            <v-navigation-drawer width="180">
            </v-navigation-drawer>
            <v-navigation-drawer location="right" width="180">
            </v-navigation-drawer>
            <v-main>
                <v-container class="align-center">
                    <v-row>
                        <v-col cols="12">
                            <p class="text-h4">{{ $t('searchMessage.title') }}</p>
                        </v-col>
                    </v-row>
                    <!-- 物件検索 -->
                    
                    <v-row class="mt-4" justify="start">
                        <v-col cols="6" sm="4" md="2">
                            <v-checkbox v-model="data.propertyType" value="1" :label="$t('searchMessage.checkbox.buying')"
                                @click="clickCheckBox"></v-checkbox>
                        </v-col>
                        <v-col cols="6" sm="4" md="2">
                            <v-checkbox v-model="data.propertyType" value="2" :label="$t('searchMessage.checkbox.rantal')"
                                @click="clickCheckBox" />
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                    
                    <v-row no-gutters class="mt-4 align-center justify-start" v-if="data.propertyType[0] == '2'">
                        <v-spacer></v-spacer>
                        <v-col cols="1">
                            <span>キーワード</span>
                        </v-col>
                        <v-col cols="11">
                            <v-text-field v-model="data.keyword"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row no-gutters class="mt-4 align-center justify-start">
                        <v-spacer></v-spacer>
                        <v-col cols="1">
                            <span>{{ $t('searchMessage.area') }}</span>
                        </v-col>
                        <v-col cols="11">
                            <v-text-field v-model="data.area"></v-text-field>
                        </v-col>
                    </v-row>
           
                    <v-row no-gutters class="mt-4 align-center justify-start">
                        <v-col cols="1">
                            <div v-if="data.propertyType[0] == '2'">
                                {{ $t('searchMessage.rent') }}
                            </div>
                            <div v-if="data.propertyType[0] == '1'">
                                {{ $t('searchMessage.buyingPrice') }}
                            </div>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field type="number" v-model="data.minPrice"></v-text-field>
                        </v-col>
                        <v-col cols="1" align="center">
                            <span>~</span>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field type="number" v-model="data.maxPrice"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row no-gutters class="mt-4 align-center justify-start">
                        <v-col cols="1">
                            <span>{{ $t('searchMessage.buildingAge') }}</span>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field class="mt-4" v-model="data.minBuildingAge" placeholder="YYYY/MM"></v-text-field>
                        </v-col>
                        <v-col cols="1" align="center">
                            <span>~</span>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field class="mt-4" v-model="data.maxBuildingAge" placeholder="YYYY/MM"></v-text-field>
                        </v-col>

                    </v-row>

                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-title>
                                {{ $t('searchMessage.detailedCondition') }}
                            </v-expansion-panel-title>
                            <v-expansion-panel-text>
                                <v-row no-gutters class="mt-4 align-center justify-start">
                                    <v-col cols="1">
                                        <span> {{ $t('searchMessage.station') }}</span>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-text-field v-model="data.station"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-text>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-row no-gutters>
                        
                        
                        <v-col cols="3" sm="3" md="2">
                            <v-btn class="mt-10" color="success" variant="outlined" @click="goBack">{{ $t('BackButtomList.goBack')}}</v-btn>
                        </v-col>
                        <v-col cols="3" sm="3" md="2">
                            <v-btn class="mt-10" color="success" @click="search">{{ $t('searchMessage.button.search')
                            }}</v-btn>
                        </v-col>
                        <v-col cols="5" sm="5" md="2">
                            <v-btn class="mt-10" color="warning" @click="clearText">{{
                                $t('searchMessage.button.clearCondition') }}</v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>

                    <v-card class="mt-10" v-for="(item, index) in data.propertyList" :key="item">

                        <v-row d-flex>

                            <v-col cols="12" sm="12" md="6">
                                <v-card style="position: relative;">
                                    <v-img v-if="item.mainImage " :src="item.mainImageFileName?item.mainImageFileName:item.mainImage" class="bg-grey-lighten-2 bigImage"></v-img>
                                    <v-dialog width="600" class="dialogBox" v-if="item.mainImage ">
                                        <template v-slot:activator="{ props }">
                                            <v-btn v-bind="props" class="imageBtn_dia"> </v-btn>
                                        </template>

                                        <template v-slot:default="{ isActive }">
                                            <v-card>
                                                <v-carousel>
                                                    <v-carousel-item
                                                        :src="item.mainImage"
                                                        cover
                                                    ></v-carousel-item>
                                                    <v-carousel-item
                                                        v-for="(itemChild, i) in item.images.filter(itemindex => itemindex !== item.mainImage)"
                                                        :key="i"
                                                        :src="itemChild"
                                                        contain
                                                    ></v-carousel-item>
                                                </v-carousel>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                text="Close"
                                                @click="isActive.value = false"
                                                ></v-btn>
                                            </v-card-actions>
                                            </v-card>
                                        </template>
                                    </v-dialog>
                                </v-card>
                            </v-col>

                            <v-col cols="12" sm="12" md="6">
                                <v-row>
                                    <v-card width="100%">
                                        <v-card-title style="margin-top: 10px;">
                                            <p class="text-h4" v-if="i18n.global.locale == 'zh'">{{
                                                item.property.buildingNameCn }}</p>
                                            <p class="text-h4" v-if="i18n.global.locale == 'jp'">{{
                                                item.property.buildingNameJp }}</p>
                                            <p class="text-h4" v-if="i18n.global.locale == 'en'">{{
                                                item.property.buildingNameEn }}</p>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="1">
                                                    <v-icon size="large">mdi-map-marker</v-icon>
                                                </v-col>
                                                <v-col>
                                                    <span v-if="i18n.global.locale == 'zh'">{{ item.property.addressCn
                                                    }}</span>
                                                    <span v-if="i18n.global.locale == 'jp'">{{ item.property.addressJp
                                                    }}</span>
                                                    <span v-if="i18n.global.locale == 'en'">{{ item.property.addressEn
                                                    }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="1">
                                                    <v-icon size="large">mdi-map-marker-path</v-icon>
                                                </v-col>
                                                <v-col>
                                                    <span v-if="i18n.global.locale == 'zh'">{{ item.property.useStation1Cn
                                                    }}</span>
                                                    <span v-if="i18n.global.locale == 'jp'">{{ item.property.useStation1Jp
                                                    }}</span>
                                                    <span v-if="i18n.global.locale == 'en'">{{ item.property.useStation1En
                                                    }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="1">
                                                    <v-icon size="large">mdi-home-outline</v-icon>
                                                </v-col>
                                                <v-col>
                                                    <span v-if="data.propertyType[0] == '2'">{{
                                                        $t('searchMessage.checkbox.rantal') }}</span>
                                                    <span v-if="data.propertyType[0] == '1'">{{
                                                        $t('searchMessage.checkbox.buying') }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="1"></v-col>
                                                <v-col>
                                                    <!-- <span>{{ item.property.floorPlan }}</span> -->
                                                    <span>{{ floorPlan_dict[item.property.floorPlan] }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="1"></v-col>
                                                <v-col>
                                                    <span>{{ item.property.buildingDate }}築</span>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-row>

                                <v-row class="mt-4">
                                    <v-col cols="4" v-for="(itemchildIndex, i) in item.images" :key="i">
                                        <v-card @click="imageChange(itemchildIndex,index)">
                                            <v-img :src="itemchildIndex" class="bg-grey-lighten-2 smallImage"></v-img>
                                        </v-card>

                                    </v-col>
                                </v-row>

                            </v-col>

                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <v-table density="comfortable" fixed-header class="tableBox">
                                    <thead class="tableBox">
                                        <tr>
                                            <th style="font-weight: bolder;" class="screenDom">
                                                No.
                                            </th>
                                            <th style="font-weight: bolder;" class="screenDom">
                                                {{ $t('searchMessage.tableInfo.roomNumber') }}
                                            </th>
                                            <th style="font-weight: bolder;" class="screenMed">
                                                <div v-if="data.propertyType[0] == '2'">
                                                    {{ $t('searchMessage.tableInfo.rentDeposit') }}
                                                </div>
                                                <div v-if="data.propertyType[0] == '1'">
                                                    {{ $t('searchMessage.tableInfo.price') }}
                                                </div>
                                                <div>
                                                    {{ $t('searchMessage.tableInfo.managementFee') }}
                                                </div>

                                            </th>
                                            <th style="font-weight: bolder;" class="screenMed">
                                                <div v-if="data.propertyType[0] == '2'">
                                                    {{ $t('searchMessage.tableInfo.securityDeposit') }}
                                                </div>
                                                <div v-if="data.propertyType[0] == '2'">
                                                    {{ $t('searchMessage.tableInfo.keyMoney') }}
                                                </div>

                                            </th>
                                            <th style="font-weight: bolder;" class="screenSmall">
                                                {{ $t('searchMessage.tableInfo.floorPlan') }}
                                                <br />
                                                {{ $t('searchMessage.tableInfo.exclusiveArea') }}
                                            </th>

                                            <th style="font-weight: bolder;" class="screenSmall">
                                                {{ $t('searchMessage.tableInfo.favorite') }}
                                            </th>
                                            <th>
                                                &nbsp;
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="tableBox">
                                        <tr>
                                            <td class="scressDom">
                                                {{ index + 1 }}
                                            </td>
                                            <td class="scressDom">
                                                {{ item.property.roomNoJp }}
                                            </td>
                                            <td class="screenMed">
                                                <div v-if="data.propertyType[0] == '1'">
                                                    <!-- 万円 -->
                                                    {{ item.propertySelling.price }}
                                                </div>
                                                <div v-if="data.propertyType[0] == '2'">
                                                    {{ item.propertyRentalDto.rent }}
                                                </div>
                                                <div>
                                                    {{ item.property.managementFee }}
                                                </div>
                                            </td>
                                            <td class="screenMed">
                                                <div v-if="data.propertyType[0] == '2'">
                                                    {{ item.propertyRentalDto.securityDepositMoney }}
                                                </div>
                                                <div v-if="data.propertyType[0] == '2'">
                                                    {{ item.propertyRentalDto.keyMoney }}
                                                </div>
                                            </td>
                                            <td class="screenSmall">
                                                {{ floorPlan_dict[item.property.floorPlan] }}
                                                <br />
                                                {{ item.property.exclusiveArea }}m²
                                            </td>

                                            <td class="screenSmall">
                                                <v-btn prepend-icon="mdi-heart-outline" class="bg-teal" variant="outlined"
                                                    @click="AddFavorites(item)"
                                                    v-if="!item.property.favorite">{{ $t('theProperty.store') }}</v-btn>
                                                <v-btn prepend-icon="mdi-heart" class="bg-teal" variant="outlined"
                                                    v-if="item.property.favorite"
                                                    @click="DelFavorites(item)">{{
                                                        $t('theProperty.storeTrue') }}</v-btn>
                                            </td>
                                            <td class="scressDom">
                                                <v-btn class="home-button" color="success" variant="outlined" @click="toDetail(item.property.propertyId)">>>{{ $t('theProperty.SeeDetails') }}</v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-table>
                            </v-col>
                        </v-row>

                    </v-card>

                    <the-pagination 
                        v-if="data.totalItems > 0"
                        :limit="data.limit"
                        :perPageOptions="data.perPageOptions"
                        :page="data.page"
                        :totalItems="data.totalItems"
                        @pagination="getList"
                    ></the-pagination>

                    <v-row class="mt-10">
                        <the-footer></the-footer>
                    </v-row>
                </v-container>
            </v-main>
        </v-layout>
    </v-card>
</template>
<style>
.table-border {
    border: 1px;
    border-style: solid;
    border-color: antiquewhite;
}
</style>
<script lang="ts">
import { defineComponent, reactive, getCurrentInstance, onMounted, defineProps, onActivated, nextTick, } from 'vue'
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import ThePagination from './components/ThePagination.vue'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import api from '@/api/axios/axios'
import i18n from '../language/index'
import { Property } from './../api/sys/model/model'
import { urlToBase64 } from '@/api/sys/model/base64Conver'

export default defineComponent({
    name: 'PropertySearch',
    components: {
        TheHeader,
        TheFooter,
    },


});
</script>
  

<script lang="ts" setup>

const props = defineProps({
    propertyInfo: Object,

})
const data = reactive({
    favoriteListData: [] as any,
    propertyList: [] as any[],
    propertyType: ["1"],
    area: '',
    keyword:'',
    minPrice: '',
    maxPrice: '',
    minBuildingAge: '',
    maxBuildingAge: '',
    station: '',
    mainImage: "detail-1.png",
    images: ["detail-2.png", "detail-3.png", "detail-4.png"],
    languageType: 'jp' as string,
    mainImageFileName: '',
    //分页
    page:1,
    limit:10,
    totalItems:0,
    perPageOptions:[5,10,15,20],
    scrollPosition:0,

})
let floorPlan_dict:any = {
    "1":'ワンルーム',
    "2":'1K',
    "3":"1DK",
    "4":'1LDK',
    '5':'2K',
    "6":'2DK',
    '7':'2LDK',
    '8':'3K',
    '9':'3DK',
    '10':'3LDK',
    '11':'4K',
    '12':'4DK',
    '13':'4LDK以上',
    }

const router = useRouter()
const route = useRoute()


const clickCheckBox = (res: any) => {

    setTimeout(function () {

        if (res.target.checked) {

            if (data.propertyType.length > 1) {
                data.propertyType.splice(0, 1);
                data.propertyList = []
                data.totalItems = 0
            }
        } else {
            if (data.propertyType.length == 0) {
                data.propertyType.push(res.target.value)
                data.propertyList = []
                data.totalItems = 0
            }
        }
    }, 1)


};

const thisFavorite =
    async () => {
        await api.get(`/user/favorite`)
            .then((res) => {
                console.log(res);
                if (res.data.length > 0) {
                    data.favoriteListData = res.data
                }
                console.log(data.favoriteListData);
            })
            .catch((error) => {
                console.log(error);
            })
    }

const goBack = () => {
    router.back();
};

const toDetail= (propertyId:string | undefined) =>{
    router.push({
        name:'detail',
        query:{
        id:propertyId,
        type:'search'
        }
    })
};

const search = () => {
    api.get(`/user/public/property`, {
        params: {
            limit: data.limit,
            offset: (data.page-1) * data.limit,
            address: data.area,
            minPrice: data.minPrice,
            maxPrice: data.maxPrice,
            minBuildingAge: data.minBuildingAge,
            maxBuildingAge: data.maxBuildingAge,
            station: data.station,
            propertyType: data.propertyType[0],
            keyword:data.keyword
        }
    })
        .then((res) => {
            data.propertyList = res.data
            data.totalItems = data.propertyList[0].countItems
        
            //数据处理
            for (let item of data.propertyList) {

                if(data.propertyType[0] == '1'){
                    //价格
                    item.propertySelling.price = item.propertySelling.price ? item.propertySelling.price + "万円" : ''

                }else {

                    //租金
                    item.propertyRentalDto.rent = item.propertyRentalDto.rent ? item.propertyRentalDto.rent + "万円" : ''

                    if(item.propertyRentalDto.guaranteeDepositMoney && !item.propertyRentalDto.guaranteeDepositMonth){
                        item.propertyRentalDto.guaranteeDepositMoney = item.propertyRentalDto.guaranteeDepositMoney + "万円"
                    }else if(item.propertyRentalDto.guaranteeDepositMoney && item.propertyRentalDto.guaranteeDepositMonth){
                        item.propertyRentalDto.guaranteeDepositMoney = item.propertyRentalDto.guaranteeDepositMonth + "ヶ月"
                    }else if(!item.propertyRentalDto.guaranteeDepositMoney && !item.propertyRentalDto.guaranteeDepositMonth){
                        item.propertyRentalDto.guaranteeDepositMoney = ''
                    }

                    if(item.propertyRentalDto.keyMoney && !item.propertyRentalDto.keyMonth){
                        item.propertyRentalDto.keyMoney = item.propertyRentalDto.keyMoney + "万円"
                    }else if(!item.propertyRentalDto.keyMoney && item.propertyRentalDto.keyMonth){
                        item.propertyRentalDto.keyMoney = item.propertyRentalDto.keyMonth + "ヶ月"
                    }else if(!item.propertyRentalDto.keyMoney && !item.propertyRentalDto.keyMonth){
                        item.propertyRentalDto.keyMoney = ''
                    }


                }

                item.property.managementFee = item.property.managementFee ? item.property.managementFee + "万円" : ''

                item.property.useStation1Jp = item.property.trafficLine1Jp + ' 線 ' + item.property.useStation1Jp + ' 駅 ' + (item.property.trafficMeans1 == '1' ? ' 徒歩 ' : 'バス') + item.property.useStation1TrafficDistance + ' 分';
                item.property.useStation1Cn = (item.property.trafficLine1Cn ? item.property.trafficLine1Cn : item.property.trafficLine1Jp) + ' 線 ' + (item.property.useStation1Cn ? item.property.useStation1Cn : item.property.useStation1Jp) + ' 駅 ' + (item.property.trafficMeans1 == '1' ? '徒歩' : 'バス') + item.property.useStation1TrafficDistance + ' 分';
                item.property.useStation1En = (item.property.trafficLine1En ? item.property.trafficLine1En : item.property.trafficLine1Jp) + ' 線 ' + (item.property.useStation1En ? item.property.useStation1En : item.property.useStation1Jp) + ' 駅 ' + (item.property.trafficMeans1 == '1' ? '徒歩' : 'バス') + item.property.useStation1TrafficDistance + ' 分';

                item.images = item.propertyFileList.map((obj: any) => obj.downloadPresignUrl)
               
                if(item.propertyFileList.length>0){

                    Promise.all(item.images.map(urlToBase64))
                    .then((base64Array) => {
                        // 将每个转换后的 Base64 数据放回原来的数组中
                        item.images = base64Array;
                    })
                    .catch((error) => {
                        console.error('转换为 Base64 出错：', error);
                    });

                    item.mainImage = item.propertyFileList.filter((file:any) => file.fileType == 1)[0].downloadPresignUrl;

                    if(item.mainImage) {
                        urlToBase64(item.mainImage).then((base64) => {
                            item.mainImage = base64;
                        });
                    }else {

                        item.mainImage = item.images[0];

                    }
                    
                }else{
                    item.mainImage = undefined
                }
                
               
                for (let itemChild of data.favoriteListData) {
                    if (item.property.propertyId == itemChild.propertyId) {
                        item.property.favorite = true
                    }
                }

            }
            console.log(data.propertyList);

        })
        .catch((error) => {
            console.log(error.message)
        })

}


    onBeforeRouteLeave((to, from, next) => {
        if(to.name === 'detail') {
            console.log("detail")
            data.scrollPosition = window.scrollY;
            next()
        }else if(to.name === 'index'){
            window.location.reload(true);
            data.scrollPosition = 0;
            next()
        }else {
            data.scrollPosition = 0;
            next()
        }
    })

    onActivated(() => {


        nextTick(() => {
            setTimeout(() => {
                window.scrollTo({ top: data.scrollPosition, behavior: 'smooth' });
            }, 100); // 调整延迟时间
        });


    })

//分页
const getList = async (payload)=>{

    data.limit = payload.limit;
    data.page = payload.page;

    console.log("翻页了"+data.page)

    search();

    // 滚动到页面顶部
    window.scrollTo({ top: 400, behavior: 'smooth' });

    }

const selectFavorite = (getPropertyId: string) => {
    if (localStorage.getItem('CLOUDPFM_CO__REFRESH__')) {
        console.log(getPropertyId)
        api.post(`/user/favorite`, {
            propertyId: getPropertyId
        })
            .then((res) => {
                alert("お気に入りに追加成功")
            })
            .catch((error) => {
                alert(error.message)
            })
            .then((res) => {
                alert("お気に入りに追加成功")
            })
            .catch((error) => {
                alert(error.message)
            })
    } else {
        router.push('./login')
    }



}

const changeLanguageType = (val: 'jp' | 'en' | 'zh') => {
    console.log(i18n.global);
    data.languageType = val

}

const toProperty = (propertyId: string) => {
    router.push('/detail/' + propertyId)
}


const imageChange = (imageFileName: string ,num:number) => {
      console.log(num);
      data.propertyList[num].mainImageFileName = imageFileName;
      console.log(data.propertyList[num]);
      
    }

const clearText = () => {

    data.area = '',
        data.propertyType = ["1"],
        data.minPrice = '',
        data.maxPrice = '',
        data.minBuildingAge = '',
        data.maxBuildingAge = '',
        data.station = ''

}



onMounted(() => {
    if(localStorage.getItem('CLOUDPFM_CO__REFRESH__')){
        thisFavorite()
    }
});

const AddFavorites = (item: string | undefined) => {
    if (localStorage.getItem('CLOUDPFM_CO__REFRESH__')) {
        let toData = {
            "propertyId": item.property.propertyId
        }
        api.post(`/user/favorite`, toData)
            .then((res) => {
                console.log(res);
                item.property.favorite = true
            })
            .catch((error) => {
                console.log(error);

            })

    } else {
        router.push('./login')
    }


}

const DelFavorites = (propertyId: string | undefined) => {
    let userFavoriteId
    for (let item of data.favoriteListData) {
        if (item.propertyId == propertyId.property.propertyId) {
            userFavoriteId = item.userFavoriteId
        }
    }
    if (localStorage.getItem('CLOUDPFM_CO__REFRESH__')) {
        api.delete(`/user/favorite/${userFavoriteId}`)
            .then((res) => {
                console.log(res);
                propertyId.property.favorite = false
            })
            .catch((error) => {
                console.log(error);
            })
    } else {
        router.push('./login')
    }
}
</script>

<style>
@media screen and (max-width:784px) {
    .screenMed {
        display: none;
    }
}

@media screen and (max-width:960px) {
    .screenSmall {
        display: none;
    }
}

.scressDom {
    min-width: 120px;
}

.tableBox .d-flex .v-input__details {
    display: none !important;
}

.tableBox .v-checkbox {
    height: 48px !important;
    overflow: hidden;
}

.tableBox .v-table__wrapper::-webkit-scrollbar {
    display: none;
}

.smallImage{
  height: 100px;
}

.bigImage{
  height: 400px;
}
.imageBtn_dia{
  position: absolute; 
  top: 0; 
  left: 0; 
  right: 0; 
  height: 100% !important;
  opacity: 0;
}

</style>