<template>
    <v-card>
      <v-layout>
           <the-header
            ></the-header>
          <v-navigation-drawer color="surface" width="180">
          </v-navigation-drawer>
          <v-navigation-drawer color="surface" location="right" width="180">
          </v-navigation-drawer>
          <v-main>
          <v-container lass="align-center">
                <p class="InitialTitle">会社情報</p>
                <div>
                    <v-row no-gutters>
                        <v-col
                            cols="12"
                            sm="6"
                            xs='12'
                            class="flexCol"
                        >   
                            <div class="bg-teal-lighten-3 tableTitle"><span>会社名:</span></div>
                            <div class="tableContext"><span>{{data.companyData.companyName}} </span></div>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            xs='12'
                            class="flexCol"
                        >
                            <div class="bg-teal-lighten-3 tableTitle"><span>電話番号:</span></div>
                            <div class="tableContext"><span>{{data.companyData.tel}}</span></div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col
                            cols="12"
                            sm="6"
                            xs='12'
                            class="flexCol "
                        >   
                            <div class="bg-teal-lighten-3 tableTitle"><span>FAX:</span></div>
                            <div class="tableContext"><span>{{data.companyData.fax}}</span></div>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            xs='12'
                            class="flexCol "
                        >
                            <div class="bg-teal-lighten-3 tableTitle"><span>営業時間:</span></div>
                            <div class="tableContext"><span>{{data.companyData.businessHours}}</span></div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col
                            cols="12"
                            sm="6"
                            xs='12'
                            class="flexCol "
                        >   
                            <div class="bg-teal-lighten-3 tableTitle"><span>定休日：</span></div>
                            <div class="tableContext"><span>{{data.companyData.regularHoliday}}</span></div>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            xs='12'
                            class="flexCol "
                        >   
                            <div class="bg-teal-lighten-3 tableTitle"><span>アクセス：</span></div>
                            <div class="tableContext"><span>{{data.companyData.access}}</span></div>
                        </v-col>
                    </v-row>
                </div>
                <div class="ButtonBox">
                    <v-btn class="BtnStyle" @click="ToBack">{{'<<'}}{{ $t('BackButtomList.goBack') }}</v-btn>
                </div>
              <v-row class="mt-10">
                  <the-footer></the-footer>
              </v-row>
          </v-container>
          </v-main>
      </v-layout>
    </v-card>
</template>
  <script lang="ts">
  import { defineComponent,reactive, computed, getCurrentInstance, onMounted, defineProps } from 'vue'
  import api from '@/api/axios/axios'
  import TheFooter from './components/TheFooter.vue'
  import TheHeader from './components/TheHeader.vue'
  import { useRouter ,useRoute} from 'vue-router'
  
  export default defineComponent({
      name: 'InitialCostView',
      components: {
          TheHeader,
          TheFooter,
      },
  });
  </script>
  <script lang="ts" setup>
      const data = reactive({
        companyData:{
        },
      })
    const router = useRouter()
    const route = useRoute()
    const ToBack = () => {
        if(route.query.typeValue=="selectLendingView"){
            router.push("/sellingLendingInput")
            router.push( { 
                path: '/sellingLendingInput', 
                query: { 
                    fromViewName:"CompanyView"
                } 
        })
        }else{
            router.go(-1)
        }
        
    }
    onMounted(()=>{
        console.log(route.query.typeValue);
        
        getCompanyData()
    })

    const getCompanyData = () =>{
        api.get(`/user/public/company/${route.query.companyId}`)
        .then((res)=>{
            console.log(res);
            data.companyData = res.data
        })
        .catch((error)=>{
            console.log(error);
            
        })
    }
     
  </script>
  <style >
  .InitialTitle{
    font-size: 2em;
    margin-bottom: 0.5rem;
  }
  .flexCol{
    display: flex;
    height: 49px;
    justify-content: center;
    border-top: 1px solid #dee2e6;
  }
  .tableContext{
    padding: 10px;
    width: 70%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tableTitle{
    padding: 10px;
    text-align: center;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .colButtonLine{
    border-bottom: 1px solid #dee2e6;
  }
  .ButtonBox{
    width: 100%;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .BtnStyle{
    color: #28a745;
  }
  .BtnStyle:hover{
    background-color: #28a745;
    color: #fff;
  }
  </style>
