<template>
    <v-card>
      <v-layout>
           <the-header
            ></the-header>
          <v-navigation-drawer color="surface" width="180">
          </v-navigation-drawer>
          <v-navigation-drawer color="surface" location="right" width="180">
          </v-navigation-drawer>
          <v-main>
          <v-container lass="align-center">
                <p class="InitialTitle">◆{{ $t('inquirtySupportView.title') }}</p>
                <p>{{ $t('inquirtySupportView.concentText') }}</p>
                <!--  -->
                <div class="contentBox"  v-if="!data.isCommiteData">
                    <div class="divListItem">
                        <div class="itemTitle">
                            <span>※</span>{{ $t('inquirtySupportView.inputTitle.type') }}：
                        </div>
                        <div class="radioItem">
                            <v-radio-group
                                v-model="data.inquirtyList.systemInquiryCategory"
                                inline
                            >
                                <v-radio
                                    :label="$t('inquirtySupportView.typeList.issue')"
                                    :value="$t('inquirtySupportView.typeList.issue')"
                                ></v-radio>
                                <v-radio
                                    :label="$t('inquirtySupportView.typeList.FunctionalRequirement')"
                                    :value="$t('inquirtySupportView.typeList.FunctionalRequirement')"
                                ></v-radio>
                                <v-radio
                                    :label="$t('inquirtySupportView.typeList.VulnerabilityReport')"
                                    :value="$t('inquirtySupportView.typeList.VulnerabilityReport')"
                                ></v-radio>
                                <v-radio
                                    :label="$t('inquirtySupportView.typeList.systemDevelopment')"
                                    :value="$t('inquirtySupportView.typeList.systemDevelopment')"
                                ></v-radio>
                                <v-radio
                                    :label="$t('inquirtySupportView.typeList.other')"
                                    :value="$t('inquirtySupportView.typeList.other')"
                                ></v-radio>
                            </v-radio-group>
                        </div>    
                    </div>
                    <div class="divListItem">
                        <div class="itemTitle">
                            {{ $t('inquirtySupportView.inputTitle.name') }}：
                        </div>
                        <div class="radioItem">
                            <v-text-field v-model="data.inquirtyList.name"></v-text-field>
                        </div>    
                    </div>
                    <div class="divListItem">
                        <div class="itemTitle">
                            {{ $t('inquirtySupportView.inputTitle.phone') }}：
                        </div>
                        <div class="radioItem">
                            <v-text-field v-model="data.inquirtyList.phoneNumber"></v-text-field>
                        </div>    
                    </div>
                    <div class="divListItem">
                        <div class="itemTitle">
                            <span>※</span>{{ $t('inquirtySupportView.inputTitle.email') }}：
                        </div>
                        <div class="radioItem">
                            <v-text-field v-model="data.inquirtyList.mail" placeholder="例: name@example.com"></v-text-field>
                        </div>    
                    </div>
                    <div class="divListItem">
                        <div class="itemTitle">
                            <span>※</span>{{ $t('inquirtySupportView.inputTitle.content') }}：
                        </div>
                        <div class="radioItem">
                            <v-textarea v-model="data.inquirtyList.content"></v-textarea>
                        </div>    
                    </div>
                    <div class="divListItem">
                        <v-btn class="addinquirtSupport" @click="isCommite">{{ $t('inquirtySupportView.buttomlais.confirm') }}</v-btn>
                        <v-btn @click="clean">{{ $t('inquirtySupportView.buttomlais.empty') }}</v-btn>
                    </div>
                </div>
                <!--确认 -->
                <div class="contentBox" v-if="data.isCommiteData && !data.isOver">
                    <div class="divListItem">
                        <div class="itemTitle">
                            <span>※</span>カテゴリー：
                        </div>
                        <div class="radioItem">
                            <v-radio-group
                            inline
                            >
                                {{data.inquirtyList.systemInquiryCategory}}
                            </v-radio-group>
                        </div>    
                    </div>
                    <div class="divListItem">
                        <div class="itemTitle">
                            {{ $t('inquirtySupportView.inputTitle.name') }}：
                        </div>
                        <div class="textItem">
                            {{ data.inquirtyList.name }}
                        </div>    
                    </div>
                    <div class="divListItem">
                        <div class="itemTitle">
                            {{ $t('inquirtySupportView.inputTitle.phone') }}：
                        </div>
                        <div class="textItem">
                            {{ data.inquirtyList.phoneNumber }}
                        </div>    
                    </div>
                    <div class="divListItem">
                        <div class="itemTitle">
                            <span>※</span> {{ $t('inquirtySupportView.inputTitle.email') }}：
                        </div>
                        <div class="textItem">
                            {{data.inquirtyList.mail}}
                        </div>    
                    </div>
                    <div class="divListItem">
                        <div class="itemTitle">
                            <span>※</span> {{ $t('inquirtySupportView.inputTitle.content') }}：
                        </div>
                        <div class="textItem">
                            {{data.inquirtyList.content}}
                        </div>    
                    </div>
                    <div class="divListItem">
                        <v-btn class="addinquirtSupport" @click="addSystemInquirty">{{ $t('BackButtomList.send') }}</v-btn>
                        <v-btn @click="data.isCommiteData = ! data.isCommiteData">{{ $t('BackButtomList.goBack') }}</v-btn>
                    </div>
                </div>
                <!-- 完了 -->
                <div class="contentBox" v-if="data.isOver">
                    <p class="overTitle">{{ $t('inquirtySupportView.overText') }}</p>
                </div>
                <div class="ButtonBox">
                    <v-btn  class="mt-10 home-button" color="success" variant="outlined" @click="toHome">
                        {{ $t('BackButtomList.goBack') }}
                    </v-btn>
                </div>
              <v-row class="mt-10">
                  <the-footer></the-footer>
              </v-row>
          </v-container>
          </v-main>
      </v-layout>
    </v-card>
</template>
  <script lang="ts">
  import { defineComponent,reactive, computed, getCurrentInstance, onMounted, defineProps } from 'vue'
  import api from '@/api/axios/axios'
  import TheFooter from './components/TheFooter.vue'
  import TheHeader from './components/TheHeader.vue'
  import { useRouter ,useRoute} from 'vue-router'
  
  export default defineComponent({
      name: 'InitialCostView',
      components: {
          TheHeader,
          TheFooter,
      },
  });
  </script>
  <script lang="ts" setup>
      const data = reactive({
        inquirtyList:{} as any,
        isCommiteData:false,
        isOver:false
      })
    const router = useRouter()
    const route = useRoute()
    const toHome = () => {
        router.go(-1)
    }
    const isCommite = () => {
        if(data.inquirtyList.systemInquiryCategory&&data.inquirtyList.mail&&data.inquirtyList.content){
            data.isCommiteData = true
        }
    }
    const clean = () => {
        data.inquirtyList = {}
    }
    const addSystemInquirty = () => {
        api.post(`/user/public/systemInquire`,data.inquirtyList)
        .then((res)=>{
            console.log(res);
            data.isOver = true
        })
        .catch((error)=>{
            console.log(error);
            
        })
    }
  </script>
  <style scoped>

  .divListItem{
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #dee2e6;
    padding: 20px ;
  }
  .radioItem{
    min-width: 300px;
    position: relative;
    bottom: -10px;
  }
  .textItem{
    min-width: 300px;
  }
  .itemTitle{
    width: 150px;
    text-align: right;
    margin-right: 20px;
  }
  .itemTitle span{
    color: #dc3581;
  }
  .contentBox{
    margin-top: 20px;
  }
  .addinquirtSupport{
    background-color: #17a2b8;
    color: #dee2e6 !important;
    margin-right: 50px;
  }
  .overTitle{
    text-align: center;
    font-size: 30px;
  }
  </style>
