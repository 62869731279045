<template>
    <v-card>
        <v-layout>
            <the-header @chooselanguage='changeLanguageType'></the-header>
            <v-navigation-drawer width="180">
            </v-navigation-drawer>
            <v-navigation-drawer location="right" width="180">
            </v-navigation-drawer>
            <v-main>
                <v-container class="align-center">
                    <v-row>
                        <v-col cols="12">
                            <p class="text-h4">{{ $t('Inquiry.InquiryList.title') }}</p>
                        </v-col>
                    </v-row>
                    <!-- お問い合わせ一覧 -->
                    <v-row no-gutters class="mt-4">
                        <v-col cols="12">
                            <v-table>
                                <thead>
                                    <tr>

                                        <th class="text-left text-base font-weight-bold">
                                            No.
                                        </th>
                                        <th class="text-left text-base font-weight-bold">
                                            {{ $t('Inquiry.InquiryList.propertyName') }}
                                        </th>
                                        <th class="text-left text-base font-weight-bold">
                                            {{ $t('Inquiry.InquiryList.status.title') }}
                                        </th>
                                        <th class="text-left text-base font-weight-bold xsScreen">
                                            {{ $t('Inquiry.InquiryList.address') }}
                                        </th>
                                        <th class="text-left text-base font-weight-bold xsScreen">
                                            {{ $t('Inquiry.InquiryList.roomNumber') }}
                                        </th>
                                        <th class="text-left text-base font-weight-bold xsScreen">
                                            {{ $t('Inquiry.InquiryList.inquiryContent') }}
                                        </th>
                                        <th class="text-left text-base font-weight-bold">
                                            {{ $t('Inquiry.InquiryList.actions.title') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in data.inquiryList" :key="item.inquiryId">
                                        <td>
                                            {{ index + 1}}
                                        </td>
                                        <td> 
                                            <a class="a-link" href="#" @click.stop.prevent="selectInquiry(item.inquiryId)">
                                                {{ item.title }}
                                            </a>
                                        </td>
                                        <td>
                                            <div v-if="item.status == 0">
                                                {{ $t('Inquiry.InquiryList.status.underInquiry') }}
                                            </div>
                                            <div v-if="item.status == 1">
                                                {{ $t('Inquiry.InquiryList.status.completed') }}
                                            </div>
                                            <div v-if="item.status == 2">
                                                {{ $t('Inquiry.InquiryList.status.cancelled') }}
                                            </div>
                                        </td>
                                        <td class="xsScreen">{{ item.addressJp }}</td>
                                        <td class="xsScreen">{{ item.roomNoJp }}</td>
                                        <td class="xsScreen">{{ item.content }}</td>
                                        <td>
                                            <span v-if="item.status === 0">
                                                <a class="a-link" href="#" @click.stop.prevent="openDialog">{{ $t('Inquiry.InquiryList.actions.cancel') }}</a>
                                            </span>
                                            <v-dialog max-width="40%" v-model="data.showDialog" position="top" class="xsScreen">
                                                <v-card>
                                                    <v-card-title>{{ $t('Inquiry.InquiryList.cancelPopupTitle') }}</v-card-title>
                                                    <v-card-text>
                                                        {{ $t('Inquiry.InquiryList.cancelPopupMessage') }}
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-btn color="normal" @click="closeDialog"
                                                            variant="text">{{ $t('Inquiry.InquiryList.actions.cancel') }}</v-btn>
                                                        <v-btn color="success" @click="confirmCancel(item.inquiryId)"
                                                            variant="text">{{ $t('Inquiry.InquiryList.actions.confirm') }}</v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                            <v-dialog max-width="80%" v-model="data.showDialog" position="top" class="laScreen">
                                                <v-card>
                                                    <v-card-title>{{ $t('Inquiry.InquiryList.cancelPopupTitle') }}</v-card-title>
                                                    <v-card-text>
                                                        {{ $t('Inquiry.InquiryList.cancelPopupMessage') }}
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-btn color="normal" @click="closeDialog"
                                                            variant="text">{{ $t('Inquiry.InquiryList.actions.cancel') }}</v-btn>
                                                        <v-btn color="success" @click="confirmCancel(item.inquiryId)"
                                                            variant="text">{{ $t('Inquiry.InquiryList.actions.confirm') }}</v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>

                                        </td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </v-col>
                    </v-row>
                    <v-row class="home-button-row">
                        <v-btn class="mt-10 home-button" color="success" variant="outlined" @click="goHome">
                            {{ $t('Inquiry.InquiryList.actions.goHome') }}
                        </v-btn>
                    </v-row>
                    <v-row class="mt-10">
                        <the-footer></the-footer>
                    </v-row>
                </v-container>
            </v-main>
        </v-layout>
    </v-card>
</template>
<style>
.table-border {
    border: 1px;
    border-style: solid;
    border-color: antiquewhite;
}
</style>
<script lang="ts">
import { defineComponent, reactive, getCurrentInstance, onMounted, defineProps } from 'vue'
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import { useRoute, useRouter } from 'vue-router'
// import axios from 'axios'
import api from '@/api/axios/axios'
import { InquiryStatus } from '../api/sys/model/enum';
import i18n from '../language/index'

export default defineComponent({
    name: 'InquiryList',
    components: {
        TheHeader,
        TheFooter,
    },
});
</script>
  
<script lang="ts" setup>

const props = defineProps({
    propertyInfo: Object,

})
const data = reactive({
    inquiryList: [],
    userId: '1',
    showDialog: false,
    languageType: 'jp' as string

})
const router = useRouter()

const openDialog = () => {
    data.showDialog = true;
}
const closeDialog = () => {
    data.showDialog = false;
}
const confirmCancel = (inquiryId: string) => {
    api.put(`/user/inquiries/`, {
        "inquiryId": inquiryId,
        "status": 2
    })
        .then((res) => {
            updateData()
            console.log("sucess")
            closeDialog()
        }).catch((error) => {
            console.log(error.message)
        })
}
// 遷移画面から取得パラメーター
const selectInquiry = (id: string) => {
    router.push('/inquiryDetail/' + id)
}
const goHome = () => {
    router.push('/');
}
const changeLanguageType = (val: 'jp' | 'en' | 'zh') => {
    data.languageType = val
}
onMounted(() => {    
    data.userId = JSON.parse(localStorage.getItem('CLOUDPFM_CO__USERINFO')).userId
    getInitData()
});
// アップデート
const updateData = () => {
    api.get(`/user/inquiries`, {
        params: {
            userId: JSON.parse(localStorage.getItem('CLOUDPFM_CO__USERINFO')).userId,
            limit: 100,
            offset: 0
        }
    })
        .then((res) => {
            data.inquiryList = res.data
        })
        .catch((error) => {
            console.log(error.message)
        })
}
const getInitData = () => {
    api.get(`/user/inquiries`, {
        params: {
            userId: data.userId,
            limit: 100,
            offset: 0
        }
    })
        .then((res) => {
            data.inquiryList = res.data
        })
        .catch((error) => {
            console.log(error.message)
        })
};
</script>
  