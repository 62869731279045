<template>
   <!-- <v-expansion-panels> -->
        <v-expansion-panel>
            <v-expansion-panel-title>
                <div>{{props.titleName}}</div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
                <div class="SelectChildBox">
                    <v-row no-gutters>
                        <v-col  cols="12" :sm="item.type=='TypeInput'||item.type=='company'||item.type=='addressInput'?'12':'6'" :md="item.type === 'TypeInput' || item.type === 'company' ? '12' : item.type === 'addressInput' ? '6' : '4'" xs='12' v-for="(item,index) in props.SelectPropertyList" :key="index" :class="!item.hidden?'':'hiddenFile'">
                            <p class="theSelectBoxCont BoxFlex" v-if="item.type=='input'">
                                <span class="textName">{{$t(item.name)}} :</span>  
                                <v-text-field
                                    v-model="item.value"
                                    class="fieldSelect"
                                    required
                                    disabled
                                ></v-text-field>
                                <span class="utilShow" v-if="item.util">{{item.util}}</span>
                            </p>
                            <p class="theSelectBoxCont BoxFlex" v-if="item.type=='radio'" style="margin-top: 12px;">
                                <span class="textName">{{$t(item.name)}} :</span>
                                <v-radio-group
                                    inline
                                    class="SelectGroup"
                                    v-model="item.value"
                                    disabled
                                    >
                                    <v-radio
                                        v-for="(itemChild,indexChild) in item.radioSelect"
                                        :key="indexChild"
                                        :label="$t(itemChild.title)"
                                        :value="itemChild.radioval"
                                    ></v-radio>
                                    
                                </v-radio-group>
                                 <span class="utilShow" v-if="item.util">{{item.util}}</span>
                            </p>
                            <p class="theSelectBoxCont BoxFlex" v-if="item.type=='checkbox'">
                                <v-container class="containerSelect">
                                    <v-checkbox
                                    :label="$t(item.name)"
                                    :model-value="item.value"
                                    disabled
                                    ></v-checkbox>
                                </v-container>
                                <span class="utilShow" v-if="item.util">{{item.util}}</span>
                            </p>
                            <p class="theSelectBoxCont BoxFlex" v-if="item.type=='select'">
                                <span class="textName">{{$t(item.name)}} :</span>
                                <v-select
                                v-model="item.selectItems[item.value-1]"
                                :items="item.selectItems"
                                disabled
                                class="fieldSelect"
                                ></v-select>
                                 <span class="utilShow" v-if="item.util">{{item.util}}</span>
                            </p>
                            <p class="theSelectBoxCont BoxFlex" v-if="item.type=='advertisingInput'">
                                <span class="textName">{{$t(item.name)}} :</span>  
                                    <v-text-field
                                    v-model="item.value[0]"
                                    class="fieldSelect"
                                   
                                    required
                                    disabled
                                    ></v-text-field>
                                    <span class="utilShow" v-if="item.util">{{item.util[0]}}</span>
                                    <v-text-field
                                    v-model="item.value[1]"
                                    class="fieldSelect"
                                   
                                    required
                                    disabled
                                    ></v-text-field>
                                    <span class="utilShow" v-if="item.util">{{item.util[1]}}</span>
                                <!-- </div>  -->
                            </p>
                           
                             <!-- 物件種目 三级下拉 --> 
                            <p class="theSelectBoxCont BoxFlex TypeInputBox" v-if="item.type=='TypeInput'">
                                <span class="textName">{{$t(item.name)}} :</span>
                                <v-text-field
                                    v-model="item.selectItemsSecond"
                                    class="fieldSelect"
                                
                                    required
                                    disabled
                                ></v-text-field>
                            </p> 

                            <!-- 所在地 --> 
                            <p class="theSelectBoxCont BoxFlex" v-if="item.type=='addressInput'">
                                <span class="textName">{{$t(item.name)}}:</span>
                                <v-text-field
                                    v-model="item.value"
                                    class="fieldSelect"
                                    required
                                    disabled
                                ></v-text-field>
                            </p> 
                            
                        </v-col>
                        
                        <v-col  cols="12" sm="12" md="12" xs='12' v-for="(item,index) in props.SelectPropertyList" :key="index" :class="!item.hidden?'':'hiddenFile'">
                                <p class="theSelectBoxCont BoxFlex checkAllBox" v-if="item.type=='checkboxList'">
                                    <v-row>
                                        <v-col cols="12" sm="2" md="2" xs='12' class="SelectTitleName">
                                            <span class="textName ">{{$t(item.title)}} :</span>
                                        </v-col>
                                        <v-col cols="12" sm="10" md="10" xs='12'>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" sm="6" md="3" xs='12'  v-for="(itemChilden,indexChilden) in item.name" :key="indexChilden">
                                                        <v-checkbox
                                                            :label="$t(itemChilden)"
                                                            :model-value="item.value[indexChilden]"
                                                            disabled
                                                            >
                                                        </v-checkbox>
                                                    </v-col>    
                                                </v-row>
                                            </v-container>
                                        </v-col>
                                    </v-row>
                                </p>
                        </v-col>
                       <v-col cols="12" sm="12" md="12"  xs='12' v-for="(item,index) in props.SelectPropertyList" :key="index" :class="!item.hidden?'':'hiddenFile'">
                            <p class="theSelectBoxCont BoxFlex" v-if="item.type=='trafficInput'">
                                <span class="textName">{{$t(item.name)}} :</span>  
                                    <v-text-field
                                    v-model="item.value4"
                                    class="fieldSelect"
                                
                                    required
                                    disabled
                                    ></v-text-field>
                                    <span>線</span>
                                    <v-text-field
                                    v-model="item.value3"
                                    class="fieldSelect"
                                
                                    required
                                    disabled
                                    ></v-text-field>
                                    <span>駅</span>
                                    <v-select
                                    v-model="item.selectItems[item.value-1]"
                                    :items="item.selectItems"
                                    disabled
                                    class="fieldSelect"
                                    ></v-select>
                                    <span>交通時間</span>
                                    <v-text-field
                                    v-model="item.value2"
                                    class="fieldSelect"
                                
                                    required
                                    disabled
                                    ></v-text-field>
                                <span class="utilShow" v-if="item.util">{{item.util}}</span>
                            </p>
                            <p class="theSelectBoxCont BoxFlex" v-if="item.type=='otherTransportation_input'">
                                <span class="textName">{{$t(item.name)}} :</span>  
                                <v-text-field
                                    v-model="item.value"
                                    class="fieldSelect"
                                    
                                    required
                                    disabled
                                ></v-text-field>
                                <span class="utilShow" v-if="item.util">{{item.util}}</span>
                            </p>
                       </v-col>
                    </v-row>        
                </div>  
            </v-expansion-panel-text>
        </v-expansion-panel>
    <!-- </v-expansion-panels> -->
</template>

<script lang="ts">
import { defineComponent,reactive, computed, onMounted, defineProps ,onUpdated ,watchEffect} from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'TheSelectBox',
  props: {
    isLogin: Boolean,
    
  },
  setup(props) {
    return {
        
    }
  },
});

</script>
<script lang="ts" setup>
    const router = useRouter()
    const route = useRoute()
    const props = defineProps({
      propertyInfo: Object,
      SelectPropertyList:Array,
      titleName:String,
      isShow:String,
      payType:Number
    })
    const data = reactive({
        test:'radio-1',
        expandedPanels: [],
    })

    const toShowCompanyDetail =(val:object) => {
        router.push(
        { 
            path: '/companyView', 
            query: { 
                companyId : val.companyId 
            } 
        }
    );
    }
</script>

<style scoped>
.theSelectBoxCont{
    text-align: center;
    margin: 10px;
}
.BoxFlex{
    display: flex;
    justify-content: center;
    align-items:  center;
}
.SelectBoxContGroup{
    display: flex;  
}
.textName{
    margin-right: 10px;
}
.containerSelect{
    width: 50%;
    /* margin: 0; */
}
.utilShow{
    width: 60px;
}
.hiddenFile{
    display: none;
}
.SelectChildBox{
    padding: 15px 0px;
}
.checkAllBox{
    margin-top: 30px !important;
}

</style>
<style >
    @media screen and (max-width:600px){
      .SelectTitleName{
        padding: 5px 0 !important;
      }
      .TypeInputBox{
        display: flex;
        flex-direction: column;
      }
      .TypeInputBox div{
        width: 100%;
      }
    }
    .containerSelect .v-input__details {
        display: none !important;
    }
    .SelectTitleName{
        padding: 42px 0;
    }
    .SelectChildBox .v-input__details{
        display: none !important;
    }
    .SelectChildBox .v-col-xxl,.SelectChildBox .v-col-12{
        width: 100%;
        padding: 0px;
    }
    .SelectChildBox .theSelectBoxCont[data-v-266ae38d] {
        text-align: center;
        margin: 5px;
    }
    .companyrow{
        text-align: left !important;
    }
    .companyrow_span{
        color: #007bff;
        cursor: pointer;
        font-weight: 700;
    }
    .companyrow_span:hover{
        color: blue;
    }
</style>
