export const ItemsFrist = (speciesNuble:string,payType:any) => {
    let OptionsList = [] as any
    console.log(payType);
    if(payType==2){
      OptionsList = Optionsbuy
    }else
    if(payType==1)
    {
      OptionsList = propertyOptions
    }
    console.log(OptionsList);
    
    const ptFrist=[]
    for(const item of OptionsList){
        for(const itemChild of speciesNuble){
            if(item.value == itemChild){
                ptFrist.push(item.label)
            }
        }
    }
    return ptFrist.toString()  
}
export const ItemsSecond = (speciesNuble:string,payType:any) => {
  console.log(speciesNuble);
  if(speciesNuble){
    let OptionsList = [] as any
    console.log(payType);
    if(payType==2){
      OptionsList = Optionsbuy
    }else
    if(payType==1)
    {
      OptionsList = propertyOptions
    }
  
    console.log(JSON.parse(speciesNuble));
    const JsonSpeciesNuble = JSON.parse(speciesNuble)
    const ptSecond=[]
    for(const item of JsonSpeciesNuble){
           if(item[1]){
            for(const itemOption of OptionsList){
                for(const itemOptionChild of itemOption.children){
                    if(itemOptionChild.value==item[1]){
                        ptSecond.push(itemOptionChild.label)
                    }
                }
            }
           }else {
              if(item[0]) {
                for(const itemOption of OptionsList){

                    if(itemOption.value==item[0]){
                        ptSecond.push(itemOption.label)
                    }

                }
                
              }
           }
    }
    
    return ptSecond.toString()  
  }
    
    
  
}


const propertyOptions = [
    {
      label: '賃貸事業用',
      value: '1',
      children: [
        {
          label: '貸店舗（一部）',
          value: '101',
        },
        {
          label: '貸店舗（一括）',
          value: '102',
        },
        {
          label: '貸事務所(一部)',
          value: '103',
        },
        {
          label: '貸事務所(一括）',
          value: '104',
        },
        {
          label: '貸店舗・事務所(一括)',
          value: '105',
        },
        {
          label: '貸住付店舗（一部）',
          value: '106',
        },
        {
          label: '貸住付店舗（一括）',
          value: '107',
        },
        {
          label: '貸工場',
          value: '108',
        },
        {
          label: '貸作業所',
          value: '109',
        },
        {
          label: '貸倉庫',
          value: '110',
        },
        {
          label: '貸医院',
          value: '111',
        },
        {
          label: '貸ガソリンスタンド',
          value: '112',
        },
        {
          label: '一括貸マンション',
          value: '113',
        },
        {
          label: '一括貸アパート',
          value: '114',
        },
        {
          label: '貸家',
          value: '115',
        },
        {
          label: '貸ビル',
          value: '116',
        },
        {
          label: '貸ホテル',
          value: '117',
        },
        {
          label: '貸モーテル',
          value: '118',
        },
        {
          label: '貸旅館',
          value: '119',
        },
        {
          label: '貸寮',
          value: '120',
        },
        {
          label: '貸別荘',
          value: '121',
        },
        {
          label: '貸保養所',
          value: '122',
        },
        {
          label: '貸特殊浴場',
          value: '123',
        },
        {
          label: '貸サウナ',
          value: '124',
        },
      ],
    },
    {
      label: '賃貸居住用',
      value: '2',
      children: [
        {
          label: '貸マンション',
          value: '201',
        },
        {
          label: '貸アパート',
          value: '202',
        },
        {
          label: '貸戸建住宅',
          value: '203',
        },
        {
          label: '貸テラスハウス',
          value: '204',
        },
        {
          label: '貸タウンハウス',
          value: '205',
        },
      ],
    },
    {
      label: '貸土地',
      value: '3',
      children: [
        {
          label: '貸地',
          value: '301',
        },
      ],
    },
    {
      label: '賃駐車場',
      value: '4',
      children: [
        {
          label: '賃駐車場',
          value: '401',
        },
      ],
    },
  ];
  const Optionsbuy = [
    {
      label: '売土地',
      value: '5',
      children: [
        {
          label: '売地',
          value: '501',
        },
        {
          label: '借地権譲渡',
          value: '502',
        },
      ],
    },
    {
      label: '売戸建住宅',
      value: '6',
      children: [
        {
          label: '売戸建住宅',
          value: '601',
        },
        {
          label: '売テラスハウス',
          value: '602',
        },
      ],
    },
    {
      label: '売マンション',
      value: '7',
      children: [
        {
          label: '売マンション',
          value: '701',
        },
        {
          label: '売公団',
          value: '702',
        },
        {
          label: '売公社',
          value: '703',
        },
        {
          label: '売タウンハウス',
          value: '704',
        },
      ],
    },
    {
      label: '売事業用（建物一部）',
      value: '8',
      children: [
        {
          label: '売店舗（建物一部）',
          value: '801',
        },
        {
          label: '売事務所',
          value: '802',
        },
        {
          label: '売店舗・事務所',
          value: '803',
        },
      ],
    },
    {
      label: '売事業用（建物一括）',
      value: '9',
      children: [
        {
          label: '売店舗',
          value: '901',
        },
        {
          label: '売事務所(一括)',
          value: '902',
        },
        {
          label: '売店舗・事務所(一括)',
          value: '903',
        },
        {
          label: '売店舗付住宅',
          value: '904',
        },
        {
          label: '売住宅付店舗',
          value: '905',
        },
        {
          label: '売工場',
          value: '906',
        },
        {
          label: '売作業所',
          value: '907',
        },
        {
          label: '売倉庫',
          value: '908',
        },
        {
          label: '売駐車場',
          value: '909',
        },
        {
          label: '一括売マンション',
          value: '910',
        },
        {
          label: '売アパート',
          value: '911',
        },
        {
          label: '売ビル',
          value: '912',
        },
        {
          label: '売ホテル',
          value: '913',
        },
        {
          label: '売モーテル',
          value: '914',
        },
        {
          label: '売アパート',
          value: '915',
        },
        {
          label: '売旅館',
          value: '916',
        },
        {
          label: '売寮',
          value: '917',
        },
        {
          label: '売旅館',
          value: '918',
        },
        {
          label: '売別荘',
          value: '919',
        },
        {
          label: '売保養所',
          value: '920',
        },
        {
          label: '売ガソリンスタンド',
          value: '921',
        },
        {
          label: '売特殊浴場',
          value: '922',
        },
        {
          label: '売サウナ',
          value: '923',
        },
      ],
    },
  ];