<template>
  <v-card>
    <v-layout>
        <the-header></the-header>
        <v-navigation-drawer color="surface" width="180">
        </v-navigation-drawer>
        <v-navigation-drawer color="surface" location="right" width="180">
        </v-navigation-drawer>
        <v-main>
        <v-container class="align-center">
            <!-- 内見予約詳細画面 -->
            <v-row no-gutters>
                <v-col cols="12">
                    <p class="text-h4">{{ $t('previewDetailView.title') }}</p>
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col cols="12" class="text-right">
                    <v-btn size="large" class="" v-on:click="toPreviewList">{{ $t('previewDetailView.goback') }}</v-btn>

                    <v-dialog max-width="40%" v-model="data.dialog">
                    <v-card>
                        <v-card-title>{{ $t('previewDetailView.noTopreview') }}</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text style="height: 300px;">
                        <v-radio-group v-model="data.dialogm1" column >
                            <v-radio label="内見時間が変わったため" value="1"></v-radio>
                            <v-radio label="お客様が他の物件で決めたため" value="2"></v-radio>
                            <v-radio label="そのた" value="3"></v-radio>
                        </v-radio-group>
                        <v-text-field label="そのたの場合はこちらにキャンセル理由をご入力ください。"></v-text-field>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                        <v-btn variant="text" @click="data.dialog = false">
                            {{ $t('previewDetailView.falseBut') }}
                        </v-btn>
                        <v-btn variant="text" @click="cancelPriview(data.propertyViewingId)">
                            {{ $t('previewDetailView.isShow') }}
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>

                    <v-btn v-show="data.preview.status != '3' && data.preview.status != '2'" size="large" class="ml-2" v-on:click="toPreview(data.propertyViewingId)">{{ $t('previewDetailView.edit') }}</v-btn>
                </v-col>
            </v-row>

            <v-row no-gutters class="mt-4">
                <v-col col="12">
                    <v-table class="table-border">
                <tbody>
                    <tr>
                        <td class="bg-teal-lighten-3" style="width: 30%;">{{ $t('previewDetailView.previewNameTh') }}</td>
                        <td>{{ data.preview.previewName }}</td>
                    </tr>
                    <tr>
                        <td class="bg-teal-lighten-3">{{ $t('previewDetailView.email') }}</td>
                        <td>
                            {{ data.preview.email }}
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-teal-lighten-3">{{ $t('previewDetailView.phone') }}</td>
                        <td>
                            {{ data.preview.phone }}
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-teal-lighten-3">{{ $t('previewDetailView.dateFrist') }}</td>
                        <td>
                            {{ data.preview.preferenceTimeFirst }}
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-teal-lighten-3">{{ $t('previewDetailView.dateSecond') }}</td>
                        <td>
                            {{ data.preview.preferenceTimeSecond}}
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-teal-lighten-3">{{ $t('previewDetailView.dateThirdly') }}</td>
                        <td>
                            {{ data.preview.preferenceTimeThird }}
                        </td>
                    </tr>
                    <tr v-if="data.preview.status == '2'">
                        <td class="bg-teal-lighten-3">{{ $t('previewDetailView.requestedApprovalTime') }}</td>
                        <td>
                            {{ data.preview.approvalTime }}
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-teal-lighten-3">{{ $t('previewDetailView.remark') }}</td>
                        <td>
                            {{ data.preview.communicationMatters }}
                        </td>
                    </tr>
                </tbody>
                </v-table>
                </v-col>

            </v-row>

            <v-row class="mt-10">
                <the-footer></the-footer>
            </v-row>
        </v-container>
        </v-main>
    </v-layout>
  </v-card>
</template>
<style>
.table-border {
  border:1px;
  border-style: solid;
  border-color: antiquewhite;
}
</style>
<script lang="ts">
import { defineComponent,reactive, computed, getCurrentInstance, onMounted, defineProps } from 'vue'
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import { useRoute, useRouter } from 'vue-router'
import api from '@/api/axios/axios'

export default defineComponent({
    name: 'PropertyDetail',
    components: {
        TheHeader,
        TheFooter,
    },
});
</script>

<script lang="ts" setup>

    type Preview = {
        propertyViewingId: string,
        previewName: string,
        buildingNameJp: string,
        communicationMatters: string,
        customerName: string,
        email: string,
        phone: string,
        preferenceTimeFirst: string,
        preferenceTimeSecond: string,
        preferenceTimeThird: string,
        requestedApprovalTime: string,
        propertyId: string,
        reservationPlace: string,
        status: string,
        approvalTime: string
    };

    const props = defineProps({
      propertyInfo: Object,
      previewId: Number
    })
    const data = reactive({
        propertyViewingId: "" as string,
        preview: {} as Preview,
        dialogm1: '',
        dialog: false,
    });

    const router = useRouter()
    const route = useRoute()

    const toPreviewList = () => {
        router.push('/previewList')
    }

    const toPreview = (propertyViewingId : string) => {
        router.push(`/preview/${propertyViewingId}/` + data.preview.propertyId)
       router.push({path:`/preview/${propertyViewingId}/` + data.preview.propertyId,
        query:{
            isEdit:"Edit",
        }})
    }

    const cancelPriview = (propertyViewingId : string) => {
        const modify = {status: "1"}
        api.put(`/user/previews/` + propertyViewingId, modify)
        .then(res => {
            data.dialog = false;
            router.push('/previewList')
        })
        .catch(error => {
            alert("キャンセル失敗しました。");
            console.log(error, propertyViewingId);
        });
    }

    onMounted(() => {
        getInitData()
    });

    const getInitData  =  () => {
        data.propertyViewingId = route.params.propertyViewingId.toString()
        api.get(`/user/previews/` + data.propertyViewingId)
        .then((res) => {
            data.preview = res.data

            console.log(data.preview.requestedApprovalTime)

            if(data.preview.requestedApprovalTime == '1'){
                data.preview.approvalTime = data.preview.preferenceTimeFirst
            }else if(data.preview.requestedApprovalTime == '2'){
                data.preview.approvalTime = data.preview.preferenceTimeSecond
            }else if(data.preview.requestedApprovalTime == '3'){
                data.preview.approvalTime = data.preview.preferenceTimeThird
            }     

        })
        .catch((error) => {
            console.log(error.message)
        })
    };


</script>
