<template>
  <v-card>
    <v-snackbar
        class="successSnackbar"
        position="absolute"
        v-model="data.snackbarSuccess"
        absolute
        top
        >
        {{ data.successText }}
    </v-snackbar>
    <v-snackbar
        class="errorSnackbar"
        position="absolute"
        v-model="data.errorSnackbar"
        absolute
        top
        >
        {{ data.errorText }}
    </v-snackbar>
    <v-layout>
        <v-app-bar color="surface-variant"  height="50">
            <v-toolbar-title class="leftHeadLogo"><img class="logoimageHeader" @click="toHome()" :src="require('@/assets/header.cffaff6f.png')" alt=""><a  class="pagetitle" href="#" style ="text-decoration: none; color:aliceblue;" @click="toHome()">CLOUDPF</a></v-toolbar-title>
            <v-select class="languageSelect" 
                v-model="data.selectLanguage" 
                :items="data.type" 
                item-text="title" 
                item-value="val"
                style="max-width: 150px;margin-top: 20px;"
                @update:modelValue="chooselanguage(data.selectLanguage)"
                ></v-select>
        </v-app-bar>
        <v-navigation-drawer color="surface" width="180">
        </v-navigation-drawer>
        <v-navigation-drawer color="surface" location="right" width="180">
        </v-navigation-drawer>
        <v-main>
        <v-container lass="align-center">
            <v-row no-gutters>
                <p class="font-weight-medium loginTitle">
                    {{ $t('LoginMessage.title') }}
                </p>
            </v-row>
            <v-row>
                <v-col cols="12" md="6" sm="12" xs="12">
                    <v-card
                    class="mx-auto loginCard"
                    max-width="540"

                    >
                        <p class="loginTitle registerTitle">
                            {{ $t('LoginMessage.registerCard.title') }}
                         </p>
                        <p class="cardContxt">
                            {{ $t('LoginMessage.registerCard.content') }}
                        </p>
                        <p>
                            <v-btn depressed class=" registerButton" @click="toRegister()">
                                {{ $t('LoginMessage.registerCard.button') }}
                            </v-btn>
                        </p>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="12" xs="12">
                    <v-card
                    class="mx-auto"
                    max-width="540"
                    outlined
                    >
                        <div class="loginTitleBox" v-if="!data.isShowforgetForm">
                            <p>{{ $t('LoginMessage.loginCard.title') }}</p>
                        </div>
                        <div class="loginTitleBox" v-if="data.isShowforgetForm">
                            <p>{{ $t('LoginMessage.forgetMessage.title') }}</p>
                        </div>
                        <div class="loginBoxBody" v-if="!data.isShowforgetForm">
                        <v-form >
                            <v-container>
                                <v-row>
                                    <v-col cols="4">
                                        <span>{{ $t('LoginMessage.loginCard.email') }}</span>
                                    </v-col>
                                    <v-col cols="8">
                                        <input type="text" style="display:none;" disabled />
                                    <v-text-field
                                        v-model="data.userName"
                                        name="userName"
                                        placeholder="例: name@example.com"
                                    ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <span>{{ $t('LoginMessage.loginCard.password') }}</span>
                                    </v-col>
                                    <v-col cols="8">
                                    <v-text-field
                                        v-model="data.password"
                                        :autocomplete="'off'"
                                        :append-icon="data.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append="toggleLoginPasswordVisibility"
                                        :type="data.showLoginPassword ? 'text' : 'password'"
                                    ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                            <p class="forget"><a class="text-danger small" @click="data.isShowforgetForm=true">&gt;&gt;{{ $t('LoginMessage.loginCard.forgetButton') }}</a></p>
                            <v-btn size="large" class="loginButton" v-on:click="toLogin">{{ $t('LoginMessage.loginCard.title') }}</v-btn>
                        </div>
                        <div class="forgetPasswordBox" v-if="data.isShowforgetForm">
                            <v-form ref="formRef">
                                <v-container>
                                    <v-row>
                                        <v-col cols="4">
                                            <span>{{ $t('LoginMessage.forgetMessage.email') }}:</span>
                                        </v-col>
                                        <v-col cols="8">
                                        <v-text-field
                                            name="userName"
                                            v-model="data.forgetPasswordMessage.email"
                                            required
                                            ref="email"
                                            :rules="rules.email"
                                            placeholder="例: name@example.com"
                                            :autocomplete="'off'"
                                        ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <p><a class="getCodebutton" @click="GetUserCode">{{ $t('LoginMessage.forgetMessage.sendButton') }}</a></p>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                            <v-form ref="repasswordRef">
                                <v-row>
                                        <v-col cols="4">
                                            <span>{{ $t('LoginMessage.forgetMessage.certification') }}:</span>
                                        </v-col>
                                        <v-col cols="8">
                                        <v-text-field  
                                            name="code"
                                            ref="textField"
                                            :readonly="data.noAutocomplete"
                                            @focus="data.noAutocomplete=false"
                                            v-model="data.forgetPasswordMessage.code"
                                            :rules="rulesRepassworld.code"
                                        ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            <span>{{ $t('LoginMessage.forgetMessage.password') }}:</span>
                                        </v-col>
                                        <v-col cols="8">

                                        <v-text-field
                                            v-model="data.forgetPasswordMessage.password"
                                            :rules="rulesRepassworld.password"
                                            required
                                            :readonly="data.noAutocomplete"
                                            @focus="data.noAutocomplete=false"
                                            :append-icon="data.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                            @click:append="togglePasswordVisibility"
                                            :type="data.showPassword ? 'text' : 'password'"
                                        ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            <span>{{ $t('LoginMessage.forgetMessage.repassword') }}:</span>
                                        </v-col>
                                        <v-col cols="8">
                                        <v-text-field
                                            v-model="data.forgetPasswordMessage.repassword"
                                            :rules="rulesRepassworld.repassword"
                                            required
                                            :readonly="data.noAutocomplete"
                                            @focus="data.noAutocomplete=false"
                                            :append-icon="data.showRePassword ? 'mdi-eye' : 'mdi-eye-off'"
                                            @click:append="toggleRePasswordVisibility"
                                            :type="data.showRePassword ? 'text' : 'password'"
                                        ></v-text-field>
                                        </v-col>
                                    </v-row>
                            </v-form>
                                <v-btn size="large" class="loginButton" @click="changePassword">{{ $t('LoginMessage.forgetMessage.changeButton') }}</v-btn>
                                <p class="gobackTologin">{{ $t('LoginMessage.forgetMessage.toLoginMessage.message') }}<span class="getCodebutton togobackButton" @click="data.isShowforgetForm=false">{{ $t('LoginMessage.forgetMessage.toLoginMessage.button') }}</span></p>
                        </div> 

                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mt-10">
                <the-footer></the-footer>
            </v-row>
        </v-container>
        </v-main>
    </v-layout>
  </v-card>
    
</template>
<script lang="ts">
import { defineComponent,reactive, computed, getCurrentInstance, onMounted, nextTick  ,defineProps,defineEmits } from 'vue'
// import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import { ref ,  watch} from 'vue';
import {  signIn ,forgotPassword , resetPassword } from '../cognito/cognito';
import api from '@/api/axios/axios'
import { resolveConfig } from 'vite';
import { Ref } from 'vue';
import i18n from '../language/index'

export default defineComponent({
    name: 'PropertyDetail',
    components: {
        // TheHeader,
        TheFooter,
    },
});
</script>
<script lang="ts" setup>
    const data = reactive({
        noAutocomplete:true,
        userName:'',
        password:'',
        showPassword: false,
        showRePassword: false,
        showLoginPassword: false,
        errorSnackbar:false,
        snackbarSuccess: false,
        successText: ``,
        errorText:'',
        isShowforgetForm:false,
        forgetPasswordMessage:{
            email:'',
            code:'',
            password:'',
            repassword:'',
        },
        selectLanguage: "jp" as 'jp'|'en'|'zh',
        type:[
            {title:i18n.global.messages[i18n.global.locale].languageType.Japanese,val:'jp'},
            {title:i18n.global.messages[i18n.global.locale].languageType.Chinese,val:'zh'},
            {title:i18n.global.messages[i18n.global.locale].languageType.English,val:'en'},
        ]
    })
    const formRef = ref(null);
    const repasswordRef = ref(null);
    const textField: Ref<HTMLElement | undefined> = ref();

    const rules = {
      email: [
        (value:string) => !!value || 'メールアドレスを入力してください。',
        (value:string) => /.+@.+\..+/.test(value) || 'ご入力したメールアドレスが不正です',
      ],
    };
    const rulesRepassworld = {
      code: [
        (value:string) => !!value || '認証コードを入力してください。',
      ],
      password: [
        (value:string) => !!value || 'パスワードを入力してください。',
        (value:string) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/.test(value) || '大文字小文字の英字と数字を入力してください。',
      ],
      repassword: [
        (value:string) => !!value || 'パスワード確認を入力してください。',
        (value:string) => value === data.forgetPasswordMessage.password  || 'パスワードが一致しません',
      ],
    };

    const router = useRouter()
    const toHome = () => {
        router.push("/")
    }
    const toRegister =()=>{
        router.push("/register")
    }

    onBeforeRouteLeave((to, from, next) => {
        to.meta.keepAlive = false;
        next()
    });
    const getUserInfo = ()=>{
        const registerData ={
            
        }
        api.post(`/user/login`,registerData)
        .then((res) => {
           console.log(res);
           
        })
        .catch((error) => {
            console.log(error.message);
        })
    }

    const togglePasswordVisibility = ()=>{
        data.showPassword = !data.showPassword;
    }

    const toggleLoginPasswordVisibility = ()=>{
        data.showLoginPassword = !data.showLoginPassword;
    }

    const toggleRePasswordVisibility = ()=>{
        data.showRePassword = !data.showRePassword;
    }
    
    watch(() => data.isShowforgetForm, (newValue, oldValue) => {
        if (newValue !== oldValue) {
            data.forgetPasswordMessage = {
                                        email:'',
                                        code:'',
                                        password:'',
                                        repassword:'',
                                    }
        }
    });
   

    // ログイン
    const toLogin =
    async()=>{
           try {
                const accessToken = await signIn(data.userName, data.password);
                localStorage.setItem('CLOUDPFM_CO__REFRESH__',accessToken)
                data.snackbarSuccess = true
                data.successText = 'ログインに成功しました。 '
                 api.post(`/user/public/login`).then(
                    async(res)=>{
                        console.log(res);
                        localStorage.setItem('CLOUDPFM_CO__USERINFO',JSON.stringify(res.data))
                            setTimeout(() => {
                                router.push("/")
                            }, 1000);
                    }
                    )
                    .catch((error)=>{
                        console.log(error);           
                    })

            } catch (error:any) {
                data.errorSnackbar = true
                data.errorText = error.message
                data.errorText = 'ログインID、またはパスワードが不正です。'
            }   
       
    }
    const emit = defineEmits(['chooselanguage'],)
    const chooselanguage = (type:'jp'|'en'|'zh') =>{
      console.log(type);
      i18n.global.locale = type;
      emit('chooselanguage',type)
      data.type =[
        {title:i18n.global.messages[i18n.global.locale].languageType.Japanese,val:'jp'},
        {title:i18n.global.messages[i18n.global.locale].languageType.Chinese,val:'zh'},
        {title:i18n.global.messages[i18n.global.locale].languageType.English,val:'en'},
      ]
    }

    const GetUserCode = () => {        
       formRef.value?.validate()
       .then((valid:string)=>{
            if(valid.valid){
            forgotPassword(data.forgetPasswordMessage.email)
                .then((res)=>{
                    console.log(res);
                    data.snackbarSuccess = true
                    data.successText = '送信成功'
                })
                .catch((error)=>{
                    console.log(error);
                })
            }
       })
    }

    const changePassword = () => {
        formRef.value?.validate()
       .then((valid:string)=>{
            if(valid.valid){
                repasswordRef.value?.validate()
                .then((thisvalid:string) => {
                    if(thisvalid.valid){
                        resetPassword(data.forgetPasswordMessage.email,data.forgetPasswordMessage.code,data.forgetPasswordMessage.password)
                        .then((res)=>{
                            data.snackbarSuccess = true
                            data.successText = 'パスワード変更成功しました'
                            data.isShowforgetForm = false
                           
                        })  
                        .catch((error)=>{
                            console.log(error);
                            data.errorSnackbar = true
                            data.errorText = error.message
                            data.errorText = '認証コードが無効です、再送信してください'
                        })
                    }
                })

            }else{
                console.log(45444);
                
            }
       })
    }


</script>
<style>
.loginTitle{
    font-size: 1.75rem;
}
.registerTitle{
    color: #dc3545;
    margin-top: 1rem !important
}
.loginCard{
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.125);
    text-align: center;
}
.cardContxt{
    margin: 16px 0;
}
.registerButton{
    width: 100%;
    color: #28a745 !important;
    border-color: #28a745;
    font-weight: 400;
    font-size: 1rem;
}
.registerButton:hover{
    background: #28a745;
    color: #ffffff !important;
}
.loginTitleBox{
    color: #fff;
    background: #dc3545;
    padding: 0.75rem 1.25rem;
}
.loginBoxBody{
    padding: 1.25rem;
}
.forgetPasswordBox{
    padding: 1.25rem;
}
.loginButton{
    margin-top: 1rem ;
    color: #dc3545 !important;
    border-color: #dc3545;
    /* border:  1px soild #000 !important; */
    width: 100%;
    box-shadow: none;
    border: 1px solid #dc3545;
    padding: 0.375rem 0.75rem;
        border-radius: 0.25rem;
}
.loginButton:hover{
    color: #fff !important;
    background: #dc3545;
    outline: #dc3545;
}
.text-danger{
    color: #dc3545;
    float: right;
    font-size: 80%;
    text-decoration: none;
    font-weight: 400;
    cursor: pointer;
}
.text-danger:hover{
    color: #cd616c;
    text-decoration:underline;
}
.successSnackbar div{
    position: absolute;
    top: 0 !important;
    height: 20px;
    background: #30aa4c;
}
.errorSnackbar div{
    position: absolute;
    top: 0 !important;
    max-height: 30px;
    color: #dc3545;
    overflow: hidden;
    /* background: #dc3545; */
}
.getCodebutton{
    color: #dc3545;
    cursor: pointer;
    font-size: 18px;

}
.getCodebutton:hover{
    text-decoration: underline;
}
.gobackTologin{
    margin-top: 20px;
    text-align: center;
    color: #b5b5b5;
}
.togobackButton{
    font-size: 16px;
}
</style>