import {Property} from './model'
import {ItemsFrist ,ItemsSecond } from "../model/speciesList";
// 物件情报
export const processingPropertyMessage = (res:Property,payType:string) =>{
    const fristTitleName = 'PropertyDetail.theSlectData.'
    const SelectPropertyMessage = [
         // 业者信息
        //  {
        //    name:'所属会社：',
        //    value:company,
        //    type:'company'
        // },
         // 物件種目
         {
            name:fristTitleName+'propertyType',
            nameLarge: fristTitleName + 'propertyTypeLarge',
            nameSmall: fristTitleName + 'propertyTypeSmall',
            value:[payType,res.propertyTypeLarge,res.propertyTypeSmall],
            type:'TypeInput',
            selectItems:payType=='1'?'賃貸':'売買',
            selectItemsFrist:ItemsFrist(res.propertyTypeLarge,payType),
            selectItemsSecond:ItemsSecond(res.propertyTypeSmall,payType),
        },
        // 建物名
        {
            name: fristTitleName + 'buildingName',
            value:res.buildingName,
            type:'input',
        },
        // 部屋番号
        {
            name: fristTitleName + 'roomNo',
            value:res.roomNo,
            type:'input',
        },
        // 建物構造
        {
            name: fristTitleName + 'buildingStructure',
            value:res.buildingStructure,
            type:'select',
            selectItems:['木造','ブロック造','鉄骨造','RC','SRC','PC','HPC','ALC','軽量鉄骨造','その他']
        },
        // 所在地
        {
            name: fristTitleName + 'address',
            value:res.address,
            type:'addressInput',
        },
        //その他所在地
        {
            name: fristTitleName + 'address_other',
            value:res.address_other,
            type:'addressInput',
        },
        
        // 築年月
        {
            name: fristTitleName + 'buildingDate',
            value:res.buildingDate,
            type:'input',
            util:'築'
        },
        // 総戸数
        {
            name: fristTitleName + 'totalUnits',
            value:res.totalUnits,
            type:'input',
        },
       
       
        // 管理員の勤務形態
        {
            name: fristTitleName + 'workStyleManager',
            value:res.workStyleManager,
            type:'select',
            selectItems:['常駐','日勤','巡回','不在']
        },
        // 間取り
        {
            name: fristTitleName + 'floorPlan',
            value:res.floorPlan,
            type:'select',
            selectItems:['ワンルーム','1K','1DK','1LDK','2K','2DK','2LDK','3K','3DK','3LDK','4K','4DK','4LDK以上']
        },
        // 専有面積
        {
            name: fristTitleName + 'exclusiveArea',
            value:res.exclusiveArea,
            type:'input',
            util:'㎡'
        },
        // バルコニー
        {
            name: fristTitleName + 'balconyArea',
            value:res.balconyArea,
            type:'input',
            util:'㎡'
            
        },
        // 主要採光面
        {
            name: fristTitleName + 'mainLightSurface',
            value:res.mainLightSurface,
            type:'select',
            selectItems:['北','南','西','東','北東','南東','南西','北西']
        },
        // 新築/中古
        {
            name: fristTitleName + 'newUsedStatus',
            value:res.newUsedStatus,
            type:'select',
            selectItems:['未入力','新築','中古']
        },
         // 交通
         {
            name: fristTitleName + 'useStation1',
            value:res.trafficMeans1,
            value2:res.useStation1TrafficDistance,
            value3:res.useStation1,
            value4:res.trafficLine1Jp,
            selectItems:['徒歩','バス'],
            type:'trafficInput',
            util:'分'
        },
         {
            name: fristTitleName + 'useStation2',
            value:res.trafficMeans2,
            value2:res.useStation2TrafficDistance,
            value3:res.useStation2,
            value4:res.trafficLine2Jp,
            selectItems:['徒歩','バス'],
            type:'trafficInput',
            util:'分'
        },
         // その他交通
        {
            name: fristTitleName + 'otherTransportation',
            value:res.otherTransportation,
            type:'otherTransportation_input',
        },
        // // 駐車場
        // {
        //     name: fristTitleName + 'parking',
        //     value:res.parking,
        //     type:'radio',
        //     radioSelect:[
        //         {
        //             title:'PropertyDetail.util.util2',
        //             radioval:true
        //         },
        //         {
        //             title:'PropertyDetail.util.util1',
        //             radioval:false
        //         }
        //     ]
        // },
        // // 駐車場費
        // {
        //     name: '駐車場費',
        //     value:res.parkingFee,
        //     type:'input',
        //     util:'万円'
        // },
        // // バイク置場
        // {
        //     name: fristTitleName + 'bikeStorage',
        //     value:res.bikeStorage,
        //     type:'radio',
        //     radioSelect:[
        //         {
        //             title:'PropertyDetail.util.util2',
        //             radioval:true
        //         },
        //         {
        //             title:'PropertyDetail.util.util1',
        //             radioval:false
        //         }
        //     ]
        // },
        // // バイク置場費
        // {
        //     name: 'バイク置場費',
        //     value:res.bikeStorageFee,
        //     type:'input',
        //     util:'万円'
        // },
        // // 駐輪場
        // {
        //     name: fristTitleName + 'bicycleParking',
        //     value:res.bicycleParking,
        //     type:'radio',
        //     radioSelect:[
        //         {
        //             title:'PropertyDetail.util.util2',
        //             radioval:true
        //         },
        //         {
        //             title:'PropertyDetail.util.util1',
        //             radioval:false
        //         }
        //     ]
        // },
        // // 駐輪場費
        // {
        //     name: '駐輪場費',
        //     value:res.bicycleParkingFee,
        //     type:'input',
        //     util:'万円'
        // },

    ] 

    return SelectPropertyMessage;
}
// 物件状況
export const ObjectCondition = (res:Property) =>{
    const fristTitleName = 'PropertyDetail.theSlectData.'
    const ObjectConditionData = [
        // 現況
        {
            name: fristTitleName + 'currentSituation',
            value:res.currentSituation,
            type:'select',
            selectItems:['居住中','空家','未完成','完成済']
        }, 
        // 現況-退去予定日
        {
            name: fristTitleName + 'scheduledMoveOutDate',
            value:res.scheduledMoveOutDate,
            type:'input',
            hidden:res.currentSituation == '1' ? true:false
        },
        // 入居日
        {
            name: fristTitleName + 'moveInSelectDueDate',
            value:res.moveInSelectDueDate,
            type:'input',
        },
        // 入居申込
        {
            name: fristTitleName + 'moveInApplication',
            value:res.moveInApplication,
            type:'radio',
            radioSelect:[
                {
                    title:'PropertyDetail.util.util2',
                    radioval:2
                },
                {
                    title:'PropertyDetail.util.util1',
                    radioval:1
                }
            ]
        },
        // 内見
        {
            name: fristTitleName + 'preview',
            value:res.preview,
            type:'select',
            selectItems:['内見可能','内見不可']
        },
        // 取引態様
        // {
        //     name: fristTitleName + 'modeOfTransaction',
        //     value:res.modeOfTransaction,
        //     type:'select',
        //     selectItems:['貸主','代理','専任','媒介','売主']
        // },
        // 自社管理番号
        // {
        //     name: fristTitleName + 'inHouseControlNumber',
        //     value:res.inHouseControlNumber,
        //     type:'input',
        // },
        // // 広告料
        // {
        //     name: fristTitleName + 'advertisingFeePercent',
        //     value:[res.advertisingFeePercent,res.moveInStatus],
        //     type:'advertisingInput',
        //     util:['%','万円']
        // },
    ]
    return ObjectConditionData
}
// その他
export const otherPropertyMessage = (res:Property) =>{
    const fristTitleName = 'PropertyDetail.theSlectData.'
    const otherMessage = [
        // コメント
        {
            name: fristTitleName + 'comment',
            value:res.comment,
            type:'input',
        },
        // アピール
        {
            name: fristTitleName + 'appeal',
            value:res.appeal,
            type:'input',
        },
    ]
    return otherMessage;
}
// 契約条件
export const ConditionContract = (res:any,otherRes:any) =>{
    const fristTitleName = 'PropertyDetail.RentalDtoData.'
    const ConditionContractData = [
        // 賃料
        {
            name: fristTitleName + 'rent',
            value:res.rent,
            type:'input',
            util:'万円'
        },
        // 管理費
        {
            name: fristTitleName + 'managementFee',
            value:otherRes.managementFee,
            type:'input',
            util:'万円'
        },
        // 共益費
        {
            name: fristTitleName + 'commonServiceFee',
            value:res.commonServiceFee,
            type:'input',
            util:'万円'
        },
        // 礼金
        {
            name: '礼金',
            value:[res.keyMonth,res.keyMoney],
            type:'advertisingInput',
            util:['ヶ月','万円']
        },
        // 敷金
        {
            name: '敷金',
            value:[res.securityDepositMonth,res.securityDepositMoney],
            type:'advertisingInput',
            util:['ヶ月','万円']
        },
        // 敷引
        {
            name: '敷引',
            value:[res.deductionFromRentalDepositMonth,res.deductionFromRentalDepositMoney],
            type:'advertisingInput',
            util:['ヶ月','万円']
        },
        // 保証金
        {
            name: '保証金',
            value:[res.guaranteeDepositMonth,res.guaranteeDepositMoney],
            type:'advertisingInput',
            util:['ヶ月','万円']
        },
        // 定期借家
        {
            name: fristTitleName + 'rentType',
            value:res.rentType,
            type:'select',
            selectItems:['定期借家','普通借家']
        },
        // 契約期間
        {
            name: fristTitleName + 'rentContractPeriod',
            value:res.rentContractPeriod,
            type:'input',
            util:'年'
        },
        // 更新料
        {
            name: '更新料',
            value:[res.renewalFeeMonth,res.renewalFeeMoney],
            type:'advertisingInput',
            util:['ヶ月','万円']
        },
        // フリーレント
        {
            name: fristTitleName + 'freeRent',
            value:res.freeRent,
            type:'input',
        },
        // // 定期借家
        // {
        //     name: fristTitleName + 'fixedTermLease',
        //     value:res.fixedTermLease,
        //     type:'input',
        // },
        // 鍵交換代
        {
            name: fristTitleName + 'keyExchangeFee',
            value:res.keyExchangeFee,
            type:'input',
            util:'万円'
        },
        // 駐車場
        {
            name: fristTitleName + 'parking',
            value:otherRes.parking,
            type:'radio',
            radioSelect:[
                {
                    title:'PropertyDetail.util.util2',
                    radioval:true
                },
                {
                    title:'PropertyDetail.util.util1',
                    radioval:false
                }
            ]
        },
        // 駐車場費
        {
            name: fristTitleName + 'parkingFee',
            value:otherRes.parkingFee,
            type:'input',
            util:'万円'
        },
        // バイク置場
        {
            name: fristTitleName + 'bikeStorage',
            value:otherRes.bikeStorage,
            type:'radio',
            radioSelect:[
                {
                    title:'PropertyDetail.util.util2',
                    radioval:true
                },
                {
                    title:'PropertyDetail.util.util1',
                    radioval:false
                }
            ]
        },
        // バイク置場費
        {
            name: fristTitleName + 'bikeStorageFee',
            value:otherRes.bikeStorageFee,
            type:'input',
            util:'万円'
        },
        // 駐輪場
        {
            name: fristTitleName + 'bicycleParking',
            value:otherRes.bicycleParking,
            type:'radio',
            radioSelect:[
                {
                    title:'PropertyDetail.util.util2',
                    radioval:true
                },
                {
                    title:'PropertyDetail.util.util1',
                    radioval:false
                }
            ]
        },
        // 駐輪場費
        {
            name: fristTitleName + 'bicycleParkingFee',
            value:otherRes.bicycleParkingFee,
            type:'input',
            util:'万円'
        },
        // その他一時金
        {
            name: fristTitleName + 'otherLumpSum',
            value:res.otherLumpSum,
            type:'input',
            
        },
        // 保険等
        {
            name: fristTitleName + 'insuranceEtc',
            value:res.insuranceEtc,
            type:'input',
        },
        // 賃貸保証
        {
            name: fristTitleName + 'rentalGuarantee',
            value:res.rentalGuarantee,
            type:'input',
        },

        // クレジットカード決済
        {
            name: fristTitleName + 'creditCardPayment',
            value:res.creditCardPayment,
            type:'input',
        },

    ]
    return  ConditionContractData
}
// 入居条件  
export const OccupancyCondition = (res:any,otherRes:any) =>{
    const fristTitleName = 'PropertyDetail.RentalDtoData.'
    const OccupancyConditionData = [
        // 法人契約
        {
            name: fristTitleName + 'corporateContract',
            value:res.corporateContract,
            type:'select',
            selectItems:['可','不可','相談','希望']
        },
        // 社宅
        {
            name: fristTitleName + 'companyHousing',
            value:res.companyHousing,
            type:'select',
            selectItems:['可','不可','相談','希望']
        },
        // 事務所利用
        {
            name: fristTitleName + 'forPaperwork',
            value:res.forPaperwork,
            type:'select',
            selectItems:['可','不可','相談','希望']
        },
        // SOHO利用
        {
            name: fristTitleName + 'sohoUse',
            value:res.sohoUse,
            type:'select',
            selectItems:['可','不可','相談','希望']
        },
        // 喫煙
        {
            name: fristTitleName + 'smoking',
            value:res.smoking,
            type:'select',
            selectItems:['可','不可','相談','希望']
        },
        // DIY
        {
            name: fristTitleName + 'diy',
            value:res.diy,
            type:'select',
            selectItems:['可','不可','相談','希望']
        },
        // ルームシェア
        {
            name: fristTitleName + 'roomShare',
            value:res.roomShare,
            type:'select',
            selectItems:['可','不可','相談','希望']
        },
        // 入居人数
        {
            name: fristTitleName + 'numberOfOccupants',
            value:res.numberOfOccupants,
            type:'select',
            selectItems:['単身者限定','二人入居可']
        },
        // 性别
        {
            name: fristTitleName + 'sex',
            value:res.sex,
            type:'select',
            selectItems:['男性限定','女性限定','無']
        },
        // 学生限定
        {
            name: fristTitleName + 'studentsOnly',
            value:res.studentsOnly,
            type:'radio',
            radioSelect:[
                {
                    title:'PropertyDetail.util.util3',
                    radioval:1
                },
                {
                    title:'PropertyDetail.util.util4',
                    radioval:0
                }
            ]
        },
        // 高齢者
        {
            name: fristTitleName + 'seniorCitizen',
            value:res.seniorCitizen,
            type:'select',
            selectItems:['可','不可','相談','希望']
        },
        // 外国人
        {
            name: fristTitleName + 'foreigner',
            value:res.foreigner,
            type:'select',
            selectItems:['可','不可','相談','希望']
        },
        // 保証人
        {
            name: fristTitleName + 'guarantor',
            value:res.guarantor,
            type:'input',
        },
        // 楽器相談
        {
            name: fristTitleName + 'instrumentConsultation',
            value:res.instrumentConsultation,
            type:'select',
            selectItems:['可','不可','相談','希望']
        },
        // その他
        {
            name: fristTitleName + 'others',
            value:res.others,
            type:'input',
        },
    ]
    return OccupancyConditionData;
}
// 売買のみ
export const propertySellingData = (res:any,otherRes:any) => {
    const fristTitleName = 'PropertyDetail.SellingData.'
    const SellingData = [
        // 価格
        {
            name: fristTitleName + 'price',
            value:res.price,
            type:'input',
            util:'万円'
        },
        // 管理費
        {
            name: fristTitleName + 'managementFee',
            value:otherRes.managementFee,
            type:'input',
            util:'万円'
        },
        // 積立金
        {
            name: fristTitleName + 'deposit',
            value:res.deposit,
            type:'input',
        },
        // 土地権利
        {
            name: fristTitleName + 'landRights',
            value:res.landRights,
            type:'select',
            selectItems:['所有権','旧法地上権','旧法賃借権','普通地上権','普通賃借権','定期借地権（地上権）','定期借地権（賃借権）']
        },
        // 特記
        {
            name: fristTitleName + 'specialNote',
            value:res.specialNote,
            type:'input',      
        },
        // // 駐車場
        // {
        //     name: fristTitleName + 'parking',
        //     value:otherRes.parking,
        //     type:'radio',
        //     radioSelect:[
        //         {
        //             title:'PropertyDetail.util.util2',
        //             radioval:true
        //         },
        //         {
        //             title:'PropertyDetail.util.util1',
        //             radioval:false
        //         }
        //     ]
        // },
        // // バイク置場
        // {
        //     name: fristTitleName + 'bikeStorage',
        //     value:res.bikeStorage==1?true:false,
        //     type:'radio',
        //     radioSelect:[
        //         {
        //             title:'PropertyDetail.util.util2',
        //             radioval:true
        //         },
        //         {
        //             title:'PropertyDetail.util.util1',
        //             radioval:false
        //         }
        //     ]
        // },
        // // 駐輪場
        // {
        //     name: fristTitleName + 'bicycleParking',
        //     value:otherRes.bicycleParking,
        //     type:'radio',
        //     radioSelect:[
        //         {
        //             title:'PropertyDetail.util.util2',
        //             radioval:true
        //         },
        //         {
        //             title:'PropertyDetail.util.util1',
        //             radioval:false
        //         }
        //     ]
        // },
        // 駐車場
        {
            name: fristTitleName + 'parking',
            value:otherRes.parking,
            type:'radio',
            radioSelect:[
                {
                    title:'PropertyDetail.util.util2',
                    radioval:true
                },
                {
                    title:'PropertyDetail.util.util1',
                    radioval:false
                }
            ]
        },
        // 駐車場費
        {
            name: fristTitleName + 'parkingFee',
            value:otherRes.parkingFee,
            type:'input',
            util:'万円'
        },
        // バイク置場
        {
            name: fristTitleName + 'bikeStorage',
            value:otherRes.bikeStorage,
            type:'radio',
            radioSelect:[
                {
                    title:'PropertyDetail.util.util2',
                    radioval:true
                },
                {
                    title:'PropertyDetail.util.util1',
                    radioval:false
                }
            ]
        },
        // バイク置場費
        {
            name: fristTitleName + 'bikeStorageFee',
            value:otherRes.bikeStorageFee,
            type:'input',
            util:'万円'
        },
        // 駐輪場
        {
            name: fristTitleName + 'bicycleParking',
            value:otherRes.bicycleParking,
            type:'radio',
            radioSelect:[
                {
                    title:'PropertyDetail.util.util2',
                    radioval:true
                },
                {
                    title:'PropertyDetail.util.util1',
                    radioval:false
                }
            ]
        },
        // 駐輪場費
        {
            name: fristTitleName + 'bicycleParkingFee',
            value:otherRes.bicycleParkingFee,
            type:'input',
            util:'万円'
        },
        // その他一時金
        {
            name: fristTitleName + 'otherLumpSum',
            value:res.otherLumpSum,
            type:'input',
            
        },
        // 年間予定賃料収入
        {
            name: fristTitleName + 'plannedAnnualRentalIncome',
            value:res.plannedAnnualRentalIncome,
            type:'input',
            util:'万円'
        },

        // 表面利回り
        {
            name: fristTitleName + 'grossYield',
            value:res.grossYield,
            type:'input',
            util:'%'
        },
        // ペット
         {
            name: fristTitleName + 'pet',
            value:res.pet,
            type:'radio',
            radioSelect:[
                {
                    title:'PropertyDetail.util.util5',
                    radioval:true
                },
                {
                    title:'PropertyDetail.util.util6',
                    radioval:false
                }
            ]
        },

        // 証明書
        {
            title:'PropertyDetail.childenClassTitle.relevantProof',
            name: [fristTitleName + 'flat35conformityCertificateAvailable',fristTitleName + 'flat35sconformityCertificate',fristTitleName + 'seismicStandardConformityCertificate',fristTitleName + 'legalComplianceSurveyReportAvailable'],
            value:[res.flat35conformityCertificateAvailable,res.flat35sconformityCertificate,res.seismicStandardConformityCertificate,res.legalComplianceSurveyReportAvailable],
            type:'checkboxList',
        },
     ]
    return SellingData
}
// 設備
export const propertyFacilitiesData = (res:any) => {
    const fristTitleName = 'PropertyDetail.FacilitiesData.'
    const featureData = [ 
        // バス・トイレ・洗面所
        {
            title:'PropertyDetail.childenClassTitle.equipmentTitle1',
            name: [
                fristTitleName + 'bathToiletSeparate',
                fristTitleName + 'bathToiletSame',
                fristTitleName + 'bathSame',
                fristTitleName + 'automaticBathShower',
                fristTitleName + 'showerRoom',
                fristTitleName + 'reheatingFunction',
                fristTitleName + 'highTemperatureDifferenceHotwaterType',
                fristTitleName + 'bathroomTv',
                fristTitleName + 'busAudio',
                fristTitleName + 'bathroomHeating',
                fristTitleName + 'bathroomDryer',
                fristTitleName + 'saunaMist',
                fristTitleName + 'sauna',
                fristTitleName + 'washToilet',
            ],
            value:[
                res.bathToiletSeparate,
                res.bathToiletSame,
                res.bathSame,
                res.automaticBathShower,
                res.showerRoom,
                res.reheatingFunction,
                res.highTemperatureDifferenceHotwaterType,
                res.bathroomTv,
                res.busAudio,
                res.bathroomHeating,
                res.bathroomDryer,
                res.saunaMist,
                res.sauna,
                res.washToilet,
            ],
            type:'checkboxList',
        },
        // 収納
        {
            title:'PropertyDetail.childenClassTitle.equipmentTitle2',
            name: [
                fristTitleName + 'storageSpace',
                fristTitleName + 'storageAllRoom',
                fristTitleName + 'walkinCloset',
                fristTitleName + 'automaticBathShower',
                fristTitleName + 'shoesBox',
                fristTitleName + 'shoesIc',
                fristTitleName + 'underfloorStorage',
                fristTitleName + 'closet',
            ],
            value:[
                res.storageSpace,
                res.storageAllRoom,
                res.walkinCloset,
                res.automaticBathShower,
                res.shoesBox,
                res.shoesIc,
                res.underfloorStorage,
                res.closet,

            ],
            type:'checkboxList',
        },
        // 設備・機能
        {
            title:'PropertyDetail.childenClassTitle.equipmentTitle3',
            name: [
                fristTitleName + 'indoorAundryRoom',
                fristTitleName + 'laundryPlace',
                fristTitleName + 'indoorClothesline',
                fristTitleName + 'hotWaterSupply',
                fristTitleName + 'energySavingWaterHeater',
                fristTitleName + 'cityGas',
                fristTitleName + 'propaneGas',
                fristTitleName + 'floto',
            ],
            value:[
                res.indoorAundryRoom,
                res.laundryPlace,
                res.indoorClothesline,
                res.hotWaterSupply,
                res.energySavingWaterHeater,
                res.cityGas,
                res.propaneGas,
                res.floto,

            ],
            type:'checkboxList',
        },
        // TV・通信
        {
            title:'PropertyDetail.childenClassTitle.equipmentTitle4',
            name: [
                fristTitleName + 'cableBroadcastCs',
                fristTitleName + 'bsTerminal',
                fristTitleName + 'catv',
                fristTitleName + 'mmOutletInternetCompatible',
                fristTitleName + 'freeInternetUsage',
            ],
            value:[
                res.cableBroadcastCs,
                res.bsTerminal,
                res.catv,
                res.mmOutletInternetCompatible,
                res.freeInternetUsage,

            ],
            type:'checkboxList',
        },
        // バルコニー・庭
        {
            title:'PropertyDetail.childenClassTitle.equipmentTitle5',
            name: [
                fristTitleName + 'balcony',
                fristTitleName + 'twoSideBalcony',
                fristTitleName + 'threeSideBalcony',
                fristTitleName + 'wideBalcony',
                fristTitleName + 'roofBalcony',
                fristTitleName + 'woodDeckTerrace',
                fristTitleName + 'garden',
                fristTitleName + 'gardenOver10',
                fristTitleName + 'privateGarden',
                fristTitleName + 'sunroomGreenSide',
            ],
            value:[
                res.balcony,
                res.twoSideBalcony,
                res.threeSideBalcony,
                res.wideBalcony,
                res.roofBalcony,
                res.woodDeckTerrace,
                res.garden,
                res.gardenOver10,
                res.privateGarden,
                res.sunroomGreenSide,

            ],
            type:'checkboxList',
        },
        // キッチン
        {
            title:'PropertyDetail.childenClassTitle.equipmentTitle6',
            name: [
                fristTitleName + 'withGasStove',
                fristTitleName + 'gasStovePossible',
                fristTitleName + 'electricStove',
                fristTitleName + 'burnerStove2',
                fristTitleName + 'burnerStove3',
                fristTitleName + 'ihCookingHeater',
                fristTitleName + 'systemKitchen',
                fristTitleName + 'grill',
            ],
            value:[
                res.withGasStove,
                res.gasStovePossible,
                res.electricStove,
                res.burnerStove2,
                res.burnerStove3,
                res.ihCookingHeater,
                res.systemKitchen,
                res.grill,

            ],
            type:'checkboxList',
        },
        // 家具・家電・照明
        {
            title:'PropertyDetail.childenClassTitle.equipmentTitle7',
            name: [
                fristTitleName + 'phone',
                fristTitleName + 'bed',
                fristTitleName + 'refrigerator',
                fristTitleName + 'washerDryerClothesDryer',
                fristTitleName + 'withLightingFixtures',
                fristTitleName + 'withHomeAppliances',
                fristTitleName + 'indirectLighting',
                fristTitleName + 'lightingWithMotionSensor',
            ],
            value:[
                res.phone,
                res.bed,
                res.refrigerator,
                res.washerDryerClothesDryer,
                res.withLightingFixtures,
                res.withHomeAppliances,
                res.indirectLighting,
                res.lightingWithMotionSensor,

            ],
            type:'checkboxList',
        },
        // 冷暖房
        {
            title:'PropertyDetail.childenClassTitle.equipmentTitle8',
            name: [
                fristTitleName + 'cooling',
                fristTitleName + 'heating',
                fristTitleName + 'floorHeating',
                fristTitleName + 'ffHeating',
                fristTitleName + 'airConditioner',
                fristTitleName + 'airConditionersTwo',
                fristTitleName + 'airConditionerBuiltin',
            ],
            value:[
                res.cooling,
                res.heating,
                res.floorHeating,
                res.ffHeating,
                res.airConditioner,
                res.airConditionersTwo,
                res.airConditionerBuiltin,
            ],
            type:'checkboxList',
        },
        // セキュリティー
        {
            title:'PropertyDetail.childenClassTitle.equipmentTitle9',
            name: [
                fristTitleName + 'doubleLockDoorAutoLock',
                fristTitleName + 'autoLockWithMonitor',
                fristTitleName + 'dimpleKey',
                fristTitleName + 'securityGlass',
                fristTitleName + 'shutter',
            ],
            value:[
                res.doubleLockDoorAutoLock,
                res.autoLockWithMonitor,
                res.dimpleKey,
                res.securityGlass,
                res.shutter,
            ],
            type:'checkboxList',
        },
        // 共用施設
        {
            title:'PropertyDetail.childenClassTitle.equipmentTitle10',
            name: [
                fristTitleName + 'elevator',
                fristTitleName + 'elevatorsTwoMore',
                fristTitleName + 'coinLaundry',
                fristTitleName + 'deliveryBox',
                fristTitleName + 'frontService',
                fristTitleName + 'cleaningService',
                fristTitleName + 'sharedRoomTheater',
                fristTitleName + 'sharedRoomGuest',
                fristTitleName + 'sharedRoomParty',
            ],
            value:[
                res.elevator,
                res.elevatorsTwoMore,
                res.coinLaundry,
                res.deliveryBox,
                res.frontService,
                res.cleaningService,
                res.sharedRoomTheater,
                res.sharedRoomGuest,
                res.sharedRoomParty,
            ],
            type:'checkboxList',
        },

     ]
    return featureData
}
// 特徴
export const propertyFeatureData = (res:any) => {
    const fristTitleName = 'PropertyDetail.featureData.'
    const featureData = [  
        // 特徴
        {
            title:'PropertyDetail.childenClassTitle.featureTitle1',
            name: [
                fristTitleName + 'allRoomsSidesLighting2',
                fristTitleName + 'sidesLighting2',
                fristTitleName + 'sidesLighting3',
                fristTitleName + 'exteriorTiled',
                fristTitleName + 'designers',
                fristTitleName + 'garbageTakenOutAnyTime',
                fristTitleName + 'shareHouse',
                fristTitleName + 'smartHouse',
                fristTitleName + 'roadHeating',
                fristTitleName + 'salesType',
                fristTitleName + 'barrierFree',
                fristTitleName + 'suitableTwoHouseholds',
                fristTitleName + 'cornerRoom',
                fristTitleName + 'topFloor',
                fristTitleName + 'allElectric',
                fristTitleName + 'twentyFourHourVentilationSystem',
                fristTitleName + 'doubleHeightSpaceAllocation',
                fristTitleName + 'propertyCorrespondingToItHeavyTheory',
            ],
            value:[
                res.allRoomsSidesLighting2,
                res.sidesLighting2,
                res.sidesLighting3,
                res.exteriorTiled,
                res.designers,
                res.garbageTakenOutAnyTime,
                res.shareHouse,
                res.smartHouse,
                res.roadHeating,
                res.salesType,
                res.barrierFree,
                res.suitableTwoHouseholds,
                res.cornerRoom,
                res.topFloor,
                res.allElectric,
                res.twentyFourHourVentilationSystem,
                res.doubleHeightSpaceAllocation,
                res.propertyCorrespondingToItHeavyTheory,

            ],
            type:'checkboxList',
        },
        // 工法・構造
        {
            title:'PropertyDetail.childenClassTitle.featureTitle2',
            name: [
                fristTitleName + 'externalInsulationMethod',
                fristTitleName + 'highlyAirtightHighlyInsulatedHouse',
                fristTitleName + 'constructionMethod24',
                fristTitleName + 'constructionMethod26',
                fristTitleName + 'outFrameMethod',
                fristTitleName + 'reverseBeamConstructionMethod',
                fristTitleName + 'skeletonInfill',
                fristTitleName + 'highStrengthConcrete',
                fristTitleName + 'nonFormaldehyde',
                fristTitleName + 'exteriorWallConcrete',
                fristTitleName + 'interiorConcrete',
                fristTitleName + 'exteriorWallSiding',
                fristTitleName + 'ceilingHeightMore250cm',
                fristTitleName + 'doubleFloor',
                fristTitleName + 'doubleFloorDoubleCeiling',
                fristTitleName + 'adoptionOfHighSash',
                fristTitleName + 'dampingStructure',
                fristTitleName + 'seismicIsolationStructure',
            ],
            value:[
                res.externalInsulationMethod,
                res.highlyAirtightHighlyInsulatedHouse,
                res.constructionMethod24,
                res.constructionMethod26,
                res.outFrameMethod,
                res.reverseBeamConstructionMethod,
                res.skeletonInfill,
                res.highStrengthConcrete,
                res.nonFormaldehyde,
                res.exteriorWallConcrete,
                res.interiorConcrete,
                res.exteriorWallSiding,
                res.ceilingHeightMore250cm,
                res.doubleFloor,
                res.doubleFloorDoubleCeiling,
                res.adoptionOfHighSash,
                res.dampingStructure,
                res.seismicIsolationStructure,

            ],
            type:'checkboxList',
        },
        // 評価・証明書
        {
            title:'PropertyDetail.childenClassTitle.featureTitle3',
            name: [
                fristTitleName + 'seismicComplianceCertificate',
                fristTitleName + 'belsEnergySavingStandardComplianceCertification',
            ],
            value:[
                res.seismicComplianceCertificate,
                res.belsEnergySavingStandardComplianceCertification,

            ],
            type:'checkboxList',
        },
    ]
    return featureData
}
