<template>
    <v-card>
        <v-snackbar
        class="errorSnackbar"
        position="absolute"
        v-model="data.errorSnackbar"
        absolute
        top
        >
        {{ data.errorText }}
        </v-snackbar>
        <v-layout>
            <the-header @chooselanguage='changeLanguageType'></the-header>
            <v-navigation-drawer color="surface" width="180">
            </v-navigation-drawer>
            <v-navigation-drawer color="surface" location="right" width="180">
            </v-navigation-drawer>
            <v-main>
                <v-container class="align-center">
                    <v-row>
                        <v-col cols="12" class="ma-3 pa-3 mt-4">
                            <p class="text-h4"> {{ $t('SellingLendingAssessment.title') }}</p>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="bg-grey-lighten-1" v-for="(item, index) in data.processTitle" :key="index" cols="12"
                            sm="4">
                            <v-sheet v-if="item.isActive" class="ma-2 pa-2 text-h6 bg-success">
                                {{ item.title }}
                            </v-sheet>
                            <v-sheet v-else class="ma-2 pa-2 text-h6 bg-grey-lighten-3">
                                {{ item.title }}
                            </v-sheet>
                        </v-col>
                    </v-row>
                    <!-- 入力画面 -->
                    <v-container class="align-center" v-if="isEdit">
                        <v-row>
                            <v-col cols="12" class="ma-3 pa-3">
                                <p class="text-h4">{{ $t('SellingLendingAssessment.way.title') }}</p>
                            </v-col>
                        </v-row>
                        <v-form ref="formRef">
                            <v-row no-gutters>  
                                    <v-col cols="12">
                                        <v-table class="table-border sellingTable">
                                            <tbody>
                                                <tr>
                                                    <td class="bg-teal-lighten-3" style="width: 30%;">
                                                        {{ $t('SellingLendingAssessment.way.title') }}</td>
                                                    <v-radio-group inline v-model="data.sellingLendingAssessmentType">
                                                        <v-radio :label="$t('SellingLendingAssessment.way.firstWay')"
                                                            :value="1"></v-radio>
                                                        <v-radio :label="$t('SellingLendingAssessment.way.secondWay')"
                                                            :value="2"></v-radio>
                                                    </v-radio-group>
                                                </tr>
                                                <tr>
                                                    <td class="bg-teal-lighten-3" style="width: 30%;">
                                                        {{ $t('SellingLendingAssessment.propertyInfo.title') }}</td>
                                                    <v-radio-group v-model="data.selectedPropertyType">
                                                        <v-radio :label="$t('SellingLendingAssessment.propertyInfo.mansion')"
                                                            value="0"></v-radio>
                                                        <v-radio
                                                            :label="$t('SellingLendingAssessment.propertyInfo.individualHouse')"
                                                            value="1"></v-radio>
                                                        <v-radio :label="$t('SellingLendingAssessment.propertyInfo.land')"
                                                            value="2"></v-radio>
                                                        <v-radio :label="$t('SellingLendingAssessment.propertyInfo.others')"
                                                            value="3"></v-radio>
                                                    </v-radio-group>
                                                </tr>
                                                <!-- 建物名 -->
                                                <!-- マンション 必須入力 -->
                                                <tr v-if="data.selectedPropertyType == '0'">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.buildingName') }} {{$t('SellingLendingAssessment.required') }}
                                                    </td>
                                                    <td>
                                                        <v-text-field v-model="data.entrustedProperty.buildingName" class="mt-4"
                                                            :label="$t('SellingLendingAssessment.propertyInfo.buildingName')"
                                                            :rules="rules.buildingName"
                                                            required></v-text-field>
                                                    </td>
                                                </tr>
                                                <!-- その他 非必須入力 -->
                                                <tr v-if="data.selectedPropertyType == '3'">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.buildingName') }}
                                                    </td>
                                                    <td>
                                                        <v-text-field v-model="data.entrustedProperty.buildingName" class="mt-4"
                                                            :label="$t('SellingLendingAssessment.propertyInfo.buildingName')"
                                                            ></v-text-field>
                                                    </td>
                                                </tr>
                                                <!-- 物件所在地 必須入力 -->
                                                <tr>
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.buildingAddress') }} {{$t('SellingLendingAssessment.required') }}</td>
                                                    <td>
                                                        <v-row style="display: flex; justify-content: center; align-items: center;">
                                                            <v-col cols="7">
                                                                <v-text-field v-model="data.postcode" class="mt-4"
                                                                :label="$t('PostcodeMessage.value')"
                                                                :rules="rules.postcode"
                                                                required>
                                                                </v-text-field>
                                                            </v-col>
                                                            <v-col>
                                                                <v-btn @click="getAddress">{{$t('PostcodeMessage.confirmed')}}</v-btn>
                                                            </v-col>
                                                        </v-row>
                                                        <v-text-field v-model="data.entrustedProperty.buildingAddress" class="mt-4"
                                                            :label="$t('SellingLendingAssessment.propertyInfo.buildingAddress')"
                                                            :rules="rules.buildingAddress"
                                                            required></v-text-field>
                                                    </td>
                                                </tr>
                                                <!-- 部屋番号 -->
                                                <!-- マンション 必須入力 -->
                                                <tr v-if="data.selectedPropertyType == '0'">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.roomNumber') }} {{$t('SellingLendingAssessment.required') }}</td>
                                                    <td>
                                                        <v-text-field v-model="data.entrustedProperty.roomNumber" class="mt-4"
                                                            
                                                            :label="$t('SellingLendingAssessment.propertyInfo.roomNumber')"
                                                            :rules="rules.roomNumber"
                                                            required></v-text-field>
                                                    </td>
                                                </tr>
                                                <!-- その他 非必須入力 -->
                                                <tr v-if="data.selectedPropertyType == '3'">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.roomNumber') }}</td>
                                                    <td>
                                                        <v-text-field v-model="data.entrustedProperty.roomNumber" class="mt-4"
                                                            
                                                            :label="$t('SellingLendingAssessment.propertyInfo.roomNumber')"
                                                           ></v-text-field>
                                                    </td>
                                                </tr>
                                                <!-- 建物構造 -->
                                                <!-- マンション 一戸建て 必須入力 -->
                                                <tr v-if="data.selectedPropertyType == '0' || data.selectedPropertyType == '1'">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.buildingStucture.title') }} {{$t('SellingLendingAssessment.required') }}</td>
                                                    <td>
                                                        <v-select :items="data.structureOptions" class="mt-4"
                                                            :error="data.buildingStuctureError"
                                                            :error-messages="data.buildingStuctureErrorMessage"
                                                            :label="$t('SellingLendingAssessment.buildingStucture.title')"
                                                            @update:modelValue="buildingStuctureSelected"
                                                            v-model="selectedStructureOptionValue"></v-select>
                                                            
                                                    </td>
                                                </tr>
                                                <!-- その他 非必須入力 -->
                                                <tr v-if="data.selectedPropertyType == '3'">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.buildingStucture.title') }}</td>
                                                    <td>
                                                        <v-select :items="data.structureOptions" class="mt-4"
                                                            :label="$t('SellingLendingAssessment.buildingStucture.title')"
                                                            v-model="selectedStructureOptionValue"></v-select>
                                                    </td>
                                                </tr>
                                                <!-- 築年月 -->
                                                <!-- マンション 一戸建て 必須入力 -->
                                                <tr v-if="data.selectedPropertyType == '1'">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.constructionDate') }} {{$t('SellingLendingAssessment.required') }}</td>
                                                    <td>
                                                        <v-text-field v-model="data.entrustedProperty.constructionDate" class="mt-4"                                                           
                                                            :label="$t('SellingLendingAssessment.propertyInfo.constructionDate')"
                                                            :rules="rules.constructionDate"
                                                            placeholder="2023/10"
                                                            required></v-text-field>
                                                    </td>
                                                </tr>
                                                <!-- その他 非必須入力 -->
                                                <tr v-if="(data.sellingLendingAssessmentType == 2 && data.selectedPropertyType == '0') || data.selectedPropertyType == '3'">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.constructionDate') }}</td>
                                                    <td>
                                                        <v-text-field v-model="data.entrustedProperty.constructionDate" class="mt-4"                                                            
                                                            :label="$t('SellingLendingAssessment.propertyInfo.constructionDate')"
                                                            :rules="rules.constructionDateUnnecessary"
                                                            placeholder="2023/10"
                                                            ></v-text-field>
                                                    </td>
                                                </tr>
                                                <!-- 間取り -->
                                                <!-- マンション 一戸建て 必須入力 -->
                                                <tr v-if="data.selectedPropertyType == '0' || data.selectedPropertyType == '1'">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.floorPlan') }} {{$t('SellingLendingAssessment.required') }}</td>
                                                    <td>
                                                        <v-row>
                                                            <v-col>
                                                                <v-select :items="floorPlanItems" class="mt-4"
                                                                    v-model="data.entrustedProperty.floorPlan"
                                                                    :error="data.floorPlanError"
                                                                    :error-messages="data.floorPlanErrorMessage"
                                                                    @update:modelValue="floorPlanSelected"
                                                                    :label="$t('SellingLendingAssessment.propertyInfo.floorPlan')"
                                                                ></v-select>
                                                            </v-col>
                                                        </v-row>
                                                    
                                                    </td>
                                                </tr>
                                                <!-- その他 非必須入力 -->
                                                <tr v-if="data.selectedPropertyType == '3'">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.floorPlan') }}</td>
                                                    <td>
                                                        <v-row>
                                                            <v-col>
                                                                <v-select :items="floorPlanItems" class="mt-4"
                                                                    v-model="data.entrustedProperty.floorPlan"
                                                                    :label="$t('SellingLendingAssessment.propertyInfo.floorPlan')"
                                                                ></v-select>
                                                            </v-col>
                                                        </v-row>
                                                    
                                                    </td>
                                                </tr>
                                                
                                                <!-- 土地権利 -->
                                                <!-- 売主（マンション 一戸建て 土地) 必須入力 -->
                                                <tr v-if="data.sellingLendingAssessmentType == 1 && data.selectedPropertyType != '3'">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.landOwnership') }} {{$t('SellingLendingAssessment.required') }}</td>
                                                    <td>
                                                        <v-select :items="contractPeriodItems" class="mt-4"
                                                            v-model="data.entrustedProperty.landOwnership"
                                                            :error="data.landOwnershipError"
                                                            :error-messages="data.landOwnershipErrorMessage"
                                                            @update:modelValue="landOwnershipSelected"
                                                            :label="$t('SellingLendingAssessment.propertyInfo.landOwnership')"
                                                            ></v-select>
                                                    </td>
                                                </tr>
                                                <!-- 売主（その他) 非必須入力 -->
                                                <tr v-if="data.sellingLendingAssessmentType == 1 && data.selectedPropertyType == '3'">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.landOwnership') }}</td>
                                                    <td>
                                                        <v-select :items="contractPeriodItems" class="mt-4"
                                                            v-model="data.entrustedProperty.landOwnership"
                                                            :label="$t('SellingLendingAssessment.propertyInfo.landOwnership')"
                                                            ></v-select>
                                                    </td>
                                                </tr>
                                                <!-- 建物面積 -->
                                                <!-- マンション 一戸建て 必須入力 -->
                                                <tr v-if="data.selectedPropertyType == '0' || data.selectedPropertyType == '1'">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.buildingArea') }} {{$t('SellingLendingAssessment.required') }}</td>
                                                    <td class="flex-align-center">
                                                        <v-row>
                                                            <v-col
                                                            cols="9" md="5"
                                                            style="display: flex; align-items: center;"
                                                            >
                                                                <v-text-field class="mt-4" type="text"
                                                                    v-model="data.entrustedProperty.buildingArea" :maxlength="10"
                                                                    :rules="rules.buildingArea"
                                                                    :placeholder="$t('SellingLendingAssessment.propertyInfo.buildingArea')"
                                                                    required>
                                                                </v-text-field>
                                                                <span>㎡</span>
                                                            </v-col>
                                                        </v-row>
                                                        
                                                    </td>
                                                </tr>
                                                <!-- その他 非必須入力 -->
                                                <tr v-if="data.selectedPropertyType == '3'">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.buildingArea') }}</td>
                                                    <td class="flex-align-center">
                                                        <v-row>
                                                            <v-col
                                                            cols="9" md="5"
                                                            style="display: flex; align-items: center;"
                                                            >
                                                                <v-text-field class="mt-4" type="text"
                                                                    v-model="data.entrustedProperty.buildingArea" :maxlength="10"
                                                                    :rules="rules.buildingAreaUnnecessary"
                                                                    :placeholder="$t('SellingLendingAssessment.propertyInfo.buildingArea')">
                                                                </v-text-field>
                                                                <span>㎡</span>
                                                            </v-col>
                                                        </v-row>
                                                        
                                                    </td>
                                                </tr>
                                                <!-- 土地面積 -->
                                                <!-- 土地 一戸建て 必須入力 -->
                                                <tr v-if="data.selectedPropertyType == '1' || data.selectedPropertyType == '2'">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.landArea') }} {{$t('SellingLendingAssessment.required') }}</td>
                                                    <td class="flex-align-center">
                                                        <v-row>
                                                            <v-col
                                                            cols="9" md="5"
                                                            style="display: flex; align-items: center;"
                                                            >
                                                                    <v-text-field class="mt-4" type="text"
                                                                    v-model="data.entrustedProperty.landArea" :maxlength="10"
                                                                    :rules="rules.landArea"
                                                                    :placeholder="$t('SellingLendingAssessment.propertyInfo.landArea')"
                                                                    required>
                                                                    </v-text-field>
                                                                    <span>㎡</span>
                                                            </v-col>
                                                        </v-row>
                                                      
                                                    </td>
                                                </tr>
                                                <!-- マンション その他 非必須入力 -->
                                                <tr v-if="data.selectedPropertyType == '0' || data.selectedPropertyType == '3'">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.landArea') }}</td>
                                                    <td class="flex-align-center">
                                                        <v-row>
                                                            <v-col
                                                            cols="9" md="5"
                                                            style="display: flex; align-items: center;"
                                                            >
                                                                    <v-text-field class="mt-4" type="text"
                                                                    v-model="data.entrustedProperty.landArea" :maxlength="10"
                                                                    :rules="rules.landAreaUnnecessary"
                                                                    :placeholder="$t('SellingLendingAssessment.propertyInfo.landArea')">
                                                                    </v-text-field>
                                                                    <span>㎡</span>
                                                            </v-col>
                                                        </v-row>
                                                      
                                                    </td>
                                                </tr>
                                                <!-- 価格 -->
                                                <!-- 売主（マンション 一戸建て 土地) 必須入力 -->
                                                <tr v-if="data.sellingLendingAssessmentType == 1 && data.selectedPropertyType != '3'">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.price') }} {{$t('SellingLendingAssessment.required') }}</td>
                                                    <td class="flex-align-center">
                                                        <v-row>
                                                            <v-col
                                                            cols="9" md="5"
                                                            style="display: flex; align-items: center;"
                                                            >
                                                                    <v-text-field class="mt-4" type="text"
                                                                    v-model="data.entrustedProperty.price" :maxlength="10"
                                                                    :rules="rules.price"
                                                                    :placeholder="$t('SellingLendingAssessment.propertyInfo.price')"
                                                                    required>
                                                                </v-text-field>
                                                                <span>万円</span>
                                                            </v-col>
                                                        </v-row>
                                                       
                                                    </td>
                                                </tr>
                                                <!-- 売主（その他) 非必須入力 -->
                                                <tr v-if="data.sellingLendingAssessmentType == 1 && data.selectedPropertyType == '3'">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.price') }}</td>
                                                    <td class="flex-align-center">
                                                        <v-row>
                                                            <v-col
                                                            cols="9" md="5"
                                                            style="display: flex; align-items: center;"
                                                            >
                                                                    <v-text-field class="mt-4" type="text"
                                                                    v-model="data.entrustedProperty.price" :maxlength="10"
                                                                    :rules="rules.priceUnnecessary"
                                                                    :placeholder="$t('SellingLendingAssessment.propertyInfo.price')">
                                                                </v-text-field>
                                                                <span>万円</span>
                                                            </v-col>
                                                        </v-row>
                                                       
                                                    </td>
                                                </tr>
                                                <!-- 管理費 -->
                                                <!-- マンション 必須入力 -->
                                                <tr v-if="data.selectedPropertyType == '0'">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.managementFee') }} {{$t('SellingLendingAssessment.required') }}</td>
                                                    <td class="flex-align-center">
                                                        <v-row>
                                                            <v-col
                                                            cols="9" md="5"
                                                            style="display: flex; align-items: center;"
                                                            >
                                                                <v-text-field class="mt-4" type="text"
                                                                v-model="data.entrustedProperty.managementFee" :maxlength="10"
                                                                :rules="rules.managementFee"
                                                                :placeholder="$t('SellingLendingAssessment.propertyInfo.managementFee')"
                                                                required>
                                                                </v-text-field>
                                                                <span>万円</span>
                                                            </v-col>
                                                        </v-row>
                                                    </td>
                                                </tr>
                                                <!-- 一戸建て その他 非必須入力 -->
                                                <tr v-if="data.selectedPropertyType == '1' || data.selectedPropertyType == '3'">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.managementFee') }}</td>
                                                    <td class="flex-align-center">
                                                        <v-row>
                                                            <v-col
                                                            cols="9" md="5"
                                                            style="display: flex; align-items: center;"
                                                            >
                                                                <v-text-field class="mt-4" type="text"
                                                                v-model="data.entrustedProperty.managementFee" :maxlength="10"
                                                                :rules="rules.managementFeeUnnecessary"
                                                                :placeholder="$t('SellingLendingAssessment.propertyInfo.managementFee')">
                                                                </v-text-field>
                                                                <span>万円</span>
                                                            </v-col>
                                                        </v-row>
                                                    </td>
                                                </tr>
                                                <!-- 積立金 -->
                                                <!-- 売主(マンション) 必須入力 -->
                                                <tr v-if="data.sellingLendingAssessmentType == 1 && data.selectedPropertyType == '0'">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.reserveFund') }} {{$t('SellingLendingAssessment.required') }}</td>
                                                    <td class="flex-align-center">
                                                        <v-row>
                                                            <v-col
                                                            cols="9" md="5"
                                                            style="display: flex; align-items: center;"
                                                            >
                                                                <v-text-field class="mt-4" type="text"
                                                                v-model="data.entrustedProperty.reserveFund" :maxlength="10"
                                                                :rules="rules.reserveFund"
                                                                :placeholder="$t('SellingLendingAssessment.propertyInfo.reserveFund')"
                                                                required>
                                                                </v-text-field>
                                                                <span>万円</span>
                                                            </v-col>
                                                        </v-row>   
                                                    </td>
                                                </tr>
                                                <!-- 売主(その他) 非必須入力 -->
                                                <tr v-if="data.sellingLendingAssessmentType == 1 && data.selectedPropertyType == '3'">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.reserveFund') }}</td>
                                                    <td class="flex-align-center">
                                                        <v-row>
                                                            <v-col
                                                            cols="9" md="5"
                                                            style="display: flex; align-items: center;"
                                                            >
                                                                <v-text-field class="mt-4" type="text"
                                                                v-model="data.entrustedProperty.reserveFund" :maxlength="10"
                                                                :rules="rules.reserveFundUnnecessary"
                                                                :placeholder="$t('SellingLendingAssessment.propertyInfo.reserveFund')">
                                                                </v-text-field>
                                                                <span>万円</span>
                                                            </v-col>
                                                        </v-row>   
                                                    </td>
                                                </tr>
                                                <!-- 保証金 -->
                                                <tr v-if="data.sellingLendingAssessmentType === 2">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.guaranteeDeposit') }}</td>
                                                    <td class="flex-align-center">
                                                        <v-row>
                                                            <v-col
                                                            cols="9" md="5"
                                                            style="display: flex; align-items: center;"
                                                            >
                                                                    <v-text-field class="mt-4" type="text"
                                                                    v-model="data.entrustedProperty.guaranteeDeposit" :maxlength="10"
                                                                    :rules="rules.guaranteeDeposit"
                                                                    :placeholder="$t('SellingLendingAssessment.propertyInfo.guaranteeDeposit')">
                                                                </v-text-field>
                                                                <span>万円</span>
                                                            </v-col>
                                                        </v-row>  
                                                    </td>
                                                </tr>
                                                <!-- 礼金 -->
                                                <tr v-if="data.sellingLendingAssessmentType === 2">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.keyMoney') }}</td>
                                                    <td class="flex-align-center">
                                                        <v-row>
                                                            <v-col
                                                            cols="9" md="5"
                                                            style="display: flex; align-items: center;"
                                                            >
                                                                    <v-text-field class="mt-4" type="text"
                                                                    v-model="data.entrustedProperty.keyMoney" :maxlength="10"
                                                                    :rules="rules.keyMoney"
                                                                    :placeholder="$t('SellingLendingAssessment.propertyInfo.keyMoney')">
                                                                    </v-text-field>
                                                                    <span>万円</span>
                                                            </v-col>
                                                        </v-row>  
                                                    </td>
                                                </tr>
                                                <!-- 敷金 -->
                                                <tr v-if="data.sellingLendingAssessmentType === 2">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.securityDeposit') }}</td>
                                                    <td class="flex-align-center">
                                                        <v-row>
                                                            <v-col
                                                            cols="9" md="5"
                                                            style="display: flex; align-items: center;"
                                                            >
                                                                <v-text-field class="mt-4" type="text"
                                                                v-model="data.entrustedProperty.securityDeposit" :maxlength="10"
                                                                :rules="rules.securityDeposit"
                                                                :placeholder="$t('SellingLendingAssessment.propertyInfo.securityDeposit')">
                                                                </v-text-field>
                                                                <span>万円</span>
                                                            </v-col>
                                                        </v-row>  
                                                    </td>
                                                </tr>
                                                <tr v-if="data.sellingLendingAssessmentType == 2">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.contractPeriod') }}</td>
                                                    <td class="flex-align-center">
                                                        <v-text-field v-model="data.entrustedProperty.contractPeriod" class="mt-4"
                                                            :rules="rules.contractPeriod"
                                                            :label="$t('SellingLendingAssessment.propertyInfo.contractPeriod')"
                                                            required></v-text-field>
                                                    </td>
                                                </tr>
                                                <!-- 賃料 -->
                                                <tr v-if="data.sellingLendingAssessmentType === 2">
                                                    <td class="bg-teal-lighten-3">{{
                                                        $t('SellingLendingAssessment.propertyInfo.rentDeposit') }} {{$t('SellingLendingAssessment.required') }}</td>
                                                    <td class="flex-align-center">
                                                        <v-row>
                                                            <v-col
                                                            cols="9" md="5"
                                                            style="display: flex; align-items: center;"
                                                            >
                                                                <v-text-field class="mt-4" type="text"
                                                                v-model="data.entrustedProperty.rentDeposit" :maxlength="10"
                                                                :rules="rules.rentDeposit"
                                                                :placeholder="$t('SellingLendingAssessment.propertyInfo.rentDeposit')"
                                                                required>
                                                                </v-text-field>
                                                                <span>万円</span>
                                                            </v-col>
                                                        </v-row>  
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </v-table>
                                    </v-col>
                            </v-row>
                        </v-form>
                        <v-form ref="formCustom">
                            <v-row no-gutters>
                                <v-col cols="12" class="ma-3 pa-3">
                                    <p class="text-h4">{{ $t('SellingLendingAssessment.customerInfo.title') }}</p>
                                </v-col>
                                <v-col cols="12">
                                    <v-table class="table-border">
                                        <tbody>
                                            <tr>
                                                <td class="bg-teal-lighten-3" style="width: 30%;">
                                                    {{ $t('SellingLendingAssessment.customerInfo.ownerName') }} {{$t('SellingLendingAssessment.required') }}</td>
                                                <td>
                                                    <v-text-field v-model="data.entrustedProperty.ownerName" class="mt-4"
                                                       
                                                        :rules="customRules.name"
                                                        :label="$t('SellingLendingAssessment.customerInfo.ownerName')"
                                                        required></v-text-field>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="bg-teal-lighten-3" style="width: 30%;">
                                                    {{ $t('SellingLendingAssessment.customerInfo.ownerPhone') }} {{$t('SellingLendingAssessment.required') }}</td>
                                                <td>
                                                    <v-text-field v-model="data.entrustedProperty.ownerPhone" class="mt-4"
                                                      
                                                        :rules="customRules.phone"
                                                        :label="$t('SellingLendingAssessment.customerInfo.ownerPhone')"
                                                        required></v-text-field>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="bg-teal-lighten-3" style="width: 30%;">
                                                    {{ $t('SellingLendingAssessment.customerInfo.ownerNote') }}</td>
                                                <td>
                                                    <v-text-field v-model="data.entrustedProperty.ownerNote" class="mt-4"
                                                        :label="$t('SellingLendingAssessment.customerInfo.ownerNote')"></v-text-field>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </v-table>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                    <!-- 不動産業者選択画面 -->
                    <v-row no-gutters v-if="isSelect">
                        <v-col cols="12" class="ma-3 pa-3">
                            <p class="text-h4">{{ $t('SellingLendingAssessment.selectEstateAgent.title') }}</p>
                        </v-col>
                        <v-col cols="12">
                            <v-table class="table-border">
                                <tbody>
                                    <tr>
                                        <td class="bg-teal-lighten-3" style="width: 30%;">{{
                                            $t('SellingLendingAssessment.selectEstateAgent.estateAgent') }}</td>
                                        <v-container fluid>
                                            <v-checkbox
                                            v-model="data.checkList"
                                            v-for="item in data.companyList"
                                            :key="item.companyId"
                                            :value="item.companyId"
                                            >
                                            <template v-slot:label>
                                                <v-tooltip location="bottom">
                                                    <template v-slot:activator="{ props }">
                                                        <span class="downFileCompany" 
                                                            v-bind="props"
                                                            @click="ToShowCompany(item.companyId)"       
                                                        >
                                                            {{item.companyName}}
                                                        </span>
                                                    </template>
                                                    Opens in new window
                                                </v-tooltip>
                                            </template>
                                        </v-checkbox>
                                        </v-container>
                                    </tr>
                                </tbody>
                            </v-table>
                            <v-btn size="large" class="mt-4" v-on:click="toEdit">{{
                                $t('SellingLendingAssessment.buttonDetailTitle.input') }}</v-btn>
                        </v-col>
                    </v-row>
                    <!-- 完了画面 -->
                    <v-row no-gutters v-if="isComplete">
                        <v-col cols="12" class="text-center mt-8 ">
                            <div class="text-h4">{{ $t('SellingLendingAssessment.lastTitle') }}</div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12 mt-4 text-center">
                            <v-btn v-if="isEdit" size="large" class="" v-on:click="select">{{
                                $t('SellingLendingAssessment.buttonDetailTitle.selectEstateAgent') }}</v-btn>
                            <v-btn v-if="isSelect" :disabled="data.checkList.length < 1" size="large" class="" v-on:click="send">{{
                                $t('SellingLendingAssessment.buttonDetailTitle.send') }}</v-btn>
                            <v-btn v-if="isComplete" size="large" class="" v-on:click="toSellingLendingList">{{
                                $t('SellingLendingAssessment.buttonDetailTitle.backSellingLendingList') }}</v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="mt-10">
                        <the-footer></the-footer>
                    </v-row>
                </v-container>
            </v-main>
        </v-layout>
    </v-card>
</template>
<style>
.table-border {
    border: 1px;
    border-style: solid;
    border-color: antiquewhite;
}
</style>
<script lang="ts">
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import { defineComponent, reactive, computed, getCurrentInstance, onMounted, defineProps, ref, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
// import axios from 'axios'
import { SellingLending } from './../api/sys/model/model'
import i18n from '../language/index'
import api from '@/api/axios/axios'
import axios from 'axios'
export default defineComponent({
    name: 'SellingLendingInput',
    components: {
        TheHeader,
        TheFooter,
    },
});
</script>
  
<script lang="ts" setup>
const props = defineProps({
    propertyInfo: Object,
})
const data = reactive({
    errorSnackbar:false,
    errorText:'',
    postcode:'',
    fromViewName:'' as any,
    selectedPropertyType:"0",
    floorPlanNumber:null,
    floorPlanUtil:null,
    checkList:[],
    entrustedProperty: {} as SellingLending,
    sellingLendingAssessmentType: 1,
    languageType: 'jp' as string,
    buildingStuctureError:false,
    buildingStuctureErrorMessage:'',
    floorPlanError:false,
    floorPlanErrorMessage:'',
    landOwnershipError:false,
    landOwnershipErrorMessage:'',
    companyList: [
        {
            "access": "string",
            "businessHours": "string",
            "companyCode": "string",
            "companyId": "string",
            "companyName": "string",
            "phone": "string",
            "planId": "string",
            "regularHoliday": "string",
            "rspRstFlag": true,
            "status": 0
        }
    ],
    processTitle: [
        { title: i18n.global.messages[i18n.global.locale].SellingLendingAssessment.navTitleFrist, isActive: true },
        { title: i18n.global.messages[i18n.global.locale].SellingLendingAssessment.navTitleSecond, isActive: false },
        { title: i18n.global.messages[i18n.global.locale].SellingLendingAssessment.navTitleThirdly, isActive: false }],
    structureOptions: [" ","木造", "ブロック造", "鉄骨造", "RC", "SRC", "PC", "HPC", "ALC", "軽量鉄骨造", "その他"],
   
});
let  floorPlanItems =[" ",'ワンルーム','1K','1DK','1LDK','2K','2DK','2LDK','3K',' 3DK','3LDK','4K','4DK','4DK以上']
let contractPeriodItems = [" ",'所有権','旧法地上権','旧法賃借権','普通地上権','普通賃借権','定期借地権（地上権）','定期借地権（賃借権）']
const companyIdList = ref<string[]>([]);
const formRef = ref(null);
const formCustom = ref(null);
const getCompanyIds = () => {
    companyIdList.value = data.companyList.map(company => company.companyId);
};
const rules = {
    buildingName: [
        (value:string) => !!value || '建物名を入力してください。',
    ],
    buildingAddress: [
        (value:string) => !!value || '物件所在地を入力してください。',
    ],
    roomNumber: [
        (value:string) => !!value || '部屋番号を入力してください。',
    ],
    structureOptions: [
        (value:string) => !!value || '建物構造を入力してください。',
    ],
    constructionDate: [
        (value:string) => !!value || '築年月を入力してください。',
        (value: string) => /^\d{4}\/(0[1-9]|1[0-2])$/.test(value) || '有効な日付（yyyy/mm）を入力してください。',
    ],
    constructionDateUnnecessary: [(value: string) => {
            if (!value) {
                return true; // 允许为空
            }
            return /^\d{4}\/(0[1-9]|1[0-2])$/.test(value) || '有効な日付（yyyy/mm）を入力してください。';
        },
    ],
    
    floorPlan: [
        (value:string) => !!value || '間取りを入力してください。',
    ],
    landOwnership: [
        (value:string) => !!value || '土地権利を入力してください。',
    ],
    buildingArea: [
        (value:string) => !!value || '建物面積を入力してください。',
        (value: string) => /^(?=.*\d)(?!0{2,}\.)(?:\d+\.?\d{0,2}|\.\d{1,2})$/.test(value) || '有効な建物面積を小数点以下2桁まで含めて入力してください。',
    ],
    buildingAreaUnnecessary: [(value: string) => {
            if (!value) {
                return true; // 允许为空
            }
            return /^(?=.*\d)(?!0{2,}\.)(?:\d+\.?\d{0,2}|\.\d{1,2})$/.test(value) || '有効な建物面積を小数点以下2桁まで含めて入力してください。';
        },
    ],
    landArea: [
        (value:string) => !!value || '土地面積を入力してください。',
        (value: string) => /^(?=.*\d)(?!0{2,}\.)(?:\d+\.?\d{0,2}|\.\d{1,2})$/.test(value) || '有効な土地面積を小数点以下2桁まで含めて入力してください。',
    ],
    landAreaUnnecessary: [(value: string) => {
            if (!value) {
                return true; // 允许为空
            }
            return /^(?=.*\d)(?!0{2,}\.)(?:\d+\.?\d{0,2}|\.\d{1,2})$/.test(value) || '有効な土地面積を小数点以下2桁まで含めて入力してください。';
        },
    ], 
    price: [
        (value:string) => !!value || '価格を入力してください。',
        (value: string) => /^(?=.*\d)(?!0{2,}\.)(?:\d+\.?\d{0,2}|\.\d{1,2})$/.test(value) || '有効な価格を小数点以下2桁まで含めて入力してください。',
    ],
    priceUnnecessary: [(value: string) => {
            if (!value) {
                return true; // 允许为空
            }
            return /^(?=.*\d)(?!0{2,}\.)(?:\d+\.?\d{0,2}|\.\d{1,2})$/.test(value) || '有効な価格を小数点以下2桁まで含めて入力してください。';
        },
    ], 
    managementFee: [
        (value:string) => !!value || '管理費を入力してください。',
        (value: string) => /^(?=.*\d)(?!0{2,}\.)(?:\d+\.?\d{0,2}|\.\d{1,2})$/.test(value) || '有効な管理費を小数点以下2桁まで含めて入力してください。',
    ],
    managementFeeUnnecessary: [(value: string) => {
            if (!value) {
                return true; // 允许为空
            }
            return /^(?=.*\d)(?!0{2,}\.)(?:\d+\.?\d{0,2}|\.\d{1,2})$/.test(value) || '有効な管理費を小数点以下2桁まで含めて入力してください。';
        },
    ], 
    reserveFund: [
        (value:string) => !!value || '積立金を入力してください。',
        (value: string) => /^(?=.*\d)(?!0{2,}\.)(?:\d+\.?\d{0,2}|\.\d{1,2})$/.test(value) || '有効な積立金を小数点以下2桁まで含めて入力してください。',
    ],
    reserveFundUnnecessary: [(value: string) => {
            if (!value) {
                return true; // 允许为空
            }
            return /^(?=.*\d)(?!0{2,}\.)(?:\d+\.?\d{0,2}|\.\d{1,2})$/.test(value) || '有効な積立金を小数点以下2桁まで含めて入力してください。';
        },
    ],
    contractPeriod:[(value: string) => {
            if (!value) {
                return true; // 允许为空
            }
            return /^\d+$/.test(value) || '有効な整数を入力してください。';
        },
    ],
    guaranteeDeposit: [(value: string) => {
            if (!value) {
                return true; // 允许为空
            }
            return /^(?=.*\d)(?!0{2,}\.)(?:\d+\.?\d{0,2}|\.\d{1,2})$/.test(value) || '有効な保証金を小数点以下2桁まで含めて入力してください。';
        },
    ],
    keyMoney: [(value: string) => {
            if (!value) {
                return true; // 允许为空
            }
            return /^(?=.*\d)(?!0{2,}\.)(?:\d+\.?\d{0,2}|\.\d{1,2})$/.test(value) || '有効な礼金を小数点以下2桁まで含めて入力してください。';
        },
    ],
    securityDeposit: [(value: string) => {
            if (!value) {
                return true; // 允许为空
            }
            return /^(?=.*\d)(?!0{2,}\.)(?:\d+\.?\d{0,2}|\.\d{1,2})$/.test(value) || '有効な敷金を小数点以下2桁まで含めて入力してください。';
        },
    ],
    rentDeposit:[
        (value:string) => !!value || '賃料を入力してください。',
        (value: string) => /^(?=.*\d)(?!0{2,}\.)(?:\d+\.?\d{0,2}|\.\d{1,2})$/.test(value) || '有効な賃料を小数点以下2桁まで含めて入力してください。',
    ],
    postcode:[(value: string) => {
            if (!value) {
                return true; // 允许为空
            }
            return /^\d{3}-\d{4}$/.test(value) || '有効な郵便番号（例：123-4567）を入力してください。';
        },
    ],

};
const customRules = {
    name: [
        (value:string) => !!value || '名前を入力してください。',
    ],
    phone: [
        (value:string) => !!value || '電話番号を入力してください。',
        (value:string) =>/^[0-9]{7,14}$/.test(value) || '正しい電話番号をしてください。',
    ],
}
const selectedStructureOptionIndex = ref(-1)
const selectedStructureOptionValue = ref('')
selectedStructureOptionValue.value = null
watchEffect(() => {
    const optionValue = selectedStructureOptionValue.value;
    const newIndex = data.structureOptions.indexOf(optionValue);
    if (newIndex !== -1) {
        selectedStructureOptionIndex.value = newIndex;
    }
})

const buildingStuctureSelected = (value) => {

    if (data.buildingStuctureError == true) {
        data.buildingStuctureError = false;
        data.buildingStuctureErrorMessage = '';
    }
}

const floorPlanSelected = (value) => {

    if (data.floorPlanError == true) {
        data.floorPlanError = false;
        data.floorPlanErrorMessage = '';
    }
}

const landOwnershipSelected = (value) => {

    if (data.landOwnershipError == true) {
        data.landOwnershipError = false;
        data.landOwnershipErrorMessage = '';
    }
}

//手动校验 三个 select组件
const validateSelect = () => {

    // console.log("执行校验")

    //校验建物構造
    if(data.selectedPropertyType == '0' || data.selectedPropertyType == '1') {
        if(selectedStructureOptionValue.value == " " || selectedStructureOptionValue.value == undefined || selectedStructureOptionValue.value == "") {
            // console.log("执行校验1")
            data.buildingStuctureError = true;
            data.buildingStuctureErrorMessage = '建物構造を入力してください。';
        }
    }

    //校验間取り
    if(data.selectedPropertyType == '0' || data.selectedPropertyType == '1') {
        if(data.entrustedProperty.floorPlan == " " || data.entrustedProperty.floorPlan == undefined || data.entrustedProperty.floorPlan == "") {
            // console.log("执行校验2")
            data.floorPlanError = true;
            data.floorPlanErrorMessage = '間取りを入力してください。';
        }
    }

    //校验土地権利
    if(data.sellingLendingAssessmentType == 1 && data.selectedPropertyType != '3') {
        if(data.entrustedProperty.landOwnership == " " || data.entrustedProperty.landOwnership == undefined || data.entrustedProperty.landOwnership == "") {
            // console.log("执行校验3")
            data.landOwnershipError = true;
            data.landOwnershipErrorMessage = '土地権利りを入力してください。';
        }
    }

    if(data.buildingStuctureError || data.floorPlanError || data.landOwnershipError) {
        return false;
    }else{
        return true;
    }
};

const select = () => {
    
    formRef.value?.validate()
    .then((valid:string)=>{
        // console.log("valid.valid:", valid.valid);
        const validateSelectResult = validateSelect();
        if(valid.valid && validateSelectResult){
            console.log("success");
            formCustom.value?.validate()
            .then((valid:string)=>{
                if(valid.valid){
                    data.processTitle[0].isActive = false;
                    data.processTitle[1].isActive = true;
                    data.processTitle[2].isActive = false;
                    // 滚动到页面顶部
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            })
            
        }else{
            console.log("error");
            
        }
    })
   
}


const toEdit = () => {
    data.processTitle[0].isActive = true;
    data.processTitle[1].isActive = false;
    data.processTitle[2].isActive = false;
}

const send = () => {

    data.processTitle[0].isActive = false;
    data.processTitle[1].isActive = false;
    data.processTitle[2].isActive = true;
    // 送信
    if (data.sellingLendingAssessmentType === 1) {
        getCompanyIds();
        let getData = {
            buildingAddress: data.entrustedProperty.buildingAddress,
            buildingArea: data.entrustedProperty.buildingArea,
            buildingName: data.entrustedProperty.buildingName,
            buildingStructure: selectedStructureOptionIndex.value,
            companyIdList: data.checkList,
            constructionDate: data.entrustedProperty.constructionDate,
            controlNumber: '1',
            floorPlan:  data.entrustedProperty.floorPlan,
            landArea: data.entrustedProperty.landArea,
            landOwnership: data.entrustedProperty.landOwnership,
            managementFee: parseInt(data.entrustedProperty.managementFee),
            ownerName: data.entrustedProperty.ownerName,
            ownerNote: data.entrustedProperty.ownerNote,
            ownerPhone: data.entrustedProperty.ownerPhone,
            price: parseInt(data.entrustedProperty.price),
            propertyType: parseInt(data.selectedPropertyType),
            reserveFund: parseInt(data.entrustedProperty.reserveFund),
            roomNumber: data.entrustedProperty.roomNumber,
            sellerRequestStatus: 0
        }
        // １：物件を売りたい
        api.post(`/user/sellerRequest`,getData )
            .then((res) => {
                console.log("sellerRequest post成功")
            })
            .catch((error) => {
                console.log(error.message)
            })
    } else if (data.sellingLendingAssessmentType === 2) {
        getCompanyIds();
        // 2：物件を貸したい
        api.post(`/user/landlordRequest`, {
            buildingAddress: data.entrustedProperty.buildingAddress,
            buildingDate: data.entrustedProperty.constructionDate,
            buildingName: data.entrustedProperty.buildingName,
            buildingStructure: selectedStructureOptionIndex.value,
            companyIdList: data.checkList,
            contractPeriod: data.entrustedProperty.contractPeriod,
            controlNumber: '1',
            floorPlan: data.entrustedProperty.floorPlan,
            guaranteeDeposit: data.entrustedProperty.guaranteeDeposit,
            keyMoney: data.entrustedProperty.keyMoney,
            landArea: data.entrustedProperty.landArea,
            managementFee: parseInt(data.entrustedProperty.managementFee),
            ownerName: data.entrustedProperty.ownerName,
            ownerNote: data.entrustedProperty.ownerNote,
            ownerPhone: data.entrustedProperty.ownerPhone,
            propertyArea: data.entrustedProperty.buildingArea,
            propertyType: data.selectedPropertyType,
            rentDeposit: data.entrustedProperty.rentDeposit,
            roomNumber: data.entrustedProperty.roomNumber,
            securityDeposit: data.entrustedProperty.securityDeposit,
            sellerRequestStatus: 0,   
        })
            .then((res) => {
                console.log("landlordRequest post成功")
            })
            .catch((error) => {
                console.log(error.message)
            })
    }
}
const router = useRouter()
const route = useRoute()
const toSellingLendingList = () => {
    router.push('/sellingLendingList/')
}
const isEdit = computed(() => { return data.processTitle[0].isActive });
const isSelect = computed(() => { return data.processTitle[1].isActive });
const isComplete = computed(() => { return data.processTitle[2].isActive });
onMounted(() => {
    console.log(route.query.fromViewName);
    if(route.query.fromViewName){
        data.entrustedProperty = JSON.parse(localStorage.getItem("CLOUDPFM_CO__SELLECTDATA__")).entrustedProperty
        data.selectedPropertyType = JSON.parse(localStorage.getItem("CLOUDPFM_CO__SELLECTDATA__")).selectedPropertyType
        data.sellingLendingAssessmentType = JSON.parse(localStorage.getItem("CLOUDPFM_CO__SELLECTDATA__")).sellingLendingAssessmentType
        console.log(data.selectedPropertyType);
        data.processTitle[0].isActive = false;
        data.processTitle[1].isActive = true;
        data.processTitle[2].isActive = false;
    }
      
    
    getInitData()
});
const getInitData = () => {
    api.get( `/user/public/company`).
        then((res) => {
            data.companyList = res.data
        }).catch((error) => {
            console.log(error.message)
        })
};
const changeLanguageType = (val: 'jp' | 'en' | 'zh') => {
    data.languageType = val
    data.processTitle = [
        { title: i18n.global.messages[i18n.global.locale].SellingLendingAssessment.navTitleFrist, isActive: true },
        { title: i18n.global.messages[i18n.global.locale].SellingLendingAssessment.navTitleSecond, isActive: false },
        { title: i18n.global.messages[i18n.global.locale].SellingLendingAssessment.navTitleThirdly, isActive: false }]
}
const ToShowCompany = (companyId:string) => {
    let sellectData = {
        entrustedProperty:data.entrustedProperty,
        sellingLendingAssessmentType:data.sellingLendingAssessmentType,
        selectedPropertyType:data.selectedPropertyType
    }
    localStorage.setItem("CLOUDPFM_CO__SELLECTDATA__",JSON.stringify(sellectData))
    
    router.push(
        { 
            path: '/companyView', 
            query: { 
                companyId ,
                typeValue:"selectLendingView"
            } 
        }
    );
}
const getAddress = () => {

    const checkPostcode = /^\d{3}-\d{4}$/.test(data.postcode);

    if(!checkPostcode) {
        data.errorSnackbar = true
        data.errorText = '有効な郵便番号を入力してください'
        return;
    }

    console.log(data.postcode);
    axios.get(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${data.postcode}`)
    .then((res)=>{
        console.log(res.data.results);
        if(res.data.results){
            data.entrustedProperty.buildingAddress =  res.data.results[0].address1+" "+res.data.results[0].address2+" "+res.data.results[0].address3
        }else{
            // data.entrustedProperty.buildingAddress = ''
            data.errorSnackbar = true
            data.errorText = i18n.global.messages[i18n.global.locale].PostcodeMessage.errorText
        }
    })
    .catch((error)=>{
        console.log(error);
    })
}
</script>
<style>
    .sellingTable .v-table__wrapper>table{
        margin-bottom: 20px;
    }
</style>
  