<template>
    <v-card>
      <v-layout>
           <the-header
            @chooselanguage='changeLanguageType'
            ></the-header>
          <v-navigation-drawer color="surface" width="180">
          </v-navigation-drawer>
          <v-navigation-drawer color="surface" location="right" width="180">
          </v-navigation-drawer>
          <v-main>
          <v-container lass="align-center">
                <p class="InitialTitle">{{ $t('InitialCostMessage.title') }}</p>
                <div>
                    <v-row no-gutters>
                        <v-col
                            cols="12"
                            sm="6"
                            xs='12'
                            class="flexCol"
                        >   
                            <div class="bg-teal-lighten-3 tableTitle"><span>{{ $t('InitialCostMessage.rent') }}</span></div>
                            <div class="tableContext"><span>{{data.costData.commission}} {{data.costData.commission?'万円':''}} </span></div>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            xs='12'
                            class="flexCol"
                        >
                            <div class="bg-teal-lighten-3 tableTitle"><span>共益費 / 管理費</span></div>
                            <div class="tableContext"><span>{{data.costData.managementFee}} {{data.costData.managementFee?'万円':''}}</span></div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col
                            cols="12"
                            sm="6"
                            xs='12'
                            class="flexCol colButtonLine"
                        >   
                            <div class="bg-teal-lighten-3 tableTitle"><span>敷金 / 礼金</span></div>
                            <div class="tableContext"><span>{{data.costData.securityDeposit}}{{data.costData.securityDeposit?'万円':''}}   {{router.currentRoute.value.query.securityDepositMonth}}{{data.costData.securityDeposit?$t('unitMessage.month'):''}}</span></div>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            xs='12'
                            class="flexCol colButtonLine"
                        >
                            <div class="bg-teal-lighten-3 tableTitle"><span>保証金 / 敷引</span></div>
                            <div class="tableContext"><span>{{data.costData.keyMoney}}{{data.costData.securityDeposit?'万円':''}}  {{router.currentRoute.value.query.keyMoneyMonth}}{{data.costData.keyMoney?$t('unitMessage.month'):''}}</span></div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col
                            cols="12"
                            sm="6"
                            xs='12'
                            class="flexCol colButtonLine"
                        >   
                            <div class="bg-teal-lighten-3 tableTitle"><span>その他一時金</span></div>
                            <div class="tableContext"><span>{{Number(data.costData.keyMoney)+Number(data.costData.securityDeposit)+Number(data.costData.managementFee)+Number(data.costData.commission)}} 万円</span></div>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            xs='12'
                            class="flexCol colButtonLine"
                        >   
                            <div class="bg-teal-lighten-3 tableTitle"><span>{{ $t('InitialCostMessage.total') }}</span></div>
                            <div class="tableContext"><span>{{Number(data.costData.keyMoney)+Number(data.costData.securityDeposit)+Number(data.costData.managementFee)+Number(data.costData.commission)}} 万円</span></div>
                        </v-col>
                    </v-row>
                </div>
                <div class="ButtonBox">
                    <v-btn class="BtnStyle" @click="toGodetail">{{'<<'}}{{ $t('InitialCostMessage.BackDetail') }}</v-btn>
                    
                </div>
              <v-row class="mt-10">
                  <the-footer></the-footer>
              </v-row>
          </v-container>
          </v-main>
      </v-layout>
    </v-card>
</template>
  <script lang="ts">
  import { defineComponent,reactive, computed, getCurrentInstance, onMounted, defineProps } from 'vue'
  import api from '@/api/axios/axios'
  import TheFooter from './components/TheFooter.vue'
  import TheHeader from './components/TheHeader.vue'
  import { useRouter ,useRoute} from 'vue-router'
  
  export default defineComponent({
      name: 'InitialCostView',
      components: {
          TheHeader,
          TheFooter,
      },
  });
  </script>
  <script lang="ts" setup>
      const data = reactive({
        costData:{
            id:'',
            keyMoney:'',
            commission:'',
            securityDeposit:'',
            managementFee:''
        }

      })
    const router = useRouter()
    const route = useRoute()
    const toHome = () => {
        router.push("/index")
    }
    const toGoBack = () => {
        router.push("/detail")
    }
    onMounted(() => {
        console.log(router.currentRoute.value.query);

        api.get(`/user/public/property/${router.currentRoute.value.query.id}`)
        .then((res)=>{
          console.log(res);
        })
        .catch((err)=>{
          console.log(err);
        })
      
    })
    const toGodetail = () => {
      router.push(`/detail?id=${router.currentRoute.value.query.id}`)
    }
   
     
  </script>
  <style >
  .InitialTitle{
    font-size: 2em;
    margin-bottom: 0.5rem;
  }
  .flexCol{
    display: flex;
    height: 49px;
    justify-content: center;
    border-top: 1px solid #dee2e6;
  }
  .tableContext{
    padding: 10px;
    width: 70%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tableTitle{
    padding: 10px;
    text-align: center;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .colButtonLine{
    border-bottom: 1px solid #dee2e6;
  }
  .ButtonBox{
    width: 100%;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .BtnStyle{
    color: #28a745;
  }
  .BtnStyle:hover{
    background-color: #28a745;
    color: #fff;
  }
  </style>
