import axios from 'axios';
import config from '@/config/index';
import { Buffer } from 'buffer';
import router from '@/router';

console.log(config.apiUrl);

const api = axios.create({
    // baseURL:'http://localhost:8081', 
  baseURL: config.apiUrl, 
});

// 判断token是否过期
const GetTokenExp = (token:string | null) => {
    let expirationDate 
    if(token){
        // 令牌解析
        const tokenParts = token.split('.');
        const payload = JSON.parse(Buffer.from(tokenParts[1], 'base64').toString('utf-8'));

        // 获取有效期
        const expiration = payload.exp;
        // 将时间戳转换为可读的日期格式
         expirationDate = new Date(expiration * 1000);
    }
    return expirationDate
}

// 清除token
const DelToken = (tokenExp:any ) => {
    const expirationDate = new Date(tokenExp).getTime();
    const timeToExpire = expirationDate - Date.now();
    setTimeout(() => {
        localStorage.clear();
        location.reload();
      }, timeToExpire);
}

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('CLOUDPFM_CO__REFRESH__'); 
        if (token) {
            config.headers.Authorization = `${token}`; 
            if(GetTokenExp(token) != undefined){
                DelToken(GetTokenExp(token))
            }   
        }
        return config;
    },
    (error) => {
        console.log(error);
        
        return Promise.reject(error);
    }
)

export default api;