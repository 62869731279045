import { Configuration } from '../typings/configuration';
import productionConfig from './production';
import developmentConfig from './development';

let config: Configuration;

console.log(process.env.NODE_ENV);

if (process.env.NODE_ENV === 'production') {
  config = productionConfig;
}else{
  config = developmentConfig;
}

export default config;