import axios from 'axios';
export  const  uploadFileToS3 =  async(file: File, s3Url: string) => {
    
    await axios.put(s3Url, file, {
      headers: { 'Content-Type': file.type },
    })
    .then((res)=>{
        console.log(res);
    })
    .catch((error)=>{
        console.log(error);
    })
}