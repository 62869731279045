<template>
  <v-card>
    <v-snackbar
        class="errorSnackbar"
        position="absolute"
        v-model="data.errorSnackbar"
        absolute
        top
        >
        {{ data.errorText }}
    </v-snackbar>
    <v-layout>
        <the-header
            @chooselanguage='changeLanguageType'
        ></the-header>
        <v-navigation-drawer color="surface" width="180">
        </v-navigation-drawer>
        <v-navigation-drawer color="surface" location="right" width="180">
        </v-navigation-drawer>
        <v-main>
        <v-container lass="align-center">
            <v-row>
                <v-col cols="12" md="10" sm="12">
                    <p class="text-h4">{{ $t('PreviewMessage.title') }}</p>
                </v-col>
                <v-col cols="12" md="2" sm="12" class="goBackBox">
                    <v-btn  class="mt-10 home-button" color="success" variant="outlined" @click="goHome">
                        {{ $t('BackButtomList.goBack') }}
                    </v-btn>
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col class="bg-grey-lighten-1"
                    v-for="(item, index) in data.processTitle" :key="index" cols="12" sm="4">
                    <v-sheet v-if="item.isActive" class="ma-2 pa-2 text-h6 bg-success">
                        {{item.title}}
                    </v-sheet>
                    <v-sheet v-else class="ma-2 pa-2 text-h6 bg-grey-lighten-3">
                        {{item.title}}
                    </v-sheet>
                </v-col>
            </v-row>

            <!-- 入力画面 -->
            <v-form ref="formRef">
                <v-row no-gutters v-if="isEdit">
                    <v-col cols="12">
                    <v-table class="table-border">
                    <tbody>
                        <tr>
                            <td class="bg-teal-lighten-3" style="width: 30%;">{{ $t('PreviewMessage.formTitle.name') }}<span class="requiredSpan">※</span></td>
                            <td>{{ data.property.buildingName }}</td>
                        </tr>
                        <tr>
                            <td class="bg-teal-lighten-3">{{ $t('PreviewMessage.formTitle.email') }}<span class="requiredSpan">※</span></td>
                            <td>
                                <v-text-field v-model="data.preview.email" :rules="ruleList.email" class="mt-4" :label=" $t('PreviewMessage.formTitle.email')"  required ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg-teal-lighten-3">{{ $t('PreviewMessage.formTitle.phone') }}<span class="requiredSpan">※</span></td>
                            <td>
                                <v-text-field v-model="data.preview.phone" :rules="ruleList.phone" class="mt-4" :label=" $t('PreviewMessage.formTitle.phone')"  required   @keydown="updateValue"></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg-teal-lighten-3">{{ $t('PreviewMessage.formTitle.dateFrist') }}<span class="requiredSpan">※</span></td>
                            <td>
                                <v-menu v-model="data.menuOpen" :close-on-content-click="false"  location="bottom">
                                    <template v-slot:activator="{ props }">
                                        <v-text-field
                                            v-model.lazy="data.preview.preferenceDateFirst"
                                            v-bind="props"
                                            class="mt-4"
                                            :label="$t('formVerification.date')"
                                            :rules="ruleList.dateFrist"
                                            placeholder="2023/08/18"
                                            @keydown="chooseDate"
                                        ></v-text-field>
                                    </template>
                                        <div class="dateBox">
                                            <v-date-picker
                                            v-model="data.Formdate.preferenceDateFirst"
                                            :min="data.date"
                                            @click:save="ToSaveData"
                                            @click:cancel="cleanButton"
                                            :ok-text="$t('BackButtomList.confirmed')"
                                            :cancel-text="$t('BackButtomList.cancle')"
                                            >
                                            </v-date-picker>
                                            <v-btn class="closeButton" @click.stop="closeMenu">{{$t('BackButtomList.closing')}}</v-btn>
                                        </div>
                                </v-menu>
                                <v-select  :rules="ruleList.dateTimeFrist" v-model="data.preview.preferenceTimePeriodFirst" class="mt-4" label="Select" :items="data.timeSelect"></v-select>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg-teal-lighten-3">{{ $t('PreviewMessage.formTitle.dateSecond') }}<span class="requiredSpan">※</span></td>
                            <td>
                                
                                <v-menu v-model="data.menuOpenSecond" :close-on-content-click="false"  location="bottom">
                                    <template v-slot:activator="{ props }">
                                        <v-text-field   :rules="ruleList.dateSecond" @keydown="chooseDate"  v-bind="props" v-model="data.preview.preferenceDateSecond" class="mt-4" :label="$t('formVerification.date')"  placeholder="2023/08/18" ></v-text-field>
                                    </template>
                                        <div class="dateBox">
                                            <v-date-picker
                                            v-model="data.Formdate.preferenceDateSecond"
                                            :min="data.date"
                                            @click:save="data.menuOpenSecond=false"
                                            @click:cancel="cleanButtonSecond"
                                            :ok-text="$t('BackButtomList.confirmed')"
                                            :cancel-text="$t('BackButtomList.cancle')"
                                            >
                                            </v-date-picker>
                                            <v-btn class="closeButton" @click.stop="data.menuOpenSecond=false">{{$t('BackButtomList.closing')}}</v-btn>
                                        </div>
                                </v-menu>
                                <v-select :rules="ruleList.dateTimeSecond" v-model="data.preview.preferenceTimePeriodSecond" class="mt-4" label="Select" :items="data.timeSelect"></v-select>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg-teal-lighten-3">{{ $t('PreviewMessage.formTitle.dateThirdly') }}<span class="requiredSpan">※</span></td>
                            <td>
                                
                                <v-menu v-model="data.menuOpenThird" :close-on-content-click="false"  location="bottom">
                                    <template v-slot:activator="{ props }">
                                        <v-text-field   :rules="ruleList.dateThirder" @keydown="chooseDate"  v-bind="props" v-model="data.preview.preferenceDateThird" class="mt-4" :label="$t('formVerification.date')"  placeholder="2023/08/18" ></v-text-field>
                                    </template>
                                        <div class="dateBox">
                                            <v-date-picker
                                            v-model="data.Formdate.preferenceDateThird"
                                            :min="data.date"
                                            @click:save="data.menuOpenThird=false"
                                            @click:cancel="cleanButtonThird"
                                            :ok-text="$t('BackButtomList.confirmed')"
                                            :cancel-text="$t('BackButtomList.cancle')"
                                            >
                                            </v-date-picker>
                                            <v-btn class="closeButton" @click.stop="data.menuOpenThird=false">{{$t('BackButtomList.closing')}}</v-btn>
                                        </div>
                                </v-menu>
                                <v-select :rules="ruleList.dateTimeThirder" v-model="data.preview.preferenceTimePeriodThird" class="mt-4" label="Select" :items="data.timeSelect"></v-select>
                            </td>
                        </tr>
                        <tr>
                            <td class="bg-teal-lighten-3">{{ $t('PreviewMessage.formTitle.ContactInformation') }}</td>
                            <td>
                                <v-textarea rows="5" v-model="data.preview.communicationMatters" class="mt-4"></v-textarea>
                            </td>
                        </tr>
                    </tbody>
                    </v-table>
                    </v-col>
                </v-row>
            </v-form>
            <!-- 確認画面 -->
            <v-row no-gutters v-if="isConfirm">
                <v-col cols="12">
                <v-table class="table-border">
                <tbody>
                    <tr>
                        <td class="bg-teal-lighten-3" style="width: 30%;">{{ $t('PreviewMessage.formTitle.name') }}</td>
                        <td>{{data.property.buildingName }}</td>
                    </tr>
                    <tr>
                        <td class="bg-teal-lighten-3">{{ $t('PreviewMessage.formTitle.email') }}</td>
                        <td>
                            {{ data.preview.email }}
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-teal-lighten-3">{{ $t('PreviewMessage.formTitle.phone') }}</td>
                        <td>
                            {{ data.preview.phone }}
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-teal-lighten-3">{{ $t('PreviewMessage.formTitle.dateFrist') }}</td>
                        <td >
                            {{ data.preview.preferenceTimeFirst }}
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-teal-lighten-3" >{{ $t('PreviewMessage.formTitle.dateSecond') }}</td>
                        <td >
                            {{ data.preview.preferenceTimeSecond}}
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-teal-lighten-3" >{{ $t('PreviewMessage.formTitle.dateThirdly') }}</td>
                        <td >
                            {{ data.preview.preferenceTimeThird }}
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-teal-lighten-3">{{ $t('PreviewMessage.formTitle.ContactInformation') }}</td>
                        <td>
                            {{ data.preview.communicationMatters }}
                        </td>
                    </tr>
                </tbody>
                </v-table>
                </v-col>
            </v-row>
            <!-- 完了画面 -->
            <v-row no-gutters v-if="isComplete">
                <v-col cols="12" class="text-center mt-8 ">
                    <div class="text-h4">{{ $t('PreviewMessage.lastTitle') }}</div>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="isConfirm">
                <v-col cols="12 mt-4 text-left">
                    <v-btn v-if="isConfirm" size="large" class="" v-on:click="toEdit">&lt;{{ $t('AppliedPropertyMessage.buttonDetailTitle.ModifyInput') }}</v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12 mt-4 text-center">
                    <v-btn v-if="isEdit" size="large" class="" v-on:click="confirm">{{ $t('AppliedPropertyMessage.buttonDetailTitle.ConfirmNext') }}</v-btn>
                    <v-btn v-if="isConfirm"  size="large" class="" v-on:click="send">{{ $t('AppliedPropertyMessage.buttonDetailTitle.send') }}</v-btn>
                    <v-btn v-if="isComplete"  size="large" class="" v-on:click="toHome">{{ $t('BackButtomList.goHome') }}</v-btn>
                </v-col>
            </v-row>
            <v-row class="mt-10">
                <the-footer></the-footer>
            </v-row>
        </v-container>
        </v-main>
    </v-layout>
  </v-card>
</template>
<style>
.table-border {
  border:1px;
  border-style: solid;
  border-color: antiquewhite;
}
</style>
<script lang="ts">
import TheHeader from './components/TheHeader.vue'
import { defineComponent,reactive, computed, watchEffect, getCurrentInstance, onMounted, defineProps ,watch , ref} from 'vue'
import TheFooter from './components/TheFooter.vue'
import { useRoute, useRouter } from 'vue-router'
import api from '@/api/axios/axios'
import { VDatePicker } from 'vuetify/labs/VDatePicker'
import {Property, Company, Preview, ApplyDetailItem, AppliedPropertyType, FileItem, ProcessTitle, ApplyProperty } from './../api/sys/model/model'
import i18n from '../language/index'
export default defineComponent({
    name: 'PropertyDetail',
    components: {
        TheHeader,
        TheFooter,
    },
});
</script>

<script lang="ts" setup>
    const props = defineProps({
      propertyInfo: Object,

    })
    const data = reactive({
        errorSnackbar:false,
        errorText:'',
        Formdate:{}as Preview,
        menuOpen:false,
        menuOpenSecond:false,
        menuOpenThird:false,
        preview: {
            preferenceDateFirst:'',
            preferenceDateSecond:'',
            preferenceDateThird:'',
            preferenceTimePeriodFirst:'指定なし',
            preferenceTimePeriodSecond:'指定なし',
            preferenceTimePeriodThird:'指定なし',
            customerType:1,
        } as Preview,
        property: {} as Property,
        actionType: "1" as string,
        propertyOrPreviewId: "" as string,
        
        mainImage: "detail-1.png",
        images: ["detail-2.png" , "detail-3.png", "detail-4.png","detail-2.png" , "detail-3.png" , "detail-4.png", "detail-2.png","detail-3.png" ,"detail-4.png" ],
        processTitle: [{title:i18n.global.messages[i18n.global.locale].PreviewMessage.navTitleFrist, isActive: true},
        {title:i18n.global.messages[i18n.global.locale].PreviewMessage.navTitleSecond, isActive: false},
        {title:i18n.global.messages[i18n.global.locale].PreviewMessage.navTitleThirdly, isActive: false}],

        timeSelect: ["指定なし","07:00〜07:30","07:30〜08:00",
            "08:00〜08:30","08:30〜09:00","09:00〜09:30","09:30〜10:00",
        "10:00〜10:30","10:30〜11:00","11:00〜11:30","11:30〜12:00"
        ,"12:00〜12:30","12:30〜13:00","13:00〜13:30","13:30〜14:00"
        ,"14:00〜14:30","14:30〜15:00","15:00〜15:30","15:30〜16:00",
        "16:00〜16:30","16:30〜17:00","17:00〜17:30","17:30〜18:00"
        ,"18:00〜18:30","18:30〜19:00","19:00〜19:30","19:30〜20:00"
        ,"20:00〜20:30","20:30〜21:00"],
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menuActive: false,
        modal: false,
        menu2: false,

    });
    const formRef = ref(null);

    const ruleList = {
        email: [
            (value:string) => !!value || i18n.global.messages[i18n.global.locale].formVerification.email,
            (value:string) => /.+@.+\..+/.test(value) || i18n.global.messages[i18n.global.locale].formVerification.emailType,
        ],
        phone:[
            (value:string) => !!value || i18n.global.messages[i18n.global.locale].formVerification.phone,
            (value:string) =>/^[0-9]{7,14}$/.test(value) || i18n.global.messages[i18n.global.locale].formVerification.phoneType,
        ],
        dateFrist:[
            (value:string) => !!value || i18n.global.messages[i18n.global.locale].formVerification.dateFrist,
            (value:string) =>/^\d{4}\/\d{2}\/\d{2}$/.test(value) || i18n.global.messages[i18n.global.locale].formVerification.dateFristType,
        ],
        dateTimeFrist:[
            (value:string) => !!value || i18n.global.messages[i18n.global.locale].formVerification.dateFrist,
        ],
        dateSecond:[
            (value:string) => !!value || i18n.global.messages[i18n.global.locale].formVerification.dateSecond,
            (value:string) =>/^\d{4}\/\d{2}\/\d{2}$/.test(value) || i18n.global.messages[i18n.global.locale].formVerification.dateFristType,
        ],
        dateTimeSecond:[
            (value:string) => !!value || i18n.global.messages[i18n.global.locale].formVerification.dateSecond,
          
        ],
        dateThirder:[
            (value:string) => !!value || i18n.global.messages[i18n.global.locale].formVerification.dateThirder,
            (value:string) =>/^\d{4}\/\d{2}\/\d{2}$/.test(value) || i18n.global.messages[i18n.global.locale].formVerification.dateFristType,
        ],
        dateTimeThirder:[
            (value:string) => !!value || i18n.global.messages[i18n.global.locale].formVerification.dateThirder,
            
        ],
    }
    const imageChange = (imageFileName: string) => {
      data.mainImage = imageFileName;
    }

    const confirm = () => {
        formRef.value?.validate()
        .then((valid:string)=>{
            if(valid.valid){
                data.processTitle[0].isActive = false;
                data.processTitle[1].isActive = true;
                data.processTitle[2].isActive = false;
            }
        })
    
    }

    const toEdit = () => {
      data.processTitle[0].isActive = true;
      data.processTitle[1].isActive = false;
      data.processTitle[2].isActive = false;
    }

    const send = () => {
        // 登録処理
        if (data.actionType == "1") {
            // 0：未確定、1：再申込 2：確定済み
            data.preview.status = '0'
            console.log(data.preview);

            api.post(`/user/previews`,  data.preview)
            .then((res) => {
                // alert("登録成功" + data.preview.propertyViewingId)
                data.processTitle[0].isActive = false;
                data.processTitle[1].isActive = false;
                data.processTitle[2].isActive = true;
            })
            .catch((error) => {
                console.log(error.message)
                data.errorSnackbar = true
                data.errorText=error.response.data.message
            })
        // 更新処理
        } else {
            data.preview.status = '1'
            api.put(`/user/previews/` + data.preview.propertyViewingId, data.preview )
            .then((res) => {
                console.log("sucess")
                data.processTitle[0].isActive = false;
                data.processTitle[1].isActive = false;
                data.processTitle[2].isActive = true;
            })
            .catch((error) => {
                console.log(error)
                data.errorSnackbar = true
                data.errorText=error.response.data.message
            })
        }
    }

    const router = useRouter()
    const route = useRoute()
    const toHome = () => {
        router.push('/')
    }

    const isEdit = computed(() => { return data.processTitle[0].isActive});
    const isConfirm = computed(() => { return data.processTitle[1].isActive});
    const isComplete = computed(() => { return data.processTitle[2].isActive});


    watchEffect(() => {
        console.log(data.preview.preferenceTimePeriodSecond)
        data.preview.preferenceTimePeriodFirst != '指定なし'? data.preview.preferenceTimeFirst =  data.preview.preferenceDateFirst + " " +  data.preview.preferenceTimePeriodFirst:data.preview.preferenceTimeFirst =  data.preview.preferenceDateFirst
        data.preview.preferenceTimePeriodSecond != '指定なし'? data.preview.preferenceTimeSecond = data.preview.preferenceDateSecond + " " + data.preview.preferenceTimePeriodSecond : data.preview.preferenceTimeSecond = data.preview.preferenceDateSecond
        data.preview.preferenceTimePeriodThird != '指定なし'? data.preview.preferenceTimeThird = data.preview.preferenceDateThird + " " + data.preview.preferenceTimePeriodThird : data.preview.preferenceTimeThird = data.preview.preferenceDateThird
    })

    onMounted(() => {
       console.log(route.params.propertyOrPreviewId.toString());
        getThisData();
    });
    const changeLanguageType = (val:'jp'|'en'|'zh')=>{
       console.log(val);
        data.processTitle = [{title:i18n.global.messages[i18n.global.locale].PreviewMessage.navTitleFrist, isActive: true},
        {title:i18n.global.messages[i18n.global.locale].PreviewMessage.navTitleSecond, isActive: false},
        {title:i18n.global.messages[i18n.global.locale].PreviewMessage.navTitleThirdly, isActive: false}]
    }

    const getInitData  =  () => {
        // 1:新規作成 2:更新
        data.preview.preferenceDateFirst = ""
        data.preview.preferenceDateSecond = ""
        data.preview.preferenceDateThird = ""
        
        data.actionType =route.params.actionType.toString()
        // 新規作成
        if (data.actionType == "1"&&route.query.isEdit != 'Edit') {
            data.property.propertyId = route.params.propertyOrPreviewId.toString();
            data.preview.propertyId = data.property.propertyId
            // 内見名
            console.log(data.property.address);
            console.log(data.property.buildingName);
            
            data.preview.previewName = data.property.address + data.property.buildingName
            console.log( data.preview.previewName);

        // 内見変更の場合
        } else {
            data.preview.propertyViewingId = route.params.actionType.toString()
            console.log(route.params);
            
            api.get(`/user/previews/` + data.preview.propertyViewingId)
            .then((res) => {
                console.log(res.data);
                
                data.preview = res.data

                data.preview.preferenceDateFirst = data.preview.preferenceTimeFirst.split(" ")[0];
                data.preview.preferenceTimePeriodFirst = data.preview.preferenceTimeFirst.split(" ")[1];

                data.preview.preferenceDateSecond = data.preview.preferenceTimeSecond.split(" ")[0];
                data.preview.preferenceTimePeriodSecond = data.preview.preferenceTimeSecond.split(" ")[1];

                data.preview.preferenceDateThird = data.preview.preferenceTimeThird.split(" ")[0];
                data.preview.preferenceTimePeriodThird = data.preview.preferenceTimeThird.split(" ")[1];

                data.property.propertyId = data.preview.propertyId


            })
            .catch((error) => {
                console.log(error.message)
            })
        }
   

    };
    const getThisData  =  () => {
        data.propertyOrPreviewId = route.params.propertyOrPreviewId.toString();
         
        api.get(`/user/public/property/` + data.propertyOrPreviewId)
        .then((res) => {
            data.property = res.data.property
           console.log(data.property);    
           if(i18n.global.locale=='zh'){
                data.property.buildingName = res.data.property.buildingNameCn
                data.property.address = res.data.property.addressCn

            }else if(i18n.global.locale=='en'){
                data.property.buildingName = res.data.property.buildingNameEn
                data.property.address = res.data.property.addressEn
               
            }else if(i18n.global.locale=='jp'){
                
                data.property.buildingName = res.data.property.buildingNameJp
                data.property.address = res.data.property.addressJp
            }
            getInitData();
        })
        .catch((error) => {
            console.log(error.message)
        })
    };
    const goHome = () => {
        router.push(`/detail?id=${data.property.propertyId}`);
    }
    const  closeMenu = () => {
      data.menuOpen = false;
    }
    const cleanButton = () => {
        data.preview.preferenceDateFirst = ''
        data.Formdate.preferenceDateFirst = ''
        data.menuOpen = false
    }
    const cleanButtonSecond = () => {
        data.preview.preferenceDateSecond = ''
        data.Formdate.preferenceDateSecond = ''
        data.menuOpenSecond = false
    }
    const cleanButtonThird = () => {
        data.preview.preferenceDateThird = ''
        data.Formdate.preferenceDateThird = ''
        data.menuOpenThird = false
    }

    watch(
      () => data.Formdate.preferenceDateFirst,
      (newValue: string | null, oldValue: string | null) => {
        console.log(newValue);
        data.preview.preferenceDateFirst = handlePreferenceChange(newValue)
      },
    );
    watch(
      () => data.Formdate.preferenceDateSecond,
      (newValue: string | null, oldValue: string | null) => {
        console.log(newValue);
        data.preview.preferenceDateSecond = handlePreferenceChange(newValue)
      },
      );
    watch(
      () => data.Formdate.preferenceDateThird,
      (newValue: string | null, oldValue: string | null) => {
        console.log(newValue);
        data.preview.preferenceDateThird = handlePreferenceChange(newValue)
      },
      );
    
    const handlePreferenceChange = (newValue:string) => {
        let preferenceDate;
        if(newValue){
            const date = new Date(newValue);
            const year = date.getFullYear();
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);
            preferenceDate = `${year}/${month}/${day}`;
        }else{
            preferenceDate = ''
        }
        return preferenceDate
    }
    const ToSaveData = () => {
        data.menuOpen = false;
    }
    const chooseDate = (event:Event) => {
        event.preventDefault();
    }
    const updateValue = (event:Event) => {
        const keyCode = event.keyCode;
        const isNumericKey = (keyCode >= 48 && keyCode <= 57) ||     
                            (keyCode >= 96 && keyCode <= 105) ||    
                            keyCode === 8 ||                        
                            keyCode === 37 ||                       
                            keyCode === 39;                         

        if (!isNumericKey) {
            event.preventDefault();    
        }
    }
    
</script>
<style>
    .goBackBox .v-btn{
        margin-top: 0 !important;
    }
    .closeButton{
        width: 100%;
    }
    .dateBox{
        width: 360px;
        position: relative;
    }
    .cleanButton{
        position: absolute;
        bottom: 50px;
        right: 70px;
        width: 100px;
        box-shadow: none;
    }
    .cleanButton:hover{
        box-shadow: none;
    }
</style>
<style scoped>
    .requiredSpan{
        margin-left: 20px;
    }
</style>
