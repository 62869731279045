<template>
    <v-card>
        <v-layout>
            <the-header @chooselanguage='changeLanguageType'></the-header>
            <v-navigation-drawer width="280">
            </v-navigation-drawer>
            <v-navigation-drawer location="right" width="280">
            </v-navigation-drawer>
            <v-main>
                <v-container class="align-center">
                    <v-row>
                        <v-col cols="12">
                            <p class="mt-4 text-h4">{{ $t('RentalManagement.rentalTenantReportDetail') }}</p>
                        </v-col>
                    </v-row>
                    <!-- 入居者対応報告詳細画面 -->
                    <v-row no-gutters class="mt-4">
                        <v-col col="12">
                            <v-table class="table-border">
                                <tbody>
                                    <tr>
                                        <th class="text-left text-white" style="width: 20%; background-color: rgb(6, 162, 183);">{{ $t('RentalManagement.title') }}</th>
                                        <th>{{ data.rentalTenantReportDetail.title }}</th>
                                        <th class="text-left text-white" style="width: 20%; background-color: rgb(6, 162, 183);">{{ $t('RentalManagement.content') }}</th>
                                        <th>{{ data.rentalTenantReportDetail.content }}</th>
                                    </tr>
                                    <tr>
                                        <th class="text-left text-white" style="width: 20%; background-color: rgb(6, 162, 183);">{{ $t('RentalManagement.manager') }}</th>
                                        <th>{{ data.rentalTenantReportDetail.manager }}</th>
                                        <th class="text-left text-white" style="width: 20%; background-color: rgb(6, 162, 183);">{{ $t('RentalManagement.date') }}</th>
                                        <th>{{ formatDate(data.rentalTenantReportDetail.createdAt) }}</th>
                                    </tr>
                                    <tr>

                                        <th class="text-left text-white"
                                            style="width: 20%; background-color: rgb(6, 162, 183);">{{ $t('RentalManagement.file') }}</th>
                                        <th v-for="item in data.rentalTenantFileList" v-bind:key="item.rentalManagementTenantInteractionReportId" @click="ToDownloadFile(item)">
                                            <a href="javascript:;">{{ item.fileName }}</a>
                                        </th>
                                    </tr>
                                </tbody>
                            </v-table>
                        </v-col>
                    </v-row>
                    <v-row class="previous-row">
                        <v-btn class="previous" color="success" variant="outlined" @click="goBack">
                            {{ $t('RentalManagement.rentManagementDetail.buttons.goBackToTenantReport') }}
                        </v-btn>
                    </v-row>
                    <v-row class="mt-10">
                        <the-footer></the-footer>
                    </v-row>
                </v-container>
            </v-main>
        </v-layout>
    </v-card>
</template>
<style>
.table-border {
    border: 1px;
    border-style: solid;
    border-color: antiquewhite;
}
.previous-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.previous {
    background-color: #ffffff;
    color: #4CAF50;
}

.previous:hover {
    background-color: #4CAF50 !important;
    color: #ffffff !important;
}
</style>
<script lang="ts">
import { reactive, onMounted, getCurrentInstance, defineComponent } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
// import axios from 'axios'
import api from '@/api/axios/axios'
import vuetify from '@/plugins/vuetify';
import i18n from '../language/index'
import { downloadFile } from '@/api/axios/downLoad';


export default defineComponent({
    name: 'RentalTenantReportDetail',
    components: {
        TheHeader,
        TheFooter,
    },
});
</script>

<script lang="ts" setup>
const data = reactive({
    rentalTenantReportDetail: {},
    rentalTenantFileList:[],
    languageType: 'jp' as string
});
const router = useRouter();
const route = useRoute();

const goBack = () => {
    router.go(-1)
}
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; 
  return `${year}年${String(month)}月`;
}
onMounted(() => {
    getInitData();
})
const getInitData = () => {
    const rentalManagementTenantInteractionReportId = route.params.rentalManagementId
    api.get('/user/rental/tenant/' + rentalManagementTenantInteractionReportId)
        .then((res) => {
            data.rentalTenantReportDetail = res.data;
            data.rentalTenantFileList = res.data.rentalTenantFileList   
        })
        .catch((error) => {
            console.log(error.message);
        });
};
const changeLanguageType = (val: 'jp' | 'en' | 'zh') => {
    data.languageType = val
}
const ToDownloadFile = (item:any) => {
    console.log(item);
    api.get(`/user/rental/tenant/download/user?companyId=${route.query.companyId}&&rentalManagementId=${data.rentalTenantReportDetail.rentalManagementId}&&rentalManagementTenantInteractionReportAttachedFileId=${item.rentalManagementTenantInteractionReportAttachedFileId}&&rentalManagementTenantInteractionReportId=${item.rentalManagementTenantInteractionReportId}`)
    .then((res)=>{
        console.log(res);
        downloadFile(res.data.downloadPresignUrl,item.fileName)
    })
    .catch((error)=>{
        console.log(error);
    })
}
</script>
