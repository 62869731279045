<template>
    <v-app-bar color="surface-variant"  height="50">
        <v-toolbar-title class="leftHeadLogo"><img class="logoimageHeader" @click="toHome()" :src="require('@/assets/header.cffaff6f.png')" alt=""><a  class="pagetitle" href="#" style ="text-decoration: none; color:aliceblue;" @click="toHome()">EASYE STATE</a></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu
          transition="slide-y-transition"
            >
          <template v-slot:activator="{ props }">
            <v-btn
              color="primary"
              v-bind="props"
              v-if="data.userInfo"
            >
                 <p class="messageComment">
                  <v-badge  color="red" dot v-model="data.showBadge">
                    <v-icon class="messageIcon">mdi-bell</v-icon>
                  </v-badge>
                </p>
            </v-btn>
          </template>
          <v-list class="listWidth">
            <div class="messageTitleBox" >
              <p :class="data.messageType==1?'messageTitle messageActive':'messageTitle'" @click.stop="FristNotification(1)">{{$t('theHeader.notification')}} ({{data.notificationList.filter(item => item.readedFlag === false).length}})</p>
              <p :class="data.messageType==2?'messageTitle messageActive':'messageTitle'" @click.stop="FristNotification(2)">{{$t('theHeader.SystemNotification')}} ({{data.inforationList.filter(item => item.readedFlag === false).length}})</p>
            </div>
            <div class="messageListBox">
              <v-list-item
                v-for="(item, i) in data.messageList[data.page-1]"
                :key="i"
              >
                <v-list-item-title
                class="notificationBox"
                >
                <div class="notificationBoxIcon">
                  <v-badge  color="red" dot v-model="data.showBadge" v-if="data.messageType==1 && item.readedFlag==false">
                      <v-icon class="messageIcon">{{'mdi-volume-high'}}</v-icon>
                  </v-badge>
                  <v-badge  color="red" dot v-model="data.showBadge" v-if="data.messageType==2 && item.readedFlag==false">
                      <v-icon class="messageIcon">{{'mdi-bell'}}</v-icon>
                  </v-badge>
                  <v-icon class="messageIcon" v-if="data.messageType==1 && item.readedFlag==true">{{'mdi-volume-high'}}</v-icon>
                  <v-icon class="messageIcon" v-if="data.messageType==2 && item.readedFlag==true" >{{'mdi-bell'}}</v-icon>
                </div>
                 <div class="notificationBoxContent">
                  <v-dialog
                      transition="dialog-bottom-transition"
                      width="auto"
                    >
                      <template v-slot:activator="{ props }">
                        <p class="title"><a v-bind="props"  @click="ToReadMessage(item,data.messageType)">{{ item.title }}</a></p>
                      </template>
                      <template v-slot:default="{ isActive }">
                        <v-card>
                          <v-toolbar
                            color="#4caf93"
                            style="color: #fff;"
                            :title="data.tempItem.title"
                          ></v-toolbar>
                          <v-card-text>
                            <div class="pa-12">{{data.tempItem.detail}}</div>
                          </v-card-text>
                          <v-card-actions class="justify-end">
                            <v-btn
                              variant="text"
                              @click="isActive.value = false"
                            >{{$t('BackButtomList.closing')}}</v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </v-dialog>

                  
                  <p class="detail">{{ item.detail }}</p>
                  <p>{{ formatDate(item.createdAt) }}</p>
                 </div>
                </v-list-item-title>
              </v-list-item>
              <div class="pageNumberBox"> 
                <div class="text-center">
                  <v-container>
                    <v-row justify="center">
                      <v-col cols="24">
                        <v-container class="max-width">
                          <v-pagination
                            v-bubble
                            v-model="data.page"
                            class="my-4 "
                            :length="data.messageType==1?((data.notificationList.length-1)/5)+1:((data.inforationList.length-1)/5)+1"
                          ></v-pagination>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </div>
            </div>
           
          </v-list>
        </v-menu>

        <v-select class="languageSelect" 
        v-model="data.selectLanguage" 
        :items="data.type" 
        item-text="title" 
        item-value="val"
        @update:modelValue="chooselanguage(data.selectLanguage)"
        ></v-select>
        <v-btn v-if="!data.userInfo" outlined fab color="success" elevation="2" @click="toLogin()">{{ $t('messageproprty.buttonName.login') }}</v-btn>
          <v-menu v-if="data.userInfo">
            <template v-slot:activator="{ props }">
              <v-btn
                color="#fff"
                v-bind="props"
                
              >
                <span class="userButton">{{data.userInfo.userName}}</span>
                <span class="userButtonNone"><v-icon>mdi-menu</v-icon></span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in data.item"
                :key="index"
                :value="index"
              >
                <v-list-item-title @click="ToMenu(index)">{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
    </v-app-bar>
</template>

<script lang="ts">
import api from '@/api/axios/axios'
import { defineComponent,reactive, computed, onMounted, defineProps, defineEmits ,watchEffect } from 'vue'
import { useRouter,onBeforeRouteLeave } from 'vue-router'
import i18n from '../../language/index'
export default defineComponent({
  name: 'TheHeader',
});
</script>
<script lang="ts" setup>
    const props = defineProps({
      isLogin: Boolean,
    });
    const data = reactive({
      showBadge:false,
      page:1,
      messageType:1,
      notificationList:[],
      inforationList:[],
      messageList:[] as any,
      tempItem: [],
      userInfo:{
        userName:''
      },
      userInfoString:''as any,
      item:[
        {title:i18n.global.messages[i18n.global.locale].theHeader.Profile},
        {title:i18n.global.messages[i18n.global.locale].theHeader.ChangePassword},
        {title:i18n.global.messages[i18n.global.locale].theHeader.LogOut},
        ],
      selectLanguage: "jp" as 'jp'|'en'|'zh',
      type:[
        {title:i18n.global.messages[i18n.global.locale].languageType.Japanese,val:'jp'},
        {title:i18n.global.messages[i18n.global.locale].languageType.Chinese,val:'zh'},
        {title:i18n.global.messages[i18n.global.locale].languageType.English,val:'en'},
      ]
    });
    onMounted(()=>{
      data.selectLanguage = i18n.global.locale
      data.userInfoString = localStorage.getItem('CLOUDPFM_CO__USERINFO')
      console.log(localStorage.getItem('CLOUDPFM_CO__USERINFO'));
      data.userInfo = JSON.parse(data.userInfoString)
      console.log(data.userInfo);
      if(localStorage.getItem('CLOUDPFM_CO__REFRESH__')){
        GetNotification();
        GetInformation();
      }
    })
    const GetNotification = () =>{
      api.get(`/user/notification?userId=${data.userInfo.userId}`)
        .then((res)=>{
          console.log(res);

          const allReaded = res.data.every(obj => obj.readedFlag === true);
          if (allReaded) {
            data.showBadge = false
          } else {
            data.showBadge = true
          }
          data.notificationList = res.data
           data.messageList = Array.from({ length: Math.ceil(res.data.length / 5) }, (v, i) =>
            res.data.slice(i * 5, i * 5 + 5)
          );
          
          
        })
        .catch((error)=>{
          console.log(error);
        })
    }


    const GetInformation = () =>{
      api.get(`/user/information`)
        .then((res)=>{
          console.log(res);
          const allReaded = res.data.every(obj => obj.readedFlag === true);
          if (allReaded) {
            data.showBadge = false
          } else {
            data.showBadge = true
          }
          data.inforationList = res.data 
          
        })
        .catch((error)=>{
          console.log(error);
        })
    }


    const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; 
    const day = date.getDate();
    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  };


    const router = useRouter()
    const toHome = () => {
      router.push("/")
    }

    onBeforeRouteLeave((to, from, next) => {
        if(to.name == 'index' && from.name != 'detail') {
            console.log("离开detail")
            to.meta.keepAlive = false;
        }
        next()
    });
    
    const toLogin = ()=>{
      router.push("/login")
    }
    const emit = defineEmits(['chooselanguage','changeUserInfo'],)
    const chooselanguage = (type:'jp'|'en'|'zh') =>{
      console.log(type);
      i18n.global.locale = type;
      emit('chooselanguage',type)
      data.type =[
        {title:i18n.global.messages[i18n.global.locale].languageType.Japanese,val:'jp'},
        {title:i18n.global.messages[i18n.global.locale].languageType.Chinese,val:'zh'},
        {title:i18n.global.messages[i18n.global.locale].languageType.English,val:'en'},
      ]
    }
    const FristNotification = (dataNumber:number) => {
      data.messageType = dataNumber
      data.page = 1
      if(dataNumber==2){
        data.messageList = Array.from({ length: Math.ceil(data.inforationList.length / 5) }, (v, i) =>
          data.inforationList.slice(i * 5, i * 5 + 5)
        );
      }else
      if(dataNumber==1){
          data.messageList = Array.from({ length: Math.ceil(data.notificationList.length / 5) }, (v, i) =>
          data.notificationList.slice(i * 5, i * 5 + 5)
        );
      }
    }
    const ToMenu = (index:number)=>{
      console.log(index);
      if(index==2){
          localStorage.removeItem('CLOUDPFM_CO__REFRESH__');
          localStorage.removeItem('CLOUDPFM_CO__REFRESH__TIME');
          localStorage.removeItem('CLOUDPFM_CO__USERINFO');
          localStorage.removeItem('CLOUDPFM_CO__PAYTYPE__');
          window.location.reload();
      }else{
        router.push({ path: '/ChangeUserInfo', query: { setpId: index } }); 
      }
    }
    const ToReadMessage = (item:object,type:string) => {
      console.log(item);
      console.log(type);
      data.tempItem = [];
      if(item.readedFlag == true) return;
      if(type=="1"){
        api.put(`/user/notification/read/${item.notificationTargetId}`)
        .then((res)=>{
          console.log(res);
          item.readedFlag = true
          data.tempItem = item;
          GetNotification()
        })
        .catch((error)=>{
          console.log(error);
        })
      }else{
        api.put(`/user/information/read/${item.systemInformationId}`)
        .then((res)=>{
          console.log(res);
          item.readedFlag = true
          data.tempItem = item;
          GetInformation()
        })
        .catch((error)=>{
          console.log(error);
        })
      }
        
    }
    watchEffect(() => {
      data.item=[
        {title:i18n.global.messages[i18n.global.locale].theHeader.Profile},
        {title:i18n.global.messages[i18n.global.locale].theHeader.ChangePassword},
        {title:i18n.global.messages[i18n.global.locale].theHeader.LogOut},
        ]
    })
</script>

<style scoped>
.languageSelect {
  max-width: 120px;
  height: 50px;
}
.userButtonNone{
  display: none;
}
  @media screen and (max-width:960px){
      .userButton{
        display: none;
      }
      .pagetitle{
        display: none;
      }
      .userButtonNone{
        display: block;
      }
  }
  .messageComment{
    margin-right: 20px;
    cursor: pointer;
  }
  .listWidth{
    width: 350px;
    padding: 20px 15px;
    height: 100%;
  }
  .messageComment .messageIcon{
    color: #cccccc;
  }
  .messageTitle{
    padding: 5px 10px;
  }
  .messageListBox{
    border: 1px solid #d9d9d9;
  }
</style>
<style>
.v-toolbar__content .text-primary {
    color: #424242 !important;
}
.notificationBox{
  display: flex;
  border-bottom: 1px solid #d9d9d9;
  padding: 10px 0;
}
.notificationBox:last-child{
  border-bottom: none;
}
.notificationBoxIcon{
  margin-right: 20px;
  margin-top: 2vh;
}
.notificationBoxIcon .messageIcon{
  color: #4caf93;

}
.notificationBoxContent p{
  /* margin-bottom: 5px; */
  cursor: pointer;
}
.notificationBoxContent .title a{
  color: #4caf93;
  text-decoration: none;
}
.notificationBoxContent .detail{
  font-size: 14px;
  width: 240px;
  white-space: nowrap;    
  overflow: hidden;        
  text-overflow: ellipsis; 
  
}
.messageTitleBox{
  display: flex;
  margin-bottom: 5px;
}
.messageTitleBox .messageTitle{
 cursor: pointer;
}
.messageActive{
  color: #4caf93;
  border-bottom: 2px solid #4caf93;
}
.pageNumberBox{
  max-width: 350px;
  overflow: hidden;
  height: 100px;
}
.pageNumberBox .my-4{
  width: 300px;
  position: relative;
  left: -20px;
  top: -20px;
}
.pageNumberBox .v-pagination__item{
  width: 30px;
  height: 30px;
}
.pageNumberBox  .v-pagination__prev, .pageNumberBox .v-pagination__next{
  width: 30px;
  height: 30px;
}
.leftHeadLogo .v-toolbar-title__placeholder{
  display: flex;
  align-items: center;
}
.logoimageHeader{
  width: 50px;
  overflow: hidden;
  cursor: pointer;
}
</style>
