<template>
    <v-card>
        <v-layout>
            <the-header @chooselanguage='changeLanguageType'></the-header>
            <v-navigation-drawer width="280">
            </v-navigation-drawer>
            <v-navigation-drawer location="right" width="280">
            </v-navigation-drawer>
            <v-main>
                <v-container class="align-center">
                    <v-row>
                        <v-col cols="12">
                            <p class="text-h4 mt-4">{{ $t('RentalManagement.rentalPaymentList') }}</p>
                        </v-col>
                    </v-row>
                    <!-- 一覧画面 -->
                    <v-table>
                        <thead>
                            <tr>
                                <th class="text-center">{{ $t('RentalManagement.date') }}</th>
                                <th class="text-center">{{ $t('RentalManagement.title') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in data.rentalManagementPayment" :key="item.rentalManagementPaymentDetailId">
                                <td class="text-center">{{ formatDate(item.createdAt) }}</td>
                                <td class="text-center"> <a href="#" @click.stop.prevent="goRentalPaymentDetail(item.rentalManagementPaymentDetailId)">
                                        {{ item.title }}
                                    </a></td>
                            </tr>
                        </tbody>
                    </v-table>
                    <v-row class="previous-row">
                        <v-btn class="previous" color="success" variant="outlined" @click="goRentalManagementDetail">
                            {{ $t('RentalManagement.rentManagementDetail.buttons.goRentalManagementDetail') }}
                        </v-btn>
                    </v-row>
                    <v-row class="mt-10">
                        <the-footer></the-footer>
                    </v-row>
                </v-container>
            </v-main>
        </v-layout>
    </v-card>
</template>
<style>
.table-border {
    border: 1px;
    border-style: solid;
    border-color: antiquewhite;
}
.previous-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.previous {
    background-color: #ffffff;
    color: #4CAF50;
}
.previous:hover {
    background-color: #4CAF50 !important;
    color: #ffffff !important;
}
</style>
<script lang="ts">
import { reactive, onMounted, getCurrentInstance, defineComponent } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
// import axios from 'axios'
import api from '@/api/axios/axios'
import i18n from '../language/index'


export default defineComponent({
    name: 'RentalPayment',
    components: {
        TheHeader,
        TheFooter,
    },
});
</script>

<script lang="ts" setup>
const data = reactive({
    companyId:'',
    rentalManagementPayment: [],
    languageType: 'jp' as string
});
const router = useRouter();
const route = useRoute();

const goRentalManagementDetail = () =>{
    router.go(-1)
}
const goRentalPaymentDetail = (id) => {
    router.push({path:'/rentalPaymentDetail/' + id,query:{companyId:data.companyId}})
}
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; 
  return `${year}-${String(month).padStart(2, '0')}`;
};
onMounted(() => {
    getInitData();
});

const getInitData = () => {
    api.get('/user/rentalManagement/' + route.params.rentalManagementId)
        .then((res) => {
            data.rentalManagementPayment = res.data.rentManagementPaymentBeanList;
            data.companyId = res.data.companyId
        })
        .catch((error) => {
            console.log(error.message);
        });
};
const changeLanguageType = (val: 'jp' | 'en' | 'zh') => {
    data.languageType = val
}
</script>
