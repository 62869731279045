<template>
    <v-card>
    <v-snackbar
        class="successSnackbar"
        position="absolute"
        v-model="data.snackbarSuccess"
        absolute
        top
        >
        {{ data.successText }}
    </v-snackbar>
        <v-snackbar
            class="errorSnackbar"
            position="absolute"
            v-model="data.errorSnackbar"
            absolute
            top
            >
            {{ data.errorText }}
        </v-snackbar>
      <v-layout>
          <v-app-bar color="surface-variant"  height="50">
            <v-toolbar-title class="leftHeadLogo"><img class="logoimageHeader" @click="toHome()" :src="require('@/assets/header.cffaff6f.png')" alt=""><a  class="pagetitle" href="#" style ="text-decoration: none; color:aliceblue;" @click="toHome()">CLOUDPF</a></v-toolbar-title>
            <v-select class="languageSelect" 
                v-model="data.selectLanguage" 
                :items="data.type" 
                item-text="title" 
                item-value="val"
                style="max-width: 150px;margin-top: 20px;"
                @update:modelValue="chooselanguage(data.selectLanguage)"
                ></v-select>
        </v-app-bar>
          <v-navigation-drawer color="surface" width="180">
          </v-navigation-drawer>
          <v-navigation-drawer color="surface" location="right" width="180">
          </v-navigation-drawer>
          <v-main>
          <v-container lass="align-center">
            <div class="outLineBox">
            <div class="registerViewTitleBox">
                <p class="registerViewTitle" v-if="data.thisStep==0"><span>{{ $t('RegisterMessage.title1') }}</span></p>
                <p class="registerViewTitle" v-if="data.thisStep==1"><span>{{ $t('RegisterMessage.title2') }}</span></p>
                <p class="registerViewTitle" v-if="data.thisStep==2"><span>{{ $t('RegisterMessage.title3') }}</span></p>
                <p class="registerViewTitle" v-if="data.thisStep==3"><span>{{ $t('RegisterMessage.title4') }}</span></p>
             </div>
             <div class="registerContainer">
                <v-row no-gutters>
                    <v-col cols="12" md="3" sm="12" xs="12" class="registerStepCol ">
                        <!-- <v-card :class="data.thisStep==0?'registerStep registerStepColactive':'registerStep'">
                            <span>ログイン情報入力</span>
                        </v-card> -->
                         <v-sheet  class="ma-2 pa-2 text-h6 " :class="data.thisStep==0?'bg-success':'g-grey-lighten-3'">
                            {{ $t('RegisterMessage.registerStep1.title') }}
                        </v-sheet>
                    </v-col>
                    <v-col cols="12" md="3" sm="12" xs="12" class="registerStepCol">
                        <v-sheet  class="ma-2 pa-2 text-h6 " :class="data.thisStep==1?'bg-success':'g-grey-lighten-3'">
                            {{ $t('RegisterMessage.registerStep2.title') }}
                        </v-sheet>
                    </v-col>
                    <v-col cols="12" md="3" sm="12" xs="12" class="registerStepCol">
                         <v-sheet  class="ma-2 pa-2 text-h6 " :class="data.thisStep==2?'bg-success':'g-grey-lighten-3'">
                            {{ $t('RegisterMessage.registerStep3.title') }}
                        </v-sheet>
                    </v-col>
                    <v-col cols="12" md="3" sm="12" xs="12" class="registerStepCol">
                        <v-sheet  class="ma-2 pa-2 text-h6 " :class="data.thisStep==3?'bg-success':'g-grey-lighten-3'">
                            {{ $t('RegisterMessage.registerStep4.title') }}
                        </v-sheet>
                    </v-col>
                </v-row>
             </div>

             <div class="registerForm" v-if="data.thisStep==0">
                <v-form ref="registerForm">
                    <v-container>
                        <v-row>
                            <v-col cols="4" class="registerinputName">
                                <span ><span class="requiredSpan">※</span>{{ $t('RegisterMessage.registerStep1.userName') }}</span>
                            </v-col>
                            <v-col cols="8" class="registerinputstyle">
                               
                            <v-text-field
                                class="persistent-hint"
                                v-model="data.userName"
                                type="email"
                                :rules="rules.username"
                                required
                                :readonly="data.noAutocomplete"
                                @focus="data.noAutocomplete=false"
                                placeholder="田中 健一"
                                persistent-hint
                                :hint="$t('RegisterMessage.registerStep1.userNameHint')"
                            ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" class="registerinputName">
                                <span ><span class="requiredSpan">※</span>{{ $t('RegisterMessage.registerStep1.email') }}</span>
                            </v-col>
                            <v-col cols="8" class="registerinputstyle">
                               
                            <v-text-field
                                class="persistent-hint"
                                v-model="data.userEmail"
                                type="email"
                                :rules="rules.email"
                                required
                                placeholder="例: name@example.com"
                                :readonly="data.noAutocomplete"
                                @focus="data.noAutocomplete=false"
                                persistent-hint
                                :hint="$t('RegisterMessage.registerStep1.emailHint')"
                            ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" class="registerinputName">
                                <span ><span class="requiredSpan">※</span>{{ $t('RegisterMessage.registerStep1.password') }}</span>
                            </v-col>
                            <v-col cols="8">
                            <v-text-field
                                class="persistent-hint"
                                v-model="data.password"
                                :rules="rules.password"
                                required
                                :readonly="data.noAutocomplete"
                                @focus="data.noAutocomplete=false"
                                persistent-hint
                                :hint="$t('RegisterMessage.registerStep1.passwordHint')"
                                :append-icon="data.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="togglePasswordVisibility"
                                :type="data.showPassword ? 'text' : 'password'"
                            ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" class="registerinputName">
                                <span ><span class="requiredSpan">※</span>{{ $t('RegisterMessage.registerStep1.repassword') }}</span>
                            </v-col>
                            <v-col cols="8">
                            <v-text-field
                                v-model="data.repassword"
                                :rules="rules.repassword"
                                required
                                :readonly="data.noAutocomplete"
                                @focus="data.noAutocomplete=false"
                                :append-icon="data.showRePassword ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="toggleRePasswordVisibility"
                                :type="data.showRePassword ? 'text' : 'password'"
                            ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="agressMessage">
                            <p class="agressMessageBox">
                                <v-checkbox v-model="data.isShow">
                                    <template v-slot:label>
                                            <div>
                                                「
                                                <span class="downFileCompany downFileCompanySeconBut" 
                                                @click="toSomeMessagePage"      
                                                >
                                                CLOUDPF規約
                                                </span>」
                                            </div>
                                            
                                            <div class="SecondFileBut">
                                                「
                                                <span class="downFileCompany downFileCompanySeconBut " 
                                                @click="toSomeMessagePageSecond"      
                                                >
                                                個人情報の取り扱いについて
                                                </span>
                                                」
                                            </div>
                                            <span class="SecondFileBut">
                                                {{ $t('RegisterMessage.registerStep1.agreementMessage.last') }}
                                            </span>
                                        </template>
                                </v-checkbox>
                            </p>
                        </v-row>
                        <v-row class="agressMessage">
                            <div class="agressMessageBox">
                                <button class="registerViewButton" @click="toRegisterNextStep(1)">{{ $t('RegisterMessage.registerStep1.agreeButton') }}</button>
                            </div>
                        </v-row>
                    </v-container>
                </v-form>
             </div>
             <div class="registerForm" v-if="data.thisStep==1">
                <v-form>
                    <v-container>
                        <v-row>
                            <v-col cols="4" class="registerinputName">
                                <span >{{ $t('RegisterMessage.registerStep2.userName') }}</span>
                            </v-col>
                            <v-col cols="8" class="registerinputstyle">
                               
                            <p><span>{{data.userName}}</span></p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" class="registerinputName">
                                <span >{{ $t('RegisterMessage.registerStep2.email') }}</span>
                            </v-col>
                            <v-col cols="8" class="registerinputstyle">
                               
                            <p><span>{{data.userEmail}}</span></p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" class="registerinputName">
                                <span >{{ $t('RegisterMessage.registerStep2.password') }}</span>
                            </v-col>
                            <v-col cols="8">
                                <div>
                                    <p><span>******</span></p>
                                    <p><span>※{{ $t('RegisterMessage.registerStep2.passwordMessage') }}</span></p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row style="margin-top: 40px;">
                            <button class="registerViewButton" @click="toRegisterNextStep(0)">{{ $t('RegisterMessage.registerStep2.changeButton') }}</button>
                        </v-row>
                        <v-row class="agressMessage">
                            <div class="agressMessageBox">
                                <v-but class="registerViewButton" @click="toRegisterAws">{{ $t('RegisterMessage.registerStep2.sendButton') }}   ></v-but>
                            </div>
                        </v-row>
                    </v-container>
                </v-form>
             </div>
             <div class="registerForm" v-if="data.thisStep==2">
                <div class="registerSuccessCont">
                    <v-form>
                        <v-container>

                            <v-row>
                                <v-col cols="4" class="registerinputName">
                                    <span >{{ $t('RegisterMessage.registerStep3.certification') }}</span>
                                </v-col>
                                <v-col cols="8">
                                <v-text-field
                                    v-model="data.loginCode"
                                    required
                                    :readonly="data.noAutocomplete"
                                    @focus="data.noAutocomplete=false"
                                ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="agressMessage">
                                <div class="agressMessageBox">
                                    <v-but class="registerViewButton" @click="toRegisterCode">{{ $t('RegisterMessage.registerStep3.confirmationButton') }}</v-but>
                                </div>
                            </v-row>
                        </v-container>
                    </v-form>
                </div>
             </div>
             <div class="registerForm" v-if="data.thisStep==3">
                <div class="registerSuccessCont">
                    <v-form>
                        <v-container>

                            <v-row>
                                <p class="regSuItOne"><span>{{ $t('RegisterMessage.registerStep4.message') }}</span></p>
                            </v-row>
                            <v-row class="agressMessage">
                                <div class="agressMessageBox">
                                    <v-but class="registerViewButton" @click="goTologin()">{{ $t('RegisterMessage.registerStep4.confirmationButton') }}</v-but>
                                </div>
                            </v-row>
                        </v-container>
                    </v-form>
                </div>
             </div>
             
             
            </div>
              <v-row class="mt-10">
                  <the-footer></the-footer>
              </v-row>
          </v-container>
          </v-main>
      </v-layout>
    </v-card>
</template>
  <script lang="ts">
  import { defineComponent,reactive, computed, getCurrentInstance, onMounted, defineProps,defineEmits  } from 'vue'
  import api from '@/api/axios/axios'
  import registerApi from '@/api/axios/registerAxios'
  import TheFooter from './components/TheFooter.vue'
  import { useRouter } from 'vue-router'
  import { signUp, confirmSignUp, signIn } from '../cognito/cognito';
  import { ref } from 'vue';
  import i18n from '../language/index'
  
  export default defineComponent({
      name: 'registerView',
      components: {
          // TheHeader,
          TheFooter,
      },
  });
  </script>
  <script lang="ts" setup>
      const data = reactive({
          showPassword: false,
          showRePassword: false,
          noAutocomplete:true,
          isShow:false,
          userName:'',
          userEmail:'',
          password:'',
          repassword:'',
          thisStep:0,
          loginCode:'',
          errorSnackbar:false,
          errorText:'',
          snackbarSuccess: false,
          successText: ``, 
          selectLanguage: "jp" as 'jp'|'en'|'zh',
          type:[
            {title:i18n.global.messages[i18n.global.locale].languageType.Japanese,val:'jp'},
            {title:i18n.global.messages[i18n.global.locale].languageType.Chinese,val:'zh'},
            {title:i18n.global.messages[i18n.global.locale].languageType.English,val:'en'},
          ]

      })
    const registerForm = ref(null);
    const rules = {
      username: [
        (value:string) => !!value || 'ユーザー名を入力してください。',
      ],
      password: [
        (value:string) => !!value || 'パスワードを入力してください。',
        (value:string) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(value) || '大文字小文字の英字と数字を入力してください。',
      ],
      repassword: [
        (value:string) => value === data.password  || 'パスワード確認を入力してください。',
      ],
      email: [
        (value:string) => !!value || 'メールアドレスを入力してください。',
        (value:string) => /.+@.+\..+/.test(value) || 'ご入力したメールアドレスが不正です。',
      ],
    };
    
    const router = useRouter()
    const toHome = () => {
        router.push("/")
    }
    const goTologin = () => {
        router.push('/login')
    }
    const toRegisterAws = 
    async() => {
         try {
                const user = await signUp(data.userEmail, data.password, data.userEmail);
                console.log('Registration successful:', user);
                data.snackbarSuccess = true
                data.successText = '登録できました'
                data.thisStep = 2
            } catch (error:any) {
                console.error('Registration error:', error);
                data.errorSnackbar = true
                data.errorText = error.message
            }
    }
    const toRegisterCode = 
    async() => {
         try {
                await confirmSignUp(data.userEmail, data.loginCode);
                console.log('Confirmation successful');
                    const registerData = {
                        email:data.userEmail,
                        userName:data.userName,
                    }
                    registerApi.post(`/user/public/register`,registerData)
                    .then((res) => {
                        console.log(res);
                        data.thisStep = 3
                    })
                    .catch((error) => {
                        console.log(error.message);
                    })
            } catch (error) {
                console.error('Confirmation error:', error);
            }
    }

      const toRegisterNextStep = (val:number)=>{
            if(val==0){
                data.thisStep = val
            }else
            if(val==1){
                registerForm.value?.validate()
                .then((valid:string) => {
                    if(valid.valid){
                        if(data.isShow){
                            data.thisStep = val 
                        }else{
                           data.errorSnackbar = true
                           data.errorText = "条約 に同意 願い ます"
                        }         
                    }
                })
            }
      }
    const toSomeMessagePage = () => {
        router.push("/")
    }
    const toSomeMessagePageSecond = () => {
        router.push("/")
    }

    const togglePasswordVisibility = ()=>{
        data.showPassword = !data.showPassword;
    }

    const toggleRePasswordVisibility = ()=>{
        data.showRePassword = !data.showRePassword;
    }

    const emit = defineEmits(['chooselanguage'],)
    const chooselanguage = (type:'jp'|'en'|'zh') =>{
      console.log(type);
      i18n.global.locale = type;
      emit('chooselanguage',type)
      data.type =[
        {title:i18n.global.messages[i18n.global.locale].languageType.Japanese,val:'jp'},
        {title:i18n.global.messages[i18n.global.locale].languageType.Chinese,val:'zh'},
        {title:i18n.global.messages[i18n.global.locale].languageType.English,val:'en'},
      ]
    }
  </script>
  <style>
  .loginTitle{
      font-size: 1.75rem;
  }
  .registerViewTitle{
    max-width: 100%;
    /* background: #dc3545; */
    color: #000;
    border-radius: 0.25rem !important;
    padding-right: 15px;
    padding-left: 15px;
    padding: 10px 15px;
  }
  .registerViewTitle span{
    font-size: 1.75rem;
  }

.registerViewTitleBox{
    /* padding-top: 0.5rem ; */
    padding-bottom: 0.5rem ;
}
.registerContainer {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    background: #e8e4db;
    padding: 8px 15px;
}
.registerStep{
    background: #f8f9fa;
    text-align: center;
    box-shadow: none;
    border-radius: 0;
    min-height: 64px;
    line-height: 64px;
    font-size: 1rem;
    color: #6c757d !important;
}
.registerStepCol{
    padding: 0 15px !important;
}
.registerStepColactive{
    background-color: #28a745;
    color: #fff !important;
}
.registerinputName{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    background: #80cbc4;
    border-bottom: 1px solid #ffffff;
    /* color: #fff; */
}
.registerinputstyle{
        padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
.registerForm{
    padding: 0 15px;
}
.outLineBox{
    border: 5px solid #CCC;
}
.agressMessage{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.registerViewButton{
    color: #28a745;
    border: 1px solid #28a745;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    cursor: pointer;
    /* border-color: #28a745 !important; */
}
.agressMessageBox{
    padding: 0.75rem;
    vertical-align: top;
}
.registerSuccessCont{
    text-align: center;
    padding: 40px 0;
}
.regSuItOne{
    width: 100%;
    color: #dc3545 !important;
    font-size: 2rem;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.regSuItTwo{
    color: #dc3545 !important;
    font-size: 1.5rem;
}
.requiredSpan{
    font-weight: 700;
    color: red;
    display: inline-block;
    margin-right: 10px;
    font-size: 15px;
}
.downFileCompanySeconBut{
    text-decoration: underline;
}
.agressMessageBox .downText{
    cursor: auto;
}
.agressMessageBox .downText:hover{
   color: #157bff; 
}
.SecondFileBut{
    position: relative;
    left: -10px;
}
@media screen and (max-width:784px){
    .agressMessage .v-checkbox-btn .v-label--clickable{
        display: flex;
        flex-direction: column;
    }
}

  </style>
