const jp = {
    languageType:{
        Japanese:'日本語',
        Chinese:'中国語',
        English:'英語'
    },
    // 单位名称
    unitMessage:{
      month:'ヶ月',
    },
    LoginMessage:{
      title:'ログイン',
      registerCard:{
        title:'登録がお済みでない方',
        content:'ログイン登録すると、「お気に入り登録した物件」や「検索履歴」などが、PC、スマホ、アプリで共有できます。',
        button:'新規登録'
      },
      loginCard:{
        title:'ログイン',
        email:'メールアドレス',
        password:'パスワード',
        forgetButton:'パスワードを忘れた方へ',
      },
      forgetMessage:{
        title:'パスワードを再設定する',
        sendButton:'認証コードを送信する',
        certification:'認証コード',
        email:'メールアドレス',
        password:'パスワード',
        repassword:'パスワード確認',
        changeButton:'変更',
        toLoginMessage:{
          message:'アカウントをお持ちの方、',
          button:'ログインへ'
        },     

      },
    },
    RegisterMessage:{
      title1:'新規登録（入力）',
      title2:'新規登録（確認）',
      title3:'新規登録（認証コード）',
      title4:'新規登録（登録完了）',
      registerStep1:{
        title:'ログイン情報入力',
        userName:'ユーザー名',
        userNameHint:'ユーザー名を入力してください',
        email:'メールアドレス',
        emailHint:'メールアドレスを入力してください',
        password:'パスワード',
        passwordHint:'大文字、小文字、数字を 8 文字以上入力してください',
        repassword:'パスワード（確認用）',
        agreementMessage:{
          first:'CLOUDPF規約',
          second:'個人情報の取り扱いについて',
          last:'に同意します。'
        },
        agreeButton:'同意の上「内容確認」へ進む',
      },
      registerStep2:{
        title:'内容確認',
        userName:'ユーザー名',
        email:'メールアドレス',
        password:'パスワード',
        repassword:'パスワード（確認用）',
        passwordMessage:'セキュリティのためパスワードが非表示になっています。',
        changeButton:'入力内容を修正する',
        sendButton:'送信する'
      },
      registerStep3:{
        title:'認証コード入力',
        certification:'認証コード',
        confirmationButton:'確定',
      },
      registerStep4:{
        title:'登録完了',
        message:'登録が完了しました。',
        confirmationButton:'確定',
      },
    },

    messageproprty: {
      roomNo:'部屋番号・階',
      propertyTypeLarge:'種目',
      commission: '賃料',
      managementFee :'管理費等',
      securityDeposit :'敷金',
      keyMoney:'礼金',
      floorPlan :'間取り',
      exclusiveArea :'専用面積',
      buildingDate :'築年月',
      address_other :'階建 / 階',
      propertyAddress :'住所',
      trafficMeans:'交通',
      SupportLanguage:'※サポート言語： 日本語、中国語、英語',
      buttonName:{
        consult:'お問い合わせ',
        FavoritesLogin:'お気に入りにする',
        Favorited:'お気に入り登録済み',
        appointment:'内見予約',
        initialcost:'初期費用見積',
        apply:'申し込み',
        login:'ログイン'
      }
    },
    AppliedPropertyMessage:{
        titleMessage:'申し込み',
        CategorySelection:'申し込み(種別選択)',
        importTitle:'申し込み(入力)',
        notarize:'申し込み(確認)',
        finish: '申し込み(完了)',
        SelectCategoryCont:{
            productName:'物件名',
            rentOrsell:'賃貸・売買',
            productType:'物件タイプ',
            ClassApplicant:'申込者種別',
            TypeOfPayment:'支払い種類',
            CategoryApplication:'申込種別',
            rent:'賃貸',
            sell:'売買',
            residence:'住宅',
            office:'オフィス',
            individual:'個人申込',
            LegalPerson:'法人申込',
            mortgage:'ローン',
            onePayment:'一括支払い'
        },
        importTitleCont:'申し込み物件種別',
        appliedDetailTitle:'申し込み詳細情報',
        buttonDetailTitle:{
            EnterDetails:'詳細情報を入力へ',
            ConfirmNext:'入力内容を確認の上、次へ進む',
            send:'送信する',
            ApplicationList:'申し込み一覧へ',
            ModifyInput:'入力内容を修正する',
            completeTitle:'物件申し込み完了しました!',
            backHomepage:'ホームへ'
        }
    },
    IndexViewMessage:{
        IndexNavList:{
          MyCollection:'お気に入り',
          RecentlyViewed:'最近見た物件',
          consultationService:'お問い合わせ',
          ObjectOfApplication:'申込 物件',
          makeAppointment:'内見予約',
          loanAdministration:'賃貸管理',
          SellOrRent:'売りたい・貸したい',
          simulator:'シミュレーション',
          Search:'物件検索'
        },
        NewGoodsList:{
            title:'最新物件一覧',
        },
        payType:{
          fristType:'賃貸',
          SecondType:'売買'
        }
    },
    theProperty:{
      roomNo:'部屋番号',
      commission: '賃料',
      price:'総額',
      payTypeFrist:'売買',
      payTypeSecond:'賃貸',
      managementFee :'管理費等',
      securityDeposit :'敷金',
      keyMoney:'礼金',
      floorPlan :'間取り',
      exclusiveArea :'専用面積',
      SeeDetails:'詳細を見る',
      ProductPicture:'画像',
      addFavorites:'お気に入り',
      ViewPhotos:'写真充実',
      store:'お気に入りにする',
      storeTrue:'お気に入り登録済み',
    },
    PreviewMessage:{
        title:'内見予約',
        navTitleFrist:'内見予約(入力)',
        navTitleSecond:'内見予約(確認)',
        navTitleThirdly:'内見予約(完了)',
        formTitle:{
          name:'物件名',
          email:'メールアドレス',
          phone:'電話番号',
          dateFrist:'第一希望日時',
          dateSecond:'第二希望日時',
          dateThirdly:'第三希望日時',
          ContactInformation:'連絡事項'
        },
        lastTitle:'内見予約情報を送信しました!'
    },
    InitialCostMessage:{
      title:'初期費用見積',
      rent:'賃料',
      managementFee:'管理費等',
      securityDeposit:'敷金',
      keyMoney:'礼金',
      BackDetail:'詳細画面へ戻る',
      total:'合計'
    },
    previewListView:{
    title:'内見予約一覧',
    previewNumber:'内見番号',
    previewNameTh:"内見名", 
    goodsName:'物件名',
    propertyType:'物件種目',
    state:'ステータス',
    dateFrist:'第一希望日時',
    dateSecond:'第二希望日時',
    dateThirdly:'第三希望日時',
    remark:'備考',
    operation:'操作',
    close:'閉じる',
    timeChange:'内見時間が変わったため',
    otherGoods:'お客様が他の物件で決めたため',
    other:'そのた',
    },
    previewDetailView:{
    title:'内見予約詳細',
    previewNameTh:"案件名",
    email:'メールアドレス',
    phone:'電話番号',
    dateFrist:'第一希望日時',
    dateSecond:'第二希望日時',
    dateThirdly:'第三希望日時',
    requestedApprovalTime:'承認希望日時',
    remark:'備考',
    edit:'編集',
    goback:'内見予約一覧画面へ',
    isShow:' 確認',
    falseBut:'キャンセル',
    propertyDetailBtn:'物件詳細',
    noTopreview:'内見予約をキャンセルする',
    noTopreviewBut:'内見予約をキャンセル',
    },
    appliedPropertyListView:{
      title:'申込物件一覧',
      appliedNo:'申込番号',
      goodName:'物件名',
      state:'状態',
      home:'住所',
      homeNumber:'部屋番号・階',
      propertyType:'物件種目',
      DedicatedArea:'専用面積'
    },
    AppliedPropertyDetail:{
      title:'申込物件詳細',
      goback:'申込物件一覧画面へ',
      subtitleFrist:'進捗状況:',
      subtitle:'現在進捗状況:',
      serve:'保存します',
      subtitleSecond:'申し込み詳細情報',
      subtitleThirdly:'物件詳細情報',
      subtitleFourthly:'業者情報',
      subtitleFristMessage:{
        frist:'資料提出',
        second:'審査',
        third:'契約',
      },
      subtitleSecondMessage:{
        applicationForm:'申込書',
        identificationPaper:'身分証明書',
        GuarantorInformation:'連帯保証人情報',
        studentIDCard:'学生証',
        noticeOfDecision:'内定通知書',
        employmentContract:'雇用契約書',
        ThreeMonthsSalaryList:'給与明細3ヶ月分',
        ThreeMonthsCopyOfPassbook:'預金通帳3ヶ月分のコピー',
        TheBalanceOfTheAccount:'口座の預金残高',
        incumbencyCertification:'在職証明書',
        residentCertificate:'住民票',
        certificate:'印鑑証明書',
        taxCertificate:'納税証明書',
        ResidentCertificateSponsor:'連帯保証人住民票',
        certificateSponsor:'連帯保証人印鑑証明書',
        taxCertificateSponsor:'連帯保証人納税証明書',
        CertificateCompleteResume:'履歴事項全部証明書',
        companyBrochure:'会社パンフレット',
        ASetOfAccounts:'決算書一式',
        proofOfIncome:'所得証明書',
      },
      subtitleThirdlyMessage:{
        goodsId:'物件番号',
        goodsName:'物件名',
        homeNumber:'部屋番号・階',
        ObjectItemLarge:'物件種目（大）',
        ObjectItemSmall:'物件種目',
        buildName:'建物名',
        address:'所在地',
        architecturalStructure:'建物構造',
        date:'築年月',
        vehicle:"交通(手段)",
        TrafficDistance:'交通(距離)',
        DedicatedArea:'専有面積',
        balcony:'バルコニー',
        MainLightSurface:'主要採光面',
        managementFee:'管理費',
        price:'価額',
        rent:'賃料',
        accumulationFund:'積立金',
        keyMoney:'礼金',
        securityDeposit :'敷金',
        earnestMoney:'保証金',
        termOfContract:'契約期間',
        drawing:'図面',
        remark:'備考',
        comment:'コメント'
      },
      subtitleFourthlyMessage:{
        id:'業者ID',
        name:'業者名',
        phoneNumber:'電話番号',
        FixedRestDay:'定休日',
        businessHours:'営業時間'
      },
      customer:{
        customInfo:'顧客詳細情報',
        name:'氏名',
        birth:'生年月日',
        age:'年齢',
        sex:'性別',
        phone:'電話番号',
        email:'メールアドレス',
        monthlySalary:'月給',
        maritalStatus:'婚姻状況',
        nationality:'国籍',
        occupationType:'職種種類',
        kinds:'種別',
        address:'住所',
        tradeName:'商号',
        headOfficeLocation:'本社所在地',
        mainPhone:'代表電話',
        tenantName:'入居者氏名',
        tenantBirth:'生年月日',
        tenantPhone:'携帯電話',
        required:'(必須)',
        selectList:{
          man:'男',
          woman:'女',
          married:'既婚',
          unmarried:'未婚',
          CompanyEmployee:'会社員',
          SelfEmployed:'自営業',
          User:'ユーザー',
          companies:'業者',
        }
      },
      propBox:{
        title:'申込物件をキャンセル',
        boxBtn:'申込物件をキャンセル',
        inputLable:'こちらにキャンセル理由をご入力ください。'
      }
    },
    favoriteListMessage:{
      title:'お気に入り一覧',
      buildingName:'建物名',
      propertyType:'物件種目',
      type:'タイプ',
      // propertyType:{
      //   rental:'賃貸',
      //   buying:'売買',
      // },
      address:'所在地',
      roomNo:'部屋番号',
      exclusiveArea:'専有面積',
      control:'操作',
      buttonName:{
        delete:'削除',
        detail:'物件詳細',
        goHome:'ホームへ'
      },
      deleteMessage:{
        title:'指定お気に入り物件削除確認',
        text:'これお気に入り物件を削除してよろしいでしょうか？',
        confirm:'確認',
        cancel:'キャンセル'
      }
    },
    browsedListMessage:{
      title:'最近見た一覧',
      buildingName:'建物名',
      type:'タイプ',
      propertyType:'物件種目',
      // propertyType:{
      //   rental:'賃貸',
      //   buying:'売買',
      // },
      address:'所在地',
      roomNo:'部屋番号',
      exclusiveArea:'専有面積',
      browsingTime:'閲覧時間',
      buttonName:{
        goHome:'ホームへ'
      }
    },
    PropertyDetail:{
      title:{
        goodsMessage:'物件情報',
        Buysell:'売買のみ',
        RentalDtoData:'入居条件',
        SellingData:'契約条件',
        StatusProperty:'物件状況',
        Others:'その他',
        featureData:'設備',
        FacilitiesData:'特徴',
        companyData:'所属会社情報',
      },
      companyData:{
        companyName:'会社名',
        staffName:'担当者氏名',
        staffEmail:'担当者メールアドレス',
        staffPhone:'担当者電話番号'
      },
      childenClassTitle:{
        relevantProof:'関連証明あり',
        equipmentTitle1:'バス・トイレ・洗面所',
        equipmentTitle2:'収納',
        equipmentTitle3:'設備・機能 ',
        equipmentTitle4:'TV・通信 ',
        equipmentTitle5:'バルコニー・庭 ',
        equipmentTitle6:'キッチン ',
        equipmentTitle7:'家具・家電・照明 ',
        equipmentTitle8:'冷暖房 ',
        equipmentTitle9:'セキュリティー  ',
        equipmentTitle10:'共用施設',
        featureTitle1:'特徴',
        featureTitle2:'工法・構造',
        featureTitle3:'評価・証明書',
      },
      util:{
        util1:'なし',
        util2:'あり',
        util3:'はい',
        util4:'いいえ',
        util5:'可',
        util6:'不可',
      },
      theSlectData:{
        buildingName:'建物名',
        address:'所在地',
        address_other:'詳しい所在地',
        advertisingFeePercent:'広告料',
        allowedNumberOfApplicants: '申込允许人数',
        appeal: 'アピール',
        balconyArea: 'バルコニー',
        buildingDate: "築年月",
        buildingNameOpenStatus: '建物名公开ステータス',
        buildingStructure: "建物構造",
        comment: 'コメント',
        commission: '手数料',
        companyId: "業者ID",
        companyStaffId: "業者スタッフID",
        contractPeriod: '契約期間',
        controlNumber: '管理番号',
        currentSituation: '現況',
        deposit: '積立金',
        drawing: '図面',
        email: 'メールアドレス',
        exclusiveArea: "専有面積",
        floorPlan: "間取り",
        guaranteeDeposit: '保証金',
        inHouseControlNumber: '自社管理番号',
        keyMoney: '礼金',
        landRights: '土地権利',
        locationOpenStatus: '所在地公开ステータス',
        mainLightSurface: '主要採光面',
        managementFee: '管理費',
        modeOfTransaction: '取引態様',
        moveInApplication: '入居申込',
        moveInSelectDueDate: '入居日-予定日選択',
        moveInStatus: '広告料-万円',
        note: '備考',
        preview: '内見',
        price: '価格',
        propertyFileId: "物件_ファイルID",
        propertyId: '物件ID',
        otherTransportation:'その他交通',
        propertyOpenStatus: '物件公开ステータス',
        propertyType: "物件種目",
        propertyTypeLarge: "物件種目（大）",
        propertyTypeSmall: "物件種目（小）",
        propertyViewingId: '内見予約ID',
        roomNo: "部屋番号",
        roomNoOpenStatus: '部屋番号公开ステータス',
        roughEstimate: '概算見積',
        scheduledMoveOutDate: '現況-退去予定日',
        securityDeposit: '敷金',
        totalUnits: '総戸数',
        trafficDistance: '交通(距離)',
        trafficMeans: '交通',
        useStation1: '利用駅１',
        useStation2: '利用駅２',
        workStyleManager: '管理員の勤務形態',
        newUsedStatus:'新築/中古',

      },
      RentalDtoData:{
        bed:'ベッド',
        parking:'駐車場',
        bicycleParking:'駐輪場',
        bikeStorage:'バイク置場',
        parkingFee:'駐車場費',
        bicycleParkingFee:'駐輪場費',
        bikeStorageFee:'バイク置場費',
        commonServiceFee:'共益費',
        companyHousing:'社宅',
        rentType:'借家種類',
        rentContractPeriod:'契約期間',
        corporateContract:'法人契約',
        creditCardPayment:'クレジットカード決済',
        deductionFromRentalDepositMoney:'敷引料金',
        deductionFromRentalDepositMonth:'敷引月',
        diy:'DIY',
        fixedTermLease:'定期借家',
        forPaperwork:'事務処理用',
        foreigner:'外国人',
        freeRent:'フリーレント',
        guaranteeDepositMoney:'保証金料金',
        guaranteeDepositMonth:'保証金月',
        guarantor:'保証人',
        homeInsurance:'住宅保険',
        instrumentConsultation:'楽器相談',
        insuranceEtc:'保険等',
        keyExchangeFee:'鍵交換代',
        keyMoney:'礼金料金',
        keyMonth:'礼金月',
        managementFee:'管理費',
        moveInPeriod:'入居可能時期',
        numberOfFloors:'所在階/階数',
        numberOfOccupants:'入居人数',
        otherLumpSum:'その他一時金',
        others:'その他',
        renewalFeeMoney:'更新料料金',
        renewalFeeMonth:'更新料月',
        renewalFeeType:'更新料タイプ',
        rent:'賃料',
        rentalGuarantee:'賃貸保証',
        roomShare:'ルームシェア',
        roughEstimate:'概算見積',
        securityDepositMoney:'敷金料金',
        securityDepositMonth:'敷金月',
        seniorCitizen:'高齢者',
        sex:'性別',
        smoking:'喫煙',
        sohoUse:'SOHO利用',
        studentsOnly:'学生限定',
      },
      SellingData:{
        parkingFee:'駐車場費',
        bicycleParkingFee:'駐輪場費',
        bikeStorageFee:'バイク置場費',
        bicycleParking:'駐輪場',
        bikeStorage:'バイク置場',
        deposit:'積立金',
        flat35conformityCertificateAvailable:'フラット35適合証明書あり',
        flat35sconformityCertificate:'フラット35Ｓ適合証明書あり',
        grossYield:'表面利回り',
        landRights:'土地権利',
        legalComplianceSurveyReportAvailable:'法適合状況調査報告書あり',
        managementFee:'管理費',
        otherLumpSum:'その他一時金',
        parking:'駐車場',
        pet:'ペット',
        plannedAnnualRentalIncome:'年間予定賃料収入',
        price:'価額',
        rentalGuarantee:'賃貸保証',
        seismicStandardConformityCertificate:'耐震基準適合証明書あり',
        specialNote:'特記',
      },
      featureData:{
        adoptionOfHighSash:'ハイサッシ採用',
        allElectric:'オール電化',
        allRoomsSidesLighting2:'全室2面採光',
        barrierFree:'バリアフリー',
        belsEnergySavingStandardComplianceCertification:'ＢＥＬＳ/省エネ基準適合認定',
        ceilingHeightMore250cm:'天井高２.５ｍ以上',
        constructionMethod24:'２×４工法',
        constructionMethod26:'２×６工法',
        cornerRoom:'角部屋',
        dampingStructure:'制震構造',
        designers:'デザイナーズ',
        doubleFloor:'二重床',
        doubleFloorDoubleCeiling:'二重床・二重天井',
        doubleHeightSpaceAllocation:'吹抜振分',
        exteriorTiled:'外観タイル張り',
        exteriorWallConcrete:'外壁コンクリート',
        exteriorWallSiding:'外壁サイディング',
        externalInsulationMethod:'外断熱工法',
        garbageTakenOutAnyTime:'常時ゴミ出し可能',
        highStrengthConcrete:'高強度コンクリート',
        highlyAirtightHighlyInsulatedHouse:'高気密高断熱住宅',
        interiorConcrete:'内装コンクリート',
        nonFormaldehyde:'ノンホルムアルデヒド',
        outFrameMethod:'アウトフレーム工法',
        propertyCorrespondingToItHeavyTheory:'ＩＴ重説対応物件',
        reverseBeamConstructionMethod:'逆梁工法',
        roadHeating:'ロードヒーティング',
        salesType:'分譲タイプ',
        seismicComplianceCertificate:'耐震基準適合証明書',
        seismicIsolationStructure:'免震構造',
        shareHouse:'シェアハウス',
        sidesLighting2:'2面採光',
        sidesLighting3:'3面採光',
        skeletonInfill:'スケルトンインフィル',
        smartHouse:'スマートハウス',
        suitableTwoHouseholds:'二世帯向き',
        topFloor:'最上階',
        twentyFourHourVentilationSystem:'24時間換気システム',
      },
      FacilitiesData:{
        airConditioner:'エアコン',
        airConditionerBuiltin:'ビルトインエアコン',
        airConditionersTwo:'エアコン2台以上',
        autoLockWithMonitor:'モニター付オートロック',
        automaticBathShower:'オートバス、シャワー',
        balcony:'バルコニー',
        bathSame:'バス（共同）',
        bathToiletSame:'B・T同室',
        bathToiletSeparate:'B・T別',
        bathroomDryer:'浴室乾燥機',
        bathroomHeating:'浴室暖房',
        bathroomTv:'TV付浴室',
        bed:'ベッド',
        bsTerminal:'BS端末',
        burnerStove2:'2口コンロ',
        burnerStove3:'３口以上コンロ',
        busAudio:'バスオーディオ',
        cableBroadcastCs:'有線放送CS',
        catv:'CATV',
        cityGas:'都市ガス',
        cleaningService:'クリーニングサービス',
        closet:'クロゼット',
        coinLaundry:'コインランドリー',
        cooling:'冷房,冷暖房',
        deliveryBox:'宅配BOX',
        dimpleKey:'ディンプルキー',
        doubleLockDoorAutoLock:'ダブルロックドアオートロック',
        electricStove:'電気コンロ',
        elevator:'エレベーター',
        elevatorsTwoMore:'エレベーター2機以上',
        energySavingWaterHeater:'省エネ給湯器',
        ffHeating:'FF暖房',
        floorHeating:'床暖房',
        floto:'フロト',
        freeInternetUsage:'インターネット使用料無料',
        frontService:'フロントサービス',
        garden:'庭',
        gardenOver10:'庭10坪以上',
        gasStovePossible:'カスコンロ可',
        grill:'グリル',
        heating:'暖房',
        highTemperatureDifferenceHotwaterType:'高温差湯式',
        hotWaterSupply:'給湯',
        ihCookingHeater:'IHクッキングヒーター',
        indirectLighting:'間接照明',
        indoorAundryRoom:'室内洗濯置き場',
        indoorClothesline:'室内物干し',
        laundryPlace:'洗濯置き場',
        lightingWithMotionSensor:'人感センサー付照明',
        mmOutletInternetCompatible:'MMコンセントインターネット対応',
        phone:'電話',
        privateGarden:'専用庭',
        propaneGas:'プロパンガス',
        propertyFacilities:'物件_設備',
        refrigerator:'冷蔵庫',
        reheatingFunction:'追焚機能',
        roofBalcony:'ルーフバルコニー',
        sauna:'サウナ',
        saunaMist:'サウナミスト',
        securityGlass:'防犯用ガラス',
        sharedRoomGuest:'共用ゲストルーム',
        sharedRoomParty:'共用パーティールーム',
        sharedRoomTheater:'共用シアタールーム',
        shoesBox:'シューズボックス',
        shoesIc:'シューズIC',
        showerRoom:'シャワールーム',
        shutter:'シャッター雨戸',
        storageAllRoom:'全居室収納',
        storageSpace:'収納スペース,収納',
        sunroomGreenSide:'サンルーム緑側',
        systemKitchen:'システムキッチン',
        threeSideBalcony:'3面バルコニー',
        twoSideBalcony:'２面バルコニー',
        underfloorStorage:'床下収納',
        walkinCloset:'ウォークインクローゼット',
        washToilet:'温水戦場便座',
        washerDryerClothesDryer:'洗濯乾燥機衣類乾燥機',
        wideBalcony:'ワイドバルコニー',
        withGasStove:'ガスコンロ付',
        withHomeAppliances:'家電付',
        withLightingFixtures:'照明器具家具付',
        woodDeckTerrace:'ウッドデッキ、テラス',
      }
    },
    SellingLendingAssessment:{
      assessmentList:'査定依頼一覧',
      title:'査定依頼',
      nameNone:'なし',
      assessmentDetail:'査定依頼詳細画面',
      detailButton:'査定依頼詳細',
      navTitleFrist:'物件情報と顧客情報入力',
      navTitleSecond:'不動産業者を選択',
      navTitleThirdly:'送信完了',
      lastTitle:'不動産業者に査定依頼を送信しました!',
      propertyName:'物件名',
      status:'ステータス',
      required:'(必須)',
      way:{
        title:'依頼方式',
        firstWay:'物件を売りたい',
        secondWay:'物件を貸したい',
      },
      propertyInfo :{
        info:'物件情報',
        type:'物件種類',
        title:'物件タイプ',
        mansion:'マンション',
        individualHouse:'一戸建て',
        land:'土地',
        others:'その他',
        buildingName:'建物名',
        buildingAddress:'物件所在地',
        roomNumber:'部屋番号',
        constructionDate:'築年月',
        floorPlan:'間取り',
        buildingArea:'建物面積',
        landArea:'土地面積',
        price:'価格',
        managementFee:'管理費',
        reserveFund:'積立金',
        landOwnership:'土地権利',
        contractPeriod:'契約期間',
        guaranteeDeposit:'保証金',
        keyMoney:'礼金',
        rentDeposit:'賃料',
        securityDeposit:'敷金'
      },
      buildingStucture:{
        title:'建物構造',
        wood:'木造',
        block:'ブロック造',
        steel:'鉄骨造',
        RC:'RC',
        SRC:'SRC',
        PC:'PC',
        HPC:'HPC',
        ALC:'ALC',
        lightweightSteel:'軽量鉄骨造',
        other:'その他',
      },
      customerInfo:{
        title:'顧客情報',
        ownerName:'名前',
        ownerEmail:'メールアドレス	',
        ownerPhone:'電話番号',
        ownerNote:'備考'
      },
      companyInfo:{
        title:"業者提案情報",
        tableHead:{
          RealEstateAgent:'	不動産業者',
          Status:'ステータス',
          proposal:'提案書',
          SalesContract:'売買契約書',
          ContractDate:'契約日付',
        },
        butList:{
          approval:'承認',
          rejected:'却下',
        },
        porpBoxText:{
          Comment:'コメント',
          Cancel:'キャンセル',
          Confirmed:'確認'
        },
        statusList:{
          InProposal:'提案中',
          Contract:'契約済み',
          elected:'当選',
          Unelected:'非当選'
        }
      },
      userUploadFiles:{
        addBut:'追加',
        title:'ユーザー添付ファイル',
        fileName:'ファイル名',
        comment:'コメント',
        actions:'操作',
      },
      selectEstateAgent:{
        title:'不動産業者選択',
        estateAgent:'不動産業者',
        agent1:'業者1',
        agent2:'業者2',
        agent3:'業者3',
        agent4:'業者4',
        agent5:'業者5',
        agent6:'業者6',
      },
      buttonDetailTitle:{
        selectEstateAgent:'不動産業者選択へ進む',
        send:'査定依頼を送信',
        input:'物件情報と顧客情報入力へ',
        backSellingLendingList:'査定依頼一覧へ',
        newAssessment:'新規作成',
        goHome:'ホームへ',
        addFile:'追加',
        upload:'	アップロード',
        download:'ダウンロード',
        approve:'承認',
        rejected:'却下',
        cancel:'キャンセル',
        delete:'削除'
      },
    },
    ModelEnumMessage:{
      ApplicationStatus:{
        Application:'申込',
        Inspection:'承認済み',
        Contract:'キャンセル',
        Complete:'完了',
      },
      PreviewStatus:{
        Unsettled:'未確定',
        ReApplication:'再申込',
        Confirmed:'確定済み',
        Cancel:'キャンセル'
      }
    },
    Inquiry:{
      InquiryList:{
        title:'お問い合わせ一覧',
        cancelPopupTitle:'問い合わせキャンセル確認',
        cancelPopupMessage:'該当問合せをキャンセルしてよろしいでしょうか？',
        propertyName:'タイトル',
        status:{
          title:'ステータス',
          underInquiry:'問い合わせ中',
          completed:'完了',
          cancelled:'キャンセル'
        },
        address:'住所',
        roomNumber:'部屋番号',
        inquiryContent:'お問い合わせ内容',
        actions:{
          title:'操作',
          cancel:'キャンセル',
          goHome:'ホームへ',
          confirm:'確認'
        }
      },
      InquiryDetail:{
        title:'お問い合わせ詳細',
        toInquiriyListTitle:'問い合わせクローズ確認',
        toInquiriyListContent:'該当問合せをクローズしてよろしいでしょうか？',
        cancelTitle:'問い合わせキャンセル確認',
        cancelContent:'該当問合せをキャンセルしてよろしいでしょうか？',
        notes:'備考',
        name:'お名前',
        mailAddress:'メールアドレス',
        phoneNumber:'電話番号',
        chat:{
          chatHistory:'チャット履歴',
          me:'自分',
        },
        actions:{
          toInquiriyList:'問い合わせ一覧画面へ',
          close:'クローズにする',
          cancel:'キャンセル',
          confirm:'確認',
          send:'送信する'
        }
      }
    },
    RentalManagement:{
      rentalManagementList:'賃貸管理一覧画面',
      propertyName:'物件名',
      propertyInfo:'物件情報',
      owner:'オーナー',
      manager:'担当者',
      roomStatus:'部屋状況',
      roomNumber:'部屋番号',
      address:'住所',
      managementStartDate:'管理開始日付',
      owenerInfoDetail:'オーナー様情報詳細',
      managerInfo:'担当者情報詳細',
      name:'名前',
      katakana:'カタカナ',
      phone:'電話番号',
      mail:'メールアドレス',
      date:'日付',
      title:'タイトル',
      content:'内容',
      file:'添付ファイル',
      reportList:'報告一覧',
      rentalContractList:'契約情報一覧画面',
      rentalContractDetail:'契約情報詳細画面',
      rentalPaymentList:'送金明細一覧画面',
      rentalPaymentDetail:'送金明細詳細画面',
      rentalPropertyReport:'物件巡回報告一覧画面',
      rentalPropertyReportDetail:'物件巡回報告詳細画面',
      rentalTenantReport:'入居者対応報告一覧画面',
      rentalTenantReportDetail:'入居者対応報告詳細画面',
      rentManagementDetail:{
        title:'賃貸管理詳細画面',
        buttons:{
          goToRentalPayment:'送金明細',
          goBackToRentalPayment:'送金明細へ',
          goToTenantReport: '入居者対応報告',
          goBackToTenantReport: '入居者対応報告へ',
          goToPropertyReport:'物件巡回報告',
          goBackToPropertyReport:'物件巡回報告へ',
          goToContract:'契約情報',
          goRentalManagementDetail:'物件詳細へ',
          goBackToContract:'契約情報一覧へ',
          goHome:'ホームへ'
        }
      }
    },
    searchMessage:{
      title:'物件検索',
      area:'地域',
      rent:'家賃',
      buyingPrice:'価格',
      buildingAge:'築年月',
      detailedCondition:'詳細条件',
      station:'駅名',
      button:{
        search:'検索',
        clearCondition:'入力した条件クリア',
        fullImage:'写真充実',
        viewDetails:'詳細を見る'
      },
      checkbox:{
        rantal:'賃貸',
        buying:'売買'
      },
      tableInfo :{
        roomNumber:'部屋番号',
        constructionDate:'築年月',
        floorPlan:'間取り',
        buildingArea:'建物面積',
        landArea:'土地面積',
        price:'価格',
        images:'画像',
        exclusiveArea:'専用面積',
        managementFee:'管理費',
        deposit: '積立金',
        guaranteeDeposit:'保証金',
        keyMoney:'礼金',
        rentDeposit:'賃料',
        securityDeposit:'敷金',
        favorite:'お気に入り'
      },
    },
    InquiryNewMessage:{
      title:'お問い合わせ',
      navtitle:{
        nav1:'お問い合わせ内容入力',
        nav2:'内容確認',
        nav3:'お問い合わせ完了'
      },
      tabletitle:{
        pid:'物件ID',
        controlNumber:'管理番号',
        inquiryUserName:'お名前',
        phone:'電話番号',
        email:'メールアドレス',
        title:'タイトル',
        content:'内容',
        note:'備考',
      },
      overContentText:'お問い合わせ内容を送信しました',
      buttonText:{
        ModifyInput:'入力内容を修正する',
        ConfirmNext:'入力内容を確認の上、次へ進む',
        send:'送信する',
        backHomepage:'ホームへ'
      },
      errText:{
        nullError:'情報が不完全です'
      }
    },
    inquirtySupportView:{
      title:"お問い合わせ",
      concentText:'ご不明な点・ご要望などがありましたら、お気軽にお問い合わせてください。 サポートチームが内容を確認の上、ご連絡いたします。（サポート時間：平日１０時 ~ １７時）',
      overText:'お問い合わせ完了しました。',
      inputTitle:{
        type:"カテゴリー",
        name:"名前",
        phone:'電話番号',
        email:'メールアドレス',
        content:'内容'
      },
      buttomlais:{
        confirm:'確認',
        empty:"リセット"
      },
      typeList:{
        issue:'ご質問',
        FunctionalRequirement:'機能のご要望',
        VulnerabilityReport:"バグ報告",
        systemDevelopment:'システム開発',
        other:'その他'
      }
    },
    theFooter:{
      ContactInformation:'連絡先',
      email:"メールアドレス",
      footerText:'システムに関するお問い合わせ'
    },
    BackButtomList:{
      goHome:'ホームへ',
      goBack:'戻る',
      send:'送信する',
      closing:'閉じる',
      cancle:'空にします',
      confirmed:'確定',
    },
    simulatorView:{
      borrowMuch:'借りたい金額',
      internalBonus:'内ボーナス分の借入金額',
      RepaymentPeriod:'返済期間',
      rate:'金利 (年)',
      repayment:'返済方法',

      CurrentIncome:'現在の年収',
      ReduceRepaymentBurden:'返済負担率',

      MonthlyRepayment:'毎月返済額',

      repaymentList:{
        EqualInterestAndPrincipal:'元利均等',
        EqualPrincipal:'元金均等'
      },
      butTo:'試算する',
      resultBox:{
        title:'シミュレーション結果',
        MonthlyRepayment:'毎月の返済額',
        MonthlyBonusPayments:'ボーナス月の返済額',
        AnnualRepaymentAmount:'年間返済額',
        TotalRepayment:'総返済額',
        TheRepayment:'首月の返済額',
        AmountOfBorrowing:'お借入可能額',
      },
      titleNavList:{
        RequiredAmountOfLoan:'借入希望額',
        AnnualIncome:'年収',
        MonthlyRepayment:'毎月の返済額',
        andOr:'から',
        frist:'月々の返済額を調べる',
        second:'借入可能額を調べる',
      }
    },
    formVerification:{
      date:'日付',
      name:'お名前を入力してください。',
      email:'メールアドレスを入力してください。',
      emailType:'ご入力したメールアドレスが不正です',
      phone:'電話番号を入力してください。',
      phoneType:'正しい電話番号をしてください。',
      title:'タイトルを入力してください。',
      content:'内容を入力してください。',

      dateFrist:'第一希望日時を入力してください。',
      dateFristType:'正しい期日をしてください。',
      dateSecond:'第二希望日時を入力してください。',
      dateThirder:'第三希望日時を入力してください。',
    },
    theHeader:{
      notification:'通知',
      SystemNotification:'システム通知',
      Profile:'プロファイル',
      ChangePassword:'パスワード変更',
      LogOut:'ログアウト'
    },
    PostcodeMessage:{
      confirmed:'郵便番号から住所を調べる',
      value:'郵便番号',
      errorText:'郵便番号は存在'
    },
    PaginationMessage:{
      ItemsPerPage:'表示件数',
    },
    InitialCost:{
      price:'価格',
      deposit:'積立金',
      CommonServiceFee:'共益費',
      ManagementFee:'管理費',
      securityDeposit:'敷金',
      reward:'礼金',
      bail:'保証金',
      depreciation:'敷引',
      lumpSum:'その他一時金'
    },
    detailButton:'詳細',
    
}    

export default jp