<template>
    <v-card>
        <v-layout>
            <the-header @chooselanguage='changeLanguageType'></the-header>
            <v-navigation-drawer width="180">
            </v-navigation-drawer>
            <v-navigation-drawer location="right" width="180">
            </v-navigation-drawer>
            <v-main>
                <v-container class="align-center">
                    <v-row>
                        <v-col cols="12">
                            <v-container class="d-flex justify-space-between">
                                <p class="text-h4">{{ $t('SellingLendingAssessment.assessmentList') }}</p>
                                <!-- button -->
                                <v-btn @click="newSellingLending" color="success" size="large">{{
                                    $t('SellingLendingAssessment.buttonDetailTitle.newAssessment') }}</v-btn>
                            </v-container>
                        </v-col>
                    </v-row>
                    <!-- 査定依頼一覧 -->
                    <v-row no-gutters class="mt-4">
                        <v-col cols="12">
                            <v-table>
                                <thead>
                                    <tr>
                                        <th class="text-left text-base font-weight-bold">
                                            No.
                                        </th>
                                        <th class="text-left text-base font-weight-bold">
                                            {{ $t('SellingLendingAssessment.propertyName') }}
                                        </th>
                                        <th class="text-left text-base font-weight-bold">
                                            {{ $t('SellingLendingAssessment.propertyInfo.title') }}
                                        </th>
                                        <th class="text-left text-base font-weight-bold">
                                            {{ $t('SellingLendingAssessment.status') }}
                                        </th>
                                        <th class="text-left text-base font-weight-bold">
                                            操作
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in data.sellerRequestList" :key="item.sellerRequestId">
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ item.buildingName ? item.buildingName :  $t('SellingLendingAssessment.nameNone') }}
                                        </td>
                                        <td>
                                            <span v-if="item.propertyType == 0">{{ $t('SellingLendingAssessment.propertyInfo.mansion') }}</span>
                                            <span v-if="item.propertyType == 1">{{ $t('SellingLendingAssessment.propertyInfo.individualHouse') }}</span>
                                            <span v-if="item.propertyType == 2">{{ $t('SellingLendingAssessment.propertyInfo.land') }}</span>
                                            <span v-if="item.propertyType == 3">{{ $t('SellingLendingAssessment.propertyInfo.others') }}</span>
                                        </td>
                                        <td>
                                            <div v-if="item.sellerRequestStatus === 1">
                                                {{ ApplicationAssessment.Settlement.text }}
                                            </div>
                                            <div v-if="item.sellerRequestStatus === 2">
                                                {{ ApplicationAssessment.AssessmentResponse.text }}
                                            </div>
                                            <div v-if="item.sellerRequestStatus === 3">
                                                {{ ApplicationAssessment.Agreement.text }}
                                            </div>
                                        </td>
                                        <td>
                                            <a class="a-link" href="#" v-on:click.stop.prevent="goDetail('seller', item.sellerRequestId)">>>{{ $t('SellingLendingAssessment.detailButton') }}</a>
                                        </td>
                                    </tr>
                                    <tr v-for="(item, index) in data.landlordRequestList" :key="item.landlordRequestId">
                                        <td>{{ index + 1 + data.sellerRequestList.length }}</td>
                                        <td>{{ item.buildingName ? item.buildingName : $t('SellingLendingAssessment.nameNone') }}</td>
                                        <td> 
                                            <span v-if="item.propertyType == 0">{{ $t('SellingLendingAssessment.propertyInfo.mansion') }}</span>
                                            <span v-if="item.propertyType == 1">{{ $t('SellingLendingAssessment.propertyInfo.individualHouse') }}</span>
                                            <span v-if="item.propertyType == 2">{{ $t('SellingLendingAssessment.propertyInfo.land') }}</span>
                                            <span v-if="item.propertyType == 3">{{ $t('SellingLendingAssessment.propertyInfo.others') }}</span>
                                        </td>
                                        <td>
                                            <div v-if="item.sellerRequestStatus === 1">
                                                {{ ApplicationAssessment.Settlement.text }}
                                            </div>
                                            <div v-if="item.sellerRequestStatus === 2">
                                                {{ ApplicationAssessment.AssessmentResponse.text }}
                                            </div>
                                            <div v-if="item.sellerRequestStatus === 3">
                                                {{ ApplicationAssessment.Agreement.text }}
                                            </div>
                                        </td>
                                        <td>
                                            <a class="a-link" href="#" v-on:click.stop.prevent="goDetail('seller', item.landlordRequestId)">>>{{ $t('SellingLendingAssessment.detailButton') }}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </v-col>
                    </v-row>
                    <v-row class="home-button-row">
                        <v-btn class="mt-10 home-button" color="success" variant="outlined" @click="goHome">
                            {{ $t('SellingLendingAssessment.buttonDetailTitle.goHome') }}
                        </v-btn>
                    </v-row>
                    <v-row class="mt-10">
                        <the-footer></the-footer>
                    </v-row>
                </v-container>
            </v-main>
        </v-layout>
    </v-card>
</template>
<style>
.table-border {
    border: 1px;
    border-style: solid;
    border-color: antiquewhite;
}
</style>
<script lang="ts">
import { defineComponent, reactive, getCurrentInstance, onMounted } from 'vue'
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import { useRoute, useRouter } from 'vue-router'
// import axios from 'axios'
import api from '@/api/axios/axios'
import i18n from '../language/index'
import { useI18n } from 'vue-i18n'
import { ApplicationAssessment } from '../api/sys/model/enum';
import { SellingLending } from './../api/sys/model/model';

export default defineComponent({
    name: 'SellingLendingList',
    components: {
        TheHeader,
        TheFooter,
    },
});
</script>
  
<script lang="ts" setup>

const data = reactive({
    sellerRequestList: [
      
    ],
    landlordRequestList: [
       
    ],
    sellingLending: {} as SellingLending,
    languageType: 'jp' as string
})

const router = useRouter()
const goHome = () => {
    router.push('/');
}
const newSellingLending = () => {
    router.push('/sellingLendingInput/')
}

const goDetail = (type:string, id:string) => {

    if(type == 'seller') {
        router.push({
            name: 'SellingLendingDetail',
            params: {
                id: id
            },
            query: {
                type: 'seller'
            }
        })
    }else {
        router.push({
            name: 'SellingLendingDetail',
            params: {
                id: id
            },
            query: {
                type: 'landlord'
            }
        })
    }
    
}

onMounted(() => {
    getInitData()

});
const getInitData = () => {
    api.get(`/user/sellerRequest`, {
        params: {
            limit: 100,
            offset: 0
        }
    }).then((res) => {
        data.sellerRequestList = res.data

    }).catch((error) => {
        console.log(error.message)
    });
    api.get( `/user/landlordRequest`, {
        params: {
            limit: 100,
            offset: 0
        }
    }).then((res) => {
        data.landlordRequestList = res.data

    }).catch((error) => {
        console.log(error.message)
    })
};
const changeLanguageType = (val: 'jp' | 'en' | 'zh') => {
    data.languageType = val
}
</script>
  