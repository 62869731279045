<template>
    <v-card>
        <v-layout>
            <the-header @chooselanguage='changeLanguageType'></the-header>
            <v-navigation-drawer width="180">
            </v-navigation-drawer>
            <v-navigation-drawer location="right" width="180">
            </v-navigation-drawer>
            <v-main>
                <v-container class="align-center">
                    <v-row>
                        <v-col cols="12">
                            <p class="text-h4">{{ $t('browsedListMessage.title') }}</p>
                        </v-col>
                    </v-row>
                    <!-- 最近見た一覧 -->
                    <v-row no-gutters class="mt-4">
                        <v-col cols="12">
                            <v-table>
                                <thead>
                                    <tr>
                                        <th class="text-left text-base font-weight-bold">
                                            No.
                                        </th>
                                        <th class="text-left text-base font-weight-bold">
                                            {{ $t('browsedListMessage.buildingName') }}
                                        </th>
                                        <th class="text-left text-base font-weight-bold xsScreen">
                                            {{ $t('browsedListMessage.propertyType') }}
                                        </th>
                                        <th class="text-left text-base font-weight-bold">
                                            {{ $t('browsedListMessage.address') }}
                                        </th>
                                        <th class="text-left text-base font-weight-bold xsScreen">
                                            {{ $t('browsedListMessage.exclusiveArea') }}
                                        </th>
                                        <th class="text-left text-base font-weight-bold xsScreen">
                                            {{ $t('browsedListMessage.browsingTime') }}
                                        </th>
                                        <th class="text-left text-base font-weight-bold">
                                            {{ $t('favoriteListMessage.control') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="data.browsedList.length>0">
                                    <tr v-for="(item, index) in data.propertyInfos" :key="item.browsedId">
                                        <td>
                                            {{ index + 1 }}
                                        </td>
                                        <td> 
                                            <span>{{ item.name }} </span>
                                        </td>
                                        <td class="xsScreen">{{ ItemsSecond(item.propertyTypeSmall, item.propertyType == 3 ? 1 : 2) }}</td>
                                        <td>{{ item.address }}</td>
                                        <td class="xsScreen">{{ item.exclusiveArea }}</td>
                                        <td class="xsScreen">{{ formatDate(item.viewDate) }}</td>
                                        <td>
                                            <span>
                                                <a class="a-link" href="#" @click.stop.prevent="toPropertyDetail(item.propertyId)" v-if="item.propertyDeletedFlag == 0">{{ $t('favoriteListMessage.buttonName.detail') }}</a>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </v-col>
                    </v-row>
                    <v-row class="home-button-row">
                        <v-btn class="mt-10 home-button" color="success" variant="outlined" @click="goHome">
                            {{ $t('browsedListMessage.buttonName.goHome') }}
                        </v-btn>
                    </v-row>
                    <v-row class="mt-10">
                        <the-footer></the-footer>
                    </v-row>
                </v-container>
            </v-main>
        </v-layout>
    </v-card>
</template>
<style>
.table-border {
    border: 1px;
    border-style: solid;
    border-color: antiquewhite;
}
</style>
<script lang="ts">
import { defineComponent, reactive, getCurrentInstance, onMounted, defineProps } from 'vue'
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import { useRoute, useRouter } from 'vue-router'
// import axios from 'axios'
import api from '@/api/axios/axios'
import i18n from '../language/index'
import {ItemsFrist ,ItemsSecond } from "../api/sys/model/speciesList";

export default defineComponent({
    name: 'BrowsedList',
    components: {
        TheHeader,
        TheFooter,
    },
});
</script>
  
<script lang="ts" setup>

const props = defineProps({
    propertyInfo: Object,

})
const data = reactive({
    browsedList: [],
    languageType:'jp' as string,
    propertyInfos: [
        {
            browsedId: "",
            propertyId: "",
            roomNumber: '',
            name: "",
            address: "",
            type: 0,
            exclusiveArea: "",
            viewDate: ""
        },
    ],
})

const changeLanguageType = (val:'jp'|'en'|'zh')=>{
        console.log(i18n.global);
        data.languageType = val
        processingData(data.browsedList)
    }

const router = useRouter()

const toPropertyDetail = (propertyId: string) => {
    router.push({
        name: 'detail',
        query: {
            id: propertyId,
            type: 'browsedList'
        },
    })

}

const goHome = () => {
    router.push('/');
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; 
  const day = date.getDate();
  return `${year}/${String(month)}/${day}`;
}

const processingData = (browsedList: any) => {
    for (let index in browsedList) {
        if (Number(index) > 0) {
            data.propertyInfos[Number(index)] = JSON.parse(JSON.stringify(data.propertyInfos[0]))
        } else if (Number(index) == 0) {
            data.propertyInfos.splice(1);
        }

        data.propertyInfos[Number(index)].browsedId = browsedList[index].userBrowsedId
        data.propertyInfos[Number(index)].propertyId = browsedList[index].propertyId
        data.propertyInfos[Number(index)].exclusiveArea = browsedList[index].exclusiveArea ? browsedList[index].exclusiveArea + ' ㎡' : '未入力'
        data.propertyInfos[Number(index)].type = browsedList[index].propertyType
        data.propertyInfos[Number(index)].viewDate = formatDate(browsedList[index].userBrowsedTime)
        data.propertyInfos[Number(index)].propertyType = browsedList[index].propertyType
        data.propertyInfos[Number(index)].propertyTypeSmall = browsedList[index].propertyTypeSmall
        data.propertyInfos[Number(index)].propertyDeletedFlag = browsedList[index].propertyDeletedFlag

        if (i18n.global.locale == 'zh') {
            data.propertyInfos[Number(index)].name = browsedList[index].buildingNameCn
            data.propertyInfos[Number(index)].address = browsedList[index].addressCn
            data.propertyInfos[Number(index)].roomNumber = browsedList[index].roomNoCn

        } else if (i18n.global.locale == 'en') {
            data.propertyInfos[Number(index)].name = browsedList[index].buildingNameEn
            data.propertyInfos[Number(index)].address = browsedList[index].addressEn
            data.propertyInfos[Number(index)].roomNumber = browsedList[index].roomNoEn

        } else if (i18n.global.locale == 'jp') {
            data.propertyInfos[Number(index)].name = browsedList[index].buildingNameJp
            data.propertyInfos[Number(index)].address = browsedList[index].addressJp
            data.propertyInfos[Number(index)].roomNumber = browsedList[index].roomNoJp

        }
    }


}


onMounted(() => {
    getInitData()
});

const getInitData = () => {
    api.get(`/user/browsed`, {
        params: {
            limit: 100,
            offset: 0
        }
    })
        .then((res) => {
            data.browsedList = res.data
           
            processingData(data.browsedList)
        })
        .catch((error) => {
            console.log(error.message)
        })
};
</script>
