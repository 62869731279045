<template>
    <v-card>
    <v-snackbar
        class="errorSnackbar"
        position="absolute"
        v-model="data.errorSnackbar"
        absolute
        top
        >
        {{ data.errorText }}
    </v-snackbar>
      <v-layout>
          <the-header
          @chooselanguage='changeLanguageType'
          ></the-header>
          <v-navigation-drawer color="surface" width="180" style="margin-top: 20px;">
          </v-navigation-drawer>
          <v-navigation-drawer color="surface" location="right" width="180">
          </v-navigation-drawer>
          <v-main>
          <v-container lass="align-center">
              <v-row>
                  <v-col cols="12" md="10" sm="12">
                      <p class="text-h4">{{ $t('InquiryNewMessage.title') }}</p>
                  </v-col>
                  <v-col cols="12" md="2" sm="12" class="goBackBox">
                      <v-btn  class="mt-10 home-button" color="success" variant="outlined" v-on:click="toBack">
                        {{ $t('BackButtomList.goBack') }}
                      </v-btn>
                  </v-col>
              </v-row>
  
              <v-row no-gutters>
                <v-col class="bg-grey-lighten-1"
                    v-for="(item, index) in data.processTitle" :key="index" cols="12" sm="4">
                    <v-sheet v-if="item.isActive" class="ma-2 pa-2 text-h6 bg-success">
                        {{item.title}}
                    </v-sheet>
                    <v-sheet v-else class="ma-2 pa-2 text-h6 bg-grey-lighten-3">
                        {{item.title}}
                    </v-sheet>
                </v-col>
              </v-row>
  
              <!-- 入力画面 -->
              <v-row no-gutters v-if="isEdit">
                  <v-col cols="12">
                    <v-form ref="formRef">
                        <v-table class="table-border">
                            <tbody>
                                <tr>
                                    <td class="bg-teal-lighten-3" style="width: 30%;"> {{ $t('InquiryNewMessage.tabletitle.pid') }}</td>
                                    <td>{{data.formData.propertyId}}</td>
                                </tr>
                                <tr>
                                    <td class="bg-teal-lighten-3" style="width: 30%;"> {{ $t('InquiryNewMessage.tabletitle.controlNumber') }}</td>
                                    <td>{{data.formData.controlNumber}}</td>
                                </tr>
                                <tr>
                                    <td class="bg-teal-lighten-3">{{ $t('InquiryNewMessage.tabletitle.inquiryUserName') }}</td>
                                    <td>
                                        <v-text-field  class="mt-4"  :rules="rules.name" v-model="data.formData.inquiryUserName"  :label="$t('InquiryNewMessage.tabletitle.inquiryUserName')" required ></v-text-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bg-teal-lighten-3">{{ $t('InquiryNewMessage.tabletitle.phone') }}</td>
                                    <td>
                                        <v-text-field  class="mt-4" :rules="rules.phone" v-model="data.formData.phone"  :label="$t('InquiryNewMessage.tabletitle.phone')"  required ></v-text-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bg-teal-lighten-3">{{ $t('InquiryNewMessage.tabletitle.email') }}</td>
                                    <td>
                                        <v-text-field  class="mt-4" :rules="rules.email" v-model="data.formData.email"  :label="$t('InquiryNewMessage.tabletitle.email')"  placeholder="例: name@example.com"  required ></v-text-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bg-teal-lighten-3">{{ $t('InquiryNewMessage.tabletitle.title') }}</td>
                                    <td>
                                        <v-text-field  class="mt-4" :rules="rules.title" v-model="data.formData.title"  :label="$t('InquiryNewMessage.tabletitle.title')"  required ></v-text-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bg-teal-lighten-3">{{ $t('InquiryNewMessage.tabletitle.content') }}</td>
                                    <td>
                                        <v-textarea class="mt-4" :rules="rules.content" v-model="data.formData.content"  :label="$t('InquiryNewMessage.tabletitle.content')"  required ></v-textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bg-teal-lighten-3">{{ $t('InquiryNewMessage.tabletitle.note') }}</td>
                                    <td>
                                        <v-textarea  class="mt-4" v-model="data.formData.note"  :label="$t('InquiryNewMessage.tabletitle.note')"  required ></v-textarea>
                                    </td>
                                </tr>
                                
                            </tbody>
                        </v-table>
                    </v-form>
                  </v-col>
              </v-row>
              <!-- 内容確認 -->
              <v-row no-gutters v-if="isConfirm">
                  <v-col cols="12">
                  <v-table class="table-border">
                  <tbody>
                    <tr>
                          <td class="bg-teal-lighten-3" style="width: 30%;"> {{ $t('InquiryNewMessage.tabletitle.pid') }}</td>
                          <td>{{data.formData.propertyId}}</td>
                    </tr>
                    <tr>
                          <td class="bg-teal-lighten-3" style="width: 30%;"> {{ $t('InquiryNewMessage.tabletitle.controlNumber') }}</td>
                          <td>{{data.formData.controlNumber}}</td>
                    </tr>
                    <tr>
                        <td class="bg-teal-lighten-3">{{ $t('InquiryNewMessage.tabletitle.inquiryUserName') }}</td>
                        <td>
                            {{data.formData.inquiryUserName}}
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-teal-lighten-3">{{ $t('InquiryNewMessage.tabletitle.phone') }}</td>
                        <td>
                            {{data.formData.phone}}
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-teal-lighten-3">{{ $t('InquiryNewMessage.tabletitle.email') }}</td>
                        <td>
                            {{data.formData.email}}
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-teal-lighten-3">{{ $t('InquiryNewMessage.tabletitle.title') }}</td>
                        <td>
                            {{data.formData.title}}
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-teal-lighten-3">{{ $t('InquiryNewMessage.tabletitle.content') }}</td>
                        <td>
                            {{data.formData.content}}
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-teal-lighten-3">{{ $t('InquiryNewMessage.tabletitle.note') }}</td>
                        <td>
                            {{data.formData.note}}
                        </td>
                    </tr>
                     
                  </tbody>
                  </v-table>
                  </v-col>
              </v-row>
              <!-- 完了 -->
              <v-row no-gutters v-if="isComplete">
                <v-col cols="12" class="text-center mt-8 ">
                    <div class="text-h4">{{ $t('InquiryNewMessage.overContentText') }}!</div>
                </v-col>
            </v-row>
              <v-row no-gutters v-if="isConfirm">
                <v-col cols="12 mt-4 text-left">
                    <v-btn v-if="isConfirm" size="large" class="" v-on:click="toEdit">&lt;{{ $t('InquiryNewMessage.buttonText.ModifyInput') }}</v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12 mt-4 text-center">
                    <v-btn v-if="isEdit" size="large" class="" v-on:click="confirm">{{ $t('InquiryNewMessage.buttonText.ConfirmNext') }}</v-btn>
                    <v-btn v-if="isConfirm"  size="large" class="" v-on:click="send">{{ $t('InquiryNewMessage.buttonText.send') }}</v-btn>
                    <v-btn v-if="isComplete"  size="large" class="" v-on:click="toHome">{{ $t('BackButtomList.goHome') }}</v-btn>
                </v-col>
            </v-row>
              <v-row class="mt-10">
                  <the-footer></the-footer>
              </v-row>
          </v-container>
          </v-main>
      </v-layout>
    </v-card>
  </template>
  <style>
  .table-border {
    border:1px;
    border-style: solid;
    border-color: antiquewhite;
  }
  </style>
  <script lang="ts">
  import TheHeader from './components/TheHeader.vue'
  import { defineComponent,reactive, computed, watchEffect, getCurrentInstance, onMounted, defineProps } from 'vue'
  import TheFooter from './components/TheFooter.vue'
  import { useRoute, useRouter } from 'vue-router'
  import { ref ,  watch} from 'vue';
  import api from '@/api/axios/axios'
  import {Property, Company, Preview, ApplyDetailItem, AppliedPropertyType, FileItem, ProcessTitle, ApplyProperty } from './../api/sys/model/model'
  import i18n from '../language/index'
  export default defineComponent({
      name: 'PropertyDetail',
      components: {
          TheHeader,
          TheFooter,
      },
  });
  </script>
  
  <script lang="ts" setup>
        const data = reactive({
            formData:{
                propertyId:'' as string |undefined,
                controlNumber:'' as string |undefined,
                inquiryUserName:'',
                phone:'',
                email:'',
                title:'',
                content:'',
                note:'',
                userId:JSON.parse(localStorage.getItem('CLOUDPFM_CO__USERINFO')).userId
            },
            processTitle: [
            {title:i18n.global.messages[i18n.global.locale].InquiryNewMessage.navtitle.nav1, isActive: true},
            {title:i18n.global.messages[i18n.global.locale].InquiryNewMessage.navtitle.nav2, isActive: false},
            {title:i18n.global.messages[i18n.global.locale].InquiryNewMessage.navtitle.nav3, isActive: false}
            ],
            errorText:i18n.global.messages[i18n.global.locale].InquiryNewMessage.errText.nullError,
            errorSnackbar:false
        })

    const isEdit = computed(() => { return data.processTitle[0].isActive});
    const isConfirm = computed(() => { return data.processTitle[1].isActive});
    const isComplete = computed(() => { return data.processTitle[2].isActive});
    const router = useRouter()
    const route = useRoute()
    const formRef = ref(null);

    const rules = {
      name:[
        (value:string) => !!value || i18n.global.messages[i18n.global.locale].formVerification.name,
      ],
      email: [
        (value:string) => !!value || i18n.global.messages[i18n.global.locale].formVerification.email,
        (value:string) => /.+@.+\..+/.test(value) || i18n.global.messages[i18n.global.locale].formVerification.emailType,
      ],
      phone:[
        (value:string) => !!value || i18n.global.messages[i18n.global.locale].formVerification.phone,
        (value:string) =>/^[0-9]{7,14}$/.test(value) || i18n.global.messages[i18n.global.locale].formVerification.phoneType,
      ],
      title:[
         (value:string) => !!value || i18n.global.messages[i18n.global.locale].formVerification.title,
      ],
      content:[
        (value:string) => !!value || i18n.global.messages[i18n.global.locale].formVerification.content,
      ]
    };

    onMounted(()=>{
        console.log(route.query);  
        data.formData.propertyId = route.query.pId?.toString();
        data.formData.controlNumber = route.query.controlNumber?.toString();
    })

   
    const toBack = () => {
        router.go(-1)
    }
    const toHome = () => {
        router.push('/')
    }


    const  toEdit= () => {
      data.processTitle[0].isActive = true;
      data.processTitle[1].isActive = false;
      data.processTitle[2].isActive = false;
      
    }

    const confirm = () => {
        formRef.value?.validate()
        .then((valid:string)=>{
            if(valid.valid){
                data.processTitle[0].isActive = false;
                data.processTitle[1].isActive = true;
                data.processTitle[2].isActive = false;
            }
        })
      
    }
    const send = () => {
        api.post(`/user/inquiries`,data.formData)
            .then((res)=>{
                console.log(res);
                data.processTitle[0].isActive = false;
                data.processTitle[1].isActive = false;
                data.processTitle[2].isActive = true;
            })
            .catch((error)=>{
                alert(error)
        })
    }
    const changeLanguageType = (val:'jp'|'en'|'zh')=>{
       console.log(val);
       data.processTitle= [
            {title:i18n.global.messages[i18n.global.locale].InquiryNewMessage.navtitle.nav1, isActive: true},
            {title:i18n.global.messages[i18n.global.locale].InquiryNewMessage.navtitle.nav2, isActive: false},
            {title:i18n.global.messages[i18n.global.locale].InquiryNewMessage.navtitle.nav3, isActive: false}
            ]
    }
        
    
  </script>
  <style>
      .goBackBox .v-btn{
          margin-top: 0 !important;
      }
      .errorSnackbar{
        position: fixed;
        margin-top: 50px;
      }
  </style>
  