<template>
  <v-card>
    <v-snackbar
        class="errorSnackbar"
        position="absolute"
        v-model="data.errorSnackbar"
        absolute
        top
        >
        {{ data.errorText }}
    </v-snackbar>
    <v-layout>
        <the-header
        @chooselanguage='changeLanguageType'
        ></the-header>
        <v-navigation-drawer color="surface" width="180">
        </v-navigation-drawer>
        <v-navigation-drawer color="surface" location="right" width="180">
        </v-navigation-drawer>
        <v-main>
        <v-container lass="align-center">
            <v-row>
                <v-col cols="12" md="10" sm="12">
                    <p class="text-h4">{{ $t('AppliedPropertyMessage.titleMessage') }}</p>
                </v-col>
                <v-col cols="12" md="2" sm="12" class="goBackBox">
                    <v-btn  class="mt-10 home-button" color="success" variant="outlined" @click="goHome">
                        {{ $t('BackButtomList.goBack') }}
                    </v-btn>
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col class="bg-grey-lighten-1"
                    v-for="(item, index) in data.processTitle" :key="index" cols="12" sm="3">
                    <v-sheet v-if="item.isActive" class="ma-2 pa-2 text-h6 bg-success">
                        {{item.title}}
                    </v-sheet>
                    <v-sheet v-else class="ma-2 pa-2 text-h6 bg-grey-lighten-3">
                        {{item.title}}
                    </v-sheet>
                </v-col>
            </v-row>

            <!-- 選択画面 -->
            <v-row no-gutters v-if="isSelect">
                <v-col cols="12">
                <v-table class="table-border">
                <tbody>
                    <tr>
                        <td class="bg-teal-lighten-3" style="width: 30%;">{{ $t('AppliedPropertyMessage.SelectCategoryCont.productName') }}</td>
                        <td>{{ data.property.buildingName}}</td>
                    </tr>
                    <tr>
                        <td class="bg-teal-lighten-3">{{
                            $t('AppliedPropertyMessage.SelectCategoryCont.rentOrsell') }}</td>
                        <td>
                            <v-radio-group inline v-model="data.appliedPropertyType.rentType">
                                <v-radio :label="$t('AppliedPropertyMessage.SelectCategoryCont.rent')"
                                    :value="'1'" disabled></v-radio>
                                <v-radio :label="$t('AppliedPropertyMessage.SelectCategoryCont.sell')"
                                    :value="'2'" disabled></v-radio>
                            </v-radio-group>
                        </td>
                    </tr>
                    <tr v-if="data.appliedPropertyType.rentType == '1'">
                        <td class="bg-teal-lighten-3">{{
                            $t('AppliedPropertyMessage.SelectCategoryCont.productType') }}</td>
                        <td>
                            <v-radio-group inline v-model="data.appliedPropertyType.propertyType" @change="toChangePropertyType">
                                <v-radio :label="$t('AppliedPropertyMessage.SelectCategoryCont.residence')"
                                    :value="1"></v-radio>
                                <v-radio :label="$t('AppliedPropertyMessage.SelectCategoryCont.office')"
                                    :value="2"></v-radio>
                            </v-radio-group>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-teal-lighten-3">{{ $t('AppliedPropertyMessage.SelectCategoryCont.ClassApplicant') }}</td>
                        <td>
                            <v-radio-group inline v-model="data.appliedPropertyType.applyerType" >
                                <v-radio :label="$t('AppliedPropertyMessage.SelectCategoryCont.individual')" 
                                :value="1"
                                :disabled="data.appliedPropertyType.rentType == '1'&& data.appliedPropertyType.propertyType=='2'?true:false"
                                ></v-radio>
                                <v-radio 
                                :label="$t('AppliedPropertyMessage.SelectCategoryCont.LegalPerson')" 
                                :value="2"
                                :disabled="data.appliedPropertyType.rentType == '1'&& data.appliedPropertyType.propertyType=='2'?true:false"
                                ></v-radio>
                            </v-radio-group>
                        </td>
                    </tr>
                    <tr v-if="data.hiddenButton">
                        <td class="bg-teal-lighten-3">{{ $t('AppliedPropertyMessage.SelectCategoryCont.TypeOfPayment') }}</td>
                        <td>
                            <v-radio-group inline v-model="data.appliedPropertyType.paymentType">
                                <v-radio 
                                :label="$t('AppliedPropertyMessage.SelectCategoryCont.mortgage')" 
                                :value="1" 
                                ></v-radio>
                                <v-radio 
                                :label="$t('AppliedPropertyMessage.SelectCategoryCont.onePayment')" 
                                :value="2"
                                ></v-radio>
                            </v-radio-group>
                        </td>
                    </tr>
                </tbody>
                </v-table>
                </v-col>
            </v-row>

            <!-- 入力画面 test-->
            <v-row no-gutters v-if="isInput">
                <v-col cols="12">
                <p class="text-h6 mt-2">◆{{ $t('AppliedPropertyMessage.importTitleCont') }}</p>

                <v-table class="table-border">
                    <tbody>
                        <tr>
                            <td class="bg-teal-lighten-3" style="width: 30%;">{{ $t('AppliedPropertyMessage.SelectCategoryCont.productName') }}</td>
                            <td>{{ data.property.buildingName}}</td>
                        </tr>
                        <tr>
                            <td class="bg-teal-lighten-3">{{ $t('AppliedPropertyMessage.SelectCategoryCont.CategoryApplication') }}</td>
                            <td>
                                {{data.appliedPropertyType.rentType=='1'?$t('AppliedPropertyMessage.SelectCategoryCont.rent'):$t('AppliedPropertyMessage.SelectCategoryCont.sell')}}
                            </td>
                        </tr>
                        <tr>
                            <td class="bg-teal-lighten-3">{{ $t('AppliedPropertyMessage.SelectCategoryCont.ClassApplicant') }}</td>
                            <td>
                                {{data.appliedPropertyType.applyerType=='1'?$t('AppliedPropertyMessage.SelectCategoryCont.individual'):$t('AppliedPropertyMessage.SelectCategoryCont.office')}}
                            </td>
                        </tr>
                        <tr v-if="data.hiddenButton">
                            <td class="bg-teal-lighten-3">{{ $t('AppliedPropertyMessage.SelectCategoryCont.TypeOfPayment') }}</td>
                            <td>
                                {{data.appliedPropertyType.paymentType=='1'?$t('AppliedPropertyMessage.SelectCategoryCont.mortgage'):$t('AppliedPropertyMessage.SelectCategoryCont.onePayment')}}
                            </td>
                        </tr>
                    </tbody>
                </v-table>
                <p class="text-h6 mt-2">◆{{ $t('AppliedPropertyMessage.appliedDetailTitle') }}</p>
                <v-table class="table-border">
                    <tbody>
                        <tr v-for="(item) in data.applicationStepDetail.applicationInputInformationBeanList" :key="item.applicationInputInformationId">
                            <td class="bg-teal-lighten-3" style="width: 30%;">{{item.displayName}}</td>
                            <td>
                                <v-file-input v-if="item.fileFlag&&!item.fileName" clearable v-bind:name="item.displayName" v-bind:label="item.displayName" @change="selectFile" ></v-file-input>
                                <v-text-field v-if="!item.fileFlag" v-model="item.content" class="mt-4" v-bind:name="item.displayName" v-bind:label="item.displayName"  placeholder="" required ></v-text-field>
                                <v-btn
                                    class=" thisButton"
                                    v-if="item.fileFlag&&item.fileName"  
                                    border="none"
                                    text
                                    >
                                        <v-icon left>
                                            mdi-paperclip
                                        </v-icon>
                                         {{item.fileName}}
                                    </v-btn>
                                    <v-btn
                                        v-if="item.fileFlag&&item.fileName" 
                                        class="ma-2 DeleteButtonStyle"
                                        @click="DeleteFile(item)"
                                    >
                                        <v-icon
                                        icon="mdi-delete"
                                        ></v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </v-table>


                <p class="text-h6 mt-2">◆{{$t('AppliedPropertyDetail.customer.customInfo')}}</p>
                <v-form ref="customerForm">
                <v-table class="table-border">

                    <tbody>

                        <!-- 個人-氏名 -->
                        <tr v-if="data.appliedPropertyType.applyerType == '1'">
                            <td class="bg-teal-lighten-3" style="width: 30%;">{{ $t('AppliedPropertyDetail.customer.name') }} {{ $t('AppliedPropertyDetail.customer.required') }}</td>
                            <td>
                                <v-text-field v-model="data.customDataList.name" class="mt-4"                                       
                                    :label="$t('AppliedPropertyDetail.customer.name')"
                                    :rules="rules.name"
                                    >
                                </v-text-field>
                            </td>
                        </tr>
                        <!-- 個人-生年月日 -->
                        <tr v-if="data.appliedPropertyType.applyerType == '1'">
                            <td class="bg-teal-lighten-3" style="width: 30%;">{{ $t('AppliedPropertyDetail.customer.birth') }} {{ $t('AppliedPropertyDetail.customer.required') }}</td>
                            <td>
                                <v-text-field v-model="data.customDataList.birth" class="mt-4"                                       
                                    :label="$t('AppliedPropertyDetail.customer.birth')"
                                    placeholder='2001/01/11'
                                    :rules="rules.birth"
                                    >
                                </v-text-field>
                            </td>
                        </tr>
                        <!-- 個人-年齢 -->
                        <tr v-if="data.appliedPropertyType.applyerType == '1'">
                            <td class="bg-teal-lighten-3" style="width: 30%;">{{ $t('AppliedPropertyDetail.customer.age') }} {{ $t('AppliedPropertyDetail.customer.required') }}</td>
                            <td>
                                <v-text-field v-model="data.customDataList.age" class="mt-4"                                       
                                    :label="$t('AppliedPropertyDetail.customer.age')"
                                    :rules="rules.age"
                                    >
                                </v-text-field>
                            </td>
                        </tr>
                        <!-- 個人-性別 -->
                        <tr v-if="data.appliedPropertyType.applyerType == '1'">
                            <td class="bg-teal-lighten-3" style="width: 30%;">{{ $t('AppliedPropertyDetail.customer.sex') }} {{ $t('AppliedPropertyDetail.customer.required') }}</td>
                            <td>
                                <v-select 
                                    :items="sexList" class="mt-4"
                                    v-model="data.customDataList.sex"
                                    :label="$t('AppliedPropertyDetail.customer.sex')"
                                    :rules="rules.sex"
                                >
                                </v-select>
                            </td>
                        </tr>
                        <!-- 個人-電話番号 -->
                        <tr v-if="data.appliedPropertyType.applyerType == '1'">
                            <td class="bg-teal-lighten-3" style="width: 30%;">{{ $t('AppliedPropertyDetail.customer.phone') }} {{ $t('AppliedPropertyDetail.customer.required') }}</td>
                            <td>
                                <v-text-field v-model="data.customDataList.phone" class="mt-4"                                       
                                    :label="$t('AppliedPropertyDetail.customer.phone')"
                                    :rules="rules.phone"
                                    >
                                </v-text-field>
                            </td>
                        </tr>
                        <!-- 個人-月給 -->
                        <tr v-if="data.appliedPropertyType.applyerType == '1'">
                            <td class="bg-teal-lighten-3" style="width: 30%;">{{ $t('AppliedPropertyDetail.customer.monthlySalary') }}</td>
                            <td>
                                <v-text-field v-model="data.customDataList.monthlySalary" class="mt-4"                                       
                                    :label="$t('AppliedPropertyDetail.customer.monthlySalary')"
                                    >
                                </v-text-field>
                            </td>
                        </tr>
                        <!-- 個人-婚姻状況 -->
                        <tr v-if="data.appliedPropertyType.applyerType == '1'">
                            <td class="bg-teal-lighten-3" style="width: 30%;">{{ $t('AppliedPropertyDetail.customer.maritalStatus') }}</td>
                            <td>
                                <v-select 
                                    :items="maritalStatusList" class="mt-4"
                                    v-model="data.customDataList.maritalStatus"
                                    :label="$t('AppliedPropertyDetail.customer.maritalStatus')"
                                >
                                </v-select>
                            </td>
                        </tr>
                        <!-- 個人-国籍 -->
                        <tr v-if="data.appliedPropertyType.applyerType == '1'">
                            <td class="bg-teal-lighten-3" style="width: 30%;">{{ $t('AppliedPropertyDetail.customer.nationality') }} {{ $t('AppliedPropertyDetail.customer.required') }}</td>
                            <td>
                                <v-select 
                                    :items="nationalityList" class="mt-4"
                                    v-model="data.customDataList.nationality"
                                    :label="$t('AppliedPropertyDetail.customer.nationality')"
                                    :rules="rules.nationality"
                                >
                                </v-select>
                                <v-text-field class="mt-4" 
                                    v-if="data.customDataList['nationality']=='その他'" 
                                    v-model="data.customDataList.nationalityInput"
                                    :rules="rules.nationalityAdress"
                                ></v-text-field>
                            </td>
                        </tr>
                        <!-- 個人-職種種類 -->
                        <tr v-if="data.appliedPropertyType.applyerType == '1'">
                            <td class="bg-teal-lighten-3" style="width: 30%;">{{ $t('AppliedPropertyDetail.customer.occupationType') }}</td>
                            <td>
                                <v-select 
                                    :items="occupationTypeList" class="mt-4"
                                    v-model="data.customDataList.occupationType"
                                    :label="$t('AppliedPropertyDetail.customer.occupationType')"
                                >
                                </v-select>
                            </td>
                        </tr>
                        <!-- 個人-住所 -->
                        <tr v-if="data.appliedPropertyType.applyerType == '1'">
                            <td class="bg-teal-lighten-3" style="width: 30%;">{{ $t('AppliedPropertyDetail.customer.address') }}</td>
                            <td>
                                <v-text-field v-model="data.postcode" class="mt-4"                                       
                                    :label="$t('PostcodeMessage.value')"
                                    >
                                </v-text-field>
                                <v-btn @click="getAddress">{{$t('PostcodeMessage.confirmed')}}</v-btn>
                                <v-text-field v-model="data.customDataList.address" class="mt-4"                                       
                                    :label="$t('AppliedPropertyDetail.customer.address')"
                                    >
                                </v-text-field>
                            </td>
                        </tr>
                        <!-- 法人-商号 -->
                        <tr v-if="data.appliedPropertyType.applyerType == '2'">
                            <td class="bg-teal-lighten-3" style="width: 30%;">{{ $t('AppliedPropertyDetail.customer.tradeName') }} {{ $t('AppliedPropertyDetail.customer.required') }}</td>
                            <td>
                                <v-text-field v-model="data.customDataList.tradeName" class="mt-4"                                       
                                    :label="$t('AppliedPropertyDetail.customer.tradeName')"
                                    :rules="rules.tradeName">
                                </v-text-field>
                            </td>
                        </tr>

                        <!-- 法人-本社所在地 -->
                        <tr v-if="data.appliedPropertyType.applyerType == '2'">
                            <td class="bg-teal-lighten-3" style="width: 30%;">{{ $t('AppliedPropertyDetail.customer.headOfficeLocation') }} {{ $t('AppliedPropertyDetail.customer.required') }}</td>
                            <td>
                                <v-text-field v-model="data.customDataList.headOfficeLocation" class="mt-4"                                       
                                    :label="$t('AppliedPropertyDetail.customer.headOfficeLocation')"
                                    :rules="rules.headOfficeLocation"
                                    >
                                </v-text-field>
                            </td>
                        </tr>
                        <!-- 法人-代表電話 -->
                        <tr v-if="data.appliedPropertyType.applyerType == '2'">
                            <td class="bg-teal-lighten-3" style="width: 30%;">{{ $t('AppliedPropertyDetail.customer.mainPhone') }} {{ $t('AppliedPropertyDetail.customer.required') }}</td>
                            <td>
                                <v-text-field v-model="data.customDataList.mainPhone" class="mt-4"                                       
                                    :label="$t('AppliedPropertyDetail.customer.mainPhone')"
                                    :rules="rules.mainPhone"
                                    >
                                </v-text-field>
                            </td>
                        </tr>
                        <!-- 法人-入居者氏名 -->
                        <tr v-if="data.appliedPropertyType.applyerType == '2'">
                            <td class="bg-teal-lighten-3" style="width: 30%;">{{ $t('AppliedPropertyDetail.customer.tenantName') }}</td>
                            <td>
                                <v-text-field v-model="data.customDataList.tenantName" class="mt-4"                                       
                                    :label="$t('AppliedPropertyDetail.customer.tenantName')"
                                    >
                                </v-text-field>
                            </td>
                        </tr>
                        <!-- 法人-生年月日 -->
                        <tr v-if="data.appliedPropertyType.applyerType == '2'">
                            <td class="bg-teal-lighten-3" style="width: 30%;">{{ $t('AppliedPropertyDetail.customer.tenantBirth') }}</td>
                            <td>
                                <v-text-field v-model="data.customDataList.tenantBirth" class="mt-4"                                       
                                    :label="$t('AppliedPropertyDetail.customer.tenantBirth')"
                                    placeholder='2001/01/11'
                                    :rules="rules.tenantBirth"
                                    >
                                </v-text-field>
                            </td>
                        </tr>
                        <!-- 法人-携帯電話 -->
                        <tr v-if="data.appliedPropertyType.applyerType == '2'">
                            <td class="bg-teal-lighten-3" style="width: 30%;">{{ $t('AppliedPropertyDetail.customer.tenantPhone') }}</td>
                            <td>
                                <v-text-field v-model="data.customDataList.tenantPhone" class="mt-4"                                       
                                    :label="$t('AppliedPropertyDetail.customer.tenantPhone')"
                                    :rules="rules.tenantPhone"
                                    >
                                </v-text-field>
                            </td>
                        </tr>

                    </tbody>
             
                
                </v-table>   
            </v-form>
                </v-col>
            </v-row>

            <!-- 確認画面 -->
            <v-row no-gutters v-if="isConfirm">
                <v-col cols="12">
                    <v-table class="table-border">
                    <tbody>
                        <tr>
                            <td class="bg-teal-lighten-3" style="width: 30%;">{{ $t('AppliedPropertyMessage.SelectCategoryCont.productName') }}</td>
                            <td>{{ data.property.buildingName}}</td>
                        </tr>
                        <tr>
                            <td class="bg-teal-lighten-3">{{ $t('AppliedPropertyMessage.SelectCategoryCont.rentOrsell') }}</td>
                            <td>
                                {{data.appliedPropertyType.rentType=='1'?$t('AppliedPropertyMessage.SelectCategoryCont.rent'):$t('AppliedPropertyMessage.SelectCategoryCont.sell')}}
                            </td>
                        </tr>
                        <tr>
                            <td class="bg-teal-lighten-3">{{ $t('AppliedPropertyMessage.SelectCategoryCont.productType') }}</td>
                            <td>
                                {{data.appliedPropertyType.propertyType=="1"?$t('AppliedPropertyMessage.SelectCategoryCont.residence'):$t('AppliedPropertyMessage.SelectCategoryCont.office')}}
                            </td>
                        </tr>
                        <tr>
                            <td class="bg-teal-lighten-3">{{ $t('AppliedPropertyMessage.SelectCategoryCont.ClassApplicant') }}</td>
                            <td>
                                {{data.appliedPropertyType.applyerType=='1'?$t('AppliedPropertyMessage.SelectCategoryCont.individual'):$t('AppliedPropertyMessage.SelectCategoryCont.office')}}
                            </td>
                        </tr>
                        <tr v-if="data.hiddenButton">
                            <td class="bg-teal-lighten-3">{{ $t('AppliedPropertyMessage.SelectCategoryCont.TypeOfPayment') }}</td>
                            <td>
                                {{data.appliedPropertyType.paymentType=='1'?$t('AppliedPropertyMessage.SelectCategoryCont.mortgage'):$t('AppliedPropertyMessage.SelectCategoryCont.onePayment')}}
                            </td>
                        </tr>
                    </tbody>
                </v-table>
                <p class="text-h6 mt-2">◆{{ $t('AppliedPropertyMessage.appliedDetailTitle') }}</p>
                <v-table class="table-border">
                    <tbody>
                        <tr v-for="(item) in data.applicationStepDetail.applicationInputInformationBeanList" :key="item.applicationInputInformationId">
                            <td class="bg-teal-lighten-3" style="width: 30%;">{{item.displayName}}</td>
                            <td>
                                <p v-if="item.fileFlag">{{item.file?.name}}</p>
                                <p v-else>{{item.content}}</p>
                            </td>
                        </tr>
                    </tbody>
                </v-table>

                <p class="text-h6 mt-2">◆{{$t('AppliedPropertyDetail.customer.customInfo')}}</p>
                <v-table class="table-border">
                    <tbody v-if="data.appliedPropertyType.applyerType == '1'">
                        <tr v-for="(item,index) in data.customerInputList" :key="index">
                            <td class="bg-teal-lighten-3" style="width: 30%;">{{item.name}}</td>
                            <td>
                                {{data.customDataList[item.value]}}
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-if="data.appliedPropertyType.applyerType == '2'">
                        <tr v-for="(item,index) in data.legalPersonInputList" :key="index">
                            <td class="bg-teal-lighten-3" style="width: 30%;">{{item.name}}</td>
                            <td>
                                {{data.customDataList[item.value]}}
                            </td>
                        </tr>
                    </tbody>
                </v-table>   
                </v-col>
            </v-row>



            <!-- 完了画面 -->
            <v-row no-gutters v-if="isComplete">
                <v-col cols="12" class="text-center mt-8 ">
                    <div class="text-h4">{{ $t('AppliedPropertyMessage.buttonDetailTitle.completeTitle') }}</div>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="isConfirm">
                <v-col cols="12 mt-4 text-left">
                    <v-btn v-if="isConfirm" size="large" class="" v-on:click="toInput(1)">&lt;{{ $t('AppliedPropertyMessage.buttonDetailTitle.ModifyInput') }}</v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12 mt-4 text-center">
                    <v-btn v-if="isSelect" size="large" class="" v-on:click="toInput(0)">{{ $t('AppliedPropertyMessage.buttonDetailTitle.EnterDetails') }}</v-btn>
                    <v-btn v-if="isInput" size="large" class="" v-on:click="toConfirm">{{ $t('AppliedPropertyMessage.buttonDetailTitle.ConfirmNext') }}</v-btn>
                    <v-btn v-if="isConfirm"  size="large" class="" v-on:click="toComplete">{{ $t('AppliedPropertyMessage.buttonDetailTitle.send') }}</v-btn>
                    <v-btn v-if="isComplete"  size="large" class="" v-on:click="toList">{{ $t('AppliedPropertyMessage.buttonDetailTitle.ApplicationList') }}</v-btn>
                </v-col>
            </v-row>
            <v-row class="mt-10">
                <the-footer></the-footer>
            </v-row>
        </v-container>
        </v-main>
    </v-layout>
  </v-card>
</template>
<style>
.table-border {
  border:1px;
  border-style: solid;
  border-color: antiquewhite;
}
</style>
<script lang="ts">
import { defineComponent,reactive, computed, watchEffect, getCurrentInstance, onMounted, defineProps ,watch} from 'vue'
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import { useRoute, useRouter } from 'vue-router'
// import axions from 'axios'
import api from '@/api/axios/axios'
import axios from 'axios'
import {Property, Preview, ApplyDetailItem, AppliedPropertyType,ApplicationFlow, Application, FileItem, ProcessTitle , ApplyProperty, ApplicationCreatedResponse, ApplicationStepDetail, ApplicationStepUpdate} from './../api/sys/model/model'
import {CustomerType} from '../api/sys/model/enum';
import i18n from '../language/index'
import {uploadFileToS3} from'@/api/sys/model/upLoadFiileS3'
import { ref } from 'vue';

export default defineComponent({
    name: 'AppliedProperty',
    components: {
        TheHeader,
        TheFooter,
    },
});
</script>

<script lang="ts" setup>
    const router = useRouter()
    const route = useRoute()

    const props = defineProps({
      propertyInfo: Object,

    })

    
    const data = reactive({
        postcode:'',
        nationalityAdress:'',
        birth:'',
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menuOpenThird:false,
        errorSnackbar:false,
        errorText:'',
        hiddenButton:false,
        // 申し込み種類
        appliedPropertyType: { rentType: "", propertyType:"", applyerType: "", paymentType: "" } as AppliedPropertyType,
        applicationFlow: {} as ApplicationFlow,
        applyProperty: {} as ApplyProperty,
        application: {} as Application,
        applicationStepUpdate: {} as ApplicationStepUpdate,

        // 申し込み物件詳細
        property: {} as Property,
        // アプリケーションレスポンス結果
        applicationCreatedResponse: {} as ApplicationCreatedResponse,
        applicationStepDetail: {} as ApplicationStepDetail,

        // 申し込みプロセスタイトル
        processTitle: [{title:i18n.global.messages[i18n.global.locale].AppliedPropertyMessage.CategorySelection, isActive: true},
        {title:i18n.global.messages[i18n.global.locale].AppliedPropertyMessage.importTitle, isActive: false},
        {title:i18n.global.messages[i18n.global.locale].AppliedPropertyMessage.notarize, isActive: false},
        {title:i18n.global.messages[i18n.global.locale].AppliedPropertyMessage.finish, isActive: false}] as ProcessTitle[],

        // 詳細項目
        items: [{name: "userName", displayName: "名前"},
         {name: "userBirthday", displayName: "生年月日"},
         {name: "userAddress", displayName: "住所"},
        ] as ApplyDetailItem[],

        // テンプファイル
        fileItems: [{name: "appliedFile", displayName: "申込書"},
         {name: "identificationFile", displayName: "身分証明書"},
         {name: "certificateOfIncumbency", displayName: "在職証明書"},
        ] as FileItem[],

        customerInputList:[
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.name,type:'input',value:'name',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.birth,type:'input',value:'birth',placeholder:'2001/01/11',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.age,type:'input',value:'age',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.sex,type:'select',value:'sex',item:['指定なし','男','女'],hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.phone,type:'input',value:'phone',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.monthlySalary,type:'input',value:'monthlySalary',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.maritalStatus,type:'select',value:'maritalStatus',item:['未婚','既婚'],hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.nationality,type:'select',value:'nationality',item:['指定なし','日本','中国','韓国','その他'],hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.occupationType,type:'select',value:'occupationType',item:['指定なし','会社員','法人','学生','主婦','無職','個人事業主'],hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.address,type:'input',value:'address',hidden:true},
        ],
        legalPersonInputList:[
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.tradeName,type:'input',value:'tradeName',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.headOfficeLocation,type:'input',value:'headOfficeLocation',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.mainPhone,type:'input',value:'mainPhone',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.tenantName,type:'input',value:'tenantName',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.tenantBirth,type:'input',value:'tenantBirth',placeholder:'2001/01/11',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.tenantPhone,type:'input',value:'tenantPhone',hidden:true},
        ],
        customDataList:{},
    });

    let kindsList=[i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.selectList.User,i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.selectList.companies]
    let occupationTypeList =['指定なし','会社員','法人','学生','主婦','無職','個人事業主']
    let nationalityList = ['国籍未入力','日本国','中国','韓国','その他']
    let sexList = ['指定なし','男','女']
    let maritalStatusList = ['未婚','既婚']
    const toSelect = () => {
      data.processTitle[0].isActive = true;
      data.processTitle[1].isActive = false;
      data.processTitle[2].isActive = false;
      data.processTitle[3].isActive = false;
    }

    const customerForm = ref(null);

    const rules = {
      name: [
        (value:string) => !!value || '氏名を入力してください。',
      ],
      birth: [
      (value:string) => {
            // 检查生年月日是否为空
            if (!value) {
                return '生年月日を入力してください。';
            }

            // 使用正则表达式检查日期格式是否为 yyyy/mm/dd
            const dateRegex = /^\d{4}\/\d{2}\/\d{2}$/;
            if (!dateRegex.test(value)) {
                return '生年月日の形式が正しくありません。正しい形式で入力してください（例：yyyy/mm/dd）。';
            }

            // 验证通过
            return true;
        },
      ],
      sex: [
        (value:string) => !!value || '性别を入力してください。',
      ],
      age: [
        (value:string) => !!value || '年齢を入力してください。',
        (value:string) => /^(?:[1-9]|[1-9][0-9]|1[0-4][0-9]|150)$/.test(value) || 'ご入力したメールアドレスが不正です。',
      ],
      phone: [
        (value:string) => !!value || '電話番号を入力してください。',
        (value:string) =>/^[0-9]{7,14}$/.test(value) || '正しい電話番号をしてください。',
      ],
      nationality: [
        (value:string) => !!value || '国籍を入力してください。',
      ],
      nationalityAdress: [
        (value) => {
            // 如果 nationality 的值为4，确保 nationalityAdress 不为空
            if (data.customDataList['nationality']=='その他') {
                return !!value || '国籍を入力してください。';
            } else {
                return true; // 允许 nationalityAdress 为空
            }
        },
      ],

      tradeName: [
        (value:string) => !!value || '商号を入力してください。',
      ],
      headOfficeLocation: [
        (value:string) => !!value || '本社所在地を入力してください。',
      ],
      mainPhone: [
        (value:string) => !!value || '代表電話を入力してください。',
        (value:string) =>/^[0-9]{7,14}$/.test(value) || '正しい代表電話をしてください。',
      ],
      
      tenantName: [
        (value:string) => !!value || '入居者氏名を入力してください。',
      ],

    tenantBirth: [(value: string) => {
            if (!value) {
                return true; // 允许为空
            }
            return /^\d{4}\/\d{2}\/\d{2}$/.test(value) || '生年月日の形式が正しくありません。正しい形式で入力してください（例：yyyy/mm/dd）。';
        },
      ],
      tenantPhone: [(value: string) => {
            if (!value) {
                return true; // 允许为空
            }
            return /^[0-9]{7,14}$/.test(value) || '正しい携帯電話をしてください。';
        },
      ],
    };

    const toInput = (type:number) => {
        if(type==1){
            data.processTitle[0].isActive = false;
            data.processTitle[1].isActive = true;
            data.processTitle[2].isActive = false;
            data.processTitle[3].isActive = false;
        }
       
        console.log(data.property);
        // 申しこみ新規作成
        data.applyProperty.customerType = CustomerType.User.val
        data.applyProperty.userId = JSON.parse(localStorage.getItem('CLOUDPFM_CO__USERINFO') as string).userId;
        data.applyProperty.companyId = data.property.companyId;
         data.applyProperty.propertyId = route.params.propertyId.toString()
        console.log(data.applyProperty);
        if(!type){
            api.post(`/user/applications` ,  data.applyProperty)
            .then((res) => {
                data.applicationCreatedResponse = res.data

                if(res.data.customerMessage){
                    data.customDataList = res.data.customerMessage
                }
            if(data.appliedPropertyType.applyerType == '1') {
                data.customDataList.sex = sexList[data.customDataList.sex]
                data.customDataList.nationality = nationalityList[data.customDataList.nationality]
                data.customDataList.occupationType = occupationTypeList[data.customDataList.occupationType]
                data.customDataList.maritalStatus = maritalStatusList[Number(data.customDataList.maritalStatus)]
                data.nationalityAdress = data.customDataList.nationalityInput
            }
     
               
                // ステップ１情報を取得
                console.log(data.applicationCreatedResponse);
                
                api.get(`/user/applications/` + data.applicationCreatedResponse.applicationId + `/` +  data.applicationCreatedResponse.applicationFlowId + `/1/detail`)
                .then((res) => {
                    data.applicationStepDetail = res.data
                    data.processTitle[0].isActive = false;
                    data.processTitle[1].isActive = true;
                    data.processTitle[2].isActive = false;
                    data.processTitle[3].isActive = false;
                })
                .catch((error) => {
                    console.log(error.message)
                    data.errorSnackbar = true
                    data.errorText=error.message
                })
            })
            .catch((error) => {
                console.log()
                data.errorSnackbar = true
                data.errorText=JSON.parse(error.request.responseText).message
        })   
        }
   
        
        
    }

    const toConfirm = () => {

        customerForm.value?.validate()
        .then((valid:string)=>{
            if(valid.valid){
                console.log("success");
                data.processTitle[0].isActive = false;
                data.processTitle[1].isActive = false;
                data.processTitle[2].isActive = true;
                data.processTitle[3].isActive = false;
                console.log(data.customDataList);

                
            }else{
                console.log("error");
                
            }
        })
    }

    

    const toComplete = () => {

      data.processTitle[0].isActive = false;
      data.processTitle[1].isActive = false;
      data.processTitle[2].isActive = false;
      data.processTitle[3].isActive = true;
      const targetObjs = data.applicationStepDetail.applicationInputInformationBeanList.map(obj => {
        const newObj = {} as ApplicationStepUpdate
        newObj.applicationId = obj.applicationId
        newObj.applicationFlorId = obj.applicationFlowId
        newObj.applicationStepId = obj.applicationStepId
        newObj.applicationStepItemId  = obj.applicationStepItemId
        newObj.applicationInputInformationId = obj.applicationInputInformationId

        newObj.displayOrder = obj.displayOrder
        newObj.displayName = obj.displayName
        newObj.content = obj.content
        newObj.requiredFlag = obj.requiredFlag
        newObj.fileFlag = obj.fileFlag
        newObj.confirmationFlag = obj.confirmationFlag

        if (obj.file) {
            newObj.fileName = obj.file.name
            newObj.fileContentType = obj.file.type
            newObj.fileSize = obj.file.size
        }
        return newObj;
      })
      console.log(targetObjs);
      if(data.appliedPropertyType.applyerType == '1') {

        data.customDataList.age = Number(data.customDataList.age)
        data.customDataList.monthlySalary = Number(data.customDataList.monthlySalary)
        data.customDataList.kinds = kindsList.indexOf(data.customDataList.kinds)
        data.customDataList.sex = sexList.indexOf(data.customDataList.sex)
        data.customDataList.nationality = nationalityList.indexOf(data.customDataList.nationality)
        data.customDataList.occupationType = occupationTypeList.indexOf(data.customDataList.occupationType)
        data.customDataList.maritalStatus = maritalStatusList.indexOf(data.customDataList.maritalStatus)
        data.customDataList.companyId = data.property.companyId
        data.customDataList.companyStaffId = data.property.companyStaffId
    
    }
      
      watchEffect(() => {
        data.customerInputList=[
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.name,type:'input',value:'name',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.birth,type:'input',value:'birth',placeholder:'2001/01/11',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.age,type:'input',value:'age',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.sex,type:'select',value:'sex',item:['指定なし','男','女'],hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.phone,type:'input',value:'phone',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.monthlySalary,type:'input',value:'monthlySalary',hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.maritalStatus,type:'select',value:'maritalStatus',item:['未婚','既婚'],hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.nationality,type:'select',value:'nationality',item:['指定なし','日本','中国','韓国','その他'],hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.occupationType,type:'select',value:'occupationType',item:['指定なし','会社員','法人','学生','主婦','無職','個人事業主'],hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.kinds,type:'select',value:'kinds',item:[i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.selectList.User,i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.selectList.companies],hidden:true},
            {name:i18n.global.messages[i18n.global.locale].AppliedPropertyDetail.customer.address,type:'input',value:'address',hidden:true},
        ]
      })

      let thisData = {
        applicationInputInformationBeanList:targetObjs,
        customerMessage:data.customDataList
      }
      console.log(targetObjs);
      
      let thisFileTrue = false; 

      for(let index in targetObjs){   
        if(targetObjs[index].fileFlag){
            if(targetObjs[index].fileName){
                let upFileData = {
                    applicationInputInformationId:targetObjs[index].applicationInputInformationId,
                    fileContentType:targetObjs[index].fileContentType,
                    fileName:targetObjs[index].fileName,
                    fileSize:targetObjs[index].fileSize,
                }
                console.log(data.applicationStepDetail.applicationInputInformationBeanList);
                
                api.post(`/user/applications/input/information/files/upload`,upFileData)
                .then((res)=>{
                    console.log(res);
                    targetObjs[index].uploadPresignUrl = res.data.uploadPresignUrl

                    uploadFileToS3(data.applicationStepDetail.applicationInputInformationBeanList[index].file,res.data.uploadPresignUrl)
                    
                    console.log(Number(index));
                    
                   
                    
                })
                .catch((error)=>{
                    console.log(error);
                    
                })
            }
            
        }
        if(Number(index) == targetObjs.length-1){
            thisFileTrue = true
        }
      }
      
      if(thisFileTrue){
          // 申込入力情報詳細更新
        api.put(`/user/applications/input/information`, thisData )
            .then((res) => {
                console.log("sucess")
            })
            .catch((error) => {
                console.log(error.message)
            })
      }

      let statusData = {
            applicationId:thisData.applicationInputInformationBeanList[0].applicationId,
            applicationStatus:1,
            cancelReason:""
        }
        api.put(`/user/applications`,statusData)
        .then((res)=>{
            console.log(res);
        })
        .catch((error)=>{
            console.log(error);
        })
    

    }





    const changeLable = () => {
        console.log(data.appliedPropertyType.rentType);
        if(data.appliedPropertyType.rentType=='2'){
            data.hiddenButton = true
        }else{
            data.hiddenButton = false
        }
        
    }
    const toList = () => {
        router.push('/appliedPropertyList')
    }

    const selectFile = ($event: Event) => {
        const target = $event.target as HTMLInputElement;
        if (target && target.files) {
            const targetFileName = target.files[0].name;
            const fileItem = data.applicationStepDetail.applicationInputInformationBeanList.find(applicationInputInformationBean => applicationInputInformationBean.displayName == target.name);
            
            if(fileItem) {
                fileItem.file =target.files[0];
                fileItem.fileName = fileItem.file.name
                fileItem.content = fileItem.file.name
                fileItem.fileSize = fileItem.file.size.toString()
                fileItem.fileContentType = fileItem.file.type
            }
           console.log(data.applicationStepDetail.applicationInputInformationBeanList);
        }
    }

    const isSelect = computed(() => { return data.processTitle[0].isActive});
    const isInput = computed(() => { return data.processTitle[1].isActive});
    const isConfirm = computed(() => { return data.processTitle[2].isActive});
    const isComplete = computed(() => { return data.processTitle[3].isActive});

    watchEffect(() => {
        data.applyProperty.companyId = data.property.companyId
        data.applyProperty.companyStaffId = data.property.companyStaffId

        data.applyProperty.isPersonal = (data.appliedPropertyType.applyerType == "1")
        data.applyProperty.isCorporate = (data.appliedPropertyType.applyerType == "2")

        data.applyProperty.isHome = (data.appliedPropertyType.propertyType == "1")
        data.applyProperty.isOffice = (data.appliedPropertyType.propertyType == "2")

        data.applyProperty.isRental = (data.appliedPropertyType.rentType == "1")
        data.applyProperty.isSell = (data.appliedPropertyType.rentType == "2")

        data.applyProperty.isLoan = (data.appliedPropertyType.paymentType == "1")
        data.applyProperty.isOnetimePayment = (data.appliedPropertyType.paymentType == "2")
    })

    onMounted(() => {
        getInitData()
    });

    const getInitData  =  () => {
        data.property.propertyId = route.params.propertyId.toString();
        console.log(data.property.propertyId);    
        api.get(`/user/public/property/` + data.property.propertyId)
        .then((res) => {
            data.property = res.data.property

            if (data.property.propertyType == '3') {
                data.appliedPropertyType.rentType = '1'
                data.hiddenButton = false
            } else {
                data.appliedPropertyType.rentType = '2'
                data.hiddenButton = true
            }

           console.log(data.property);    
           if(i18n.global.locale=='zh'){
                data.property.buildingName = res.data.property.buildingNameCn

            }else if(i18n.global.locale=='en'){
                data.property.buildingName = res.data.property.buildingNameEn
               
            }else if(i18n.global.locale=='jp'){
                
                data.property.buildingName = res.data.property.buildingNameJp
            }
        })
        .catch((error) => {
            console.log(error.message)
        })
    };

    const toSave = () => {
        alert("toSave run ");
    };

    const changeLanguageType = (val:'jp'|'en'|'zh')=>{
       console.log(val);
    //    getInitData();
       data. processTitle=[{title:i18n.global.messages[i18n.global.locale].AppliedPropertyMessage.CategorySelection, isActive: true},
        {title:i18n.global.messages[i18n.global.locale].AppliedPropertyMessage.importTitle, isActive: false},
        {title:i18n.global.messages[i18n.global.locale].AppliedPropertyMessage.notarize, isActive: false},
        {title:i18n.global.messages[i18n.global.locale].AppliedPropertyMessage.finish, isActive: false}]
    }
    const DeleteFile = (item:any) => {
        console.log(item);
        item.fileContentType = null
        item.fileName = null
        item.fileSize = null
        item.content = null
        item.file = {}
        console.log(item);
    }
    const goHome = () => {
        router.push(`/detail?id=${route.params.propertyId.toString()}`);
    }
    const toChangePropertyType = () => {
       if(data.appliedPropertyType.rentType == '1'&&data.appliedPropertyType.propertyType=='2'){
            data.appliedPropertyType.applyerType = 2
       }else{
            data.appliedPropertyType.applyerType = ''
       }
        
    }
    const chooseDate = (event:Event) => {
        event.preventDefault();
    }
    const cleanButtonThird = () => {
        data.customDataList['birth'] = ''
        data.customDataList['birth'] = ''
        data.menuOpenThird = false
    }
    watch(
      () => data.birth,
      (newValue: string | null, oldValue: string | null) => {
        console.log(newValue);
        data.customDataList['birth'] = handlePreferenceChange(newValue)
      },
      );
    
    const handlePreferenceChange = (newValue:string) => {
        let preferenceDate;
        if(newValue){
            const date = new Date(newValue);
            const year = date.getFullYear();
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);
            preferenceDate = `${year}/${month}/${day}`;
        }else{
            preferenceDate = ''
        }
        return preferenceDate
    }
const getAddress = () => {
    console.log(data.postcode);
    axios.get(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${data.postcode}`)
    .then((res)=>{
        console.log(res.data.results);
        if(res.data.results){
            data.customDataList.address=  res.data.results[0].address1+" "+res.data.results[0].address2+" "+res.data.results[0].address3
        }else{
            data.errorSnackbar = true
            data.errorText = i18n.global.messages[i18n.global.locale].PostcodeMessage.errorText
        }
    })
    .catch((error)=>{
        console.log(error);
    })
}
</script>
<style scoped>
.DeleteButtonStyle{
    color: #989898;
    box-shadow: none;
}
</style>
