import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        (_openBlock(), _createBlock(_KeepAlive, null, [
          (_ctx.$route.meta.keepAlive)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                key: _ctx.$route.path
              }))
            : _createCommentVNode("", true)
        ], 1024)),
        (!_ctx.$route.meta.keepAlive)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
              key: _ctx.$route.path
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}