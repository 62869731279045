import { createI18n } from "vue-i18n";
import en from './en'
import cn from './cn'
import jp from './jp'
const messages = {
  en: en,
  zh: cn,
  jp:jp
}
const i18n = createI18n({
  locale: 'jp',
  messages,
})

export default i18n
