<template>
  <v-container>
    <v-row class="mt-10">
      <v-col cols="2" class="mt-2">
        <v-select
          v-model="data.limit"
          :items="data.perPageOptions"
          :hide-details="true"
          :label="$t('PaginationMessage.ItemsPerPage')"
          @update:modelValue="handleSelectChange"
        ></v-select>
      </v-col>
      <v-col cols="16" class="mt-2">
        <v-pagination
          v-model="data.page"
          :length="totalPages"
          total-visible="9"
          @update:modelValue="handlePageChange"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  defineProps,
  reactive,
  defineEmits
} from "vue";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "ThePagination",
});

</script>

<script lang="ts" setup>
    const router = useRouter();
    const route = useRoute();
    const emit = defineEmits(['update:limit','update:page','pagination']);

    const props = defineProps({
        limit: {
            type: Number,
            default: 10
        },
        page: {
            type: Number,
            default: 1
        },
        totalItems: {
            type: Number,
            default: 10
        },
        perPageOptions: {
            type: Array,
            default: () => [5, 10, 15, 20],
        },
    })

    const data = reactive({
      limit: props.limit,
      perPageOptions: props.perPageOptions,
      page: props.page,
      totalItems: props.totalItems,    
    });

    const handleSelectChange = (value) => {
      // 处理 v-select 组件值的变化
      if((data.page ? data.page : 1) > totalPages.value) {
        data.page = totalPages.value;
      }
      // 更新本地状态
      data.limit = value;
        // 通知父组件进行状态更改
      emit("update:limit", value);
      emit("pagination", { page: data.page, limit: data.limit });
    };

    const handlePageChange = (value) => {
      // 处理 v-pagination 组件值的变化
      // 通知父组件进行状态更改
      emit("update:page", value);
      emit("pagination", { page: data.page, limit: data.limit });
    };

    const totalPages = computed(() => { 
    console.log('totalPages'+ (data.totalItems && data.limit ? Math.ceil(data.totalItems / data.limit) : 0))
    return data.totalItems && data.limit ? Math.ceil(data.totalItems / data.limit) : 0;
    });
    

</script>

