export function downloadFile(url: string, filename: string): void {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = filename;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        a.remove();
        URL.revokeObjectURL(downloadUrl);
      })
      .catch(error => {
        console.error('ファイルのダウンロードに失敗します:', error);
      });
  }