import config from '@/config/index';
import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
    CognitoUserAttribute
  } from 'amazon-cognito-identity-js';
  console.log(config);
  const poolData = {
    UserPoolId: config.UserPoolId,
    ClientId: config.ClientId
  };
  
  
  const userPool = new CognitoUserPool(poolData);
  
  export const signUp = async (username: string, password: string, email: string): Promise<CognitoUser> => {
    const attributeList: CognitoUserAttribute[] = [];
  
    const dataEmail = {
      Name: 'email',
      Value: email
    };
  
    const emailAttribute = new CognitoUserAttribute(dataEmail);
  
    attributeList.push(emailAttribute);
  
    return new Promise((resolve, reject) => {
      userPool.signUp(username, password, attributeList, [], (err:any, result:any) => {
        if (err) {
          reject(err);
          return;
        }
  
        resolve(result.user);
      });
    });
  };
  
  export const confirmSignUp = async (username: string, confirmationCode: string): Promise<unknown> => {
    const userData = {
      Username: username,
      Pool: userPool
    };
  
    const cognitoUser = new CognitoUser(userData);
  
    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
        if (err) {
          reject(err);
          return;
        }
  
        resolve(result);
      });
    });
     
  };
  
  export const signIn = async (username: string, password: string): Promise<string> => {
    const authenticationData = {
      Username: username,
      Password: password
    };
  
    const authenticationDetails = new AuthenticationDetails(authenticationData);
  
    const userData = {
      Username: username,
      Pool: userPool
    };
  
    const cognitoUser = new CognitoUser(userData);
  
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          // const accessToken = result.getAccessToken().getJwtToken();
          const accessToken = result.getIdToken().getJwtToken();
          // console.log(accessToken);
          

          resolve(accessToken);
        },
        onFailure: (err) => {
          reject(err);
        }
      });
    });
  };

  export const forgotPassword = async (username: string): Promise<void> => {
    const userData = {
      Username: username,
      Pool: userPool
    };
  
    const cognitoUser = new CognitoUser(userData);
  
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: () => {
          resolve();
        },
        onFailure: (err) => {
          reject(err);
        }
      });
    });
  };
  
  export const resetPassword = async (username: string, verificationCode: string, newPassword: string): Promise<void> => {
    const userData = {
      Username: username,
      Pool: userPool
    };
  
    const cognitoUser = new CognitoUser(userData);
  
    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: () => {
          resolve();
        },
        onFailure: (err) => {
          reject(err);
        }
      });
    });
  };

export const changePassword = (username: string, currentPassword: string, newPassword: string) => {
    
    
    // 创建Cognito用户对象
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool, 
    });

    // 构建身份验证详细信息对象
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: currentPassword,
    });
    return new Promise((resolve, reject) => {
      // 调用Cognito用户对象的authenticateUser方法进行身份验证
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
            // 修改密码
            cognitoUser.changePassword(currentPassword, newPassword, (err, result) => {
            if (err) {
                console.error('无法修改密码', err);
                resolve("无法修改密码");
            } else {
                console.log("修改成功");  
                resolve("修改成功");
            }
            });
        },
        onFailure: (err) => {
            reject(err);
        },
      });
  });
};
  