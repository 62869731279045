<template>
  <v-card>
    <v-layout>
        <the-header></the-header>
        <v-navigation-drawer width="180">
        </v-navigation-drawer>
        <v-navigation-drawer location="right" width="180">
        </v-navigation-drawer>
        <v-main>
        <v-container class="align-center">
            <v-row>
                <v-col cols="12" md="10" sm="12">
                    <p class="text-h4">{{ $t('appliedPropertyListView.title') }}</p>
                </v-col>
                <v-col cols="12" md="2" sm="12" class="goBackBox">
                    <v-btn  class="mt-10 home-button" color="success" variant="outlined" @click="goHome">
                        {{ $t('Inquiry.InquiryList.actions.goHome') }}
                    </v-btn>
                </v-col>
            </v-row>
            <!-- 一覧画面 -->
            <v-row no-gutters>
                <v-col cols="12">
                <v-table>
                <thead>
                    <tr>
                        <th class="text-left text-h6">
                        No.
                        </th>
                        <th class="text-left text-h6">
                        {{ $t('appliedPropertyListView.goodName') }}
                        </th>
                        <th class="text-left text-h6">
                        {{ $t('appliedPropertyListView.state') }}
                        </th>
                        <th class="text-left text-h6 xsScreen">
                        {{ $t('appliedPropertyListView.propertyType') }}
                        </th>
                        <th class="text-left text-h6 xsScreen">
                        {{ $t('appliedPropertyListView.home') }}
                        </th>
                        <th class="text-left text-h6 screenSmall">
                        {{ $t('appliedPropertyListView.DedicatedArea') }}
                        </th>
                        <th class="text-left text-h6 xsScreen">
                        {{ $t('previewListView.operation') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in data.applyProperty" :key="item.applicationNumber">
                        <td> 
                            <span>{{ index + 1 }}</span>    
                        </td>
                        <td >{{ item.buildingNameJp }}</td>
                        <td class="">
                            <div  v-if="item.applicationStatus == 1">
                                {{ $t(ApplicationStatus.Application.text) }}
                            </div>
                            <div v-if="item.applicationStatus == 2">
                                {{ $t(ApplicationStatus.Inspection.text) }}
                            </div>
                            <div v-if="item.applicationStatus == 3">
                                {{ $t(ApplicationStatus.Contract.text) }}
                            </div>
                            <div v-if="item.applicationStatus == 4">
                                {{ $t(ApplicationStatus.Complete.text) }}
                            </div>
                        </td>
                        <td class="xsScreen" >{{ ItemsSecond(item.propertyTypeSmall, item.propertyType == 3 ? 1 : 2) }}</td>
                        <td class="xsScreen" >{{ item.addressJp }}</td>
                        <td class="screenSmall" >{{ item.exclusiveArea ? item.exclusiveArea + ' ㎡' : '未入力' }}</td>
                        <td class="xsScreen" >
                            <span>
                                <a class="a-link" href="#" @click.stop.prevent="selectAppliedProperty(item.applicationId)" v-if="item.propertyDeletedFlag != 1">{{ $t('detailButton') }}</a>
                            </span>

                            <span>&ensp; </span>
                            <span>
                                <a class="a-link" href="#" @click.stop.prevent="data.dialog=true,data.clickData=item" v-if="(item.applicationStatus!=3 && item.applicationStatus!=4) && item.propertyDeletedFlag != 1">{{ $t('previewDetailView.falseBut') }}</a>
                            </span>
                            <v-dialog max-width="70%"   v-model="data.dialog" class="custom-dialog">
                                <v-card>
                                    <v-card-title>{{$t('AppliedPropertyDetail.propBox.title')}}</v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text style="height: 200px;">
                                        <v-textarea  v-model="data.cleanText" :label="$t('AppliedPropertyDetail.propBox.inputLable')"></v-textarea>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                    <v-btn variant="text" @click="data.dialog = false">
                                        {{$t('previewListView.close')}}
                                    </v-btn>
                                    <v-btn variant="text" @click="cancelButton(data.clickData)">
                                        {{$t('AppliedPropertyDetail.propBox.boxBtn')}}
                                    </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </td>
                    </tr>
                </tbody>
                </v-table>
                </v-col> 
            </v-row>

            <v-row class="mt-10">
                <the-footer></the-footer>
            </v-row>
        </v-container>
        </v-main>
    </v-layout>
  </v-card>
</template>
<style>
.table-border {
  border:1px;
  border-style: solid;
  border-color: antiquewhite;
}
</style>
<script lang="ts">
import { defineComponent,reactive, computed, getCurrentInstance, onMounted, defineProps ,watch } from 'vue'
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import { useRoute, useRouter } from 'vue-router'
import api from '@/api/axios/axios'
import {Property, Company, Preview, ApplyDetailItem, AppliedPropertyType, FileItem, ProcessTitle, ApplyProperty, ApplicationCreatedResponse } from './../api/sys/model/model'
import {ApplicationStatus} from '../api/sys/model/enum';
import {ItemsFrist ,ItemsSecond } from "../api/sys/model/speciesList";

export default defineComponent({
    name: 'AppliedPropertyList',
    components: {
        TheHeader,
        TheFooter,
    },
});
</script>

<script lang="ts" setup>

    const props = defineProps({
      propertyInfo: Object,

    })
    const data = reactive({
        cleanText:'',
        clickData:{},
        dialogm1: '',
        dialog: false,
        applyProperty: {} as ApplyProperty[]
    });

    const router = useRouter()

    const cancelPriview = (propertyViewingId : string) => {
        const modify = {status: "1"}
        api.put(`/user/previews/` + propertyViewingId, modify)
        .then(res => {
            data.dialog = false;
            // router.push('/previewList')
        })
        .catch(error => {
            alert("キャンセル失敗しました。");
            console.log(error, propertyViewingId);
        });
    }
    // 遷移画面から取得パラメーター
     const selectAppliedProperty = (appliedPropertyId : string ) => {
        router.push('/appliedPropertyDetail/' + appliedPropertyId)
     }

     onMounted(() => {
        getInitData()
    });

    const getInitData  =  () => {
        api.get(`/user/applications`)
        .then((res) => {
            data.applyProperty = res.data;
        })
        .catch((error) => {
            console.log(error.message);
        })
    };
    const goHome = () => {
        router.push(`/`);
    }
    watch(() => data.dialog, (newValue) => {
      if (newValue) {
        data.cleanText = '';
      }
    });
    const cancelButton = (item:object) => {
        console.log(item);
        let thisData = {
            applicationId:item.applicationId,
            applicationStatus:3,
            cancelReason:data.cleanText
        }
        api.put(`/user/applications`,thisData)
        .then((res)=>{
            console.log(res);
            data.dialog = false
            getInitData()
        })
        .catch((error)=>{
            console.log(error);
        })
    }
</script>
<style scoped>
    @media screen and (max-width:784px){
      .xsScreen{
        display: none;
      }
    }
    @media screen and (max-width:1520px){
      .screenSmall{
        display: none;
      }
    }
</style>
