import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import AboutView from '@/views/AboutView.vue'
import IndexView from '@/views/IndexView.vue'
import PropertyDetail from '@/views/PropertyDetail.vue'
import Preview from '@/views/Preview.vue'
import PreviewList from '@/views/PreviewList.vue'
import PreviewDetail from '@/views/PreviewDetail.vue'
import AppliedPropertyList from '@/views/AppliedPropertyList.vue'
import AppliedPropertyDetail from '@/views/AppliedPropertyDetail.vue'
import AppliedProperty from '@/views/AppliedProperty.vue'
import RentalManagement from '@/views/RentalManagement.vue'
import RentalManagementDetail from '@/views/RentalManagementDetail.vue'
import RentalPayment from '@/views/RentalPayment.vue'
import RentalPaymentDetail from '@/views/RentalPaymentDetail.vue'
import RentalTenantReport from '@/views/RentalTenantReport.vue' 
import RentalPropertyReport from '@/views/RentalPropertyReport.vue'
import RentalContract from '@/views/RentalContract.vue'
import RentalContractDetail from '@/views/RentalContractDetail.vue'
import LoginView from '@/views/LoginView.vue'
import RegisterView from '@/views/RegisterView.vue'
import ChangeUserInfo from '@/views/ChangeUserInfo.vue'
import InquiryList from '@/views/InquiryList.vue'
import InquiryDetail from '@/views/InquiryDetail.vue'
import FavoriteList from '@/views/FavoriteList.vue'
import SellingLendingList from '@/views/SellingLendingList.vue'
import SellingLendingDetail from '@/views/SellingLendingDetail.vue'
import InitialCostView from '@/views/InitialCostView.vue'
import BrowsedList from '@/views/BrowsedList.vue'
import SellingLendingInput from '@/views/SellingLendingInput.vue'
import PropertySearch from '@/views/PropertySearch.vue'
import InquiryNew from '@/views/InquiryNew.vue'
import rentalTenantReportDetail from '@/views/RentalTenantReportDetail.vue'
import rentalPropertyReportDetail from '@/views/RentalPropertyReportDetail.vue'
import InquirtySupport from '@/views/InquirtySupport.vue'
import SimulatorView from '@/views/SimulatorView.vue'
import CompanyView from '@/views/CompanyView.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: IndexView,
    meta: { keepAlive: true },
  },
  {
    path: '/login',
    name: 'loginView',
    component: LoginView
  },
  {
    path: '/register',
    name: 'registerView',
    component: RegisterView
  },
  {
    path: '/ChangeUserInfo',
    name: 'ChangeUserInfo',
    component: ChangeUserInfo
  },
  {
    path: '/InitialCost',
    name: 'InitialCostView',
    component: InitialCostView,
    meta: { requiresAuth: true },

  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/detail',
    name: 'detail',
    component: PropertyDetail,
    meta: { keepAlive: false },
  },
  {
    path: '/preview/:actionType/:propertyOrPreviewId',
    name: 'preview',
    component: Preview,
    meta: { requiresAuth: true },
  },
  {
    path: '/inquiry',
    name: 'inquiryView',
    component: InquiryNew,
    meta: { requiresAuth: true },
  },
  {
    path: '/previewList',
    name: 'previewList',
    component: PreviewList,
    meta: { requiresAuth: true },
  },
  {
    path: '/previewDetail/:propertyViewingId',
    name: 'previewDetail',
    component: PreviewDetail,
    meta: { requiresAuth: true },
  },
  {
    path: '/appliedPropertyList',
    name: 'appliedPropertyList',
    component: AppliedPropertyList,
    meta: { requiresAuth: true },
  },
  {
    path: '/appliedPropertyDetail/:appliedPropertyId',
    name: 'appliedPropertyDetail',
    component: AppliedPropertyDetail,
    meta: { requiresAuth: true },
  },
  {
    path: '/appliedProperty/:propertyId',
    name: 'appliedProperty',
    component: AppliedProperty,
    meta: { requiresAuth: true },
  },
  {
    path: '/rentalManagement',
    name: 'rentalManagement',
    component: RentalManagement,
    meta: { requiresAuth: true },
  },
  {
    path: '/rentalManagement/:rentalManagementId',
    name: 'RentalManagementDetail', 
    component: RentalManagementDetail,
    meta: { requiresAuth: true },
  },
  {
    path: '/rentalPayment/:rentalManagementId',
    name: 'RentalPayment', 
    component: RentalPayment,
    meta: { requiresAuth: true },
  },
  {
    path: '/rentalPaymentDetail/:rentalManagementPaymentDetailId',
    name: 'RentalPaymentDetail', 
    component: RentalPaymentDetail,
    meta: { requiresAuth: true },
  },
  {
    path: '/rentalTenantReport/:rentalManagementId',
    name: 'RentalTenantReport', 
    component: RentalTenantReport,
    meta: { requiresAuth: true },
  },
  {
    path: '/rentalTenantReportDetail/:rentalManagementId',
    name: 'rentalTenantReportDetail', 
    component: rentalTenantReportDetail,
    meta: { requiresAuth: true },
  },
  {
    path: '/rentalPropertyReport/:rentalManagementId',
    name: 'RentalPropertyReport', 
    component: RentalPropertyReport,
    meta: { requiresAuth: true },
  },
  {
    path: '/rentalPropertyReportDetail/:rentalManagementId',
    name: 'rentalPropertyReportDetail', 
    component: rentalPropertyReportDetail,
    meta: { requiresAuth: true },
  },
  {
    path: '/rentalContract/:rentalManagementId',
    name: 'RentalContract', 
    component: RentalContract,
    meta: { requiresAuth: true },
  },
  {
    path: '/rentalContractDetail/:rentalManagementContractInformationId',
    name: 'RentalContractDetail', 
    component: RentalContractDetail,
    meta: { requiresAuth: true },
  },
  {
    path: '/inquiryList/',
    name: 'InquiryList', 
    component: InquiryList,
    meta: { requiresAuth: true },
  },
  {
    path: '/inquiryDetail/:inquiryId',
    name: 'InquiryDetail', 
    component: InquiryDetail,
    meta: { requiresAuth: true },
  },  
  {
    path: '/favoriteList/',
    name: 'FavoriteList', 
    component: FavoriteList,
    meta: { requiresAuth: true },
  },
  {
    path: '/browsedList/',
    name: 'BrowsedList', 
    component: BrowsedList,
    meta: { requiresAuth: true },
  },
  {
    path: '/sellingLendingList/',
    name: 'SellingLendingList', 
    component: SellingLendingList,
    meta: { requiresAuth: true },
  },
  {
    path: '/sellingLendingInput/',
    name: 'SellingLendingInput', 
    component: SellingLendingInput,
    meta: { requiresAuth: true },
  },
  {
    path: '/sellingLendingDetail/:id',
    name: 'SellingLendingDetail', 
    component: SellingLendingDetail,
    meta: { requiresAuth: true },
  },
  {
    path: '/inquirtySupport',
    name: 'InquirtySupport', 
    component: InquirtySupport,
    meta: { requiresAuth: true },
  },
  {
    path: '/search/',
    name: 'PropertySearch', 
    component: PropertySearch,
    meta: { keepAlive: true },
  },
  {
    path: '/simulator',
    name: 'SimulatorView', 
    component: SimulatorView
  },
  {
    path: '/companyView',
    name: 'CompanyView', 
    component: CompanyView
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
})

// 路由守卫
router.beforeEach((to, from, next) => {

  const requiresAuth = to.meta.requiresAuth; 

  if (requiresAuth && !localStorage.getItem('CLOUDPFM_CO__REFRESH__')) {
    
    next('/login'); 

  } else {

    next();
    
  }
});

export default router;
